import * as tslib_1 from "tslib";
import { map, take } from 'rxjs/operators';
import { ContentAPIService } from '../../apiServices/contentAPIService';
import { ArianeejsAPIService } from '../../apiServices/arianeejsAPIService';
import { FirebaseHttpClient } from '../firebaseHttpClient/firebaseHttpClient';
import { EndpointsService } from '../../apiServices/endpointsService/endpointsService';
import { CertificateService } from '../../apiServices/BDHApi';
import { WalletCoreService } from '../walletCoreService/walletCoreService';
import { mongooseQueryFactory } from '../../helpers/mongoose-query-factory';
import * as i0 from "@angular/core";
import * as i1 from "../../apiServices/contentAPIService";
import * as i2 from "../../apiServices/arianeejsAPIService";
import * as i3 from "../../apiServices/endpointsService/endpointsService";
import * as i4 from "../../apiServices/BDHApi/api/certificate.service";
import * as i5 from "../walletCoreService/walletCoreService";
import * as i6 from "../firebaseHttpClient/firebaseHttpClient";
var CertificateCoreService = /** @class */ (function () {
    function CertificateCoreService(contentAPIService, arianeeJSAPIService, endpointService, certificateService, walletCoreService, firebaseHttpClient) {
        var _this = this;
        this.contentAPIService = contentAPIService;
        this.arianeeJSAPIService = arianeeJSAPIService;
        this.endpointService = endpointService;
        this.certificateService = certificateService;
        this.walletCoreService = walletCoreService;
        this.firebaseHttpClient = firebaseHttpClient;
        this.getCertificateCount = function (query, sort, limit) {
            if (limit === void 0) { limit = 0; }
            var options = mongooseQueryFactory(query, sort, limit);
            return _this.firebaseHttpClient.get(_this.endpointService.endpoints.certificate.count, options);
        };
        this.getCertificateList = function (query, sort, limit) {
            if (limit === void 0) { limit = 100; }
            var options = mongooseQueryFactory(query, sort, limit);
            return _this.firebaseHttpClient.get(_this.endpointService.endpoints.certificate.list, options);
        };
        this.getReportReservedCertificatesInCsv = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.firebaseHttpClient
                            .post(this.endpointService.endpoints.reports.reserved, {}, {
                            responseType: 'blob'
                        }).toPromise()];
                    case 1:
                        data = _a.sent();
                        this.downloadBlob(data, 'bdh-reserved-report.csv');
                        return [2 /*return*/];
                }
            });
        }); };
        this.getReportInCsv = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.firebaseHttpClient
                            .post(this.endpointService.endpoints.reports.certificate, {}, {
                            responseType: 'blob'
                        }).toPromise()];
                    case 1:
                        data = _a.sent();
                        this.downloadBlob(data, 'bdh-report.csv');
                        return [2 /*return*/];
                }
            });
        }); };
    }
    CertificateCoreService.prototype.getCertificate = function (certificateId) {
        return this.firebaseHttpClient.get(this.endpointService.endpoints.certificate.list, {
            params: {
                q: JSON.stringify({
                    tokenId: certificateId
                })
            }
        }).pipe(map(function (certificates) { return certificates[0]; }));
    };
    CertificateCoreService.prototype.getEvent = function (certificateId) {
        return this.getCertificate(parseInt(certificateId.toString()))
            .pipe(map(function (data) { return data.events; }));
    };
    CertificateCoreService.prototype.getCertificateOnce = function (certificateId) {
        return this.getCertificate(certificateId)
            .pipe(take(1));
    };
    CertificateCoreService.prototype.getReservedCertificate = function () {
        return this.firebaseHttpClient.get(this.endpointService.endpoints.certificate.count, {
            params: {
                q: JSON.stringify({
                    isConsumed: false
                })
            }
        });
    };
    CertificateCoreService.prototype.store = function (certificate) {
        return this.contentAPIService.storeCertificateContent(certificate.tokenId, certificate.json);
    };
    CertificateCoreService.prototype.createCertificate = function (certificate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.arianeeJSAPIService.createCertificate(certificate).toPromise()];
                    case 1: 
                    // no need to store as API is storing json
                    return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    CertificateCoreService.prototype.update = function (parameters) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.arianeeJSAPIService.updateCertificate(parameters).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    CertificateCoreService.prototype.doctorI18N = function (certificateContent) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.contentAPIService.doctorCertificate(certificateContent).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
       * Function to have certificateId that is free
       */
    CertificateCoreService.prototype.getFreeTokenId = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var tokenId, ownerExist, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        tokenId = Math.round(Math.random() * 100000000);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.certificateService.ownerOf([tokenId]).toPromise()];
                    case 2:
                        ownerExist = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        ownerExist = false;
                        return [3 /*break*/, 4];
                    case 4:
                        if (!!ownerExist) return [3 /*break*/, 5];
                        return [2 /*return*/, tokenId];
                    case 5: return [4 /*yield*/, this.getFreeTokenId()];
                    case 6: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Function to check if a list of certificatedIds is usable to create a certificate by current user
     * @param certificateIds
     */
    CertificateCoreService.prototype.certificateIdsAvailibility = function (certificateIds, isReservedByMe) {
        if (isReservedByMe === void 0) { isReservedByMe = true; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all(certificateIds
                            .map(function (certiD) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var canCreate;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.canCreateCertificateWithCertificateId(certiD, isReservedByMe)];
                                    case 1:
                                        canCreate = _a.sent();
                                        return [2 /*return*/, [certiD, canCreate]];
                                }
                            });
                        }); }))];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, {
                                notAvailablesIds: result
                                    .filter(function (_a) {
                                    var certiD = _a[0], canCreate = _a[1];
                                    return canCreate === false;
                                })
                                    .map(function (_a) {
                                    var certiD = _a[0];
                                    return certiD;
                                }),
                                availableIds: result
                                    .filter(function (_a) {
                                    var certiD = _a[0], canCreate = _a[1];
                                    return canCreate === true;
                                })
                                    .map(function (_a) {
                                    var certiD = _a[0];
                                    return certiD;
                                })
                            }];
                }
            });
        });
    };
    /**
     * Function to check if current user can create certificate using a given certificateId
     * It checks if it free or reserved by him.herself
     * @param certificateId
     * @param isReservedByMe
     */
    CertificateCoreService.prototype.canCreateCertificateWithCertificateId = function (certificateId, isReservedByMe) {
        if (isReservedByMe === void 0) { isReservedByMe = true; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var isFree;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.certificateService.ownerOf([certificateId]).toPromise()
                            .then(function (d) { return false; })
                            .catch(function () { return true; })];
                    case 1:
                        isFree = _a.sent();
                        if (isFree) {
                            return [2 /*return*/, true];
                        }
                        else if (isReservedByMe) {
                            return [2 /*return*/, this.isCertificateReservedByMe(certificateId)];
                        }
                        else {
                            return [2 /*return*/, false];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Function to check if given certificateId is reserved by current user
     * @param certificateId
     */
    CertificateCoreService.prototype.isCertificateReservedByMe = function (certificateId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, ownerAddress, imprint, address, amIOwner, isImprintEmpty;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Promise
                            .all([
                            this.certificateService.ownerOf([certificateId]).toPromise(),
                            this.arianeeJSAPIService.getImprint(certificateId).toPromise(),
                            this.walletCoreService.getAddress()
                        ])];
                    case 1:
                        _a = _b.sent(), ownerAddress = _a[0], imprint = _a[1], address = _a[2];
                        amIOwner = ownerAddress === address;
                        isImprintEmpty = (!imprint || imprint === '0x0000000000000000000000000000000000000000000000000000000000000000');
                        return [2 /*return*/, amIOwner && isImprintEmpty];
                }
            });
        });
    };
    CertificateCoreService.prototype.downloadBlob = function (blob, filename) {
        var binaryData = [];
        binaryData.push(blob);
        var downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
        downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
    };
    CertificateCoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CertificateCoreService_Factory() { return new CertificateCoreService(i0.ɵɵinject(i1.ContentAPIService), i0.ɵɵinject(i2.ArianeejsAPIService), i0.ɵɵinject(i3.EndpointsService), i0.ɵɵinject(i4.CertificateService), i0.ɵɵinject(i5.WalletCoreService), i0.ɵɵinject(i6.FirebaseHttpClient)); }, token: CertificateCoreService, providedIn: "root" });
    return CertificateCoreService;
}());
export { CertificateCoreService };
