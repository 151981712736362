<ion-header>
  <div color="light" class="header">
    <ion-menu-button></ion-menu-button>
    <ion-title> {{ 'pages.assets.title' | translate }}</ion-title>
  </div>
</ion-header>


<ion-content class="item text-center">
  <ion-list>


    <div class="uploadForm">
      <ion-item>
        <label>Upload:</label>
        <input type="file" (change)="upload($event)"  />
        <ion-toggle [(ngModel)]="optimize"></ion-toggle> <label>Optimize image</label>
      </ion-item>

    </div>

    <ion-item>
      <label>Look in your existing medias:</label>
      <ion-input class="searchForm" [(ngModel)]="filter" placeholder="text search" (ionChange)="filterItem($event)"></ion-input>
    </ion-item>
    <ion-grid>
      <ion-row>
        <ion-col size="6" *ngFor="let file of filteredpublicFilesList">
          <div class="img-container clickable" (click)="click(file.file)">
              <div>
                <img src="{{getLink(file.file)}}" height=150 align=right>
              </div>
              <div>
                <ion-icon name="link" (click)="copyToClipboard(getLink(file.file))"></ion-icon>
                <span>{{file.name}}</span>
              </div>
          </div>
        </ion-col>
      </ion-row>

    </ion-grid>

  </ion-list>

</ion-content>
