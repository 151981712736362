

<ion-item *ngIf="language && language.length > 1">
    <ion-label>Language</ion-label>
    <ion-select [(ngModel)]="selectedLanguage" (ngModelChange)="changeLanguage($event)">
        <ion-select-option [value]="lang" *ngFor="let lang of language" >{{lang.lang}}</ion-select-option>
    </ion-select>
</ion-item>

<div *ngIf="displayedItem" id="certifViewer" [ngClass]="{'v2':schemaVersion == EnumSchemaName.i18N}">
    <div id="iphone-layout"  *ngIf="schemaVersion == EnumSchemaName.i18N"></div>
    <div id="certifV1" *ngIf="schemaVersion == EnumSchemaName.arianeeAsset">

        <ion-card *ngIf="displayedItem">
            <ion-card-content text-center>
                <ion-card-title text-wrap>
                    <div style="white-space: pre-line!important" *ngIf="displayedItem.name">{{ displayedItem.name }}</div>
                    <div style="white-space: pre-line!important" *ngIf="displayedItem.model">{{ displayedItem.model }}</div>
                </ion-card-title>


            </ion-card-content>
            <ion-card-content>
                <ion-slides pager>
                    <ion-slide>
                        <img src="{{ displayedItem.picture }}" style="width:inherit"/>
                    </ion-slide>
                    <ion-slide *ngFor="let picture of displayedItem.pictures" style="width:inherit">
                        <img src="{{picture.src}}">
                    </ion-slide>
                </ion-slides>
            </ion-card-content>

            <ion-card-content>
                <div *ngIf="isArray(displayedItem.serialnumber)" text-wrap>
                    <div *ngFor="let serialnumber of displayedItem.serialnumber">

                        <div *ngIf="serialnumber.type=='serialnumber'">Serial Number : {{ serialnumber.value }}</div>
                        <div *ngIf="serialnumber.type=='casenumber'">Case Number : {{ serialnumber.value }}</div>
                        <div *ngIf="serialnumber.type=='movementnumber'">Movement Number : {{ serialnumber.value }}
                        </div>

                    </div>
                </div>
            </ion-card-content>

            <ion-card-content *ngIf="truncating" text-wrap style="white-space: pre-line!important"
                              [innerHTML]="displayedItem.description | truncate : 150">

            </ion-card-content>
            <button class="showmore" *ngIf="truncating&&displayedItem.description&&displayedItem.description.length>=150"
                    (click)="truncating = false">show more
            </button>

            <ion-card-content *ngIf="!truncating" text-wrap style="white-space: pre-line!important"
                              [innerHTML]="displayedItem.description">

            </ion-card-content>
            <button class="showmore" *ngIf="!truncating&&displayedItem.description&&displayedItem.description.length>=150"
                    (click)="truncating = true">show less
            </button>


        </ion-card>
        <ion-card *ngIf="displayedItem&&displayedItem.socialmedia" text-center class="socialmedia-card">
            <table style="width:100%">
                <tr>
                    <td>
                        <button *ngIf="displayedItem.socialmedia.facebook" class="socialmedia" >
                            <ion-icon name="logo-facebook" large></ion-icon>
                        </button>
                    </td>
                    <td>
                        <button *ngIf="displayedItem.socialmedia.instagram" class="socialmedia" >
                            <ion-icon name="logo-instagram" large></ion-icon>
                        </button>
                    </td>
                    <td>
                        <button *ngIf="displayedItem.socialmedia.twitter" class="socialmedia">
                            <ion-icon name="logo-twitter" large></ion-icon>
                        </button>
                    </td>

                </tr>
            </table>

        </ion-card>

        <ion-card *ngIf="displayedItem">
            <ion-list>
                <ion-item class="externalContent" *ngFor="let externalContent of displayedItem.externalContents"
                         >

                    <div displayedItem-start *ngIf="externalContent.icon">
                        <img src="{{externalContent.icon}}" class="icon" displayedItem-start/>
                    </div>

                    <h2>{{externalContent.title}}</h2>

                    <ion-icon displayedItem-end name="arrow-dropright" color="custom"></ion-icon>
                </ion-item>

            </ion-list>

        </ion-card>
    </div>


    <div id="certifV2" *ngIf="schemaVersion == EnumSchemaName.i18N">
        <div>
            <div id="pd-bg"
                 [ngStyle]="{'background-image': 'linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 1)),url(' + displayedItem.itemBackgroundPicture + ')'}"></div>
            <div id="pd-main">
                <div id="pd-brand-image">
                    <img [src]="displayedItem.brandLogoHeaderReversed"/>
                </div>
                <div id="pd-product-main-img">
                    <img [src]="hightlightPicture" *ngIf="hightlightPicture"
                         [attr.data-e2e]="'hightlight-'+hightlightPictureIndex"/>

                    <div id="playerIframe" *ngIf="embedURL">
                        <iframe [src]="embedURL | safe" frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                    </div>
                </div>

                <ion-grid class="altPictureGrid">
                    <ion-row nowrap>
                        <ion-col class="altPictureCol" *ngFor="let image of displayedItem.pictures; let index=index;">
                            <div class="altPicture"
                                 [attr.data-e2e]="'picture-'+index"
                                 [ngClass]="{'highlightedPicture':index===hightlightPictureIndex}"
                                 (click)="selectMainPicture(index)">
                                <img [src]="image"/>
                            </div>
                        </ion-col>
                        <ion-col class="altPictureCol" *ngFor="let media of displayedItem.otherMedias; let index=index;"
                        >
                            <div class="videoThumbnail altPicture"
                                 [ngStyle]="{'background-image': 'url(' + (media.thumbnail)  + ')'}"
                                 [ngClass]="{'highlightedPicture':index===hightlightMediaIndex}"
                                 (click)="selectMedia(index)"
                            ></div>
                        </ion-col>
                    </ion-row>
                </ion-grid>

                <div id="pd-info">
                    <div id="pd-info-header">
                        <div>
                            <p>
                                {{displayedItem.name}}
                            </p>
                            <p>
                                {{displayedItem.model}}
                            </p>
                        </div>

                        <div>
                            <img data-e2e='validity-picture' src="assets/img/img_product_cert.svg"/>
                        </div>
                    </div>

                    <div *ngIf="isArray(displayedItem.serialnumber)" text-wrap class="serialnumber-container">
                        <div *ngFor="let serialnumber of displayedItem.serialnumber">

                            <div *ngIf="serialnumber.type=='serialnumber'"><b>Serial Number
                                :</b> {{ serialnumber.value }}
                            </div>
                            <div *ngIf="serialnumber.type=='casenumber'"><b>Case Number
                                : </b>{{ serialnumber.value }}
                            </div>
                            <div *ngIf="serialnumber.type=='movementnumber'"><b>Movement Number : </b>
                                {{serialnumber.value }}
                            </div>

                        </div>
                    </div>
                    <div class="description-container" (click)="toggleSeeMore()">
                        <div
                                [ngClass]="{'max-height-description':seeMore}">
                            <p
                                    style="white-space: pre-line!important"
                                    [innerHTML]="displayedItem.description"
                            >
                            </p>
                        </div>
                        <div class="seemore" *ngIf="seeMore">
                            <ion-icon name="eye"></ion-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
