import * as tslib_1 from "tslib";
import { LoadingController } from '@ionic/angular';
import { Utils } from '../../services/utils/utils';
import { ContentAPIService } from '../../apiServices/contentAPIService';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';
import { CertificateService, IdentityService } from '../../apiServices/BDHApi';
import { WalletCoreService } from '../../services/walletCoreService/walletCoreService';
import { cleanObject } from '../../helpers/cleanObject/cleanObject';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { DomainService } from '../../services/domain-service/domain.service';
import { httpOrHttps } from '../../helpers/httpOrHttps.helper';
var SetIdentity = /** @class */ (function () {
    function SetIdentity(utils, loadingCtrl, contentAPIService, uiAlert, eventLogger, identityService, walletCoreService, certificateService, httpClient, domainService) {
        this.utils = utils;
        this.loadingCtrl = loadingCtrl;
        this.contentAPIService = contentAPIService;
        this.uiAlert = uiAlert;
        this.eventLogger = eventLogger;
        this.identityService = identityService;
        this.walletCoreService = walletCoreService;
        this.certificateService = certificateService;
        this.httpClient = httpClient;
        this.domainService = domainService;
        this.subscribe = {};
        this.model = {};
    }
    SetIdentity.prototype.copyIdentity = function (identity) {
        this.model = identity;
    };
    SetIdentity.prototype.ionViewDidEnter = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.eventLogger.logScreen('setIdentity-page');
                        _a = this;
                        return [4 /*yield*/, this.httpClient.get('https://cert.arianee.org/version1/ArianeeBrandIdentity-i18n.json')
                                .toPromise()];
                    case 1:
                        _a.schema = _b.sent();
                        this.load();
                        return [2 /*return*/];
                }
            });
        });
    };
    SetIdentity.prototype.loadIsApprovedIdentity = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var address, isApproved;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.walletCoreService.getAddress()];
                    case 1:
                        address = _a.sent();
                        return [4 /*yield*/, this.identityService.addressIsApproved([address]).toPromise()];
                    case 2:
                        isApproved = _a.sent();
                        if (!isApproved) {
                            this.addressApproved = false;
                            this.utils.alert('Your public address is not approved. Please contact Arianee');
                        }
                        else {
                            this.addressApproved = true;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SetIdentity.prototype.loadWaitingIdentity = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var address, waiting, identity, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 7]);
                        return [4 /*yield*/, this.walletCoreService.getAddress()];
                    case 1:
                        address = _a.sent();
                        return [4 /*yield*/, this.identityService.waitingURI([address]).toPromise()];
                    case 2:
                        waiting = _a.sent();
                        if (!(waiting && waiting.length > 0)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.httpClient.get(waiting).toPromise()];
                    case 3:
                        identity = _a.sent();
                        if (identity) {
                            this.waitingIdentity = identity;
                        }
                        _a.label = 4;
                    case 4: return [3 /*break*/, 7];
                    case 5:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [4 /*yield*/, this.uiAlert.error('Unable to fetch waiting identity data')];
                    case 6:
                        _a.sent();
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    SetIdentity.prototype.loadValidatedIdentity = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var address, result, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 5]);
                        return [4 /*yield*/, this.walletCoreService.getAddress()];
                    case 1:
                        address = _a.sent();
                        return [4 /*yield*/, this.identityService.getIdentity([address]).toPromise()];
                    case 2:
                        result = _a.sent();
                        this.identity = result.data;
                        return [3 /*break*/, 5];
                    case 3:
                        e_2 = _a.sent();
                        return [4 /*yield*/, this.uiAlert.error('Unable to fetch validated identity data')];
                    case 4:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    SetIdentity.prototype.load = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var loader;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.uiAlert.load()];
                    case 1:
                        loader = _a.sent();
                        return [4 /*yield*/, Promise.all([
                                this.loadIsApprovedIdentity(),
                                this.loadValidatedIdentity(),
                                this.loadWaitingIdentity()
                            ])];
                    case 2:
                        _a.sent();
                        loader.dismiss();
                        this.subscribe.domains = this.domainService.getDomains()
                            .pipe(take(1))
                            .subscribe(function (data) {
                            _this.publicDomain = data.public_identity;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    SetIdentity.prototype.setNewIdentity = function (identityToStore) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var identity, loading, e_3, errorMSGAlert;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        identityToStore.rpcEndpoint = identityToStore.rpcEndpoint || httpOrHttps(this.publicDomain + "/rpc");
                        this.copyIdentity(identityToStore);
                        identity = cleanObject(identityToStore);
                        return [4 /*yield*/, this.loadingCtrl.create({
                                spinner: 'dots',
                                cssClass: 'loading',
                                message: 'Waiting blockchain confirmation for <b>brand identity</b>.'
                            })];
                    case 1:
                        loading = _a.sent();
                        loading.present();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 6]);
                        return [4 /*yield*/, this.contentAPIService.storeIdentityContent(identity).toPromise()];
                    case 3:
                        _a.sent();
                        loading.dismiss();
                        this.uiAlert.alert({
                            message: 'Identity was successfully created !',
                            cssClass: 'e2e_identitySuccess'
                        });
                        return [3 /*break*/, 6];
                    case 4:
                        e_3 = _a.sent();
                        loading.dismiss();
                        return [4 /*yield*/, this.loadingCtrl.create({
                                cssClass: 'e2e_identityError',
                                message: 'Something went wrong.'
                            })];
                    case 5:
                        errorMSGAlert = _a.sent();
                        errorMSGAlert.present();
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    return SetIdentity;
}());
export { SetIdentity };
