import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EndpointsService } from '../../apiServices/endpointsService/endpointsService';
import { FirebaseHttpClient } from '../firebaseHttpClient/firebaseHttpClient';

@Injectable({ providedIn: 'root' })
export class DomainService {
  constructor (
    private endpointService: EndpointsService,
    private firebaseHttpClient: FirebaseHttpClient) {

  }

  getDomains ():Observable<any> {
    return this.firebaseHttpClient.get(this.endpointService.endpoints.domain.list)
      .pipe(
        map(d => d[0])
      );
  }
}
