import { Injectable } from '@angular/core';
import { FEATURE_FLIP } from '../feature-flip-service/feature-flip-enum';
import { SchemaService } from '../schema-service/schema-service';
import { config } from '../../../config';
import { AngularFireAuth } from '@angular/fire/auth';
import { FeatureFlipService } from '../feature-flip-service/feature-flip-service';
import { FirebaseHttpClient } from '../firebaseHttpClient/firebaseHttpClient';
import { ArianeejsAPIService } from '../../apiServices/arianeejsAPIService';
import { ContentAPIService } from '../../apiServices/contentAPIService';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class MessageService {
  constructor (
        private arianeejsServerAPIService: ArianeejsAPIService,
        private _firebaseAuth: AngularFireAuth,
        private contentService:ContentAPIService,
        private firebaseHttpClient:FirebaseHttpClient,
        private featureFlipService: FeatureFlipService,
        private httpClient:HttpClient
  ) {}

  async sendDMessage (certificateId, content) {
    const { messageId } = await this.arianeejsServerAPIService.createAndStoreMessage(
      {
        certificateId,
        content
      }
    ).toPromise();
  }

  /**
     * Legacy!
     * @param certificateId
     * @param {{title: string; content: string}} message
     * @returns {Promise<void>}
     */
  async sendMessage (certificateId, message: { title: string, content: string }) {
    const content = {
      $schema: SchemaService.schemasURL.ArianeeMessageAlpha,
      title: message.title,
      content: message.content
    };

    const { messageId } = await this.arianeejsServerAPIService.createMessage(
      {
        certificateId,
        content
      }
    ).toPromise();

    await this.contentService.storeMessageContent(certificateId, messageId, content).toPromise();
  }

  /**
     * Send message to multiple owner
     * @param certificateIds
     * @param message
     */
  public async sendMessages (certificateIds:number[], message:{ title: string, content: string }) {
    return Promise.all(certificateIds.map((certificateId) => this.sendMessage(certificateId, message)));
  }

  public getSchema () {
    return this.httpClient.get(SchemaService.schemasURL.ArianeeMessage);
  }
}
