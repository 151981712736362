import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { Utils } from '../../services/utils/utils';
import { AuthService } from '../../services/auth/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { CertificateCoreService } from '../../services/certificate-service/certificate-core.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { MessageService } from '../../services/messageService/messageService';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';
import { CertificateService, IdentityService, WhitelistService } from '../../apiServices/BDHApi';
import { WalletCoreService } from '../../services/walletCoreService/walletCoreService';
/*
*
*  Wallet Page
*
*/
var Item = /** @class */ (function () {
    function Item(utils, activiated, loadingCtrl, alertController, authService, router, certificateCoreService, http, _firebaseAuth, uiAlert, messageService, eventLogger, certificateService, identityService, whitelistService, walletCoreService) {
        var _this = this;
        this.utils = utils;
        this.activiated = activiated;
        this.loadingCtrl = loadingCtrl;
        this.alertController = alertController;
        this.authService = authService;
        this.router = router;
        this.certificateCoreService = certificateCoreService;
        this.http = http;
        this._firebaseAuth = _firebaseAuth;
        this.uiAlert = uiAlert;
        this.messageService = messageService;
        this.eventLogger = eventLogger;
        this.certificateService = certificateService;
        this.identityService = identityService;
        this.whitelistService = whitelistService;
        this.walletCoreService = walletCoreService;
        this.itemsEvents = [];
        this.isOwner = false;
        this.message = {};
        this.recoverable = false;
        this.recoverableTested = false;
        this.subscribe = {};
        this.loadContent = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a, owner, recover, content;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.certificateService
                            .getCertificate([
                            this.itemId,
                            this.viewKey,
                            {
                                content: true,
                                owner: true,
                                recover: true
                            }
                        ]).toPromise()];
                    case 1:
                        _a = _b.sent(), owner = _a.owner, recover = _a.recover, content = _a.content;
                        this.item = content.data;
                        this.currentOwner = owner.address;
                        this.isOwner = owner.isOwner;
                        this.recoverable = recover.isRecoverable;
                        this.recoverable = true;
                        this.recoverableTested = true;
                        this.isDestroyed = owner.address === '0x000000000000000000000000000000000000dead';
                        return [2 /*return*/];
                }
            });
        }); };
        this.loadEvent = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var events;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.certificateService
                            .getCertificate([
                            this.itemId,
                            this.viewKey,
                            {
                                events: true,
                                arianeeEvents: true
                            }
                        ]).toPromise()];
                    case 1:
                        events = (_a.sent()).events;
                        this.itemsEvents = events.transfer;
                        this.itemsEvents.shift();
                        this.itemsEvents.reverse();
                        this.eventsJSON = events.arianeeEvents.map(function (arianeeEvent) {
                            return {
                                json: arianeeEvent.content.data,
                                issuerAddress: arianeeEvent.issuer.address,
                                mintTimeStamp: arianeeEvent.timestamp
                            };
                        });
                        return [2 /*return*/];
                }
            });
        }); };
    }
    Item.prototype.ngOnInit = function () {
        this.itemId = parseInt(this.activiated.snapshot.paramMap.get('certificateId'));
        this.eventLogger.logScreen('item-page', { itemId: this.itemId });
        this.load();
    };
    Item.prototype.load = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var loader, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.uiAlert.load()];
                    case 1:
                        loader = _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 8, , 9]);
                        return [4 /*yield*/, this.loadContent()];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.getBddInfos()];
                    case 4:
                        _a.sent();
                        loader.dismiss();
                        return [4 /*yield*/, this.loadBrandMessagingAuthorization()];
                    case 5:
                        _a.sent();
                        return [4 /*yield*/, this.loadEvent()];
                    case 6:
                        _a.sent();
                        return [4 /*yield*/, this.loadQRCode()];
                    case 7:
                        _a.sent();
                        return [3 /*break*/, 9];
                    case 8:
                        e_1 = _a.sent();
                        console.error(e_1);
                        loader.dismiss();
                        this.uiAlert.error('An error occured while trying to display your certificate');
                        return [3 /*break*/, 9];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(Item.prototype, "canSendMessage", {
        get: function () {
            return this.item !== undefined &&
                !this.isOwner &&
                !this.isDestroyed &&
                this.authService.hasRight('messageSend') &&
                this.isBrandAthorizedToMessage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Item.prototype, "canDisplayQRCode", {
        get: function () {
            return this.isOwner &&
                this.authService.hasRight('certPrint');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Item.prototype, "canCreateEvent", {
        get: function () {
            return this.authService.hasRight('eventCreate');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Item.prototype, "canCertificateCreate", {
        get: function () {
            return this.authService.hasRight('certCreate');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Item.prototype, "canCertificateUpdate", {
        get: function () {
            return this.authService.hasRight('certCreate');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Item.prototype, "canRecover", {
        get: function () {
            return this.recoverableTested &&
                !this.isOwner &&
                !this.isDestroyed &&
                this.authService.hasRight('certRecover');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Item.prototype, "canDestroy", {
        get: function () {
            return this.isOwner &&
                this.authService.hasRight('certDelete');
        },
        enumerable: true,
        configurable: true
    });
    Item.prototype.getBddInfos = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var certif, _a, viewKey, mintTimeStamp;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.certificateCoreService.getCertificate(this.itemId)
                            .toPromise()];
                    case 1:
                        certif = _b.sent();
                        _a = certif, viewKey = _a.viewKey, mintTimeStamp = _a.mintTimeStamp;
                        if (!viewKey) {
                            this.uiAlert.error('an error occurer. This Certificate does not have viewkey');
                        }
                        this.viewKey = viewKey;
                        this.createdAt = mintTimeStamp * 1000;
                        return [2 /*return*/];
                }
            });
        });
    };
    Item.prototype.navigateTosendDmessage = function () {
        this.router.navigate(['/certificate/message/create'], { queryParams: { certificateIds: this.itemId } });
    };
    Item.prototype.findBrandLogoSquareFromIdentity = function (data) {
        if (data && data.pictures) {
            var brandLogoSquare = data.pictures.find(function (element) { return element.type === 'brandLogoSquare'; });
            if (brandLogoSquare) {
                return brandLogoSquare.url;
            }
        }
    };
    Item.prototype.loadQRCode = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.deepLink = "" + this.utils.deeplink() + this.itemId + "," + this.viewKey;
                this.qrCode = "https://tools.arianee.org/qrcode.php?&size=200x200&logo=https://demo.arianee.org/demo/logoa.png&data=" + this.deepLink;
                return [2 /*return*/];
            });
        });
    };
    Item.prototype.loadBrandMessagingAuthorization = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var address, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.walletCoreService.getAddress()];
                    case 1:
                        address = _b.sent();
                        _a = this;
                        return [4 /*yield*/, this.whitelistService.isAuthorized([
                                this.itemId,
                                address,
                                this.currentOwner
                            ]).toPromise()];
                    case 2:
                        _a.isBrandAthorizedToMessage = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Item.prototype.duplicate = function (item) {
        return this.router.navigate(["/certificate/duplicate/" + this.itemId]);
    };
    Item.prototype.update = function (item) {
        return this.router.navigate(["/certificate/update/" + this.itemId]);
    };
    Item.prototype.asynchTransferItem = function () {
        return this.router.navigate(['certificate', 'transfer', this.itemId]);
    };
    Item.prototype.destroyItem = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            header: 'Are you sure ?',
                            message: 'This action cannot be undone',
                            buttons: [
                                {
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'secondary'
                                }, {
                                    text: 'Confirm',
                                    handler: function () {
                                        _this.destroyItemProcess();
                                    }
                                }
                            ]
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Item.prototype.destroyItemProcess = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var loading;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadingCtrl.create({
                            spinner: 'dots',
                            message: 'Certificate destruction in progress'
                        })];
                    case 1:
                        loading = _a.sent();
                        loading.present();
                        return [4 /*yield*/, this.certificateService.addTokenAccess([this.itemId, '0x0000000000000000000000000000000000000000', false, 1]).toPromise()];
                    case 2:
                        _a.sent();
                        this.certificateService.destroyCertificate([this.itemId]).toPromise();
                        loading.dismiss();
                        return [2 /*return*/];
                }
            });
        });
    };
    Item.prototype.recoverItem = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            header: 'Are you sure ?',
                            message: 'This action cannot be undone',
                            buttons: [
                                {
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'secondary'
                                }, {
                                    text: 'Confirm',
                                    handler: function () {
                                        _this.recoverItemProcess();
                                    }
                                }
                            ]
                        })];
                    case 1:
                        alert = _a.sent();
                        alert.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    Item.prototype.recoverItemProcess = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var loading;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadingCtrl.create({
                            spinner: 'dots',
                            message: 'Certificate recovering in progress'
                        })];
                    case 1:
                        loading = _a.sent();
                        loading.present();
                        return [4 /*yield*/, this.certificateService.recoverCertificate([this.itemId]).toPromise()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.certificateService.createCertificateRequestOwnershipLink([this.itemId, this.viewKey]).toPromise()];
                    case 3:
                        _a.sent();
                        loading.dismiss();
                        return [2 /*return*/];
                }
            });
        });
    };
    return Item;
}());
export { Item };
