import * as tslib_1 from "tslib";
import { LoadingController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { Utils } from '../../services/utils/utils';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { ActivatedRoute, Router } from '@angular/router';
import { CertificateCoreService } from '../../services/certificate-service/certificate-core.service';
import { ArianeeEventService } from 'src/app/services/ArianeeEventService/arianeeEventService';
import { MessageService } from '../../services/messageService/messageService';
import { map, take } from 'rxjs/operators';
import { Location } from '@angular/common';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';
import { EconomyService } from '../../apiServices/BDHApi';
var MessageBuilder = /** @class */ (function () {
    function MessageBuilder(utils, http, loadingCtrl, uIAleertService, arianeEventService, activiated, certificateService, router, messageService, location, eventLogger, economyService) {
        this.utils = utils;
        this.http = http;
        this.loadingCtrl = loadingCtrl;
        this.uIAleertService = uIAleertService;
        this.arianeEventService = arianeEventService;
        this.activiated = activiated;
        this.certificateService = certificateService;
        this.router = router;
        this.messageService = messageService;
        this.location = location;
        this.eventLogger = eventLogger;
        this.economyService = economyService;
        this.schema = {
            properties: {}
        };
        this.item = {};
    }
    MessageBuilder.prototype.loadCreditLeft = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var credit;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.economyService.balanceOfCredit(['message']).toPromise()];
                    case 1:
                        credit = _a.sent();
                        if (+credit === 0) {
                            this.utils.alert('Not enough credit', 'Please add credit to continue. Go on settings tab');
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    MessageBuilder.prototype.navigateBack = function () {
        this.location.back();
    };
    MessageBuilder.prototype.ionViewDidEnter = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b;
            var _this = this;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.loadCreditLeft();
                        _a = this;
                        return [4 /*yield*/, this.activiated.queryParams
                                .pipe(take(1), map(function (d) { return d.certificateIds; }), map(function (certificateIds) { return certificateIds.split(',').map(function (certId) { return +certId; }); }))
                                .toPromise()];
                    case 1:
                        _a.arrayOfCertificateIds = _c.sent();
                        this.eventLogger.logScreen('MessageBuilder-page', { itemIds: this.arrayOfCertificateIds });
                        if (!(this.arrayOfCertificateIds.length === 0)) return [3 /*break*/, 2];
                        this.utils.alert('You need to specify at least one certificateID');
                        return [3 /*break*/, 5];
                    case 2:
                        if (!(this.arrayOfCertificateIds.length === 1)) return [3 /*break*/, 4];
                        this.itemId = this.arrayOfCertificateIds[0];
                        _b = this;
                        return [4 /*yield*/, this.certificateService.getCertificateOnce(this.itemId).toPromise()];
                    case 3:
                        _b.item = _c.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        this.item.name = this.arrayOfCertificateIds.length + " certificate owners";
                        _c.label = 5;
                    case 5:
                        this.messageService.getSchema()
                            .subscribe(function (schema) {
                            _this.schema = schema;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    MessageBuilder.prototype.sendMessage = function (message) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var loading, toast, e_1, toast;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.uIAleertService.load({
                            message: 'Waiting blockchain confirmation for <b>message creation</b>.'
                        })];
                    case 1:
                        loading = _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 6, , 9]);
                        return [4 /*yield*/, Promise.all(this.arrayOfCertificateIds
                                .map(function (certId) { return _this.messageService.sendDMessage(certId, message); }))];
                    case 3:
                        _a.sent();
                        loading.dismiss();
                        return [4 /*yield*/, this.uIAleertService.toast({
                                cssClass: 'e2e_message_created',
                                message: 'Your message has been sent',
                                duration: 2000
                            })];
                    case 4:
                        toast = _a.sent();
                        return [4 /*yield*/, toast.onDidDismiss()];
                    case 5:
                        _a.sent();
                        this.router.navigate(["/certificate/read/" + this.itemId]);
                        return [3 /*break*/, 9];
                    case 6:
                        e_1 = _a.sent();
                        return [4 /*yield*/, loading.dismiss()];
                    case 7:
                        _a.sent();
                        return [4 /*yield*/, this.uIAleertService.error('Something went wrong.', {
                                duration: 2000
                            })];
                    case 8:
                        toast = _a.sent();
                        return [3 /*break*/, 9];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    return MessageBuilder;
}());
export { MessageBuilder };
