import { Component } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Utils } from '../../services/utils/utils';
import { take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { PaperCertService } from '../../services/paper-cert-service/paper-cert.service';
import { Location } from '@angular/common';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';
import { CertificateService } from '../../apiServices/BDHApi';
import { PublicConfigurationService } from '../../services/public-configuration-service/public-configuration-service';
import { CertificateCoreService } from '../../services/certificate-service/certificate-core.service';

/*
*
*  AsyncTransfer
*
*/

@Component({
  selector: 'page-asyncTransfer',
  styleUrls: ['./asyncTransfer.scss'],
  templateUrl: 'asyncTransfer.html'
})
export class AsyncTransfer {
    public itemId: any;
    public item: any;
    public passphrase: string;
    public passphraseSetted : boolean;
    public transactionSent : boolean;
    public waiting : boolean;
    public qrCode : string;
    public qrCode2 : string;
    public potentialPassphrase: string;

    public paperTplList;
    public selectedPaperTpl;

    public withUrl=true;

    public subscribe: any = {};

    public deeplinkUrl= '';

    constructor (
        public utils: Utils,
        public loadingCtrl: LoadingController,
        private activiatedRoute:ActivatedRoute,
        private paperCertService:PaperCertService,
        private location:Location,
        private eventLogger: EventLoggerService,
        private certificateService: CertificateService,
        private publicConfigurationService:PublicConfigurationService,
        private certificateCoreService: CertificateCoreService
    ) {
      this.utils.getPaperTplList().then((data) => {
        this.paperTplList = data;
        this.selectedPaperTpl = this.paperTplList[0];
      });

      // certificate/tranfert/:certificateId/:passphrase
      this.itemId = parseInt(activiatedRoute.snapshot.paramMap.get('certificateId'));

      this._getItem();

      this.passphrase = activiatedRoute.snapshot.paramMap.get('passphrase');

      this.deeplinkUrl = this.utils.deeplink();
    }

    ionViewDidEnter () {
      this.eventLogger.logScreen('asyncTransfer-page', { itemId: this.itemId });
    }

    _getItem () {
      this.certificateCoreService.getCertificate(this.itemId)
        .pipe(take(1))
        .subscribe((data:any) => {
          this.item = JSON.parse(data.json);
          this.potentialPassphrase = data.viewKey;
          this.setItemPassphrase();
        });
    }

    public navigateBack () {
      this.location.back();
    }

    setItemPassphrase = async () => {
      this.passphraseSetted = true;
      this.waiting = true;

      if (this.potentialPassphrase) {
        const { isRequestable } = await this.certificateService
          .getCertificate([this.itemId, this.potentialPassphrase, { isRequestable: true }])
          .toPromise();

        if (isRequestable) {
          this.waiting = false;

          this.passphrase = this.potentialPassphrase;

          var payload2 = this.itemId + ',' + this.passphrase;

          this.transactionSent = true;
          this.qrCode = 'https://tools.arianee.org/qrcode.php?&size=200x200&logo=https://demo.arianee.org/demo/logoa.png&data=' + payload2;

          this.qrCode2 = 'https://tools.arianee.org/qrcode.php?&chld=L&size=200x200&data=' + payload2;
        } else {
          this.newPassphrase();
        }
      } else {
        this.newPassphrase();
      }
    }

    async newPassphrase () {
      this.passphrase = this.potentialPassphrase;

      if (!this.passphrase) { this.passphrase = Math.random().toString(36).substring(2, 8) + Math.random().toString(36).substring(2, 8); }

      const loading = await this.loadingCtrl.create({
        spinner: 'dots',
        message: 'Waiting blockchain confirmation'
      });
      loading.present();

      await this.certificateService.createCertificateRequestOwnershipLink([this.itemId, this.passphrase]);
      this.waiting = false;

      loading.dismiss();

      var payload2 = this.item + ',' + this.passphrase;

      this.transactionSent = true;
      this.qrCode = 'https://tools.arianee.org/qrcode.php?&size=200x200&logo=https://demo.arianee.org/demo/logoa.png&data=' + payload2;

      this.qrCode2 = 'https://tools.arianee.org/qrcode.php?&chld=L&size=200x200&data=' + payload2;
    }

    async paperCert () {
      const loading = await this.loadingCtrl.create({
        spinner: 'dots',
        message: 'Generate PDF'
      });
      loading.present();
      await this.paperCertService.generatePaperCert([this.itemId], this.selectedPaperTpl.name);
      loading.dismiss();
    }
}
