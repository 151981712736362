import { FirebaseHttpClient } from '../services/firebaseHttpClient/firebaseHttpClient';
import { EndpointsService } from './endpointsService/endpointsService';
import * as i0 from "@angular/core";
import * as i1 from "../services/firebaseHttpClient/firebaseHttpClient";
import * as i2 from "./endpointsService/endpointsService";
var MainnetAPIService = /** @class */ (function () {
    function MainnetAPIService(firebaseHttpClient, endpointService) {
        this.firebaseHttpClient = firebaseHttpClient;
        this.endpointService = endpointService;
    }
    MainnetAPIService.prototype.getBalance = function (address) {
        return this.firebaseHttpClient.get(this.endpointService.endpoints.mainnet.getBalance, [address]);
    };
    MainnetAPIService.prototype.aria20Balance = function (address) {
        return this.firebaseHttpClient.post(this.endpointService.endpoints.mainnet.aria20BalanceOf, [address]);
    };
    MainnetAPIService.prototype.aria20Transfer = function (address, value) {
        return this.firebaseHttpClient.post(this.endpointService.endpoints.mainnet.aria20Transfer, [address, value]);
    };
    MainnetAPIService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MainnetAPIService_Factory() { return new MainnetAPIService(i0.ɵɵinject(i1.FirebaseHttpClient), i0.ɵɵinject(i2.EndpointsService)); }, token: MainnetAPIService, providedIn: "root" });
    return MainnetAPIService;
}());
export { MainnetAPIService };
