import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Login } from './pages/login/login';
import { Transaction } from './pages/transaction/transaction';
import { JsonBuilder } from './pages/jsonBuilder/jsonBuilder';
import { UploadAssets } from './pages/uploadAssets/uploadAssets';
import { SetIdentity } from './pages/setIdentity/setIdentity';
import { Reporting } from './pages/reporting/reporting';
import { Settings } from './pages/settings/settings';
import { Admin } from './pages/admin/admin';
import { UsersManager } from './pages/userManager/usersManager';
import { Item } from './pages/item/item';
import { AsyncTransfer } from './pages/asyncTransfer/asyncTransfer';

import { IsAuthGuard, IsNotAuthGuard } from './guards';
import { AutoMessages } from './pages/autoMessages/autoMessages';
import { CanLeavePreviousPage } from './guards/can-leave-previous-page/can-leave-previous-page';
import { EventBuilder } from './pages/eventBuilder/eventBuilder';

import { LoggedComponent } from './components/loggedComponent/logged.component';
import { ResetPassword } from './pages/reset-password/resetPassword';
import { CreditCheckGuard } from './guards/creditCheck-guard/credit-check-guard.service';
import { MessageBuilder } from './pages/MessageBuilder/message-builder.page';
import { WalletPage } from './pages/certificate-page/wallet.page';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    canActivate: [IsNotAuthGuard],
    component: Login
  },
  {
    path: 'reset-password/reset',
    component: ResetPassword
  },
  {
    path: 'docs-api',
    loadChildren: () => import('./pages/openapi/openapi.module').then(m => m.OpenapiModule)

  },
  {
    path: '',
    canActivate: [CanLeavePreviousPage, IsAuthGuard, CreditCheckGuard],
    component: LoggedComponent,
    children: [
      {
        path: 'wallet',
        redirectTo: 'certificate/list',
        canActivate: [CanLeavePreviousPage, IsAuthGuard],
        pathMatch: 'full'
      },
      {
        path: 'transaction',
        redirectTo: 'transaction/list',
        canActivate: [CanLeavePreviousPage, IsAuthGuard],
        pathMatch: 'full'
      },
      {
        path: 'transaction/list',
        canActivate: [CanLeavePreviousPage, IsAuthGuard],
        component: Transaction
      },
      {
        path: 'home',
        canActivate: [CanLeavePreviousPage, IsAuthGuard],
        loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
      },
      {
        path: 'certificate/list',
        canActivate: [CanLeavePreviousPage, IsAuthGuard],
        component: WalletPage
      },
      {
        path: 'certificate/:certificateId/event/create',
        canActivate: [CanLeavePreviousPage, IsAuthGuard],
        component: EventBuilder
      },
      {
        path: 'certificate/message/create',
        canActivate: [CanLeavePreviousPage, IsAuthGuard],
        component: MessageBuilder
      },
      {
        path: 'certificate/create',
        canActivate: [CanLeavePreviousPage, IsAuthGuard],
        component: JsonBuilder
      },
      {
        path: 'certificate/read/:certificateId',
        canActivate: [CanLeavePreviousPage, IsAuthGuard],
        component: Item
      },
      {
        path: 'certificate/transfer/:certificateId',
        canActivate: [CanLeavePreviousPage, IsAuthGuard],
        component: AsyncTransfer
      },
      {
        path: 'certificate/:method/:certificateId',
        canActivate: [CanLeavePreviousPage, IsAuthGuard],
        component: JsonBuilder
      },
      {
        path: 'assets/upload',
        canActivate: [CanLeavePreviousPage, IsAuthGuard],
        component: UploadAssets
      },
      {
        path: 'identity',
        canActivate: [CanLeavePreviousPage, IsAuthGuard],
        component: SetIdentity
      },
      {
        path: 'reporting',
        canActivate: [CanLeavePreviousPage, IsAuthGuard],
        component: Reporting
      },
      {
        path: 'settings',
        canActivate: [CanLeavePreviousPage, IsAuthGuard],
        component: Settings
      },
      {
        path: 'admin',
        canActivate: [CanLeavePreviousPage, IsAuthGuard],
        component: Admin
      },
      {
        path: 'users',
        canActivate: [CanLeavePreviousPage, IsAuthGuard],
        component: UsersManager
      },
      {
        path: 'message',
        canActivate: [CanLeavePreviousPage, IsAuthGuard],
        component: AutoMessages
      }

    ]
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
