import { StaticAssetsWidget } from './static-assets-widget/staticAssetsWidget';
import { SchemaFormModule, WidgetRegistry, DefaultWidgetRegistry } from 'ngx-schema-form';
import { ParentWidget } from './parent-widget/parent-widget';

export class MyWidgetRegistry extends DefaultWidgetRegistry {
  constructor () {
    super();
    this.register('staticAssets', StaticAssetsWidget);
    this.register('parentWidget', ParentWidget);
  }
}
