import { config } from '../config';
import { HttpClient } from '@angular/common/http';
import { Configuration } from './apiServices/BDHApi';
import { httpOrHttps } from './helpers/httpOrHttps.helper';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function apiModuleRootFactory() {
    return new Configuration({
        basePath: httpOrHttps(config.backendDomain),
        accessToken: function () { return window.accessToken; }
    });
}
export function createTranslateLoader(http) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
var ɵ0 = (createTranslateLoader);
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
