export const widgetMessagei18n = {
  $id: 'https://cert.arianee.org/version1/ArianeeMessage-i18n.json',
  $schema: 'https://cert.arianee.org/version1/ArianeeMessage-i18n.json',
  properties: {
    i18n: {
      items: {
        type: 'object',
        properties: {
          externalContents: {
            items: {
              properties: {
                order: {
                  widget: 'hidden'
                }
              }
            }
          }
        }
      }
    },
    pictures: {
      items: {
        properties: {
          hash: {
            widget: 'hidden'
          }
        }
      }
    },
    externalContents: {
      items: {
        type: 'object',
        properties: {
          order: {
            widget: 'hidden'
          }
        }
      }
    }
  }
};
