import { config } from '../../../config';
import { endpoints } from '../../../environments/enpoints';
import { httpOrHttps } from '../../helpers/httpOrHttps.helper';
import * as i0 from "@angular/core";
var EndpointsService = /** @class */ (function () {
    function EndpointsService() {
        endpoints.baseRoot = EndpointsService.getBaseRoot(config.backendDomain);
        this.endpoints = EndpointsService.prepare(endpoints);
    }
    EndpointsService.getBaseRoot = function (baseRoute) {
        return httpOrHttps(baseRoute);
    };
    EndpointsService.prepare = function (endpointsConfiguration) {
        return Object.keys(endpointsConfiguration)
            .filter(function (d) { return d !== 'baseRoot'; })
            .reduce(function (acc, currEndpoints) {
            acc[currEndpoints] = {};
            Object.keys(endpointsConfiguration[currEndpoints])
                .filter(function (d) { return d !== 'baseRoot'; })
                .forEach(function (endpointName) {
                acc[currEndpoints][endpointName] = endpointsConfiguration.baseRoot + "/" + endpointsConfiguration[currEndpoints].baseRoot + "/" + endpointsConfiguration[currEndpoints][endpointName];
            });
            return acc;
        }, {});
    };
    EndpointsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EndpointsService_Factory() { return new EndpointsService(); }, token: EndpointsService, providedIn: "root" });
    return EndpointsService;
}());
export { EndpointsService };
