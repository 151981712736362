import * as tslib_1 from "tslib";
import { AlertController, LoadingController, NavController } from '@ionic/angular';
import { Utils } from '../../services/utils/utils';
import { AuthService } from '../../services/auth/auth';
import { filter, map, take, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';
/*
*
*  Intro Page
*
*/
var Login = /** @class */ (function () {
    function Login(utils, authService, loadingCtrl, alertCtrl, route, navCtrl, router, eventLogger) {
        this.utils = utils;
        this.authService = authService;
        this.loadingCtrl = loadingCtrl;
        this.alertCtrl = alertCtrl;
        this.route = route;
        this.navCtrl = navCtrl;
        this.router = router;
        this.eventLogger = eventLogger;
        this.user = {
            email: '',
            password: ''
        };
        this.subscribe = {};
        this.returnView = this.route.snapshot.queryParamMap.get('returnView');
    }
    Login.prototype.ionViewDidEnter = function () {
        this.eventLogger.logScreen('login-page');
    };
    Login.prototype.signInWithEmail = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var loading;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadingCtrl.create({
                            spinner: 'dots',
                            message: 'Loading'
                        })];
                    case 1:
                        loading = _a.sent();
                        loading.present();
                        this.authService.signInRegular(this.user.email, this.user.password)
                            .then(function (res) {
                            res.user.getIdToken(true).then(function (token) {
                                _this.authService.getUserProfile()
                                    .then(function (role) {
                                    loading.dismiss();
                                    _this.authService.$userState
                                        .pipe(map(function (d) { return d.isLoggedIn; }), filter(function (isLoggedIn) { return isLoggedIn; }), take(1), tap(function (isLoggedIn) {
                                        if (!_this.returnView) {
                                            return _this.router.navigate(['certificate/list']);
                                        }
                                        else {
                                            return _this.router.navigate([_this.returnView]);
                                        }
                                    }))
                                        .subscribe();
                                });
                            })
                                .catch(function (err) {
                                loading.dismiss();
                                _this.utils.error(err);
                            });
                        })
                            .catch(function (err) {
                            console.error('error: ' + err);
                            loading.dismiss();
                            _this.utils.error(err);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    Login.prototype.showForgetPassword = function () {
        /* this.subscribe.publicConfig = this.fireStore.collection('publicConfig').snapshotChanges()
              .pipe(map((payload: any) => {
                  return payload.map(a => {
                      let data = a.payload.doc.data();
                      data.id = a.payload.doc.id;
                      return data;
                  })
              })).subscribe(async (data: any) => {
                  let subTitle = 'Please contact your administrator to request a password reset';
  
                  if (data[0] && data[0].emailAdmin) {
                      subTitle = 'Please contact <b>' + data[0].emailAdmin + '</b> to request a password reset';
                  }
  
                  let alert = await this.alertCtrl.create({
                      header: 'Forgot password',
                      subHeader: subTitle,
                      buttons: ['Understood']
                  });
                  alert.present();
                  this.subscribe.publicConfig.unsubscribe();
              }) */
    };
    return Login;
}());
export { Login };
