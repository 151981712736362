import { Injectable } from '@angular/core';
import { FirebaseHttpClient } from '../firebaseHttpClient/firebaseHttpClient';
import { EndpointsService } from '../../apiServices/endpointsService/endpointsService';
import { UserModel } from '../../models/User.model';
import { from, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PublicConfigurationService {
  constructor (
        private firebaseHttpClient: FirebaseHttpClient,
        private endpointService: EndpointsService
  ) {
  }

    /**
     * Get public configuration based
     * @param {string} name
     * @returns {Observable<UserModel[]>}
     */
    public getByName = (name:string): Observable<{name:string, value:any}> => {
      return this.firebaseHttpClient.get(this.endpointService.endpoints.publicConfiguration.list,
        {
          params: {
            q: JSON.stringify({
              name
            })
          }
        }
      ).pipe(
        map(d => d[0])
      );
    };

    public update = (id:string, value:any): Observable<{_id:string, name:string, value:any}> => {
      const url = this.endpointService.endpoints.publicConfiguration.update.replace(':id', id);
      return this.firebaseHttpClient.put(url,
        value
      );
    }

    public create = (value: { name:string, value:any }): Observable<{_id:string, name:string, value:any}> => {
      return this.firebaseHttpClient.post(this.endpointService.endpoints.publicConfiguration.create,
        value
      );
    }
}
