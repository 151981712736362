import { FirebaseHttpClient } from '../services/firebaseHttpClient/firebaseHttpClient';
import { EndpointsService } from './endpointsService/endpointsService';
import * as i0 from "@angular/core";
import * as i1 from "../services/firebaseHttpClient/firebaseHttpClient";
import * as i2 from "./endpointsService/endpointsService";
var ArianeejsAPIService = /** @class */ (function () {
    function ArianeejsAPIService(firebaseHttpClient, endpointService) {
        this.firebaseHttpClient = firebaseHttpClient;
        this.endpointService = endpointService;
    }
    ArianeejsAPIService.prototype.chain = function () {
        return this.firebaseHttpClient.post(this.endpointService.endpoints.arianeejsServer.chain, []);
    };
    ArianeejsAPIService.prototype.createMessage = function (message) {
        return this.firebaseHttpClient.post(this.endpointService.endpoints.arianeejsServer.createMessage, [message]);
    };
    ArianeejsAPIService.prototype.createAndStoreArianeeEvent = function (event) {
        return this.firebaseHttpClient.post(this.endpointService.endpoints.arianeejsServer.createAndStoreArianeeEvent, [event]);
    };
    ArianeejsAPIService.prototype.createCertificate = function (certificate) {
        return this.firebaseHttpClient.post(this.endpointService.endpoints.arianeejsServer.createCertificate, [certificate]);
    };
    ArianeejsAPIService.prototype.updateCertificate = function (certificate) {
        return this.firebaseHttpClient.post(this.endpointService.endpoints.arianeejsServer.updateAndStoreCertificate, [certificate]);
    };
    ArianeejsAPIService.prototype.createAndStoreMessage = function (data) {
        var rpcURL = this.endpointService.endpoints.rpc.message;
        return this.firebaseHttpClient.post(this.endpointService.endpoints.arianeejsServer.createAndStoreMessage, [data, rpcURL]);
    };
    ArianeejsAPIService.prototype.balanceOfCredit = function (typeOfCredit) {
        return this.firebaseHttpClient.post(this.endpointService.endpoints.arianeejsServer.balanceOfCredit, [typeOfCredit]);
    };
    ArianeejsAPIService.prototype.getImprint = function (certificateId) {
        return this.firebaseHttpClient.post(this.endpointService.endpoints.arianeejsServer.getImprint, [certificateId]);
    };
    ArianeejsAPIService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ArianeejsAPIService_Factory() { return new ArianeejsAPIService(i0.ɵɵinject(i1.FirebaseHttpClient), i0.ɵɵinject(i2.EndpointsService)); }, token: ArianeejsAPIService, providedIn: "root" });
    return ArianeejsAPIService;
}());
export { ArianeejsAPIService };
