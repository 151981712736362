import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { from } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/fire/auth";
var FirebaseHttpClient = /** @class */ (function () {
    function FirebaseHttpClient(httpClient, _firebaseAuth) {
        var _this = this;
        this.httpClient = httpClient;
        this._firebaseAuth = _firebaseAuth;
        this.refreshAccessToken = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var exp, expDate;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!window.accessToken) return [3 /*break*/, 4];
                        exp = FirebaseHttpClient.parseJwt(window.accessToken).exp;
                        expDate = new Date((exp * 1000) - (60 * 1000));
                        if (!(expDate.valueOf() < new Date().valueOf())) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.refreshTokenId().toPromise()];
                    case 1:
                        _a.sent();
                        this.refreshAccessToken();
                        return [3 /*break*/, 3];
                    case 2:
                        this.timer = FirebaseHttpClient.setToHappen(this.refreshAccessToken, expDate);
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        this.timer = setTimeout(this.refreshAccessToken, 5000);
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        }); };
    }
    FirebaseHttpClient.parseJwt = function (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    };
    ;
    FirebaseHttpClient.setToHappen = function (fn, d) {
        var t = d.getTime() - (new Date()).getTime();
        return setTimeout(fn, t);
    };
    FirebaseHttpClient.prototype.init = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.timer) {
                            clearTimeout(this.timer);
                        }
                        return [4 /*yield*/, this._firebaseAuth.authState
                                .pipe(filter(function (user) { return user !== undefined; }), take(1))
                                .toPromise()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.refreshTokenId().toPromise()];
                    case 2:
                        _a.sent();
                        this.refreshAccessToken();
                        return [2 /*return*/];
                }
            });
        });
    };
    FirebaseHttpClient.prototype.get = function (url, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return this.refreshTokenId()
            .pipe(mergeMap(function (authorizationHeader) {
            return _this.httpClient.get(url, tslib_1.__assign({ observe: 'body', responseType: 'json' }, options, { headers: tslib_1.__assign({}, authorizationHeader, options.headers) }));
        }));
    };
    FirebaseHttpClient.prototype.post = function (url, body, options) {
        var _this = this;
        return this.refreshTokenId()
            .pipe(mergeMap(function (authorizationHeader) { return _this.httpClient.post(url, body, tslib_1.__assign({ observe: 'body', responseType: 'json', headers: tslib_1.__assign({}, authorizationHeader) }, options)); }));
    };
    FirebaseHttpClient.prototype.put = function (url, body, options) {
        var _this = this;
        return this.refreshTokenId()
            .pipe(mergeMap(function (authorizationHeader) { return _this.httpClient.put(url, body, tslib_1.__assign({ observe: 'body', responseType: 'json', headers: tslib_1.__assign({}, authorizationHeader) }, options)); }));
    };
    FirebaseHttpClient.prototype.delete = function (url, options) {
        var _this = this;
        return this.refreshTokenId()
            .pipe(mergeMap(function (authorizationHeader) { return _this.httpClient.delete(url, tslib_1.__assign({ observe: 'body', responseType: 'json', headers: tslib_1.__assign({}, authorizationHeader) }, options)); }));
    };
    /**
     * Refresh access token of user and return authorization header property
     */
    FirebaseHttpClient.prototype.refreshTokenId = function () {
        var _this = this;
        var $idToken = function () { return from(_this._firebaseAuth.auth.currentUser.getIdToken()); };
        return this._firebaseAuth.authState
            .pipe(filter(function (user) { return user !== undefined; }), take(1), mergeMap(function (user) { return $idToken(); }), tap(function (accessToken) { return window.accessToken = accessToken; }), map(function (userId) { return ({ authorization: "Bearer " + userId }); }));
    };
    FirebaseHttpClient.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FirebaseHttpClient_Factory() { return new FirebaseHttpClient(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AngularFireAuth)); }, token: FirebaseHttpClient, providedIn: "root" });
    return FirebaseHttpClient;
}());
export { FirebaseHttpClient };
