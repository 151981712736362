import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ArianeejsAPIService } from '../../apiServices/arianeejsAPIService';
import { ContentAPIService } from '../../apiServices/contentAPIService';

@Injectable({ providedIn: 'root' })
export class ArianeeEventService {
  constructor (
        private arianeejsServerAPIService: ArianeejsAPIService,
        private _firebaseAuth: AngularFireAuth,
        private contentService: ContentAPIService) {
  }

  async createEvent (event: {
        uri?: string;
        contentImprint?: string;
        certificateId: number,
        arianeeEventId?: number;
        content?: { $schema: string; [key: string]: any };
    }) {
    const { arianeeEventId } = await this.arianeejsServerAPIService.createAndStoreArianeeEvent(event).toPromise();

    return {
      arianeeEventId
    };
  }
}
