import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { config } from '../../../config';
import { PublicConfigurationService } from '../public-configuration-service/public-configuration-service';
import { map } from 'rxjs/operators';

/*
*
*  Utils Provider
*
*/
@Injectable({ providedIn: 'root' })
export class Utils {
    public paperTplList = [{ title: 'Format A4 ', name: 'paperCertDefault', fontSize: '12', format: 'a4', orientation: 'p', heightIncrementcol1: 7, logo: true },
      { title: 'Format A5', name: 'paperCertDefaultA5', fontSize: '10', format: 'a5', orientation: 'p', heightIncrementcol1: 5, logo: true },
      { title: 'Format A5 No Logo', name: 'paperCertDefaultNoLogoA5', fontSize: '10', format: 'a5', orientation: 'p', heightIncrementcol1: 5, logo: false },
      { title: 'Small Landscape', name: 'paperCertSmallLandscape', fontSize: '7', format: [328.819, 249.449], orientation: 'l', heightIncrementcol1: 6, heightIncrementcol2: 8, logo: false, multiplier: 7, printedIn: 'Switzerland' },
      { title: 'Small Landscape Chinese Market', name: 'paperCertSmallLandscapeChinese', fontSize: '10', format: 'a5', orientation: 'l', heightIncrementcol1: 10, logo: false },
      { title: 'Small Landscape masterline', name: 'paperCertSmallLandscapeMasterline', fontSize: '10', format: 'a5', orientation: 'l', heightIncrementcol1: 10, logo: true }

    ];

    private loading: any;

    constructor (
        public toastCtrl: ToastController,
        public alertCtrl: AlertController,
        public loadingCtrl: LoadingController,
        private publicConfigurationService:PublicConfigurationService
    ) {

    }

    public getPaperTpl (paperTplName) {
      return this.paperTplList.find(tpl => tpl.name === paperTplName);
    }

    getPaperTplList () {
      return this.publicConfigurationService.getByName('paperTemplate')
        .pipe(
          map((paperTplDocument) => {
            if (!paperTplDocument) {
              return this.getPaperTplListComplete();
            }
            const paperTpl = paperTplDocument.value.filter(val => val.value === true);
            return paperTpl;
          })
        ).toPromise();
    }

    getPaperTplListComplete () {
      return this.paperTplList;
    }

    /*
    *  Loading
    */
    async load () {
      if (!this.loading) {
        this.loading = await this.loadingCtrl.create({
          message: 'Please wait...'
          // dismissOnPageChange: false
        });

        this.loading.present();
      }
    }

    /*
    *  Dismiss loading
    */
    unload () {
      if (this.loading) {
        this.loading.dismiss();
        this.loading = null;
      }
    }

    /*
    *  Toast error message
    */
    async error (message, params?: any) {
      const toast = await this.toastCtrl.create({
        message: message,
        duration: 3000,
        cssClass: 'toast-error',
        position: 'top'
      });
      toast.present();
    }

    /*
    *  Toast success message
    */
    async success (message, params?: any) {
      const toast = await this.toastCtrl.create({
        message: message,
        duration: 3000,
        cssClass: 'toast-success',
        position: 'top'
      });
      toast.present();
    }

    /*
    *    Alert
    */
    async alert (title, message?: string) {
      const alert = await this.alertCtrl.create({
        header: title,
        message: (message || ''),
        buttons: ['Dismiss']
      });
      alert.present();
    }

    public deeplink (certificateId?:number, viewKey?:string) {
      const urlOfChain = config.network === 'mainnet' ? 'https://arian.ee/' : 'https://test.arian.ee/';

      if (certificateId && viewKey) {
        return `${urlOfChain}${certificateId},${viewKey}`;
      } else {
        return urlOfChain;
      }
    }
}
