import * as tslib_1 from "tslib";
import { LoadingController, ModalController } from '@ionic/angular';
import { Utils } from '../../services/utils/utils';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../../services/auth/auth';
import { ActivatedRoute } from '@angular/router';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';
import { AssetService } from '../../apiServices/BDHApi';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { EndpointsService } from '../../apiServices/endpointsService/endpointsService';
import { take } from 'rxjs/operators';
import { AssetsService } from '../../services/assets-service/assetsService';
import { DomainService } from '../../services/domain-service/domain.service';
import { httpOrHttps } from '../../helpers/httpOrHttps.helper';
var UploadAssets = /** @class */ (function () {
    function UploadAssets(route, utils, afStorage, authService, loadingCtrl, _firebaseAuth, modalController, eventLogger, assetService, uiAlertService, endPointService, assetsService, domainService) {
        var _this = this;
        this.route = route;
        this.utils = utils;
        this.afStorage = afStorage;
        this.authService = authService;
        this.loadingCtrl = loadingCtrl;
        this._firebaseAuth = _firebaseAuth;
        this.modalController = modalController;
        this.eventLogger = eventLogger;
        this.assetService = assetService;
        this.uiAlertService = uiAlertService;
        this.endPointService = endPointService;
        this.assetsService = assetsService;
        this.domainService = domainService;
        this.isModal = false;
        this.publicFilesList = [];
        this.filteredpublicFilesList = [];
        this.subscribe = {};
        this.optimize = true;
        this.getLink = function (file) {
            return httpOrHttps(_this.publicDomain + "/pub/" + file);
        };
        this.fetchAssets = function () {
            var sortByCreatedAt = function (a, b) { return (a.createdAt > b.createdAt) ? -1 : 1; };
            _this.assetsService.all({}, { createdAt: -1 }, 50)
                .pipe(take(1))
                .subscribe(function (data) {
                var sortedData = data.map(function (d) {
                    if (d.createdAt) {
                        d.createdAt = d.createdAt.seconds * 1000;
                    }
                    else {
                        d.createdAt = 0;
                    }
                    return d;
                }).sort(sortByCreatedAt);
                _this.publicFilesList = sortedData;
                _this.assignCopy();
                if (_this.filename && _this.isModal) {
                    _this.dismissModal(_this.filename);
                    _this.filename = '';
                }
                _this.loading.dismiss();
            });
        };
        this.dismissModal = function (fileName) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.modalController.dismiss(this.getLink(fileName));
                return [2 /*return*/];
            });
        }); };
    }
    UploadAssets.prototype.click = function (file) {
        if (this.isModal) {
            this.dismissModal(file);
        }
        else {
            window.open(this.getLink(file), 'blank');
        }
    };
    UploadAssets.prototype.assignCopy = function () {
        this.filteredpublicFilesList = Object.assign([], this.publicFilesList);
    };
    UploadAssets.prototype.filterItem = function (value) {
        var _this = this;
        if (!value) {
            this.assignCopy();
        } // when nothing has typed
        this.filteredpublicFilesList = Object.assign([], this.publicFilesList)
            .filter(function (item) { return ((_this.filter && item.name.toLowerCase().indexOf(_this.filter.toLowerCase()) > -1) || !_this.filter); });
        if (value.stopPropagation) {
            value.stopPropagation();
        }
    };
    UploadAssets.prototype.ionViewDidEnter = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, publicDomains;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.eventLogger.logScreen('userManager-page');
                        _a = this;
                        return [4 /*yield*/, this.loadingCtrl.create({
                                spinner: 'dots',
                                message: 'Loading'
                            })];
                    case 1:
                        _a.loading = _b.sent();
                        this.loading.present();
                        return [4 /*yield*/, this.domainService.getDomains()
                                .pipe(take(1))
                                .toPromise()];
                    case 2:
                        publicDomains = _b.sent();
                        // @ts-ignore
                        this.publicDomain = publicDomains.public_domain;
                        this.fetchAssets();
                        return [2 /*return*/];
                }
            });
        });
    };
    UploadAssets.prototype.upload = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, resize, e_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loadingCtrl.create({
                                spinner: 'dots',
                                message: 'Waiting upload to finish.'
                            })];
                    case 1:
                        _a.loading = _b.sent();
                        this.loading.present();
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 4, , 5]);
                        resize = this.optimize;
                        return [4 /*yield*/, this.assetService
                                .postStaticAssetForm(event.target.files[0], resize)
                                .toPromise()];
                    case 3:
                        _b.sent();
                        this.loading.dismiss();
                        this.uiAlertService.success('Your asset has been uploaded successfully');
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _b.sent();
                        console.error(e_1);
                        this.loading.dismiss();
                        this.uiAlertService.error('An error occured while uploading your asset');
                        return [3 /*break*/, 5];
                    case 5:
                        this.fetchAssets();
                        return [2 /*return*/];
                }
            });
        });
    };
    UploadAssets.prototype.copyToClipboard = function (item) {
        this.utils.success('Copied to clipboard');
        document.addEventListener('copy', function (e) {
            e.clipboardData.setData('text/plain', (item));
            e.preventDefault();
            document.removeEventListener('copy', null);
        });
        document.execCommand('copy');
    };
    return UploadAssets;
}());
export { UploadAssets };
