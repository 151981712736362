/**
 * Arianee Brand Data Hub API
 * List API available in Brand Data Hub
 *
 * OpenAPI spec version: 1.0.0
 * Contact: techteam@arianee.org
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { Asset } from '../model/asset';
import { AssetUpload } from '../model/assetUpload';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class AssetService {
    protected basePath = 'https://us-central1-bdhalex-826d5.cloudfunctions.net/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor (protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
      if (basePath) {
        this.basePath = basePath;
      }
      if (configuration) {
        this.configuration = configuration;
        this.basePath = basePath || configuration.basePath || this.basePath;
      }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm (consumes: string[]): boolean {
      const form = 'multipart/form-data';
      for (const consume of consumes) {
        if (form === consume) {
          return true;
        }
      }
      return false;
    }

    /**
     * Retrieve static assets such as images
     * Retrieve static assets such as images
     * @param id pass asset id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStaticAsset(id: string, observe?: 'body', reportProgress?: boolean): Observable<Asset>;
    public getStaticAsset(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Asset>>;
    public getStaticAsset(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Asset>>;
    public getStaticAsset (id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined when calling getStaticAsset.');
      }

      let headers = this.defaultHeaders;

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'image/png',
        'image/jpeg',
        'application/pdf',
        'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
      ];

      return this.httpClient.request<Asset>('get', `${this.basePath}/assets/pub/${encodeURIComponent(String(id))}`,
        {
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Store static assets such as images
     * Post file as static asset
     * @param fileName
     * @param resize
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postStaticAssetForm(fileName?: Blob, resize?: boolean, observe?: 'body', reportProgress?: boolean): Observable<AssetUpload>;
    public postStaticAssetForm(fileName?: Blob, resize?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AssetUpload>>;
    public postStaticAssetForm(fileName?: Blob, resize?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AssetUpload>>;
    public postStaticAssetForm (fileName?: Blob, resize?: boolean, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
        'multipart/form-data'
      ];

      const canConsumeForm = this.canConsumeForm(consumes);

      let formParams: { append(param: string, value: any): void; };
      let useForm = false;
      const convertFormParamsToString = false;
      // use FormData to transmit files using content-type "multipart/form-data"
      // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
      useForm = canConsumeForm;
      if (useForm) {
        formParams = new FormData();
      } else {
        formParams = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
      }

      if (fileName !== undefined) {
        formParams = formParams.append('fileName', <any>fileName) as any || formParams;
      }
      if (resize !== undefined) {
        formParams = formParams.append('resize', <any>resize) as any || formParams;
      }

      return this.httpClient.request<AssetUpload>('post', `${this.basePath}/assets/pub/`,
        {
          body: convertFormParamsToString ? formParams.toString() : formParams,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }
}
