const cruds = Object.freeze({
  list: '',
  create: '',
  delete: ':id',
  count: 'count',
  update: ':id'
});
export const endpoints = {
  baseRoot: '// will be replaced by config',
  assets: {
    baseRoot: 'pub',
    pub: ':id',
    list: 'list'
  },
  assetsCrud: {
    baseRoot: 'pub/assets',
    ...cruds
  },
  user: {
    baseRoot: 'users',
    delete: 'delete',
    create: 'create',
    update: 'update',
    list: ''
  },
  arianeejsServer: {
    baseRoot: 'api',
    createMessage: 'createMessage',
    createAndStoreArianeeEvent: 'createAndStoreArianeeEvent',
    createCertificate: 'createCertificate',
    updateAndStoreCertificate: 'updateAndStoreCertificate',
    createAndStoreMessage: 'createAndStoreMessage',
    chain: 'chain',
    balanceOfCredit: 'balanceOfCredit',
    getImprint: 'contracts/smartAssetContract/tokenImprint/call'
  },
  content: {
    baseRoot: 'content/content',
    certificate: 'certificate',
    message: 'message',
    event: 'event'
  },
  certificate: {
    baseRoot: 'certificate',
    ...cruds,
    doctor: 'doctor'
  },
  rpc: {
    baseRoot: 'rpc',
    message: 'message'
  },
  identity: {
    baseRoot: 'identity',
    create: 'identity'
  },
  transactions: {
    baseRoot: 'transaction/transaction',
    send: 'send/id/:id',
    reset: 'reset/id/:id',
    status: 'status/:status',
    ...cruds
  },
  reports: {
    baseRoot: 'reports',
    certificate: 'certificates/export/csv',
    reserved: 'certificates/reserved/export/csv'
  },
  mainnet: {
    baseRoot: 'ethereum',
    getBalance: 'getBalance',
    aria20BalanceOf: 'aria20/balanceOf/call',
    aria20Transfer: 'aria20/transfer/send'
  },
  profile: {
    baseRoot: 'profile',
    ...cruds
  },
  autoMessage: {
    baseRoot: 'autoMessage',
    ...cruds
  },
  domain: {
    baseRoot: 'domain',
    ...cruds
  },
  publicConfiguration: {
    baseRoot: 'publicConfiguration',
    ...cruds
  }
};
