import { Component } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../services/auth/auth';

import { Utils } from '../../services/utils/utils';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { FirebaseHttpClient } from '../../services/firebaseHttpClient/firebaseHttpClient';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';
import { EndpointsService } from '../../apiServices/endpointsService/endpointsService';
import { UsersService } from '../../services/users-service/userService';
import { ProfileService } from '../../services/profile-service/profileService';

@Component({
  selector: 'page-user',
  styleUrls: ['usersManager.scss'],
  templateUrl: 'usersManager.html'
})
export class UsersManager {
    public users: any = [];
    public profiles: any = [];
    public addUser: boolean = false;
    public addProfile: boolean = false;

    public myuid: any;

    public newUser: any = {};
    public newProfile: any = {};

    public subscribe: any = {};

    public rights = [
      { mnemonic: 'Admin', title: 'admin' },
      { mnemonic: 'certRead', title: 'Certificate read' },
      { mnemonic: 'certCreate', title: 'Certificate creation' },
      { mnemonic: 'certPrint', title: 'Certificate print' },
      { mnemonic: 'certDelete', title: 'Certificate deletion' },
      { mnemonic: 'certRecover', title: 'Certificate recovery' },
      { mnemonic: 'messageSend', title: 'Message sending' },
      { mnemonic: 'assetsUpload', title: 'Assets upload' },
      { mnemonic: 'identityUpdate', title: 'Identity update' },
      { mnemonic: 'accountManage', title: 'Account management' },
      { mnemonic: 'messageSetupAuto', title: 'Message Auto setup' },
      { mnemonic: 'usersManage', title: 'Users management' },
      { mnemonic: 'eventCreate', title: 'Event creation' },
      { mnemonic: 'reporting', title: 'Reporting' }

    ];

    public newRights = this.rights;

    public formGroup = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      profile: new FormControl('', [Validators.required])
    });

    constructor (
        private _firebaseAuth: AngularFireAuth,
        public http: HttpClient,
        private firebaseHttpClient:FirebaseHttpClient,
        public authService: AuthService,
        public utils: Utils,
        private uIAlertService: UIAlertService,
        private eventLogger: EventLoggerService,
        private endpointService: EndpointsService,
        private userService: UsersService,
        private profileService: ProfileService

    ) {
      this.getProfiles();

      this.myuid = this._firebaseAuth.auth.currentUser.uid;
    }

    public getUsers = async () => {
      this.users = await this.userService.all().toPromise();
    }

    ionViewDidEnter () {
      this.eventLogger.logScreen('userManager-page');

      this.getUsers();
    }

    async getProfiles () {
      this.profiles = await this.profileService.all().toPromise();
    }

    toggleAddUser () {
      this.addUser = !this.addUser;
    }

    toggleAddProfile () {
      this.addProfile = !this.addProfile;
      this.newRights = JSON.parse(JSON.stringify(this.rights));
    }

    toggleAll () {
      this.addProfile = false;
      this.addUser = false;
      this.newRights = JSON.parse(JSON.stringify(this.rights));
    }

    async createUser () {
      const loading = await this.uIAlertService.load();

      const values = this.formGroup.getRawValue();

      try {
        await this.userService.create(values).toPromise();

        this.toggleAddUser();
        await this.getUsers();

        loading.dismiss();
        await this.uIAlertService.toast({
          cssClass: 'e2e_user_create_success',
          message: `User ${values.email} has been created`,
          duration: 8000
        });
      } catch (e) {
        loading.dismiss();

        await this.uIAlertService.alert({
          cssClass: 'e2e_user_create_error',
          message: 'an error occured'
        });
        console.error(e);
      }
    }

    async profileChange (user) {
      if (this._firebaseAuth.auth.currentUser.uid !== user.id) {
        await this.userService.update(user).toPromise();
        await this.getUsers();
      } else {
        await this.uIAlertService.toast({
          message: 'You can\'t edit your own profile.',
          duration: 2000
        });
      }
    }

    async createProfile () {
      const rights = [];
      this.newRights.forEach(function (value: any) {
        if (value.checked) {
          rights.push(value.mnemonic);
        }
      });

      await this.profileService.create(this.newProfile.name, rights).toPromise();
      await this.getProfiles();
      this.newProfile = '';

      this.toggleAddProfile();
    }

    confirmReset (user) {
      return this.uIAlertService.alert({
        header: 'Confirm reset password',
        message: 'Are you sure to reset <b>' + user + '</b> password ?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
            }
          },
          {
            text: 'Reset password',
            handler: () => {
              this.resetPassword(user);
            }
          }
        ]
      });
    }

    resetPassword (user) {
      const that = this;
      this._firebaseAuth.auth.sendPasswordResetEmail(user).then(function () {
        that.utils.success('Reset password email sent !');
      });
    }

    confirmDelete (user): Promise<any> {
      if (this._firebaseAuth.auth.currentUser.uid !== user.id) {
        return this.uIAlertService.alert({
          header: 'Confirm delete',
          message: 'Are you sure to delete ' + user.email + ' ?',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => {
              }
            },
            {
              text: 'Delete',
              cssClass: 'e2e_user_delete_confirm',
              handler: () => {
                this.deleteUser(user);
              }
            }
          ]
        });
      } else {
        return this.uIAlertService.toast({
          message: 'You can\'t delete your own account.',
          duration: 2000
        });
      }
    }

   public deleteUser=async (user) => {
     const loading = await this.uIAlertService.load();

     try {
       await this.firebaseHttpClient.post(this.endpointService.endpoints.user.delete, {
         uid: user.userId
       }, { responseType: 'text' })
         .toPromise();

       await this.getUsers();
       loading.dismiss();

       await this.uIAlertService.toast({
         cssClass: 'e2e_user_delete_success',
         message: 'User has been deleted',
         duration: 2500
       });
     } catch (e) {
       loading.dismiss();

       await this.uIAlertService.alert({
         cssClass: 'e2e_user_delete_error',
         message: 'an error occured'
       });

       console.error(e);
     }
   }

   confirmDeleteProfile (profile) {
     return this.uIAlertService.alert({
       header: 'Confirm delete',
       message: 'Are you sure to delete ' + profile.name + ' ?',
       buttons: [
         {
           text: 'Cancel',
           role: 'cancel',
           handler: () => {
           }
         },
         {
           text: 'Delete',
           handler: () => {
             this.deleteProfile(profile._id);
           }
         }
       ]
     });
   }

   async deleteProfile (profileId) {
     await this.profileService.delete(profileId).toPromise();
     await this.getProfiles();
   }
}
