import { Component } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { config } from '../../../config';
import { AngularFireAuth } from '@angular/fire/auth';
import { Utils } from '../../services/utils/utils';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';
import { PublicConfigurationService } from '../../services/public-configuration-service/public-configuration-service';

@Component({
  selector: 'page-admin',
  templateUrl: 'admin.html'
})
export class Admin {
    public recovery:any={};
    public subscribe:any ={};
    public paperTplList;

    private paperTplDoc;
    public backupAutoDoc:{_id?:string, name:string, value:boolean};

    constructor (
        public http: HttpClient,
        private _firebaseAuth: AngularFireAuth,
        public toastCtrl: ToastController,
        public utils: Utils,
        private eventLogger: EventLoggerService,
        private publicConfigurationService: PublicConfigurationService

    ) {
      const that = this;

      this.getAutoBackupStatus();

      let tmpPaperTplList;

      this.publicConfigurationService.getByName('paperTemplate')
        .subscribe((paperTplDocument:any) => {
          tmpPaperTplList = that.utils.getPaperTplListComplete();

          if (paperTplDocument) {
            this.paperTplDoc = paperTplDocument;
            const paperTplConfigs = paperTplDocument.value;

            tmpPaperTplList = tmpPaperTplList.map((paperTpl) => {
              const config = paperTplConfigs.find(config => config.name === paperTpl.name);

              paperTpl.value = config ? config.value : true;
              return paperTpl;
            });
          } else {
            this.createPaperTplDoc(tmpPaperTplList);
          }
          this.paperTplList = tmpPaperTplList;
        });
    }

    ionViewDidEnter () {
      this.eventLogger.logScreen('admin-page');
    }

    async createPaperTplDoc (tmpPaperTplList) {
      this.paperTplDoc = await this.publicConfigurationService.create({ name: 'paperTemplate', value: tmpPaperTplList }).toPromise();
    }

    changePaperTpl () {
      this.paperTplDoc.value = this.paperTplList;
      this.publicConfigurationService.update(this.paperTplDoc._id, this.paperTplDoc).toPromise();
    }

    getAutoBackupStatus () {
      this.publicConfigurationService.getByName('backupauto')
        .subscribe((backupAuto:any) => {
          this.backupAutoDoc = backupAuto || { name: 'backupauto', value: false };
        });
    }

    async changeBackupStatus () {
      if (this.backupAutoDoc._id) {
        this.publicConfigurationService.update(this.backupAutoDoc._id, this.backupAutoDoc).toPromise();
      } else {
        this.backupAutoDoc = await this.publicConfigurationService.create(this.backupAutoDoc).toPromise();
      }
    }

    backupEverything () {
      const that = this;
      this._firebaseAuth.auth.currentUser.getIdToken(true).then(function (idToken) {
        that.http.post('https://' + config.authDomain + '/init/backup', { idToken: idToken }, { responseType: 'json' })
          .subscribe(async (msg) => {
            const toast = await that.toastCtrl.create({
              message: 'Certificates, assets and users are backed',
              duration: 2000,
              position: 'top'
            });
            toast.present();
          });
      });
    }

    recover () {
      const that = this;
      this._firebaseAuth.auth.currentUser.getIdToken(true).then(function (idToken) {
        const url = that.recovery.id ? 'https://' + config.authDomain + '/recovery/collection/' + that.recovery.collection + '/' + that.recovery.id : 'https://' + config.authDomain + '/recovery/collection/' + that.recovery.collection;
        that.http.post(url, { idToken: idToken }, { responseType: 'json' })
          .subscribe(async (msg) => {
            const toast = await that.toastCtrl.create({
              message: 'Data recovered',
              duration: 2000,
              position: 'top'
            });
            toast.present();
          });
      });
    }
}
