import { FirebaseHttpClient } from '../firebaseHttpClient/firebaseHttpClient';
import { EndpointsService } from '../../apiServices/endpointsService/endpointsService';
import { mongooseQueryFactory } from '../../helpers/mongoose-query-factory';
import * as i0 from "@angular/core";
import * as i1 from "../firebaseHttpClient/firebaseHttpClient";
import * as i2 from "../../apiServices/endpointsService/endpointsService";
var AssetsService = /** @class */ (function () {
    function AssetsService(firebaseHttpClient, endpointService) {
        var _this = this;
        this.firebaseHttpClient = firebaseHttpClient;
        this.endpointService = endpointService;
        this.all = function (query, sort, limit) {
            if (limit === void 0) { limit = 20; }
            var options = mongooseQueryFactory(query, sort, limit);
            return _this.firebaseHttpClient.get(_this.endpointService.endpoints.assetsCrud.list, options);
        };
    }
    AssetsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AssetsService_Factory() { return new AssetsService(i0.ɵɵinject(i1.FirebaseHttpClient), i0.ɵɵinject(i2.EndpointsService)); }, token: AssetsService, providedIn: "root" });
    return AssetsService;
}());
export { AssetsService };
