import { Component } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Utils } from '../../services/utils/utils';
import { ContentAPIService } from '../../apiServices/contentAPIService';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';
import { CertificateService, IdentityService } from '../../apiServices/BDHApi';
import { WalletCoreService } from '../../services/walletCoreService/walletCoreService';
import { cleanObject } from '../../helpers/cleanObject/cleanObject';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { DomainService } from '../../services/domain-service/domain.service';
import { httpOrHttps } from '../../helpers/httpOrHttps.helper';

@Component({
  selector: 'page-setIdentity',
  styleUrls: ['./setIdentity.scss'],
  templateUrl: 'setIdentity.html'
})
export class SetIdentity {
    public itemId: any;
    public item: any;
    public identity: any;
    public publicDomain: any;
    public addressApproved: boolean;
    public waitingIdentity: any;
    public subscribe: any = {};
    public model: any = {};

    public schema: any;

    constructor (
        public utils: Utils,
        public loadingCtrl: LoadingController,
        private contentAPIService: ContentAPIService,
        private uiAlert:UIAlertService,
        private eventLogger: EventLoggerService,
        private identityService: IdentityService,
        private walletCoreService: WalletCoreService,
        private certificateService: CertificateService,
        private httpClient: HttpClient,
        private domainService: DomainService

    ) {
    }

    copyIdentity (identity) {
      this.model = identity;
    }

    async ionViewDidEnter () {
      this.eventLogger.logScreen('setIdentity-page');
      this.schema = await this.httpClient.get('https://cert.arianee.org/version1/ArianeeBrandIdentity-i18n.json')
        .toPromise();

      this.load();
    }

    async loadIsApprovedIdentity () {
      const address = await this.walletCoreService.getAddress();
      const isApproved = await this.identityService.addressIsApproved([address]).toPromise();
      if (!isApproved) {
        this.addressApproved = false;
        this.utils.alert('Your public address is not approved. Please contact Arianee');
      } else {
        this.addressApproved = true;
      }
    }

    async loadWaitingIdentity () {
      try {
        const address = await this.walletCoreService.getAddress();
        const waiting = await this.identityService.waitingURI([address]).toPromise();
        if (waiting && waiting.length > 0) {
          const identity = await this.httpClient.get(waiting).toPromise();
          if (identity) {
            this.waitingIdentity = identity;
          }
        }
      } catch (e) {
        console.error(e);
        await this.uiAlert.error(
          'Unable to fetch waiting identity data'
        );
      }
    }

    async loadValidatedIdentity () {
      try {
        const address = await this.walletCoreService.getAddress();
        const result = await this.identityService.getIdentity([address]).toPromise();
        this.identity = result.data;
      } catch (e) {
        await this.uiAlert.error(
          'Unable to fetch validated identity data'
        );
      }
    }

    async load () {
      const loader = await this.uiAlert.load();
      await Promise.all([
        this.loadIsApprovedIdentity(),
        this.loadValidatedIdentity(),
        this.loadWaitingIdentity()
      ]);

      loader.dismiss();

      this.subscribe.domains = this.domainService.getDomains()
        .pipe(take(1))
        .subscribe((data: any) => {
          this.publicDomain = data.public_identity;
        });
    }

    async setNewIdentity (identityToStore) {
      identityToStore.rpcEndpoint = identityToStore.rpcEndpoint || httpOrHttps(`${this.publicDomain}/rpc`);
      this.copyIdentity(identityToStore);

      const identity = cleanObject(identityToStore);

      const loading = await this.loadingCtrl.create({
        spinner: 'dots',
        cssClass: 'loading',
        message: 'Waiting blockchain confirmation for <b>brand identity</b>.'
      });
      loading.present();

      try {
        await this.contentAPIService.storeIdentityContent(identity).toPromise();

        loading.dismiss();
        this.uiAlert.alert({
          message: 'Identity was successfully created !',
          cssClass: 'e2e_identitySuccess'
        });
      } catch (e) {
        loading.dismiss();
        const errorMSGAlert = await this.loadingCtrl.create({
          cssClass: 'e2e_identityError',
          message: 'Something went wrong.'
        });
        errorMSGAlert.present();
      }
    }
}
