import * as tslib_1 from "tslib";
import { LoadingController } from '@ionic/angular';
import { Utils } from '../../services/utils/utils';
import { take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { PaperCertService } from '../../services/paper-cert-service/paper-cert.service';
import { Location } from '@angular/common';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';
import { CertificateService } from '../../apiServices/BDHApi';
import { PublicConfigurationService } from '../../services/public-configuration-service/public-configuration-service';
import { CertificateCoreService } from '../../services/certificate-service/certificate-core.service';
/*
*
*  AsyncTransfer
*
*/
var AsyncTransfer = /** @class */ (function () {
    function AsyncTransfer(utils, loadingCtrl, activiatedRoute, paperCertService, location, eventLogger, certificateService, publicConfigurationService, certificateCoreService) {
        var _this = this;
        this.utils = utils;
        this.loadingCtrl = loadingCtrl;
        this.activiatedRoute = activiatedRoute;
        this.paperCertService = paperCertService;
        this.location = location;
        this.eventLogger = eventLogger;
        this.certificateService = certificateService;
        this.publicConfigurationService = publicConfigurationService;
        this.certificateCoreService = certificateCoreService;
        this.withUrl = true;
        this.subscribe = {};
        this.deeplinkUrl = '';
        this.setItemPassphrase = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var isRequestable, payload2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.passphraseSetted = true;
                        this.waiting = true;
                        if (!this.potentialPassphrase) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.certificateService
                                .getCertificate([this.itemId, this.potentialPassphrase, { isRequestable: true }])
                                .toPromise()];
                    case 1:
                        isRequestable = (_a.sent()).isRequestable;
                        if (isRequestable) {
                            this.waiting = false;
                            this.passphrase = this.potentialPassphrase;
                            payload2 = this.itemId + ',' + this.passphrase;
                            this.transactionSent = true;
                            this.qrCode = 'https://tools.arianee.org/qrcode.php?&size=200x200&logo=https://demo.arianee.org/demo/logoa.png&data=' + payload2;
                            this.qrCode2 = 'https://tools.arianee.org/qrcode.php?&chld=L&size=200x200&data=' + payload2;
                        }
                        else {
                            this.newPassphrase();
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        this.newPassphrase();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.utils.getPaperTplList().then(function (data) {
            _this.paperTplList = data;
            _this.selectedPaperTpl = _this.paperTplList[0];
        });
        // certificate/tranfert/:certificateId/:passphrase
        this.itemId = parseInt(activiatedRoute.snapshot.paramMap.get('certificateId'));
        this._getItem();
        this.passphrase = activiatedRoute.snapshot.paramMap.get('passphrase');
        this.deeplinkUrl = this.utils.deeplink();
    }
    AsyncTransfer.prototype.ionViewDidEnter = function () {
        this.eventLogger.logScreen('asyncTransfer-page', { itemId: this.itemId });
    };
    AsyncTransfer.prototype._getItem = function () {
        var _this = this;
        this.certificateCoreService.getCertificate(this.itemId)
            .pipe(take(1))
            .subscribe(function (data) {
            _this.item = JSON.parse(data.json);
            _this.potentialPassphrase = data.viewKey;
            _this.setItemPassphrase();
        });
    };
    AsyncTransfer.prototype.navigateBack = function () {
        this.location.back();
    };
    AsyncTransfer.prototype.newPassphrase = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var loading, payload2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.passphrase = this.potentialPassphrase;
                        if (!this.passphrase) {
                            this.passphrase = Math.random().toString(36).substring(2, 8) + Math.random().toString(36).substring(2, 8);
                        }
                        return [4 /*yield*/, this.loadingCtrl.create({
                                spinner: 'dots',
                                message: 'Waiting blockchain confirmation'
                            })];
                    case 1:
                        loading = _a.sent();
                        loading.present();
                        return [4 /*yield*/, this.certificateService.createCertificateRequestOwnershipLink([this.itemId, this.passphrase])];
                    case 2:
                        _a.sent();
                        this.waiting = false;
                        loading.dismiss();
                        payload2 = this.item + ',' + this.passphrase;
                        this.transactionSent = true;
                        this.qrCode = 'https://tools.arianee.org/qrcode.php?&size=200x200&logo=https://demo.arianee.org/demo/logoa.png&data=' + payload2;
                        this.qrCode2 = 'https://tools.arianee.org/qrcode.php?&chld=L&size=200x200&data=' + payload2;
                        return [2 /*return*/];
                }
            });
        });
    };
    AsyncTransfer.prototype.paperCert = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var loading;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadingCtrl.create({
                            spinner: 'dots',
                            message: 'Generate PDF'
                        })];
                    case 1:
                        loading = _a.sent();
                        loading.present();
                        return [4 /*yield*/, this.paperCertService.generatePaperCert([this.itemId], this.selectedPaperTpl.name)];
                    case 2:
                        _a.sent();
                        loading.dismiss();
                        return [2 /*return*/];
                }
            });
        });
    };
    return AsyncTransfer;
}());
export { AsyncTransfer };
