import { Injectable } from '@angular/core';
import { FirebaseHttpClient } from '../firebaseHttpClient/firebaseHttpClient';
import { EndpointsService } from '../../apiServices/endpointsService/endpointsService';
import { Observable } from 'rxjs';

import { ProfileModel } from '../../models/Profile.model';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProfileService {
  constructor (
    private firebaseHttpClient: FirebaseHttpClient,
    private endpointService: EndpointsService
  ) {
  }

  /**
   * Get list of users
   * @returns {Observable<ProfileModel[]>}
   */
  public all = (): Observable<ProfileModel[]> => {
    return this.firebaseHttpClient.get(this.endpointService.endpoints.profile.list);
  };

  public getProfile = (name:string):Observable<ProfileModel> => {
    return this.firebaseHttpClient.get(this.endpointService.endpoints.profile.list,
      {
        params: {
          q: JSON.stringify({ name })
        }
      })
      .pipe(
        map(d => d[0])
      );
  }

  public create = (name, rights):Observable<ProfileModel> => {
    return this.firebaseHttpClient.post(this.endpointService.endpoints.profile.create, { name, rights });
  }

  public delete= (profileId) => {
    const url = this.endpointService.endpoints.profile.delete.replace(':id', profileId);
    return this.firebaseHttpClient.delete(url);
  }
}
