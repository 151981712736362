import * as tslib_1 from "tslib";
import { Utils } from '../../services/utils/utils';
import { AuthService } from '../../services/auth/auth';
import { map } from 'rxjs/operators';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';
import { CertificateCoreService } from '../../services/certificate-service/certificate-core.service';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { WalletCoreService } from '../../services/walletCoreService/walletCoreService';
/*
*
*  Reporting
*
*/
var Reporting = /** @class */ (function () {
    /*
    public barChartData = [
      {data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A'},
      {data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B'}
    ];
    */
    function Reporting(utils, authService, eventLogger, certificateService, loaderService, walletCoreService) {
        var _this = this;
        this.utils = utils;
        this.authService = authService;
        this.eventLogger = eventLogger;
        this.certificateService = certificateService;
        this.loaderService = loaderService;
        this.walletCoreService = walletCoreService;
        this.barChartOptions = {
            scales: {
                xAxes: [{
                        type: 'time',
                        tick: {
                            source: 'auto'
                        },
                        time: {
                            unit: 'day',
                            displayFormats: {
                                quarter: 'MMM YYYY'
                            }
                        }
                    }],
                yAxes: [{
                        id: 'y-axis-1',
                        ticks: {
                            beginAtZero: true,
                            stepSize: 10,
                            maxTicksLimit: 10
                        }
                    },
                    {
                        id: 'y-axis-2',
                        position: 'right',
                        ticks: {
                            beginAtZero: true,
                            stepSize: 10,
                            maxTicksLimit: 10
                        },
                        gridLines: {
                            display: false
                        }
                    }
                ]
            }
        };
        this.barChartType = 'bar';
        this.barChartLegend = true;
        this.barChartData2 = [{ data: [], label: 'Certificates Creation' }, {
                data: [],
                label: 'Cumulative',
                yAxisID: 'y-axis-2',
                type: 'line',
                lineTension: 0,
                fill: false,
                gridLines: {
                    drawOnChartArea: false
                }
            }];
        this.transferChartData2 = [{ data: [], label: 'First Transfer' }, {
                data: [],
                label: 'Cumulative',
                yAxisID: 'y-axis-2',
                type: 'line',
                lineTension: 0,
                fill: false,
                gridLines: {
                    drawOnChartArea: false
                }
            }];
        this.totalCertificate = 0;
        this.destroyedCertificate = 0;
        this.reservedCertificate = 0;
        this.totalFirstTransfer = 0;
        this.transferedAtLeastOnce = 0;
        this.totalOptin = 0;
        this.subscribe = {};
        this.loadStats = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var deadAddress, $totalHydatedCertificate, $totalReserved, $totalDestroyed, $transferdAtLeastOnce, $optin, _a, totalHydratedCertificate, reservedCertificate, destroyedCertificate, transferedAtLeastOnce, totalOptin;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        deadAddress = '0x000000000000000000000000000000000000dEaD';
                        $totalHydatedCertificate = this.certificateService.getCertificateCount({ isConsumed: { $ne: false } }).toPromise();
                        $totalReserved = this.certificateService.getCertificateCount({ isConsumed: false }).toPromise();
                        $totalDestroyed = this.certificateService.getCertificateCount({ owner: deadAddress }).toPromise();
                        $transferdAtLeastOnce = this.certificateService.getCertificateCount({
                            $nor: [{ owner: '0x000000000000000000000000000000000000dEaD' }, { owner: this.address }]
                        }).toPromise();
                        $optin = this.certificateService.getCertificateCount({
                            $nor: [{ owner: '0x000000000000000000000000000000000000dEaD' }, { owner: this.address }],
                            whitelist: true
                        }).toPromise();
                        return [4 /*yield*/, Promise.all([
                                $totalHydatedCertificate,
                                $totalReserved,
                                $totalDestroyed,
                                $transferdAtLeastOnce,
                                $optin
                            ])];
                    case 1:
                        _a = _b.sent(), totalHydratedCertificate = _a[0], reservedCertificate = _a[1], destroyedCertificate = _a[2], transferedAtLeastOnce = _a[3], totalOptin = _a[4];
                        this.reservedCertificate = reservedCertificate.count;
                        this.destroyedCertificate = destroyedCertificate.count;
                        this.totalCertificate = totalHydratedCertificate.count;
                        this.transferedAtLeastOnce = transferedAtLeastOnce.count;
                        this.totalOptin = totalOptin.count;
                        return [2 /*return*/];
                }
            });
        }); };
        this.extractAsCsvFile = function (fileType) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var loader, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loaderService.load()];
                    case 1:
                        loader = _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.certificateService.getReportInCsv()];
                    case 3:
                        _a.sent();
                        loader.dismiss();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _a.sent();
                        console.error(e_1);
                        loader.dismiss();
                        this.loaderService.error('an error occured. Please contact us');
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
    }
    Object.defineProperty(Reporting.prototype, "totalFirstTransferRatio", {
        get: function () {
            if (this.totalCertificate > 0) {
                return Math.round((this.transferedAtLeastOnce / this.totalCertificate) * 100);
            }
            else {
                return 0;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Reporting.prototype, "totalOptinRatio", {
        get: function () {
            if (this.totalOptin > 0) {
                return Math.round((this.totalOptin / this.transferedAtLeastOnce) * 100);
            }
            else {
                return 0;
            }
        },
        enumerable: true,
        configurable: true
    });
    Reporting.prototype.ionViewDidEnter = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var loader, _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.loaderService.load()];
                    case 1:
                        loader = _b.sent();
                        _a = this;
                        return [4 /*yield*/, this.walletCoreService.getAddress()];
                    case 2:
                        _a.address = _b.sent();
                        return [4 /*yield*/, this.loadStats()];
                    case 3:
                        _b.sent();
                        this.eventLogger.logScreen('reporting-page');
                        this.subscribe.json = this.certificateService.getCertificateList({ mintTimeStamp: { $gte: 0 } }, { mintTimeStamp: 1 }, 0)
                            .pipe(map(function (data) {
                            return data.map(function (data) {
                                // const id = a.payload.doc.id;
                                var json = {};
                                json.id = data.tokenId;
                                json.blockNumberCreation = data.blockNumberCreation;
                                json.mintDate = new Date(data.mintTimeStamp * 1000);
                                json.firstTransferDate = new Date(data.firstTransferTimeStamp * 1000);
                                json.firstTransfer = data.firstTransfer;
                                json.firstTransferTimeStamp = data.firstTransferTimeStamp;
                                json.destroyed = data.destroyed;
                                json.owner = data.owner;
                                if (data.owner === _this.address) {
                                    json.isOwner = true;
                                }
                                else {
                                    json.isOwner = false;
                                }
                                if (!data.events) {
                                    json.events = [];
                                }
                                else {
                                    json.events = data.events;
                                }
                                if (data.whitelist === true || data.whitelist === undefined) {
                                    json.whitelist = true;
                                }
                                else {
                                    json.whitelist = false;
                                }
                                return json;
                                // return { id, ...data };
                            });
                        })).subscribe(function (data) {
                            _this.calculateData(data);
                            loader.dismiss();
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    Reporting.prototype.calculateData = function (data) {
        var tmpData = {};
        var tmpDataCumulative = {};
        var tmpDataCumulativeCounter = 0;
        var tmpTransferData = {};
        var tmpTransferDataCumulative = {};
        var tmpTransferDataCumulativeCounter = 0;
        var optoutCounter = 0;
        data.forEach(function (item, idx, dataArray) {
            // Only real certificates
            if (!item.destroyed) {
                var datum = new Date(Date.UTC(item.mintDate.getUTCFullYear(), item.mintDate.getUTCMonth(), item.mintDate.getUTCDate(), 0, 0, 0));
                var timestamp = datum.getTime();
                if (item.whitelist === false) {
                    optoutCounter++;
                }
                if (!tmpData[timestamp]) {
                    tmpData[timestamp] = 1;
                }
                else {
                    tmpData[timestamp]++;
                }
                tmpDataCumulativeCounter++;
                tmpDataCumulative[timestamp] = tmpDataCumulativeCounter;
            }
        });
        var nowTimeStamp = new Date().valueOf();
        tmpDataCumulative[nowTimeStamp] = tmpDataCumulativeCounter;
        tmpData[nowTimeStamp] = 0;
        data.sort(function (a, b) { return (a.firstTransferTimeStamp > b.firstTransferTimeStamp || a.firstTransferTimeStamp === undefined) ? 1 : -1; });
        data.forEach(function (item, idx, dataArray) {
            // Only real certificates
            if (!item.destroyed) {
                if (item.firstTransfer) {
                    tmpTransferDataCumulativeCounter++;
                    var firstTransferDate = new Date(Date.UTC(item.firstTransferDate.getUTCFullYear(), item.firstTransferDate.getUTCMonth(), item.firstTransferDate.getUTCDate(), 0, 0, 0));
                    var firstTimestamp = firstTransferDate.getTime();
                    if (!tmpTransferData[firstTimestamp]) {
                        tmpTransferData[firstTimestamp] = 1;
                    }
                    else {
                        tmpTransferData[firstTimestamp]++;
                    }
                    tmpTransferDataCumulative[firstTimestamp] = tmpTransferDataCumulativeCounter;
                }
            }
        });
        tmpTransferDataCumulative[nowTimeStamp] = tmpTransferDataCumulativeCounter;
        tmpTransferData[nowTimeStamp] = 0;
        var prop;
        for (prop in tmpData) {
            this.barChartData2[0].data.push({ x: new Date(parseInt(prop)), y: tmpData[prop] });
            this.barChartData2[1].data.push({ x: new Date(parseInt(prop)), y: tmpDataCumulative[prop] });
        }
        var ordered = {};
        Object.keys(tmpTransferData).sort().forEach(function (key) {
            ordered[key] = tmpTransferData[key];
        });
        tmpTransferData = ordered;
        for (prop in tmpTransferData) {
            this.transferChartData2[0].data.push({ x: new Date(parseInt(prop)), y: tmpTransferData[prop] });
            this.transferChartData2[1].data.push({ x: new Date(parseInt(prop)), y: tmpTransferDataCumulative[prop] });
        }
        this.totalFirstTransfer = tmpTransferDataCumulativeCounter;
        this.barChartData = this.barChartData2;
        this.transferChartData = this.transferChartData2;
    };
    return Reporting;
}());
export { Reporting };
