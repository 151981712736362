import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AssetService } from './api/asset.service';
import { CertificateService } from './api/certificate.service';
import { EconomyService } from './api/economy.service';
import { EventService } from './api/event.service';
import { IdentityService } from './api/identity.service';
import { MessageService } from './api/message.service';
import { WalletService } from './api/wallet.service';
import { WhitelistService } from './api/whitelist.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    AssetService,
    CertificateService,
    EconomyService,
    EventService,
    IdentityService,
    MessageService,
    WalletService,
    WhitelistService]
})
export class ApiModule {
  public static forRoot (configurationFactory: () => Configuration): ModuleWithProviders {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }]
    };
  }

  constructor (@Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
