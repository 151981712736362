import * as tslib_1 from "tslib";
import { LoadingController, NavController } from '@ionic/angular';
import { Utils } from '../../services/utils/utils';
import { AuthService } from '../../services/auth/auth';
import { SchemaService } from '../../services/schema-service/schema-service';
import { Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { config } from '../../../config';
import { Router } from '@angular/router';
import { PaperCertService } from '../../services/paper-cert-service/paper-cert.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';
import { TransactionService } from '../../services/transaction-service/transaction-service';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { DecodeAbiService } from '../../services/decodeAbiService/decodeAbiService';
import { TransactionStatus } from '../../models/transactionStatus.enum';
import { distinctUntilChangedArrayOfObjectChanged, fakeWebSocketObservable } from '../../helpers/fakeWebSocketObservable/fakeWebSocketObservable';
import { UsersService } from '../../services/users-service/userService';
/*
*
*  Transaction Page
*
*/
var Transaction = /** @class */ (function () {
    function Transaction(navCtrl, utils, loadingCtrl, authService, schemaService, router, paperCertService, http, _firebaseAuth, transactionService, eventLogger, uiAlertService, decodeAbiSerivce, usersService) {
        var _this = this;
        this.navCtrl = navCtrl;
        this.utils = utils;
        this.loadingCtrl = loadingCtrl;
        this.authService = authService;
        this.schemaService = schemaService;
        this.router = router;
        this.paperCertService = paperCertService;
        this.http = http;
        this._firebaseAuth = _firebaseAuth;
        this.transactionService = transactionService;
        this.eventLogger = eventLogger;
        this.uiAlertService = uiAlertService;
        this.decodeAbiSerivce = decodeAbiSerivce;
        this.usersService = usersService;
        this.filterStatus = undefined;
        this.stillOwner = false;
        this.read = 'na';
        this.filter = false;
        this.message = {};
        this.subscribe = {};
        this.users = {};
        this.blockscoutBaseUrl = '';
        this.transactionStatus = TransactionStatus;
        this.displayFilter = function (filterName) {
            _this.loading.present();
            if (_this.filterStatus !== filterName) {
                _this.filterStatus = filterName;
                if (_this.subscribe.transaction) {
                    _this.subscribe.transaction.unsubscribe();
                }
                _this.watchTransactionByStatus(filterName);
            }
            else {
                _this.loading.dismiss();
            }
        };
        this.watchTransactionByStatus = function (status) {
            if (_this.$transactionSub) {
                _this.$transactionSub.unsubscribe();
            }
            var $transactions = fakeWebSocketObservable(_this.transactionService
                .getTransactionByStatus(status, 100));
            _this.$transactionSub = $transactions
                .pipe(distinctUntilChangedArrayOfObjectChanged('transactionHash'), _this.mapTransaction(), _this.tapSetTransaction())
                .subscribe(function (d) {
            });
        };
        this.tapSetTransaction = function () {
            return tap(function (data) {
                _this.items = data;
                _this.loading.dismiss();
            });
        };
        this.mapTransaction = function () {
            return map(function (data) {
                return data
                    .map(function (data) {
                    var call = _this.decodeAbiSerivce.decodeAbi(data.to, data.data);
                    if (call) {
                        var methodName = '<b>' + call.method + '</b>';
                        var methodParameters = call.names.map(function (parameterName, index) {
                            var value;
                            if (call.types[index] === 'bytes32') {
                                // @ts-ignore
                                var bytes32 = Buffer.from(call.inputs[index]);
                                value = "0x" + bytes32.toString('hex');
                            }
                            else if (call.types[index] === 'address') {
                                value = "0x" + call.inputs[index];
                            }
                            else {
                                value = call.inputs[index];
                            }
                            return parameterName + "=`" + value + "`";
                        }).join(', ');
                        data.call = methodName + "(" + methodParameters + ")";
                        data.contractName = call.contractName;
                    }
                    else {
                        data.contractName = 'Unknown contract';
                        data.call = data.data;
                    }
                    return data;
                });
            });
        };
        if (config.network === 'testnet') {
            this.blockscoutBaseUrl = 'https://blockscout.com/poa/sokol/tx/';
        }
        else {
            this.blockscoutBaseUrl = 'https://blockscout.com/poa/core/tx/';
        }
    }
    Transaction.prototype.ionViewWillLeave = function () {
        this.$transactionSub.unsubscribe();
    };
    Transaction.prototype.ionViewDidEnter = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loadingCtrl.create({
                                spinner: 'dots',
                                message: 'Loading transactions'
                            })];
                    case 1:
                        _a.loading = _b.sent();
                        this.loading.present();
                        this.eventLogger.logScreen('transaction-page');
                        return [4 /*yield*/, this.decodeAbiSerivce.initDecodeAbi()];
                    case 2:
                        _b.sent();
                        this.authService.$userState
                            .subscribe(function (state) {
                            if (state.isLoggedIn) {
                                _this.stillOwner = false;
                                _this.load();
                            }
                            else {
                                _this.navCtrl.navigateForward('login', { queryParams: { returnView: 'transaction' } });
                            }
                        });
                        this.displayFilter(TransactionStatus.minted);
                        return [2 /*return*/];
                }
            });
        });
    };
    Transaction.prototype.checkLoading = function (that) {
        if (!that.arianeeProtocol.firstLoading) {
            setTimeout(that.checkLoading, 1000, that);
        }
        else {
            that.load();
            that.loading.dismiss();
        }
    };
    Transaction.prototype.load = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var that;
            return tslib_1.__generator(this, function (_a) {
                that = this;
                this.usersService.all().subscribe(function (data) {
                    var user;
                    for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
                        user = data_1[_i];
                        that.users[user.userId] = user.email;
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    Transaction.prototype.resetTransaction = function (itemId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loadingCtrl.create({
                                spinner: 'dots',
                                message: 'Re creating a new transaction'
                            })];
                    case 1:
                        _a.loading = _b.sent();
                        this.loading.present();
                        this.transactionService.resetTransaction(itemId)
                            .subscribe(function (d) {
                            _this.loading.dismiss();
                        }, function (err) {
                            _this.loading.dismiss();
                            _this.uiAlertService.error('An error occured');
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    Transaction.prototype.resendTransaction = function (itemId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loadingCtrl.create({
                                spinner: 'dots',
                                message: 'Resending transaction'
                            })];
                    case 1:
                        _a.loading = _b.sent();
                        this.loading.present();
                        this.transactionService.resendTransaction(itemId)
                            .subscribe(function (d) {
                            _this.loading.dismiss();
                        }, function (err) {
                            _this.loading.dismiss();
                            _this.uiAlertService.error('An error occured');
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    return Transaction;
}());
export { Transaction };
