export const mongooseQueryFactory = (query: any, sort?: any, limit = 100) => {
  const options: { params: { q?: string, s?: string, limit: number } } = { params: { limit } };
  if (query) {
    options.params.q = JSON.stringify(query);
  }

  if (sort) {
    options.params.s = JSON.stringify(sort);
  }

  return options;
};
