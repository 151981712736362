import { Injectable } from '@angular/core';
import { FirebaseHttpClient } from '../services/firebaseHttpClient/firebaseHttpClient';
import { EndpointsService } from './endpointsService/endpointsService';

@Injectable({ providedIn: 'root' })
export class MainnetAPIService {
  constructor (
        private firebaseHttpClient: FirebaseHttpClient,
        private endpointService: EndpointsService) {

  }

  public getBalance (address?: string) {
    return this.firebaseHttpClient.get(this.endpointService.endpoints.mainnet.getBalance, [address]);
  }

  public aria20Balance (address: string) {
    return this.firebaseHttpClient.post(this.endpointService.endpoints.mainnet.aria20BalanceOf, [address]);
  }

  public aria20Transfer (address: string, value:string) {
    return this.firebaseHttpClient.post(this.endpointService.endpoints.mainnet.aria20Transfer, [address, value]);
  }
}
