/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./schema-builder.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i4 from "@ionic/angular";
import * as i5 from "@angular/common";
import * as i6 from "../../../../node_modules/ngx-schema-form/ngx-schema-form.ngfactory";
import * as i7 from "ngx-schema-form";
import * as i8 from "@angular/router";
import * as i9 from "./schema-builder.component";
import * as i10 from "../../services/utils/utils";
import * as i11 from "../../services/schema-service/schema-service";
import * as i12 from "../../apiServices/arianeejsAPIService";
var styles_SchemaBuilderComponent = [i0.styles];
var RenderType_SchemaBuilderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SchemaBuilderComponent, data: {} });
export { RenderType_SchemaBuilderComponent as RenderType_SchemaBuilderComponent };
function View_SchemaBuilderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [["data-e2e", "select-schema2"]], null, null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.id; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.title; _ck(_v, 3, 0, currVal_2); }); }
function View_SchemaBuilderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "ion-padding-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ion-label", [], null, null, null, i3.View_IonLabel_0, i3.RenderType_IonLabel)), i1.ɵdid(2, 49152, null, 0, i4.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["Please select schema :"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "select", [["class", "select"], ["data-e2e", "select-schema"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.schemaSelectChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SchemaBuilderComponent_3)), i1.ɵdid(6, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.schemasSelectList; _ck(_v, 6, 0, currVal_0); }, null); }
function View_SchemaBuilderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "ion-card-content", [], null, null, null, i3.View_IonCardContent_0, i3.RenderType_IonCardContent)), i1.ɵdid(1, 49152, null, 0, i4.IonCardContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SchemaBuilderComponent_2)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 10, "sf-form", [], null, [[null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onChange" === en)) {
        var pd_0 = (_co.onChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_FormComponent_0, i6.RenderType_FormComponent)), i1.ɵprd(4608, null, i7.ɵf, i7.ɵf, []), i1.ɵprd(4608, null, i7.WidgetFactory, i7.WidgetFactory, [i7.WidgetRegistry, i1.ComponentFactoryResolver]), i1.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.FormComponent]), i1.ɵprd(512, null, i7.ɵc, i7.ɵc, []), i1.ɵprd(512, null, i7.ɵd, i7.ɵd, []), i1.ɵprd(1024, null, i7.ɵg, i7.ɵa, [i7.SchemaValidatorFactory, i7.ɵc, i7.ɵd, i7.ɵh]), i1.ɵprd(512, null, i7.ɵb, i7.ɵb, []), i1.ɵprd(512, null, i7.ɵe, i7.ɵe, []), i1.ɵprd(512, null, i7.TerminatorService, i7.TerminatorService, []), i1.ɵdid(14, 573440, null, 0, i7.FormComponent, [i7.ɵg, i7.ɵb, i7.ɵc, i7.ɵe, i1.ChangeDetectorRef, i7.TerminatorService], { schema: [0, "schema"], model: [1, "model"] }, { onChange: "onChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isOneOfList() && (_co.schemasSelectList.length > 1)); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.selectedSchemas; var currVal_2 = _co.documentModel; _ck(_v, 14, 0, currVal_1, currVal_2); }, null); }
function View_SchemaBuilderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ion-button", [["color", "primary"], ["data-e2e", "submit"], ["text-center", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSubmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_IonButton_0, i3.RenderType_IonButton)), i1.ɵdid(1, 49152, null, 0, i4.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "ion-icon", [["name", "create-outline"]], null, null, null, i3.View_IonIcon_0, i3.RenderType_IonIcon)), i1.ɵdid(3, 49152, null, 0, i4.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "span", [["class", "button__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var currVal_0 = "primary"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "create-outline"; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.buttonLabels.create; _ck(_v, 5, 0, currVal_2); }); }
function View_SchemaBuilderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ion-button", [["color", "primary"], ["text-center", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copy() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_IonButton_0, i3.RenderType_IonButton)), i1.ɵdid(1, 49152, null, 0, i4.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "ion-icon", [["name", "copy-outline"]], null, null, null, i3.View_IonIcon_0, i3.RenderType_IonIcon)), i1.ɵdid(3, 49152, null, 0, i4.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "span", [["class", "button__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var currVal_0 = "primary"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "copy-outline"; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.buttonLabels.copy; _ck(_v, 5, 0, currVal_2); }); }
function View_SchemaBuilderComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ion-button", [["color", "primary"], ["text-center", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.paste() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_IonButton_0, i3.RenderType_IonButton)), i1.ɵdid(1, 49152, null, 0, i4.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "ion-icon", [["name", "clipboard-outline"]], null, null, null, i3.View_IonIcon_0, i3.RenderType_IonIcon)), i1.ɵdid(3, 49152, null, 0, i4.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "span", [["class", "button__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var currVal_0 = "primary"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "clipboard-outline"; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.buttonLabels.paste; _ck(_v, 5, 0, currVal_2); }); }
function View_SchemaBuilderComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.creditAsset; _ck(_v, 1, 0, currVal_0); }); }
function View_SchemaBuilderComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "ion-card-content", [], null, null, null, i3.View_IonCardContent_0, i3.RenderType_IonCardContent)), i1.ɵdid(1, 49152, null, 0, i4.IonCardContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 6, "a", [["routerLink", "/settings"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 737280, null, 0, i4.RouterLinkDelegate, [i5.LocationStrategy, i4.NavController, i1.ElementRef, i8.Router, [2, i8.RouterLink]], null, null), i1.ɵdid(4, 671744, null, 0, i8.RouterLinkWithHref, [i8.Router, i8.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", " credit left: "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SchemaBuilderComponent_8)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_2 = "/settings"; _ck(_v, 4, 0, currVal_2); var currVal_4 = _co.creditAsset; _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _co.assetType; _ck(_v, 6, 0, currVal_3); }); }
export function View_SchemaBuilderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "ion-card", [], null, null, null, i3.View_IonCard_0, i3.RenderType_IonCard)), i1.ɵdid(1, 49152, null, 0, i4.IonCard, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SchemaBuilderComponent_1)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(0, 0), (_l()(), i1.ɵeld(5, 0, null, 0, 7, "ion-card-content", [], null, null, null, i3.View_IonCardContent_0, i3.RenderType_IonCardContent)), i1.ɵdid(6, 49152, null, 0, i4.IonCardContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SchemaBuilderComponent_4)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SchemaBuilderComponent_5)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SchemaBuilderComponent_6)), i1.ɵdid(12, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SchemaBuilderComponent_7)), i1.ɵdid(14, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedSchemas; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.showButtons.create; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.showButtons.copy; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.showButtons.paste; _ck(_v, 12, 0, currVal_3); var currVal_4 = _co.assetType; _ck(_v, 14, 0, currVal_4); }, null); }
export function View_SchemaBuilderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "schema-builder", [], null, null, null, View_SchemaBuilderComponent_0, RenderType_SchemaBuilderComponent)), i1.ɵdid(1, 8437760, null, 0, i9.SchemaBuilderComponent, [i10.Utils, i1.Renderer2, i11.SchemaService, i1.ElementRef, i12.ArianeejsAPIService], null, null)], null, null); }
var SchemaBuilderComponentNgFactory = i1.ɵccf("schema-builder", i9.SchemaBuilderComponent, View_SchemaBuilderComponent_Host_0, { showButtons: "showButtons", assetType: "assetType", documentModel: "documentModel", buttonLabels: "buttonLabels", schemaUrls: "schemaUrls" }, { onSubmitEmitter: "onSubmit", onChangeEmitter: "onChange" }, ["*"]);
export { SchemaBuilderComponentNgFactory as SchemaBuilderComponentNgFactory };
