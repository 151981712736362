import { Component } from '@angular/core';

@Component({
  selector: 'arianee-loader',
  styleUrls: ['./arianeeLoaderComponent.scss'],
  template: '<div class="arianee-logo"></div>'
})

export class ArianeeLoaderComponent {
}
