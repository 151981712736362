import { Component } from '@angular/core';

import { LoadingController } from '@ionic/angular';
import { AutoMessages } from '../../pages/autoMessages/autoMessages';
import { AuthService } from '../../services/auth/auth';
import { config } from '../../../config';
import { filter, skip } from 'rxjs/operators';
import { Router } from '@angular/router';
import { version } from '../../../../package.json';
import { IdentityService } from '../../apiServices/BDHApi';
import { WalletCoreService } from '../../services/walletCoreService/walletCoreService';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { fakeWebSocketObservable } from '../../helpers/fakeWebSocketObservable/fakeWebSocketObservable';
import { TransactionStatus } from '../../models/transactionStatus.enum';
import { TransactionService } from '../../services/transaction-service/transaction-service';
import { CertificateCoreService } from '../../services/certificate-service/certificate-core.service';

@Component({
  selector: 'app-logged',
  templateUrl: 'logged.component.html',
  styleUrls: ['logged.component.scss']

})
export class LoggedComponent {
  // @ts-ignore
  public navCtrl: any;
  public token: any = '';
  public logged = false;
  public admin = false;
  public developerCount = 0;
  public config;
  public subscribeLogged = false;
  public brandLogo:any;
  public version = version;
  public transactionsPending;
  public certificateNotSync;

  public subscriptions;

  blockiesOptions: Object = { // All options are optional
    seed: 'randstring', // seed used to generate icon data, default: random
    color: '#ffb200', // to manually specify the icon color, default: random
    bgcolor: '#5c5c5c', // choose a different background color, default: random
    size: 8, // width/height of the icon in blocks, default: 8
    scale: 4, // width/height of each block in pixels, default: 4
    spotcolor: '#000' // each pixel has a 13% chance of being of a third color,
    // default: random. Set to -1 to disable it. These "spots" create structures
    // that look like eyes, mouths and noses.
  };

  constructor (
      public authService: AuthService,
      public loadingCtrl: LoadingController,
      private router: Router,
      private identityService: IdentityService,
      private walletCoreService: WalletCoreService,
      private uiAlertService: UIAlertService,
      private transactionService: TransactionService,
      private certificateService: CertificateCoreService
  ) {
    this.config = config;

    this.subscribeLogged = true;
    this.initArianeeProtocol();
  }

  async getIdentityLogo () {
    const address = await this.walletCoreService.getAddress();
    const identityObject = await this.identityService.getIdentity([address]).toPromise();
    if (identityObject && identityObject.data.pictures) {
      this.brandLogo = identityObject.data.pictures.find(element => element.type === 'brandLogoHeader');
    }
  }

  initArianeeProtocol () {
    this.authService.$userState
      .pipe(filter(d => d.isLoggedIn))
      .subscribe(async (state) => {
        const [userDetails, profile] = await Promise.all([this.authService.getUserDetails(), this.authService.getUserProfile()]);

        // @ts-ignore
        const token = await userDetails.getIdToken(true);

        this.logged = true;
        this.admin = (profile === 'admin');

        fakeWebSocketObservable(this.certificateService
          .getCertificateCount({ isSync: false }))
          .subscribe((data) => {
            this.certificateNotSync = data.count;
          });

        fakeWebSocketObservable(this.transactionService
          .getTransactionCountByStatus([TransactionStatus.waiting, TransactionStatus.pending]))
          .subscribe((data) => {
            this.transactionsPending = data.count;
          });

        this.getIdentityLogo();
      });
  }

  autoMessages () {
    this.navCtrl.setRoot(AutoMessages);
  }

  iterateForDev () {
    if (this.admin) {
      this.developerCount++;
    }
  }

  async logout () {
    const buttons = [
      {
        text: 'Cancel',
        role: 'cancel'
      },
      {
        text: 'Logout',
        handler: async () => {
          this.logged = false;
          this.authService.logout();
          const d = await this.authService.$userState
            .pipe(skip(1))
            .toPromise();
          await this.router.navigate(['login']);
        }
      }
    ];
    this.uiAlertService.alert({
      message: 'Are you sure you want to log out?',
      buttons
    });
  }
}
