import * as tslib_1 from "tslib";
import { ToastController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { config } from '../../../config';
import { AngularFireAuth } from '@angular/fire/auth';
import { Utils } from '../../services/utils/utils';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';
import { PublicConfigurationService } from '../../services/public-configuration-service/public-configuration-service';
var Admin = /** @class */ (function () {
    function Admin(http, _firebaseAuth, toastCtrl, utils, eventLogger, publicConfigurationService) {
        var _this = this;
        this.http = http;
        this._firebaseAuth = _firebaseAuth;
        this.toastCtrl = toastCtrl;
        this.utils = utils;
        this.eventLogger = eventLogger;
        this.publicConfigurationService = publicConfigurationService;
        this.recovery = {};
        this.subscribe = {};
        var that = this;
        this.getAutoBackupStatus();
        var tmpPaperTplList;
        this.publicConfigurationService.getByName('paperTemplate')
            .subscribe(function (paperTplDocument) {
            tmpPaperTplList = that.utils.getPaperTplListComplete();
            if (paperTplDocument) {
                _this.paperTplDoc = paperTplDocument;
                var paperTplConfigs_1 = paperTplDocument.value;
                tmpPaperTplList = tmpPaperTplList.map(function (paperTpl) {
                    var config = paperTplConfigs_1.find(function (config) { return config.name === paperTpl.name; });
                    paperTpl.value = config ? config.value : true;
                    return paperTpl;
                });
            }
            else {
                _this.createPaperTplDoc(tmpPaperTplList);
            }
            _this.paperTplList = tmpPaperTplList;
        });
    }
    Admin.prototype.ionViewDidEnter = function () {
        this.eventLogger.logScreen('admin-page');
    };
    Admin.prototype.createPaperTplDoc = function (tmpPaperTplList) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.publicConfigurationService.create({ name: 'paperTemplate', value: tmpPaperTplList }).toPromise()];
                    case 1:
                        _a.paperTplDoc = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Admin.prototype.changePaperTpl = function () {
        this.paperTplDoc.value = this.paperTplList;
        this.publicConfigurationService.update(this.paperTplDoc._id, this.paperTplDoc).toPromise();
    };
    Admin.prototype.getAutoBackupStatus = function () {
        var _this = this;
        this.publicConfigurationService.getByName('backupauto')
            .subscribe(function (backupAuto) {
            _this.backupAutoDoc = backupAuto || { name: 'backupauto', value: false };
        });
    };
    Admin.prototype.changeBackupStatus = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.backupAutoDoc._id) return [3 /*break*/, 1];
                        this.publicConfigurationService.update(this.backupAutoDoc._id, this.backupAutoDoc).toPromise();
                        return [3 /*break*/, 3];
                    case 1:
                        _a = this;
                        return [4 /*yield*/, this.publicConfigurationService.create(this.backupAutoDoc).toPromise()];
                    case 2:
                        _a.backupAutoDoc = _b.sent();
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    Admin.prototype.backupEverything = function () {
        var that = this;
        this._firebaseAuth.auth.currentUser.getIdToken(true).then(function (idToken) {
            var _this = this;
            that.http.post('https://' + config.authDomain + '/init/backup', { idToken: idToken }, { responseType: 'json' })
                .subscribe(function (msg) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var toast;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, that.toastCtrl.create({
                                message: 'Certificates, assets and users are backed',
                                duration: 2000,
                                position: 'top'
                            })];
                        case 1:
                            toast = _a.sent();
                            toast.present();
                            return [2 /*return*/];
                    }
                });
            }); });
        });
    };
    Admin.prototype.recover = function () {
        var that = this;
        this._firebaseAuth.auth.currentUser.getIdToken(true).then(function (idToken) {
            var _this = this;
            var url = that.recovery.id ? 'https://' + config.authDomain + '/recovery/collection/' + that.recovery.collection + '/' + that.recovery.id : 'https://' + config.authDomain + '/recovery/collection/' + that.recovery.collection;
            that.http.post(url, { idToken: idToken }, { responseType: 'json' })
                .subscribe(function (msg) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var toast;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, that.toastCtrl.create({
                                message: 'Data recovered',
                                duration: 2000,
                                position: 'top'
                            })];
                        case 1:
                            toast = _a.sent();
                            toast.present();
                            return [2 /*return*/];
                    }
                });
            }); });
        });
    };
    return Admin;
}());
export { Admin };
