import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SlackService = /** @class */ (function () {
    function SlackService(http) {
        this.http = http;
    }
    SlackService.prototype.sendMessage = function (text, channel) {
        if (channel === void 0) { channel = '#alerts'; }
        var options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        };
        var body = {
            text: text,
            channel: '#alerts'
        };
        this.http.post('https://hooks.slack.com/services/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', body, options)
            .pipe(catchError(function (d) { return EMPTY; }))
            .subscribe();
    };
    SlackService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SlackService_Factory() { return new SlackService(i0.ɵɵinject(i1.HttpClient)); }, token: SlackService, providedIn: "root" });
    return SlackService;
}());
export { SlackService };
