import * as tslib_1 from "tslib";
import { WalletService } from '../../apiServices/BDHApi';
import { config } from '../../../config';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "../../apiServices/BDHApi/api/wallet.service";
import * as i2 from "@angular/common/http";
var WalletCoreService = /** @class */ (function () {
    function WalletCoreService(walletService, httpClient) {
        var _this = this;
        this.walletService = walletService;
        this.httpClient = httpClient;
        this.getContractAddress = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var url;
            return tslib_1.__generator(this, function (_a) {
                url = config.network === 'mainnet'
                    ? 'https://cert.arianee.org/contractAddresses/newpoacore.json'
                    : 'https://cert.arianee.org/contractAddresses/newsokol.json';
                return [2 /*return*/, this.httpClient.get(url).toPromise()];
            });
        }); };
        this.getAddress = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (this.address$ === undefined) {
                    this.address$ = this.walletService.getWalletAddress()
                        .toPromise()
                        .then(function (d) { return d.address; });
                }
                return [2 /*return*/, this.address$];
            });
        }); };
    }
    WalletCoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WalletCoreService_Factory() { return new WalletCoreService(i0.ɵɵinject(i1.WalletService), i0.ɵɵinject(i2.HttpClient)); }, token: WalletCoreService, providedIn: "root" });
    return WalletCoreService;
}());
export { WalletCoreService };
