/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./jazzicon";
var styles_JazziconComponent = [];
var RenderType_JazziconComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_JazziconComponent, data: {} });
export { RenderType_JazziconComponent as RenderType_JazziconComponent };
export function View_JazziconComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { jazziconElement: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["jazziconElement", 1]], null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_JazziconComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "angular-jazzicon", [], null, null, null, View_JazziconComponent_0, RenderType_JazziconComponent)), i0.ɵdid(1, 4243456, null, 0, i1.JazziconComponent, [i0.Renderer2], null, null)], null, null); }
var JazziconComponentNgFactory = i0.ɵccf("angular-jazzicon", i1.JazziconComponent, View_JazziconComponent_Host_0, { seed: "seed", size: "size" }, {}, []);
export { JazziconComponentNgFactory as JazziconComponentNgFactory };
