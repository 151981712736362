<ion-header>
    <div color="light" class="header">
        <div>
            <ion-menu-button></ion-menu-button>
            <ion-title>Auto Message</ion-title>
        </div>
    </div>
</ion-header>

<ion-content class="item text-center" *ngIf="schema">
      <ion-card >
          <ion-card-content >
                  <ion-item>
                      <span>Enable autoMessaging on each transfer</span>
                      <ion-toggle rows="5" cols="80" [(ngModel)]="message.enable" color="primary"
                                  checked="message.enable" name="enable"></ion-toggle>
                  </ion-item>
              <p>A message will be automatically sent to the new owner after each transfer.</p>
          </ion-card-content>
      </ion-card >
    <schema-builder
            [buttonLabels]="{'create':'Update'}"
            [documentModel]="message.content"
            assetType="message"
            (onSubmit)="updateAutoMessages($event)"
            [schemaUrls]="['https://cert.arianee.org/version1/ArianeeMessage-i18n.json']"
    >
    </schema-builder>
</ion-content>
