<ion-header>
    <div color="light" class="header">
        <div>
            <ion-title>Admin</ion-title>
        </div>
    </div>
</ion-header>

<ion-content class="item text-center">
    <ion-card>
        <ion-card-header><b>Back up</b></ion-card-header>
        <ion-item *ngIf="backupAutoDoc">
            <ion-label>Back up auto</ion-label>
            <ion-toggle (ionChange)="changeBackupStatus()" color="custom" [(ngModel)]="backupAutoDoc.value"></ion-toggle>
        </ion-item>

        <button ion-button item-end color="custom" (click)="backupEverything()">Backup all</button>
    </ion-card>

    <ion-card>
        <ion-card-header><b>Recovery</b></ion-card-header>
        <div style="position:relative">
            <ion-select [(ngModel)]="recovery.collection" name="collection" placeholder="collection">
                <ion-select-option value="json">Certificate</ion-select-option>
                <ion-select-option value="users">Users</ion-select-option>
                <ion-select-option value="public">Assets</ion-select-option>
            </ion-select>
        </div>
        <ion-item>
            <ion-label>Id (optional)</ion-label>
            <ion-input type="text" [(ngModel)]="recovery.id" name="recoveryId"></ion-input>
        </ion-item>
        <button ion-button item-end color="custom" (click)="recover()">Recover</button>
    </ion-card>
    <ion-card>
        <ion-card-header><b>Paper Certificates Template</b></ion-card-header>
        <ion-item *ngFor="let paperTplItem of paperTplList" >
            <ion-label>{{paperTplItem.title}}</ion-label>
            <ion-toggle (ionChange)="changePaperTpl()" color="custom" [(ngModel)]="paperTplItem.value"></ion-toggle>
        </ion-item>

    </ion-card>
</ion-content>
