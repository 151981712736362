<ion-header>
    <div color="light" class="header">
        <div>
            <ion-menu-button></ion-menu-button>
            <ion-title>Certificate</ion-title>
        </div>
  </div>
</ion-header>

<ion-content class="item text-center" data-e2e="certificate_details_page">

        <ion-grid *ngIf="item">
                <ion-row>
    <ion-col class="first-col col">

            <div class="contents" *ngIf="item" style="width:80%; margin:auto">
                <certificate-viewer [item]="item" [itemId]="itemId" >

                </certificate-viewer>

            </div>




    </ion-col>
    <ion-col class="second-col col">


        <ion-card *ngIf="item" class="ion-padding">
            <ion-card-content>
                <div class="ion-padding-bottom">
                    <h2> Certificate Id (ArianeeId): {{itemId}} </h2>
                    <small> Created  {{createdAt | amTimeAgo }}</small>
                </div>
                <div
                        class="ion-padding-bottom"
                        *ngIf="canDisplayQRCode">
                    <ion-item style="display:none">
                            <ion-label>Passphrase</ion-label>
                            <ion-input type="text" [(ngModel)]="passphrase" name="passphrase" placeholder="optional"></ion-input>
                    </ion-item>
                    <ion-button expand="full"
                                data-e2e="asynchronous_transfer_button"
                                color="primary"
                                (click)="asynchTransferItem()">
                        <ion-icon name="qr-code-outline"></ion-icon>
                        <div class="button__title">QR code</div>

                    </ion-button>
                    <h4 class="animated ">Display QR code of this certificate and Arianee Link.</h4>
                </div>
                <div
                        class="ion-padding-bottom"
                        *ngIf="canSendMessage">
                        <ion-button
                                data-e2e="certifDetails-createdMessage"

                                expand="full" color="primary" (click)="navigateTosendDmessage()">
                            <ion-icon name="chatbubble-ellipses-outline" class="ion-margin-right"></ion-icon>
                            <div class="button__title">Send Message</div>
                        </ion-button>
                        <h4 class="animated ">Send a decentralized message to owner</h4>

                    <ng-container *ngIf="item&&!isBrandAthorizedToMessage&&authService.hasRight('messageSend')">

                        <ion-card-content style="bottom:inherit;padding:0 15%;">

                            <h2>Owner opt-out from messaging</h2>
                        </ion-card-content>

                    </ng-container>
                </div>
                <div
                        class="ion-padding-bottom"
                        *ngIf="this.canCreateEvent">
                    <ion-button
                            expand="full"
                            data-e2e="certifDetails-createArianeeEvent"
                            [routerLink]="['/certificate/'+itemId+'/event/create']"
                            block color="primary">
                        <ion-icon name="calendar-outline"></ion-icon>
                        <div class="button__title">Create an event</div>
                    </ion-button>
                    <h4 class="animated ">Create an event for this certificate</h4>
                </div>

                <div
                        class="ion-padding-bottom"
                        *ngIf="canCertificateUpdate">
                    <ion-button expand="full" color="primary" (click)="update(item)">
                        <ion-icon name="create-outline"></ion-icon>
                        <div class="button__title">Update content</div>
                    </ion-button>
                        <h4 class="animated ">Update certificate content.</h4>
                </div>

                <div
                        class="ion-padding-bottom"
                        *ngIf="canCertificateCreate">
                    <ion-button expand="full" color="primary" (click)="duplicate(item)">
                        <ion-icon name="document-outline"></ion-icon>
                        <div class="button__title">Use as template</div>
                    </ion-button>
                        <h4 class="animated ">Pre-fill form with this certificate.</h4>
                </div>


                <div *ngIf="canRecover">
                    <div
                            class="ion-padding-bottom"
                            *ngIf="recoverable">

                        <ion-button expand="full" color="danger" (click)="recoverItem()">
                            <ion-icon name="reload-outline"></ion-icon>
                            <div class="button__title">Recover</div>
                        </ion-button>
                        <h4 class="animated ">
                            Recover this certificate</h4>

                    </div>

                </div>


                <div
                        class="ion-padding-bottom"
                        *ngIf="canDestroy">
                    <ion-button
                            expand="full"
                            data-e2e="certifDetails-destroy" block color="danger" (click)="destroyItem()">
                        <ion-icon name="trash-bin-outline"></ion-icon>
                        <div class="button__title">Destroy</div>
                    </ion-button>
                    <h4 class="animated ">Destroy this certificate</h4>
                </div>

            </ion-card-content>

        </ion-card>


        <ion-card *ngIf="itemsEvents">

            <ion-card-header>
                Transfers
            </ion-card-header>

            <ion-list>
                <div *ngFor="let itemsEvent of itemsEvents">
                    <ion-item
                            class="event__content"
                            *ngIf="!(itemsEvent.event=='EventCreated'&&!itemsEvent.json)">

                        <angular-jazzicon size="40" item-start
                                          [seed]="itemsEvent.returnValues._to"
                                          *ngIf="!itemsEvent.identity&&itemsEvent.event=='Transfer'&&itemsEvent.returnValues._to!='0x000000000000000000000000000000000000dEaD'"
                                          style="float:left;"></angular-jazzicon>


                        <angular-jazzicon size="40" item-start [seed]="itemsEvent.identity.address"
                                          *ngIf="!itemsEvent.identity.data"
                                          style="float:left;"></angular-jazzicon>

                        <div item-start *ngIf="itemsEvent.identity" >
                            <img src="{{findBrandLogoSquareFromIdentity(itemsEvent.identity.data)}}" height=40
                                 style="width:auto">
                        </div>


                        <div *ngIf="itemsEvent.returnValues._from=='0x0000000000000000000000000000000000000000'&&itemsEvent.event=='Transfer'">
                            Created {{  itemsEvent.timestamp | amFromUnix | amTimeAgo }} <span
                                *ngIf="itemsEvent.identity&&itemsEvent.identity.name">({{  itemsEvent.identity.name }}
                            )</span>
                        </div>

                        <div *ngIf="itemsEvent.returnValues._from!='0x0000000000000000000000000000000000000000'&&itemsEvent.event=='Transfer'">

                            <div *ngIf="itemsEvent.returnValues._to=='0x000000000000000000000000000000000000dEaD'">
                                Destroyed {{  itemsEvent.timestamp | amFromUnix | amTimeAgo }}
                            </div>

                            <div *ngIf="itemsEvent.returnValues._to!='0x000000000000000000000000000000000000dEaD'">
                                Received {{  itemsEvent.timestamp | amFromUnix | amTimeAgo }}  <span
                                    *ngIf="itemsEvent.identity&&itemsEvent.identity.name">({{  itemsEvent.identity.name }}
                                )</span>
                            </div>

                        </div>

                    </ion-item>
                </div>
            </ion-list>

        </ion-card>

        <ion-card *ngIf="eventsJSON?.length>0">
            <ion-card-header>
                Events
            </ion-card-header>
            <ion-list>
                <ion-item
                        class="event__content"
                        *ngFor="let event of eventsJSON">
                    <ng-container *ngIf="event.json&&event.json.title; else noJson">
                        <angular-jazzicon size="40" item-start [seed]="event.issuer"
                                          style="float:left;"></angular-jazzicon>
                        <p>{{event.json.title}} <span *ngIf="event.accepted">(accepted)</span></p><br/>
                        <p> Created {{  event.mintTimeStamp | amFromUnix | amTimeAgo }}</p>
                    </ng-container>
                    <ng-template #noJson>
                        <p>The content of this event is unreachable.</p>
                    </ng-template>
                </ion-item>
            </ion-list>
        </ion-card>
        <ion-card *ngIf="qrCode" class="ion-padding">
            <ion-card-content class="qrcode-content">
                <div>
                    Scan QR Code with your smartphone &
                    download the Arianee .Wallet app.
                    <br>
                    <a target="_blank" [href]="deepLink">Or display it directly in your browser.</a>
                </div>
                <img
                        data-e2e="asynchronous_qrcode"
                        [src]="qrCode"
                        width="150" height="150"/>
            </ion-card-content>
        </ion-card>

    </ion-col>
</ion-row>
</ion-grid>

</ion-content>
