// No critical credentials. This config is public (in frontend)


export var config = {
    apiKey: "AIzaSyDFCQdMYcCiHrsGlQskZPJsmTtL1leV70I",
    authDomain: "bdh-breitling.firebaseapp.com",
    databaseURL: "https://bdh-breitling.firebaseio.com",
    backendDomain:"api.bdh-breitling.arianee.com",
    projectId: "bdh-breitling",
    storageBucket: "bdh-breitling.appspot.com",
    messagingSenderId: "983325550128",
    network:'mainnet',
    web3Provider: "",
    web3ProviderHeader:undefined,
    featureFlips:{
      arianeeEvent:true,
      decentralizedMessage:true,
      transactionManager:true,
               i18NV2:true
      }
};
