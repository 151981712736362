<ion-header>
    <div color="light" class="header">
        <ion-menu-button></ion-menu-button>

        <ion-title *ngIf="method=='update'"><a (click)="navigateBack()"><ion-icon name="chevron-back-outline"></ion-icon></a> Certificate Update </ion-title>
        <ion-title *ngIf="method!='update'"> {{ 'pages.createCertificate.title' | translate }}</ion-title>

    </div>
</ion-header>


<ion-content id="form" data-e2e="certificate-builder" >
                 <schema-builder
                        [documentModel]="certificateDocumentModel"
                        [assetType]=assetType
                        (onSubmit)="executeBlockChainOperation($event)"
                        (onChange)="onChangeModel($event)"
                        [schemaUrls]="schemaUrls"
                >
                     <ion-item>
                         <ion-label>Tag as parent certificate</ion-label>
                         <ion-checkbox [(ngModel)]="isParent" name="transferable"></ion-checkbox>
                     </ion-item>
                     <ion-item *ngIf="isParent">
                         <ion-label>Parent Title*</ion-label>
                         <ion-input [(ngModel)]="parentCertificateTitle" required></ion-input>
                     </ion-item>
                     <ion-item>
                         <ion-label>Tags (separate tags with comma)</ion-label>
                         <ion-input [(ngModel)]="customTags" required></ion-input>
                     </ion-item>
                    <ion-item *ngIf="method!='update'">
                        <ion-label>Directly Transferable</ion-label>
                        <ion-checkbox checked="true"  [(ngModel)]="sameRequestOwnershipPassphrase" name="transferable"></ion-checkbox>
                    </ion-item>
                    <ion-item>

                        <ion-label>Arianee ID</ion-label>
                        <ion-input data-e2e="jsonbuild-tokenId" [disabled]="method=='update'" [(ngModel)]="tokenIdInput" name="tokenId" type="number" placeholder="leave empty to create random id"></ion-input>
                    </ion-item>

                </schema-builder>

                <ion-card *ngIf="method!='update'">
                    <ion-card-content>
                        <fieldset>
                            <legend class="legend-bold">Duplicate Certificate</legend>
                        </fieldset>
                        <ion-item>
                            <ion-label>Arianee IDs</ion-label>
                            <ion-textarea [(ngModel)]="arianeeIds" (ionChange)="updateArianeeId()" name="arianeeIds"
                                          placeholder="paste list of Arianee IDs  (1 per line)"></ion-textarea>
                        </ion-item>
                        <p>Leave blank for random IDs</p>

                        <ion-item>
                            <ion-label>Custom content</ion-label>
                            <ion-textarea
                                    data-e2e="duplicate_values"
                                    [(ngModel)]="duplicateValue" (ionChange)=updateDuplicateValue()
                                          name="duplicateValue" placeholder="paste list of values (1 per line)"></ion-textarea>
                        </ion-item>
                        <p> %ID% in certificate content will be replaced by these values</p>

                        <ion-item>
                            <ion-label>ViewKey list</ion-label>
                            <ion-textarea [(ngModel)]="recoverKey" (ionChange)=updateRecoverKey() name="recoverKey"
                                          placeholder="paste list of viewKeys (1 per line)"></ion-textarea>
                        </ion-item>
                        <p>Define viewKey/recoverKey per certificate</p>


                        <ion-button
                                data-e2e="duplicate_multiple_button"
                                (click)="duplicateCert()" text-center block color="primary"
                                [disabled]="!certNumber">Duplicate&nbsp;<span *ngIf="certNumber"> {{certNumber}} certificate(s)</span>
                        </ion-button>


                    </ion-card-content>
                </ion-card>



</ion-content>
<div id="viewer">
    <div class="contents" *ngIf="!templateForWysiwyg" text-center>
        <br/><br/>
        <img src="assets/img/loading.gif" width="50"/>
    </div>


    <div class="contents" *ngIf="templateForWysiwyg&&templateForWysiwyg.id==0">
        <ion-card text-wrap style="padding: 15px;" text-center>


            <h2>Certificate does not exist</h2>


        </ion-card>
    </div>

    <div id="" class="contents" *ngIf="templateForWysiwyg">


        <certificate-viewer [item]="templateForWysiwyg" [itemId]="certificateId"></certificate-viewer>

        <ion-card *ngIf="schemaErrors">
            <ion-card-content>
                <fieldset>
                    <legend class="legend-bold">Certificate Errors</legend>
                </fieldset>

                <ion-card *ngFor="let schemaError of schemaErrors">
                    <ion-list>
                        <ion-item> Field name: {{schemaError.mediaType}}</ion-item>
                        <ion-item> Type: {{schemaError.type}}</ion-item>
                        <ion-item> Url: {{schemaError.url}}</ion-item>
                        <ion-item>Expected ratio: {{schemaError.doctor.expectedRatio}}</ion-item>
                        <ion-item>Actual ratio: {{schemaError.doctor.actualRatio}}</ion-item>
                        <ion-item>Expected width: {{schemaError.doctor.expectedWidth}}</ion-item>
                        <ion-item>Actual width: {{schemaError.doctor.actualWidth}}</ion-item>
                        <ion-item> Expected height: {{schemaError.doctor.expectedHeight}}</ion-item>
                        <ion-item> Expected height: {{schemaError.doctor.actualHeight}}</ion-item>
                    </ion-list>
                </ion-card>

            </ion-card-content>
        </ion-card>
        <ion-card *ngIf="templateForWysiwyg&&templateForWysiwyg.v=='0.1'">
            <ion-list>
                <ion-item *ngFor="let externalContent of templateForWysiwyg.externalContents">
                    <div item-start *ngIf="externalContent.icon">
                        <img src="{{externalContent.icon}}" class="icon" item-start/>
                    </div>
                    <h2>{{externalContent.title}}</h2>
                    <ion-icon item-end name="arrow-dropright" color="primary"></ion-icon>

                </ion-item>
            </ion-list>

        </ion-card>


    </div>
</div>
