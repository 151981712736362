import { Component, Input } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { Utils } from '../../services/utils/utils';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../../services/auth/auth';
import { ActivatedRoute } from '@angular/router';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';
import { AssetService } from '../../apiServices/BDHApi';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { EndpointsService } from '../../apiServices/endpointsService/endpointsService';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AssetsService } from '../../services/assets-service/assetsService';
import { DomainService } from '../../services/domain-service/domain.service';
import { httpOrHttps } from '../../helpers/httpOrHttps.helper';
import {
  distinctUntilChangedArrayOfObjectChanged,
  fakeWebSocketObservable
} from '../../helpers/fakeWebSocketObservable/fakeWebSocketObservable';

@Component({
  selector: 'page-uploadAssets',
  styleUrls: ['./uploadAssets.scss'],
  templateUrl: 'uploadAssets.html'
})
export class UploadAssets {
    @Input() isModal: boolean = false;
    public itemId: any;
    public item: any;
    public uri: string;
    public publicFilesList: any = [];
    public filteredpublicFilesList: any = [];
    public publicDomain: string;
    public subscribe: any = {};
    public filter: string;
    public optimize = true;
    public filename;
    public loading;

    constructor (
        private route: ActivatedRoute,
        public utils: Utils,
        private afStorage: AngularFireStorage,
        private authService: AuthService,
        public loadingCtrl: LoadingController,
        private _firebaseAuth: AngularFireAuth,
        private modalController: ModalController,
        private eventLogger: EventLoggerService,
        private assetService: AssetService,
        private uiAlertService: UIAlertService,
        private endPointService:EndpointsService,
        private assetsService:AssetsService,
        private domainService:DomainService
    ) {

    }

    click (file) {
      if (this.isModal) {
        this.dismissModal(file);
      } else {
        window.open(this.getLink(file), 'blank');
      }
    }

    assignCopy () {
      this.filteredpublicFilesList = Object.assign([], this.publicFilesList);
    }

    filterItem (value) {
      if (!value) {
        this.assignCopy();
      } // when nothing has typed
      this.filteredpublicFilesList = Object.assign([], this.publicFilesList)
        .filter(
          item => (
            (this.filter && item.name.toLowerCase().indexOf(this.filter.toLowerCase()) > -1) || !this.filter
          )
        );
      if (value.stopPropagation) {
        value.stopPropagation();
      }
    }

    getLink=(file:string):string => {
      return httpOrHttps(`${this.publicDomain}/pub/${file}`);
    }

    async ionViewDidEnter () {
      this.eventLogger.logScreen('userManager-page');

      this.loading = await this.loadingCtrl.create({
        spinner: 'dots',
        message: 'Loading'
      });
      this.loading.present();

      const publicDomains = await this.domainService.getDomains()
        .pipe(take(1))
        .toPromise();

      // @ts-ignore
      this.publicDomain = publicDomains.public_domain;

      this.fetchAssets();
    }

    public fetchAssets = () => {
      const sortByCreatedAt = (a, b) => (a.createdAt > b.createdAt) ? -1 : 1;

      this.assetsService.all({},
        { createdAt: -1 },
        50)
        .pipe(take(1))
        .subscribe((data: any[]) => {
          const sortedData = data.map(d => {
            if (d.createdAt) {
              d.createdAt = d.createdAt.seconds * 1000;
            } else {
              d.createdAt = 0;
            }
            return d;
          }).sort(sortByCreatedAt);

          this.publicFilesList = sortedData;
          this.assignCopy();

          if (this.filename && this.isModal) {
            this.dismissModal(this.filename);
            this.filename = '';
          }
          this.loading.dismiss();
        });
    };

    dismissModal = async (fileName: string) => {
      this.modalController.dismiss(this.getLink(fileName));
    };

    async upload (event) {
      this.loading = await this.loadingCtrl.create({
        spinner: 'dots',
        message: 'Waiting upload to finish.'
      });
      this.loading.present();

      try {
        const resize = this.optimize;

        await this.assetService
          .postStaticAssetForm(event.target.files[0], resize)
          .toPromise();
        this.loading.dismiss();
        this.uiAlertService.success('Your asset has been uploaded successfully');
      } catch (e) {
        console.error(e);
        this.loading.dismiss();
        this.uiAlertService.error('An error occured while uploading your asset');
      }
      this.fetchAssets();
    }

    copyToClipboard (item) {
      this.utils.success('Copied to clipboard');
      document.addEventListener('copy', (e: ClipboardEvent) => {
        e.clipboardData.setData('text/plain', (item));
        e.preventDefault();
        document.removeEventListener('copy', null);
      });
      document.execCommand('copy');
    }
}
