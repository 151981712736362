import { AfterViewInit, Component, Input, Renderer2, ViewChild } from '@angular/core';
import jazzicon from 'jazzicon';

// example <angular-jazzicon seed="'zefozenfioeznfze'" ></angular-jazzicon>

@Component({
  selector: 'angular-jazzicon',
  template: '<div #jazziconElement></div>'
})
export class JazziconComponent implements AfterViewInit {
  @Input('seed') seed:string|number;
  @Input('size') size:number=100;
  @ViewChild('jazziconElement', { static: true })jazziconElement;

  constructor (private renderer:Renderer2) {}

  ngAfterViewInit (): void {
    let seedInteger = this.seed;
    if (typeof this.seed === 'string') {
      seedInteger = JazziconComponent.jsNumberForAddress(this.seed);
    }
    const value = jazzicon(this.size, seedInteger);
    this.renderer.appendChild(this.jazziconElement.nativeElement, value);
  }

  private static jsNumberForAddress (address) {
    const addr = address.slice(2, 10);
    return parseInt(addr, 16);
  }
}
