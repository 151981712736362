import * as tslib_1 from "tslib";
import { IsEmail, IsOptional, IsString } from 'class-validator';
var UserModel = /** @class */ (function () {
    function UserModel() {
    }
    tslib_1.__decorate([
        IsOptional(),
        IsString(),
        tslib_1.__metadata("design:type", String)
    ], UserModel.prototype, "userId", void 0);
    tslib_1.__decorate([
        IsString(),
        tslib_1.__metadata("design:type", String)
    ], UserModel.prototype, "profile", void 0);
    tslib_1.__decorate([
        IsEmail(),
        tslib_1.__metadata("design:type", String)
    ], UserModel.prototype, "email", void 0);
    tslib_1.__decorate([
        IsOptional(),
        IsString(),
        tslib_1.__metadata("design:type", String)
    ], UserModel.prototype, "apiKey", void 0);
    tslib_1.__decorate([
        IsOptional(),
        IsString(),
        tslib_1.__metadata("design:type", String)
    ], UserModel.prototype, "pubkey", void 0);
    tslib_1.__decorate([
        IsOptional(),
        IsString(),
        tslib_1.__metadata("design:type", Boolean)
    ], UserModel.prototype, "deleted", void 0);
    return UserModel;
}());
export { UserModel };
