import { Injectable } from '@angular/core';
import { FirebaseHttpClient } from '../services/firebaseHttpClient/firebaseHttpClient';
import { EndpointsService } from './endpointsService/endpointsService';

@Injectable({ providedIn: 'root' })
export class ArianeejsAPIService {
  constructor (
        private firebaseHttpClient: FirebaseHttpClient,
        private endpointService: EndpointsService) {

  }

  public chain () {
    return this.firebaseHttpClient.post(this.endpointService.endpoints.arianeejsServer.chain, []);
  }

  public createMessage (message: {
        contentImprint?: string;
        certificateId: number,
        content?: { $schema: string; [key: string]: any };
        messageId?: number;

    }) {
    return this.firebaseHttpClient.post(this.endpointService.endpoints.arianeejsServer.createMessage, [message]);
  }

  public createAndStoreArianeeEvent (event: {
        uri?: string;
        contentImprint?: string;
        certificateId: number,
        arianeeEventId?:number;
        content?: { $schema: string;[key: string]: any };
    }) {
    return this.firebaseHttpClient.post(this.endpointService.endpoints.arianeejsServer.createAndStoreArianeeEvent,
      [event]);
  }

  public createCertificate (certificate: {
        contentImprint?: string;
        certificateId?: number,
        passphrase?:string,
        content?: { $schema: string; [key: string]: any }
    }) {
    return this.firebaseHttpClient.post(this.endpointService.endpoints.arianeejsServer.createCertificate, [certificate]);
  }

  public updateCertificate (certificate:{
     certificateId: number,
        content: any
    }) {
    return this.firebaseHttpClient.post(this.endpointService.endpoints.arianeejsServer.updateAndStoreCertificate,
      [certificate]
    );
  }

  public createAndStoreMessage (data: {
        uri?: string;
        certificateId: number,
        content?: { $schema: string; [key: string]: any };
        messageId?: number;
    }) {
    const rpcURL = this.endpointService.endpoints.rpc.message;
    return this.firebaseHttpClient.post(this.endpointService.endpoints.arianeejsServer.createAndStoreMessage, [data, rpcURL]);
  }

  public balanceOfCredit (typeOfCredit: string) {
    return this.firebaseHttpClient.post(this.endpointService.endpoints.arianeejsServer.balanceOfCredit, [typeOfCredit]);
  }

  public getImprint (certificateId) {
    return this.firebaseHttpClient.post(this.endpointService.endpoints.arianeejsServer.getImprint, [certificateId]);
  }
}
