/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ionic/angular";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "./app.component";
import * as i6 from "./services/auth/auth";
import * as i7 from "./services/event-logger/event-logger-service";
import * as i8 from "./services/UI-alert-service/UI-alert-service";
import * as i9 from "./apiServices/BDHApi/api/wallet.service";
import * as i10 from "./services/firebaseHttpClient/firebaseHttpClient";
import * as i11 from "./services/walletCoreService/walletCoreService";
import * as i12 from "./apiServices/BDHApi/api/identity.service";
import * as i13 from "@ngx-translate/core";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "ion-router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.IonRouterOutlet, [i3.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], [8, null], i2.Config, i2.NavController, i4.Location, i1.ElementRef, i3.Router, i1.NgZone, i3.ActivatedRoute, [3, i2.IonRouterOutlet]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i5.AppComponent, [i6.AuthService, i2.LoadingController, i3.Router, i7.EventLoggerService, i8.UIAlertService, i9.WalletService, i10.FirebaseHttpClient, i11.WalletCoreService, i12.IdentityService, i13.TranslateService], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i5.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
