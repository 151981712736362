import { Injectable } from '@angular/core';
import { FirebaseHttpClient } from '../services/firebaseHttpClient/firebaseHttpClient';
import { EndpointsService } from './endpointsService/endpointsService';

@Injectable({ providedIn: 'root' })
export class ContentAPIService {
  constructor (
        private firebaseHttpClient: FirebaseHttpClient,
        private endpointService: EndpointsService) {

  }

  /**
     * Store content of certificate without passing by RPC Server
     * @param tokenId
     * @param json
     */
  public storeCertificateContent (tokenId, json) {
    return this.firebaseHttpClient.post(this.endpointService.endpoints.content.certificate, { tokenId, json });
  }

  /**
     * Store content of certificate without passing by RPC Server
     * @param tokenId
     * @param json
     */
  public doctorCertificate (json) {
    return this.firebaseHttpClient.post(this.endpointService.endpoints.certificate.doctor, { ...json });
  }

  /**
     * Store content of Event without passing by RPC Server
     * @param tokenId
     * @param eventId
     * @param json
     */
  public storeEventContent (tokenId, eventId, json) {
    return this.firebaseHttpClient.post(this.endpointService.endpoints.content.event, {
      tokenId,
      eventId,
      json
    });
  }

  /**
     * Store Message of Event without passing by RPC Server
     * @param tokenId
     * @param messageId
     * @param json
     */
  public storeMessageContent (tokenId, messageId, json) {
    return this.firebaseHttpClient.post(this.endpointService.endpoints.content.message, {
      tokenId,
      messageId,
      json
    });
  }

  /**
     * Store Brand identity
     * @param json
     * @param imprint
     */
  public storeIdentityContent (content) {
    return this.firebaseHttpClient.post(this.endpointService.endpoints.identity.create, {
      content
    });
  }
}
