import * as tslib_1 from "tslib";
import { Platform } from '@ionic/angular';
import * as amplitude from 'amplitude-js';
import { version } from '../../../../package.json';
import { config } from '../../../config';
import { AuthService } from '../auth/auth';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "../auth/auth";
var EventLoggerService = /** @class */ (function () {
    function EventLoggerService(platform, authService) {
        this.platform = platform;
        this.authService = authService;
        this.queueLogs = [];
    }
    EventLoggerService.prototype.init = function (userId) {
        var _this = this;
        var instance = amplitude.getInstance();
        instance.init('3f7bdb21badb340f48d1a4161391de4b', config.projectId);
        instance.setVersionName(version);
        this.authService.$userState
            .subscribe(function (state) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                instance.setUserProperties({ email: state.details.email });
                this.client = instance;
                return [2 /*return*/];
            });
        }); });
    };
    EventLoggerService.prototype.logScreen = function (screenName, params) {
        if (params === void 0) { params = {}; }
        this.logEvent('SCREEN_' + screenName, params);
    };
    EventLoggerService.prototype.logEvent = function (eventName, params) {
        if (params === void 0) { params = {}; }
        if (!this.client) {
            this.queueLogs.push({ eventName: eventName, params: params });
        }
        else {
            this.client.logEvent(eventName, params);
        }
    };
    EventLoggerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EventLoggerService_Factory() { return new EventLoggerService(i0.ɵɵinject(i1.Platform), i0.ɵɵinject(i2.AuthService)); }, token: EventLoggerService, providedIn: "root" });
    return EventLoggerService;
}());
export { EventLoggerService };
