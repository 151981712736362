import { HttpErrorResponse } from '@angular/common/http';
import * as Sentry from '@sentry/browser';
import { config } from '../../../config';
import { SlackService } from '../slack-service/slack-service';
import * as i0 from "@angular/core";
import * as i1 from "../slack-service/slack-service";
// @ts-ignore
var pjson = require('../../../../package.json');
var SentryErrorHandlerService = /** @class */ (function () {
    function SentryErrorHandlerService(slackService) {
        this.slackService = slackService;
        this.init();
    }
    SentryErrorHandlerService.prototype.init = function () {
        Sentry.init({
            dsn: 'https://7e8dd2c82d0c4ec6a4963151578f1a9b@o343653.ingest.sentry.io/5375837',
            // TryCatch has to be configured to disable XMLHttpRequest wrapping, as we are going to handle
            // http module exceptions manually in Angular's ErrorHandler and we don't want it to capture the same error twice.
            // Please note that TryCatch configuration requires at least @sentry/browser v5.16.0.
            integrations: [new Sentry.Integrations.TryCatch({
                    XMLHttpRequest: false
                })]
        });
        Sentry.configureScope(function (scope) {
            scope.setTag('bdhName', config.projectId);
            scope.setTag('network', config.network);
            Object.entries(function (key) {
                scope.setTag("featureFlips__" + key, config.featureFlips[key]);
            });
        });
    };
    SentryErrorHandlerService.prototype.extractError = function (error) {
        // Try to unwrap zone.js error.
        // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
        if (error && error.ngOriginalError) {
            error = error.ngOriginalError;
        }
        // We can handle messages and Error objects directly.
        if (typeof error === 'string' || error instanceof Error) {
            return error;
        }
        // If it's http module error, extract as much information from it as we can.
        if (error instanceof HttpErrorResponse) {
            // The `error` property of http exception can be either an `Error` object, which we can use directly...
            if (error.error instanceof Error) {
                return error.error;
            }
            // ... or an`ErrorEvent`, which can provide us with the message but no stack...
            if (error.error instanceof ErrorEvent) {
                return error.error.message;
            }
            // ...or the request body itself, which we can use as a message instead.
            if (typeof error.error === 'string') {
                return "Server returned code " + error.status + " with body \"" + error.error + "\"";
            }
            // If we don't have any detailed information, fallback to the request message itself.
            return error.message;
        }
        // Skip if there's no error, and let user decide what to do with it.
        return null;
    };
    SentryErrorHandlerService.prototype.handleError = function (error) {
        var extractedError = this.extractError(error) || 'Handled unknown error';
        // Capture handled exception and send it to Sentry.
        var eventId = Sentry.captureException(extractedError);
        console.error(extractedError);
        this.sendMessageToSlack(eventId);
        // Optionally show user dialog to provide details on what happened.
        // Sentry.showReportDialog({eventId});
    };
    SentryErrorHandlerService.prototype.sendMessageToSlack = function (eventId) {
        var urlLink = "https://sentry.io/organizations/arianee/issues/?project=5375837&query=+" + eventId + "&statsPeriod=1h";
        var version = pjson.version;
        this.slackService.sendMessage("[*Sentry: " + config.projectId + " | env " + config.network + " | version " + version + "*] new error occured: " + urlLink);
    };
    SentryErrorHandlerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SentryErrorHandlerService_Factory() { return new SentryErrorHandlerService(i0.ɵɵinject(i1.SlackService)); }, token: SentryErrorHandlerService, providedIn: "root" });
    return SentryErrorHandlerService;
}());
export { SentryErrorHandlerService };
