<ion-header>
    <div color="light" class="header">
        <div>
            <ion-menu-button></ion-menu-button>
            <ion-title>{{ 'pages.userAndProfile.title' | translate }}</ion-title>
        </div>
    </div>
</ion-header>


<ion-content class="item text-center">
    <ion-card>
        <ion-card-header>
            <ion-card-title>Users</ion-card-title>
        </ion-card-header>
        <ion-list>
            <div *ngFor="let user of users" >
            <ion-item [attr.data-e2e]="'list-user-'+user.email.split('@')[0]"  *ngIf="!user.deleted">
                    <ion-label>{{user.email}}</ion-label>
                    <ion-select item-content [(ngModel)]="user.profile" (ngModelChange)="profileChange(user)">

                        <ion-select-option *ngFor="let profile of profiles" [value]="profile.name">{{profile.name}}</ion-select-option>
                    </ion-select>
                    <ion-button ion-button item-end data-e2e='usersManager-reset' (click)="confirmReset(user.email)"
                                color="primary">Reset password
                    </ion-button>

                    <ion-button ion-button item-end data-e2e='usersManager-delete' (click)="confirmDelete(user)"
                                color="primary" [disabled]="myuid == user.id">Delete
                    </ion-button>

            </ion-item>
            </div>
        </ion-list>
    </ion-card>

    <ion-card *ngIf="authService.hasRight('admin')">
        <ion-card-header>
            <ion-card-title>Profiles</ion-card-title>
        </ion-card-header>
        <ion-list>
            <ion-item *ngFor="let profile of profiles">
                <ion-label>{{profile.name}}
                    <small>
                        <i>
                            <span *ngFor="let right of profile.rights; let last = last;">
                                    {{right}}<span *ngIf="!last">, </span>
                            </span>
                        </i>
                    </small>

                </ion-label>
                <ion-button ion-button item-end (click)="confirmDeleteProfile(profile)" color="primary"
                            [disabled]="profile.id=='admin'">Delete
                </ion-button>
            </ion-item>
        </ion-list>
    </ion-card>
</ion-content>


<ion-footer style="overflow: auto">
    <div class="footerHeader">
        <ion-title *ngIf="!addUser&&!addProfile">
            <ion-button ion-button (click)="toggleAddUser()" data-e2e="usersManager-CreateUser-toggle" color="primary">
                Create User
            </ion-button>
            <ion-button ion-button (click)="toggleAddProfile()" data-e2e="usersManager-CreateProfile-toggle"
                        color="primary" *ngIf="authService.hasRight('admin')">Create Profile
            </ion-button>
        </ion-title>
        <ion-title *ngIf="addUser">
            New user
        </ion-title>
        <ion-title *ngIf="addProfile">
            New profile
        </ion-title>
        <ion-buttons end *ngIf="addUser||addProfile">
            <ion-button ion-button icon-only (click)="toggleAll()">
                <ion-icon name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </div>


    <ion-toolbar *ngIf="addUser">
        <form (ngSubmit)="createUser()" [formGroup]="formGroup">
            <ion-item>
                <ion-label>Email</ion-label>
                <ion-input data-e2e="usersManager-CreateUser-email" type="email" formControlName="email"></ion-input>
            </ion-item>
            <ion-item>
                <ion-label>Password (minimum 6 characters)</ion-label>
                <ion-input type="password" data-e2e="usersManager-CreateUser-password" formControlName="password"
                           (minlength)="6"></ion-input>
            </ion-item>
            <div style="position:relative">
                <ion-select formControlName="profile" placeholder="profile" data-e2e="usersManager-CreateUser-profile">
                    <ion-select-option *ngFor="let profile of profiles" [value]="profile.name">{{profile.name}}</ion-select-option>
                </ion-select>
            </div>
            <ion-button ion-button type="submit"
                        data-e2e="usersManager-CreateUser-submit"
                        [disabled]="!formGroup.valid" block color="primary">Create User
            </ion-button>
        </form>
    </ion-toolbar>


    <ion-toolbar *ngIf="addProfile">
        <form (ngSubmit)="createProfile()">
            <ion-item>
                <ion-label>Profile name</ion-label>
                <ion-input type="text" [(ngModel)]="newProfile.name" name="profile"
                           placeholder="new profile title"></ion-input>
            </ion-item>
            <div style="position:relative">
                <ion-list>
                    <ion-item *ngFor="let right of newRights; let last = last;">
                        <ion-label>{{right.title}}</ion-label>
                        <ion-checkbox [ngModelOptions]="{standalone: true}" [(ngModel)]="right.checked"
                                      value="true"></ion-checkbox>
                    </ion-item>
                </ion-list>
            </div>
            <ion-button ion-button type="submit" block color="primary" [disabled]="!newProfile.name">Create Profile
            </ion-button>
        </form>
    </ion-toolbar>


</ion-footer>
