import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserSessionService } from '../../services/user-session-service/user-session-service';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';

@Injectable({
  providedIn: 'root'
})
export class CanLeavePreviousPage implements CanActivate {
  constructor (private userSessionService:UserSessionService, private uIAlertService:UIAlertService) {
  }

  async askUserToConfirmLeaving ():Promise<boolean> {
    let resolveLeaving;

    // eslint-disable-next-line no-return-assign
    const canLeave = new Promise<boolean>(resolve => resolveLeaving = resolve);

    const alert = await this.uIAlertService.alert({
      header: 'Confirm leave',
      message: 'You will lose your modifications if you leave the page. Are you sure you want to leave the page?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => resolveLeaving(false)
        },
        {
          text: 'Confirm',
          handler: () => {
            this.userSessionService.preventUserFromLeavingPage = true;
            return resolveLeaving(true);
          }
        }
      ]
    });

    return canLeave;
  }

  public canActivate (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot

  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.userSessionService.preventUserFromLeavingPage === false) {
      return this.askUserToConfirmLeaving();
    } else {
      return true;
    }
  }
}
