import { Injectable } from '@angular/core';
import { FirebaseHttpClient } from '../firebaseHttpClient/firebaseHttpClient';
import { EndpointsService } from '../../apiServices/endpointsService/endpointsService';
import { UserModel } from '../../models/User.model';
import { validate } from 'class-validator';
import { from, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UsersService {
  constructor (
        private firebaseHttpClient: FirebaseHttpClient,
        private endpointService: EndpointsService
  ) {
  }

    /**
     * Get list of users
     * @returns {Observable<UserModel[]>}
     */
    public all = (): Observable<UserModel[]> => {
      return this.firebaseHttpClient.get(this.endpointService.endpoints.user.list);
    };

    /**
     * Get a user from firebaseUid
     * @returns {Observable<UserModel>}
     */
    public getUserFromUserUid = (userId:string):Observable<UserModel> => {
      return this.firebaseHttpClient.get(this.endpointService.endpoints.user.list,
        {
          params: {
            q: JSON.stringify({ userId })
          }
        })
        .pipe(
          map(d => d[0])
        );
    }

  /**
   * Update a user
   * @param {UserModel} user
   * @returns {Observable<UserModel>}
   */
  public update = (user: UserModel): Observable<UserModel> => {
    return from(validate(UserModel))
      .pipe(mergeMap(() => this.firebaseHttpClient.post(this.endpointService.endpoints.user.update, user)
      ));
  };

  /**
   * Create a user
   * @param {UserModel} user
   * @returns {Observable<UserModel>}
   */
  public create = (user: UserModel): Observable<UserModel> => {
    return from(validate(UserModel))
      .pipe(mergeMap(() => this.firebaseHttpClient.post(this.endpointService.endpoints.user.create, user)
      ));
  };

    /**
     * Delete user by userId
     * @param {string} userId
     * @returns {Observable<UserModel>}
     */
    public delete = (userId: string): Observable<UserModel> => {
      return this.firebaseHttpClient.post(this.endpointService.endpoints.user.delete, { userId });
    };
}
