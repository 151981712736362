import { firestore } from 'firebase';
import * as namespace from 'namespace';
import Timestamp = firestore.Timestamp;
import CertificateItem = namespace.CertificateItem;

export const certificateDBTOCertificateItem = (data, address):CertificateItem => {
  let json = {} as any;
  try {
    json = data.updatedJson ? JSON.parse(data.updatedJson) : JSON.parse(data.json);
    json.id = data.tokenId;

    json.createdAt = data.mintTimeStamp ? data.mintTimeStamp * 1000 : undefined;

    json.class = '';

    if (!data.blockNumberCreation) {
      data.blockNumberCreation = Infinity;
      json.class = 'waiting';
    }

    json.blockNumberCreation = data.blockNumberCreation;

    json.owner = data.owner;

    json.viewKey = data.viewKey;
    json.userId = data.userId;

    json.name = data.displayTitle || json.name;
    if (data._log_firstAccess) {
      json._log_firstAccess = data._log_firstAccess;
    }

    if (data._log_lastAccess) {
      json._log_lastAccess = data._log_lastAccess;
    }

    if (data._log_fetchedCount) {
      json._log_fetchedCount = data._log_fetchedCount;
    }

    if (data.owner === address) {
      json.isOwner = true;
    } else {
      json.isOwner = false;
    }

    if (data.legacy === true) {
      json.legacy = true;
    } else {
      json.legacy = false;
    }

    if (data.destroyed === true) {
      json.destroyed = true;
    } else {
      json.destroyed = false;
    }

    if (data.whitelist === true || data.whitelist === undefined) {
      json.whitelist = true;
    } else {
      json.whitelist = false;
    }

    if (data.isLostOrStolen === false || data.isLostOrStolen === undefined) {
      json.isLostOrStolen = false;
    } else {
      json.isLostOrStolen = true;
    }

    json.tags = data.tags || [];

    if (!data.events) {
      json.events = [];
    } else {
      json.events = data.events.filter((event) => event.event === 'Transfer');
    }
  } catch (e) {
    console.error(e);
    console.error(`one certificate can not be displayed with docId ${data.id} and tokenid ${data.tokenId}`);
    console.error(JSON.stringify(data.json));
    json = undefined;
  }
  return json;
};
