import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SCHEMA_NAME, SchemaService } from '../../services/schema-service/schema-service';
import { CertificateService, IdentityService } from '../../apiServices/BDHApi';
import { WalletCoreService } from '../../services/walletCoreService/walletCoreService';

@Component({
  selector: 'certificate-viewer',
  styleUrls: ['./certificateViewer.scss'],
  templateUrl: 'certificateViewer.html'
})

export class CertificateViewer implements OnChanges {
  @Input('item') item: any;
  @Input('refresh') refresh: any;
  @Input('itemId') itemId: any;

  public truncating=false;

  public hightlightPicture: string;
  public hightlightMediaIndex: number;
  public hightlightPictureIndex: number;
  public embedURL: string;
  public seeMore = false;
  public language:any[];
  public selectedLanguage;
  public schemaVersion;

  public displayedItem:any;
  private timer;

  constructor (
      private schemaService: SchemaService,
      private certificateService: CertificateService,
      private identityService: IdentityService,
      private walletCoreService: WalletCoreService
  ) {
  }

  ngOnChanges (changes:SimpleChanges) {
    if (changes.item.previousValue !== changes.item.currentValue) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => { this.setCertV2Media(); }, 1000);
    }
  }

  get EnumSchemaName () {
    return SCHEMA_NAME;
  }

  private async setCertV2Media () {
    this.schemaVersion = this.schemaService.getSchemaItemFromURL(this.item.$schema).name;

    this.displayedItem = { ...this.item };
    if (this.item && this.schemaVersion === SCHEMA_NAME.i18N) {
      let identity;

      if (this.itemId) {
        const { issuer } = await this.certificateService
          .getCertificate([this.itemId, undefined, { issuer: true }])
          .toPromise();
        identity = issuer.identity.data;
      } else {
        // identité de lamarque

        const address = await this.walletCoreService.getAddress();
        const { data } = await this.identityService.getIdentity([address]).toPromise();
        identity = data;
      }

      this.displayedItem.itemBackgroundPicture = certificateImageLogo(this.item, ['brandItemBackgroundPicture']) || brandImageLogo(identity, ['brandItemBackgroundPicture']);
      this.displayedItem.brandLogoHeaderReversed = brandImageLogo(identity, ['brandLogoHeaderReversed', 'brandLogoHeader']);

      this.displayedItem = this.handleMedias(this.displayedItem);

      const medias = this.item.medias || [];
      this.displayedItem.pictures = medias
        .filter(media => media.mediaType === 'picture' && media.type === 'product')
        .map(media => media.url)
        .filter(i => i !== undefined);

      this.displayedItem.otherMedias = medias
        .filter(media => media.mediaType !== 'picture')
        .map(media => media)
        .filter(i => i !== undefined);

      if (this.item.description && this.item.description.length > 100) {
        this.seeMore = true;
      }

      this.language = this.detectLanguage();

      this.selectMainPicture(0);
    }
  }

  private detectLanguage () {
    const language = [];

    this.selectedLanguage = { lang: this.item.language, index: null };
    language.push(this.selectedLanguage);

    if (this.item.i18n) {
      this.item.i18n.forEach((lang, index) => {
        if (lang.language) {
          language.push({ lang: lang.language, index });
        }
      });
    }

    return language;
  }

  public changeLanguage (event) {
    if (this.selectedLanguage.index === null) {
      this.displayedItem.description = this.item.description;
    } else {
      this.displayedItem.description = this.item.i18n[this.selectedLanguage.index].description;
    }
  }

  public isArray (value) {
    return Array.isArray(value);
  }

  public selectMainPicture (index: number) {
    this.embedURL = null;
    this.hightlightPicture = this.displayedItem.pictures[index];
    this.hightlightMediaIndex = null;
    this.hightlightPictureIndex = index;
  }

  public selectMedia (index: number) {
    this.hightlightPicture = null;
    this.embedURL = this.displayedItem.otherMedias[index].embedURL;
    this.hightlightPictureIndex = null;
    this.hightlightMediaIndex = index;
  }

  public toggleSeeMore () {
    this.seeMore = !this.seeMore;
  }

  private handleMedias (certificateDisplay) {
    if (certificateDisplay.medias) {
      certificateDisplay.medias.map(media => {
        let returnedMedia;
        switch (media.mediaType) {
          case 'youtube':
            returnedMedia = getYoutubeMedia(media);
            break;
          default:
            returnedMedia = media;
            break;
        }

        return returnedMedia;
      });
    }

    return certificateDisplay;
  }
}

export const getYoutubeMedia = (media) => {
  if (media.url) {
    media.ytId = extractYoutubeId(media.url);
    media.thumbnail = getYoutubeThumbnail(media.ytId);
    media.embedURL = getYoutubeEmbedUrl(media.ytId);
  }

  return media;
};

export const extractYoutubeId = (url: string) => {
  return url.match(/(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/user\/\S+|\/ytscreeningroom\?v=|\/sandalsResorts#\w\/\w\/.*\/))([^/&]{10,12})/)[1];
};

export const getYoutubeThumbnail = (ytId: string) => {
  return `https://img.youtube.com/vi/${ytId}/0.jpg`;
};

export const getYoutubeEmbedUrl = (ytId: string) => {
  return `https://www.youtube.com/embed/${ytId}`;
};

export const brandImageLogo = (identity, picturesOrder: string[]): string => {
  const { pictures } = identity;

  const isPicture = (pictureName): boolean => {
    const picture = pictures
      .find(pic => pic.type === pictureName);

    return picture !== undefined;
  };

  const pictureUrl = (pictureName): string => {
    return pictures
      .find(pic => pic.type === pictureName).url;
  };

  const findPicture = (picturesNames: string[]): string => {
    for (const pictureName of picturesNames) {
      if (isPicture(pictureName)) {
        return pictureUrl(pictureName);
      }
    }
    return '';
  };

  if (pictures) {
    return findPicture(picturesOrder);
  }

  return '';
};

export const certificateImageLogo = (certificate, picturesOrder: string[]): string => {
  const { medias } = certificate;

  const isPicture = (pictureName): boolean => {
    const picture = medias
      .find(pic => pic.type === pictureName);

    return picture !== undefined;
  };

  const pictureUrl = (pictureName): string => {
    return medias
      .find(pic => pic.type === pictureName).url;
  };

  const findPicture = (picturesNames: string[]): string => {
    for (const pictureName of picturesNames) {
      if (isPicture(pictureName)) {
        return pictureUrl(pictureName);
      }
    }
    return undefined;
  };

  if (medias) {
    return findPicture(picturesOrder);
  }

  return undefined;
};
