import * as tslib_1 from "tslib";
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { config } from '../../../config';
import { PublicConfigurationService } from '../public-configuration-service/public-configuration-service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "../public-configuration-service/public-configuration-service";
/*
*
*  Utils Provider
*
*/
var Utils = /** @class */ (function () {
    function Utils(toastCtrl, alertCtrl, loadingCtrl, publicConfigurationService) {
        this.toastCtrl = toastCtrl;
        this.alertCtrl = alertCtrl;
        this.loadingCtrl = loadingCtrl;
        this.publicConfigurationService = publicConfigurationService;
        this.paperTplList = [{ title: 'Format A4 ', name: 'paperCertDefault', fontSize: '12', format: 'a4', orientation: 'p', heightIncrementcol1: 7, logo: true },
            { title: 'Format A5', name: 'paperCertDefaultA5', fontSize: '10', format: 'a5', orientation: 'p', heightIncrementcol1: 5, logo: true },
            { title: 'Format A5 No Logo', name: 'paperCertDefaultNoLogoA5', fontSize: '10', format: 'a5', orientation: 'p', heightIncrementcol1: 5, logo: false },
            { title: 'Small Landscape', name: 'paperCertSmallLandscape', fontSize: '7', format: [328.819, 249.449], orientation: 'l', heightIncrementcol1: 6, heightIncrementcol2: 8, logo: false, multiplier: 7, printedIn: 'Switzerland' },
            { title: 'Small Landscape Chinese Market', name: 'paperCertSmallLandscapeChinese', fontSize: '10', format: 'a5', orientation: 'l', heightIncrementcol1: 10, logo: false },
            { title: 'Small Landscape masterline', name: 'paperCertSmallLandscapeMasterline', fontSize: '10', format: 'a5', orientation: 'l', heightIncrementcol1: 10, logo: true }
        ];
    }
    Utils.prototype.getPaperTpl = function (paperTplName) {
        return this.paperTplList.find(function (tpl) { return tpl.name === paperTplName; });
    };
    Utils.prototype.getPaperTplList = function () {
        var _this = this;
        return this.publicConfigurationService.getByName('paperTemplate')
            .pipe(map(function (paperTplDocument) {
            if (!paperTplDocument) {
                return _this.getPaperTplListComplete();
            }
            var paperTpl = paperTplDocument.value.filter(function (val) { return val.value === true; });
            return paperTpl;
        })).toPromise();
    };
    Utils.prototype.getPaperTplListComplete = function () {
        return this.paperTplList;
    };
    /*
    *  Loading
    */
    Utils.prototype.load = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!!this.loading) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.loadingCtrl.create({
                                message: 'Please wait...'
                                // dismissOnPageChange: false
                            })];
                    case 1:
                        _a.loading = _b.sent();
                        this.loading.present();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    /*
    *  Dismiss loading
    */
    Utils.prototype.unload = function () {
        if (this.loading) {
            this.loading.dismiss();
            this.loading = null;
        }
    };
    /*
    *  Toast error message
    */
    Utils.prototype.error = function (message, params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toastCtrl.create({
                            message: message,
                            duration: 3000,
                            cssClass: 'toast-error',
                            position: 'top'
                        })];
                    case 1:
                        toast = _a.sent();
                        toast.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    /*
    *  Toast success message
    */
    Utils.prototype.success = function (message, params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toastCtrl.create({
                            message: message,
                            duration: 3000,
                            cssClass: 'toast-success',
                            position: 'top'
                        })];
                    case 1:
                        toast = _a.sent();
                        toast.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    /*
    *    Alert
    */
    Utils.prototype.alert = function (title, message) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertCtrl.create({
                            header: title,
                            message: (message || ''),
                            buttons: ['Dismiss']
                        })];
                    case 1:
                        alert = _a.sent();
                        alert.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    Utils.prototype.deeplink = function (certificateId, viewKey) {
        var urlOfChain = config.network === 'mainnet' ? 'https://arian.ee/' : 'https://test.arian.ee/';
        if (certificateId && viewKey) {
            return "" + urlOfChain + certificateId + "," + viewKey;
        }
        else {
            return urlOfChain;
        }
    };
    Utils.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Utils_Factory() { return new Utils(i0.ɵɵinject(i1.ToastController), i0.ɵɵinject(i1.AlertController), i0.ɵɵinject(i1.LoadingController), i0.ɵɵinject(i2.PublicConfigurationService)); }, token: Utils, providedIn: "root" });
    return Utils;
}());
export { Utils };
