import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter, Renderer2 } from '@angular/core';
import { SchemaService } from '../../services/schema-service/schema-service';
import { Utils } from '../../services/utils/utils';
import { ArianeejsAPIService } from '../../apiServices/arianeejsAPIService';
import { cleanObject } from '../../helpers/cleanObject/cleanObject';
var SchemaBuilderComponent = /** @class */ (function () {
    function SchemaBuilderComponent(utils, _renderer, schemaService, elem, arianeeJsServerService) {
        var _this = this;
        this.utils = utils;
        this._renderer = _renderer;
        this.schemaService = schemaService;
        this.elem = elem;
        this.arianeeJsServerService = arianeeJsServerService;
        this.showButtons = {
            create: true,
            copy: true,
            paste: true
        };
        this.onSubmitEmitter = new EventEmitter();
        this.onChangeEmitter = new EventEmitter();
        this._buttonLabelsDefault = {
            create: 'Create in blockchain',
            copy: 'copy',
            paste: 'paste'
        };
        this.creditCheck = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.assetType) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.arianeeJsServerService.balanceOfCredit(this.assetType).toPromise()];
                    case 1:
                        _a.creditAsset = _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        this.matchingSchema = function ($schema) { return _this.schemasDetails.find(function (schema) {
            return schema.$schema === $schema;
        }); };
    }
    Object.defineProperty(SchemaBuilderComponent.prototype, "documentModel", {
        get: function () {
            return this._documentModel;
        },
        set: function (value) {
            this._documentModel = value;
            this.prepare();
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(SchemaBuilderComponent.prototype, "buttonLabels", {
        get: function () {
            return this._buttonLabelsDefault;
        },
        set: function (value) {
            this._buttonLabelsDefault = tslib_1.__assign({}, this._buttonLabelsDefault, value);
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(SchemaBuilderComponent.prototype, "document", {
        get: function () {
            return this._document;
        },
        set: function (value) {
            this._document = cleanObject(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SchemaBuilderComponent.prototype, "schemaUrls", {
        get: function () {
            return this._schemaUrls;
        },
        set: function (value) {
            this._schemaUrls = value;
            this.prepare();
        },
        enumerable: true,
        configurable: true
    });
    ;
    SchemaBuilderComponent.prototype.prepare = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, schema;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.creditCheck();
                        if (!this._schemaUrls) return [3 /*break*/, 4];
                        _a = this;
                        return [4 /*yield*/, Promise.all(this._schemaUrls.map(function (url) { return _this.schemaService.overrideSchemaWidget(url); }))];
                    case 1:
                        _a.schemasDetails = _b.sent();
                        this.schemasSelectList = this.schemasDetails.map(function (schema) { return ({
                            title: schema.title,
                            id: schema.$schema
                        }); });
                        if (!(this.documentModel && this.documentModel.$schema)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.schemaService.overrideSchemaWidget(this.documentModel.$schema)];
                    case 2:
                        schema = _b.sent();
                        this.selectedSchemas = schema;
                        return [3 /*break*/, 4];
                    case 3:
                        this.selectedSchemas = this.schemasDetails[0];
                        _b.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    SchemaBuilderComponent.prototype.schemaSelectChange = function ($event) {
        this._documentModel = undefined;
        this.selectedSchemas = this.schemasDetails.find(function (schema) {
            return schema.$schema === $event;
        });
    };
    SchemaBuilderComponent.prototype.isOneOfList = function () {
        if (this.document) {
            var $schema = this.document.$schema;
            var isOneOfList = this.matchingSchema($schema);
            return isOneOfList !== undefined;
        }
        else {
            return true;
        }
    };
    SchemaBuilderComponent.prototype.onSubmit = function () {
        if (this.document) {
            this.onSubmitEmitter.emit(this.document);
        }
    };
    SchemaBuilderComponent.prototype.copy = function () {
        var item = JSON.stringify(this.document);
        function copyToClipboard(e) {
            e.clipboardData.setData('text/plain', (item));
            e.preventDefault();
            document.removeEventListener('copy', copyToClipboard);
        }
        document.addEventListener('copy', copyToClipboard);
        document.execCommand('copy');
        this.utils.success('Content copied to your clipboard');
    };
    SchemaBuilderComponent.prototype.paste = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var text, parsedDoc_1, matchingSchema;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, navigator.clipboard.readText()];
                    case 1:
                        text = _a.sent();
                        try {
                            parsedDoc_1 = JSON.parse(text);
                            matchingSchema = this.schemasDetails.find(function (schema) {
                                return schema.$schema === parsedDoc_1.$schema;
                            });
                            if (!matchingSchema) {
                                this.utils.alert('The content you are trying to paste is not from a schema in the list');
                            }
                            else {
                                this.documentModel = parsedDoc_1;
                                this.selectedSchemas = matchingSchema;
                                this.utils.success('Content pasted sucessfully');
                            }
                        }
                        catch (e) {
                            this.utils.alert('The content you are trying to paste is not json schema.');
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SchemaBuilderComponent.prototype.onChange = function (event) {
        this.document = event.value;
        if (this.document) {
            this.onChangeEmitter.emit(this.document);
        }
    };
    SchemaBuilderComponent.prototype.replaceInformationForm = function () {
        var elements = this.elem.nativeElement.querySelectorAll('.formHelp');
        for (var i = 0; i < elements.length; ++i) {
            var elem = elements[i];
            var isExist = elem.querySelector('.description-container');
            if (isExist === null) {
                var originalContent = elem.textContent.replace(/^\n|\n$/g, '').replace(/\n/g, '<br />');
                this._renderer.setProperty(elem, 'innerHTML', "\n                    <div class=\"description-container\">\n          <div class=\"description-button\"><img src=\"/assets/img/info.svg\" />\n          </div>\n          <div class=\"description-content\" >" + originalContent + "</div>\n      </div>");
            }
        }
    };
    SchemaBuilderComponent.prototype.ngAfterViewChecked = function () {
        this.replaceInformationForm();
    };
    return SchemaBuilderComponent;
}());
export { SchemaBuilderComponent };
