import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ContentAPIService } from '../../apiServices/contentAPIService';
import { ArianeejsAPIService } from '../../apiServices/arianeejsAPIService';
import { hydrateTokenParameters } from '@arianee/arianeejs/dist/src/models/transaction-parameters';
import { FirebaseHttpClient } from '../firebaseHttpClient/firebaseHttpClient';
import { EndpointsService } from '../../apiServices/endpointsService/endpointsService';
import { CertificateService } from '../../apiServices/BDHApi';
import { WalletCoreService } from '../walletCoreService/walletCoreService';
import { mongooseQueryFactory } from '../../helpers/mongoose-query-factory';
export interface JSONCertificate{
    createdAt: any,
    blockNumberCreation: number,
    userId:string,
    json?: any,
    issuer: string;
    legacy:boolean;
    tags?: any,
    viewKey?: string,
    tokenId: number,
    recoverable?: boolean,
    recoverTimeStamp?: string,
    imprint?: string,
    transferable?: boolean,
    concealed?: boolean,
    reserved?:boolean
}

@Injectable({ providedIn: 'root' })
export class CertificateCoreService {
  constructor (
                private contentAPIService: ContentAPIService,
                private arianeeJSAPIService: ArianeejsAPIService,
                private endpointService: EndpointsService,
                private certificateService:CertificateService,
                private walletCoreService:WalletCoreService,
                private firebaseHttpClient: FirebaseHttpClient) {

  }

  getCertificate (certificateId: string | number):Observable<any> {
    return this.firebaseHttpClient.get(this.endpointService.endpoints.certificate.list,
      {
        params: {
          q: JSON.stringify({
            tokenId: certificateId
          })
        }
      }
    ).pipe(
      map(certificates => certificates[0])
    );
  }

  getEvent (certificateId: string | number): Observable<any> {
    return this.getCertificate(parseInt(certificateId.toString()))
      .pipe(map((data: any) => data.events));
  }

  getCertificateOnce (certificateId: string | number):Observable<any> {
    return this.getCertificate(certificateId)
      .pipe(take(1));
  }

  getReservedCertificate ():Observable<{count:number}> {
    return this.firebaseHttpClient.get(this.endpointService.endpoints.certificate.count,
      {
        params: {
          q: JSON.stringify({
            isConsumed: false
          })
        }
      }
    );
  }

  store (certificate: JSONCertificate) {
    return this.contentAPIService.storeCertificateContent(certificate.tokenId, certificate.json);
  }

  async createCertificate (certificate: hydrateTokenParameters) {
    // no need to store as API is storing json
    return await this.arianeeJSAPIService.createCertificate(certificate).toPromise();
  }

  async update (parameters: {
        certificateId: number,
        content: any
    }) {
    return await this.arianeeJSAPIService.updateCertificate(parameters).toPromise();
  }

  async doctorI18N (certificateContent) {
    return await this.contentAPIService.doctorCertificate(certificateContent).toPromise();
  }

  /**
     * Function to have certificateId that is free
     */
  async getFreeTokenId (): Promise<number> {
    const tokenId = Math.round(Math.random() * 100000000);

    let ownerExist;

    try {
      ownerExist = await this.certificateService.ownerOf([tokenId]).toPromise();
    } catch (e) {
      ownerExist = false;
    }

    if (!ownerExist) { return tokenId; } else { return await this.getFreeTokenId(); }
  }

    public getCertificateCount = (query: any, sort?: any, limit = 0):Observable<{count:number}> => {
      const options = mongooseQueryFactory(query, sort, limit);
      return this.firebaseHttpClient.get(this.endpointService.endpoints.certificate.count,
        options
      );
    };

    public getCertificateList=(query:any, sort?:any, limit = 100) => {
      const options = mongooseQueryFactory(query, sort, limit);
      return this.firebaseHttpClient.get(this.endpointService.endpoints.certificate.list,
        options
      );
    }

    /**
     * Function to check if a list of certificatedIds is usable to create a certificate by current user
     * @param certificateIds
     */
    public async certificateIdsAvailibility (certificateIds: number[], isReservedByMe = true) {
      const result = await Promise.all(certificateIds
        .map(async certiD => {
          const canCreate = await this.canCreateCertificateWithCertificateId(certiD, isReservedByMe);
          return [certiD, canCreate];
        }));

      return {
        notAvailablesIds: result
          .filter(([certiD, canCreate]) => canCreate === false)
          .map(([certiD]) => certiD),
        availableIds: result
          .filter(([certiD, canCreate]) => canCreate === true)
          .map(([certiD]) => certiD)
      };
    }

    /**
     * Function to check if current user can create certificate using a given certificateId
     * It checks if it free or reserved by him.herself
     * @param certificateId
     * @param isReservedByMe
     */
    public async canCreateCertificateWithCertificateId (certificateId: number, isReservedByMe = true): Promise<boolean> {
      const isFree = await this.certificateService.ownerOf([certificateId]).toPromise()
        .then(d => false)
        .catch(() => true);
      if (isFree) {
        return true;
      } else if (isReservedByMe) {
        return this.isCertificateReservedByMe(certificateId);
      } else {
        return false;
      }
    }

    /**
     * Function to check if given certificateId is reserved by current user
     * @param certificateId
     */
    public async isCertificateReservedByMe (certificateId: number): Promise<boolean> {
      const [ownerAddress, imprint, address] = await Promise
        .all([
          this.certificateService.ownerOf([certificateId]).toPromise(),
          this.arianeeJSAPIService.getImprint(certificateId).toPromise(),
          this.walletCoreService.getAddress()
        ]);

      const amIOwner = ownerAddress === address;
      const isImprintEmpty = (!imprint || imprint === '0x0000000000000000000000000000000000000000000000000000000000000000');

      return amIOwner && isImprintEmpty;
    }

    downloadBlob (blob, filename) {
      const binaryData = [];
      binaryData.push(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
      downloadLink.setAttribute('download', filename);
      document.body.appendChild(downloadLink);
      downloadLink.click();
    }

    getReportReservedCertificatesInCsv = async () => {
      const data = await this.firebaseHttpClient
        .post(this.endpointService.endpoints.reports.reserved,
          {},
          {
            responseType: 'blob'
          }).toPromise();

      this.downloadBlob(data, 'bdh-reserved-report.csv');
    };

    getReportInCsv = async () => {
      const data = await this.firebaseHttpClient
        .post(this.endpointService.endpoints.reports.certificate,
          {},
          {
            responseType: 'blob'
          }).toPromise();

      this.downloadBlob(data, 'bdh-report.csv');
    };
}
