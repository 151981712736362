import { Component } from '@angular/core';
import { LoadingController } from '@ionic/angular'; // PopoverController
import { Utils } from '../../services/utils/utils';
import { AuthService } from '../../services/auth/auth';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { MessageService } from '../../services/messageService/messageService';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';
import { AutoMessageService } from '../../services/autoMessage-service/automessage.service';
import { filter, tap } from 'rxjs/operators';

/*
*
*  AutoMessages
* {'companyName':'Arianee',
*  'companyLogo':'https://demo.arianee.org/demo/logo-arianee.png',
*  'companyWebsite':'https://arianee.org'}
*
*/
@Component({
  selector: 'page-autoMessages',
  templateUrl: 'autoMessages.html'
})
export class AutoMessages {
    public subscribe: any = {};
    public message: { content: string; enable: boolean; type?: string };
    private docId: string;
    public schema;

    constructor (
        public utils: Utils,
        public authService: AuthService,
        public loadingCtrl: LoadingController,
        private uiAlert: UIAlertService,
        private messageService: MessageService,
        private eventLogger: EventLoggerService,
        private autoMessageService: AutoMessageService
    ) {
      this.message = {
        enable: false,
        content: ''
      };
    }

    async ionViewDidEnter () {
      this.eventLogger.logScreen('autoMessage-page');

      const loader = await this.uiAlert.load();
      this.schema = await this.messageService.getSchema();
      await this.autoMessageService.getAutoMessage('eachTransfer')
        .pipe(
          filter(autoMessageDoc => !!autoMessageDoc),
          tap(autoMessage => {
            this.message = autoMessage;
            this.message.content = JSON.parse(this.message.content);
            this.docId = autoMessage._id;
          })
        )
        .subscribe();

      loader.dismiss();
    }

    async updateAutoMessages (messageToUpdate) {
      const loader = await this.uiAlert.load();

      const message = {
        ...this.message,
        content: JSON.stringify(messageToUpdate),
        type: 'eachTransfer'
      };

      if (this.docId) {
        await this.autoMessageService.updateAutoMessage(this.docId, message).toPromise();
      } else {
        const { _id } = await this.autoMessageService.createAutoMessage(message).toPromise();
        this.docId = _id;
      }

      this.utils.success('Update successful');
      loader.dismiss();
    }
}
