import * as tslib_1 from "tslib";
import { ToastController, LoadingController, AlertController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
var UIAlertService = /** @class */ (function () {
    function UIAlertService(toasterCtrl, loadingController, alertController) {
        this.toasterCtrl = toasterCtrl;
        this.loadingController = loadingController;
        this.alertController = alertController;
    }
    UIAlertService.prototype.alert = function (opts, withButton) {
        if (withButton === void 0) { withButton = false; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (withButton) {
                            opts = tslib_1.__assign({}, opts, { buttons: [
                                    {
                                        text: 'ok',
                                        role: 'cancel'
                                    }
                                ] });
                        }
                        return [4 /*yield*/, this.alertController.create(opts)];
                    case 1:
                        alert = _a.sent();
                        alert.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    UIAlertService.prototype.load = function (opts) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toaster;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadingController.create(tslib_1.__assign({ spinner: 'dots', message: 'loading', cssClass: '.loading-spinner' }, opts))];
                    case 1:
                        toaster = _a.sent();
                        toaster.present();
                        return [2 /*return*/, toaster];
                }
            });
        });
    };
    UIAlertService.prototype.loadUpdatable = function (opts) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var loadReturn, toaster;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loadReturn = null;
                        return [4 /*yield*/, this.loadingController.create(tslib_1.__assign({ spinner: 'dots', message: 'loading', cssClass: 'toast-success' }, opts)).then(function (load) {
                                loadReturn = load;
                                load.present();
                            })];
                    case 1:
                        toaster = _a.sent();
                        return [2 /*return*/, loadReturn];
                }
            });
        });
    };
    UIAlertService.prototype.toast = function (opts, withButton) {
        if (withButton === void 0) { withButton = false; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toaster;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (withButton) {
                            opts = tslib_1.__assign({}, opts, { buttons: [
                                    {
                                        text: 'ok',
                                        role: 'cancel'
                                    }
                                ] });
                        }
                        return [4 /*yield*/, this.toasterCtrl.create(tslib_1.__assign({ message: 'hello' }, opts))];
                    case 1:
                        toaster = _a.sent();
                        toaster.present();
                        return [2 /*return*/, toaster];
                }
            });
        });
    };
    UIAlertService.prototype.error = function (message, params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toasterCtrl.create({
                            message: message,
                            duration: 3000,
                            cssClass: 'toast-error',
                            position: 'top'
                        })];
                    case 1:
                        toast = _a.sent();
                        toast.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    /*
      *  Toast success message
      */
    UIAlertService.prototype.success = function (message, params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toasterCtrl.create({
                            message: message,
                            duration: 3000,
                            cssClass: 'toast-success',
                            position: 'top'
                        })];
                    case 1:
                        toast = _a.sent();
                        toast.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    UIAlertService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UIAlertService_Factory() { return new UIAlertService(i0.ɵɵinject(i1.ToastController), i0.ɵɵinject(i1.LoadingController), i0.ɵɵinject(i1.AlertController)); }, token: UIAlertService, providedIn: "root" });
    return UIAlertService;
}());
export { UIAlertService };
