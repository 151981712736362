/**
 * Arianee Brand Data Hub API
 * List API available in Brand Data Hub
 *
 * OpenAPI spec version: 1.0.0
 * Contact: techteam@arianee.org
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { BalanceOfCreditReturn } from '../model/balanceOfCreditReturn';
import { TransactionCreated } from '../model/transactionCreated';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class EconomyService {
    protected basePath = 'https://us-central1-bdhalex-826d5.cloudfunctions.net/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor (protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
      if (basePath) {
        this.basePath = basePath;
      }
      if (configuration) {
        this.configuration = configuration;
        this.basePath = basePath || configuration.basePath || this.basePath;
      }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm (consumes: string[]): boolean {
      const form = 'multipart/form-data';
      for (const consume of consumes) {
        if (form === consume) {
          return true;
        }
      }
      return false;
    }

    /**
     * Get address aria allowance quantity for address
     * Get store approved status for address
     * @param body Request address and store address
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public allowance(body: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public allowance(body: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public allowance(body: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public allowance (body: Array<string>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling allowance.');
      }

      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'document'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
        'application/json'
      ];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.request<string>('post', `${this.basePath}/api/contracts/ariaContract/allowance/call`,
        {
          body: body,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Set approved quantity for adress
     * Get store approved status for address
     * @param body Set approved quantity for adress
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approve(body: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<TransactionCreated>;
    public approve(body: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TransactionCreated>>;
    public approve(body: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TransactionCreated>>;
    public approve (body: Array<string>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling approve.');
      }

      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'document'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
        'application/json'
      ];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.request<TransactionCreated>('post', `${this.basePath}/api/contracts/ariaContract/approve/send`,
        {
          body: body,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Get Aria Price in USD
     * Get Aria Price in USD
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ariaUSDExchange(observe?: 'body', reportProgress?: boolean): Observable<string>;
    public ariaUSDExchange(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public ariaUSDExchange(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public ariaUSDExchange (observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'document'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
      ];

      return this.httpClient.request<string>('post', `${this.basePath}/api/contracts/storeContract/ariaUSDExchange/call`,
        {
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Get Aria Balance
     * Get Aria Balance
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public balanceOfAria(observe?: 'body', reportProgress?: boolean): Observable<string>;
    public balanceOfAria(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public balanceOfAria(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public balanceOfAria (observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'document'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
      ];

      return this.httpClient.request<string>('post', `${this.basePath}/api/balanceOfAria`,
        {
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Get credit balance by type
     * Get credit available (type: certificate, message, event)
     * @param body Credit type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public balanceOfCredit(body: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<BalanceOfCreditReturn>;
    public balanceOfCredit(body: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BalanceOfCreditReturn>>;
    public balanceOfCredit(body: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BalanceOfCreditReturn>>;
    public balanceOfCredit (body: Array<string>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling balanceOfCredit.');
      }

      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
        'application/json'
      ];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.request<BalanceOfCreditReturn>('post', `${this.basePath}/api/balanceOfCredit`,
        {
          body: body,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Get Native Crypto Balance
     * Get Native Crypto Balance
     * @param body Address
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public balanceOfPoa(body?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public balanceOfPoa(body?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public balanceOfPoa(body?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public balanceOfPoa (body?: Array<string>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'document'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
        'application/json'
      ];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.request<string>('post', `${this.basePath}/api/balanceOfPoa`,
        {
          body: body,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Buy credits by type
     * Buy credit (type: certificate, message, event)
     * @param body Credit type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public buyCredits(body: Array<string | number>, observe?: 'body', reportProgress?: boolean): Observable<TransactionCreated>;
    public buyCredits(body: Array<string | number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TransactionCreated>>;
    public buyCredits(body: Array<string | number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TransactionCreated>>;
    public buyCredits (body: Array<string | number>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling buyCredits.');
      }

      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
        'application/json'
      ];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.request<TransactionCreated>('post', `${this.basePath}/api/buyCredits`,
        {
          body: body,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Get credit price by type
     * Get credit price by type
     * @param body Credit type (0,1,2,...)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCreditPrice(body: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getCreditPrice(body: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getCreditPrice(body: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getCreditPrice (body: Array<number>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling getCreditPrice.');
      }

      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'document'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
        'application/json'
      ];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.request<string>('post', `${this.basePath}/api/contracts/storeContract/getCreditPrice/call`,
        {
          body: body,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Get last events on store contract
     * Get last events on store contract
     * @param body GetPastEvents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPastEvents(body: Array<string | any>, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getPastEvents(body: Array<string | any>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getPastEvents(body: Array<string | any>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getPastEvents (body: Array<string | any>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling getPastEvents.');
      }

      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'document'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
        'application/json'
      ];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.request<string>('post', `${this.basePath}/api/contracts/storeContract/getPastEvents`,
        {
          body: body,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Get Aria name
     * Get Aria name
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public name(observe?: 'body', reportProgress?: boolean): Observable<string>;
    public name(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public name(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public name (observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'document'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
      ];

      return this.httpClient.request<string>('post', `${this.basePath}/api/contracts/ariaContract/name/call`,
        {
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Get store owner
     * Get store owner
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public owner(observe?: 'body', reportProgress?: boolean): Observable<string>;
    public owner(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public owner(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public owner (observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'document'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
      ];

      return this.httpClient.request<string>('post', `${this.basePath}/api/contracts/storeContract/owner/call`,
        {
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Stake for caller
     * Get stake for address
     * @param body Quantity + data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public stake(body: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<TransactionCreated>;
    public stake(body: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TransactionCreated>>;
    public stake(body: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TransactionCreated>>;
    public stake (body: Array<string>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling stake.');
      }

      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'document'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
        'application/json'
      ];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.request<TransactionCreated>('post', `${this.basePath}/api/contracts/stakingContract/stake/send`,
        {
          body: body,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Get stake for address
     * Get stake for address
     * @param body Address who staked
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public totalStakedFor(body: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public totalStakedFor(body: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public totalStakedFor(body: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public totalStakedFor (body: Array<string>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling totalStakedFor.');
      }

      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'document'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
        'application/json'
      ];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.request<string>('post', `${this.basePath}/api/contracts/stakingContract/totalStakedFor/call`,
        {
          body: body,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Transfer Aria
     * Transfer Aria
     * @param body Receiver addresse + Aria quantity
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public transfer(body: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<TransactionCreated>;
    public transfer(body: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TransactionCreated>>;
    public transfer(body: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TransactionCreated>>;
    public transfer (body: Array<string>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling transfer.');
      }

      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'document'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
        'application/json'
      ];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.request<TransactionCreated>('post', `${this.basePath}/api/contracts/ariaContract/transfer/send`,
        {
          body: body,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }
}
