import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { get } from 'lodash';
import * as namespace from 'namespace';
import CertificateItem = namespace.CertificateItem;

/*
*
*  Wallet Page
*
*/
@Component({
  selector: 'component-wallet',
  styleUrls: ['./wallet.scss'],
  templateUrl: 'wallet.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WalletComponent {
    @Input('certificateList') certificateList: CertificateItem[];
    @Input('users') users: { [id: string]: string };
    @Output('onItemClick') onItemClick: EventEmitter<number> = new EventEmitter();

    public onInternalItemClick = (certificateId: number) => {
      this.onItemClick.emit(certificateId);
    };

    public isArray (value): boolean {
      return Array.isArray(value) && value[0];
    };

    public getUser = (userId) => {
      return this.users && this.users[userId];
    };

    public getImage = (certificate) => {
      const defaultImage = 'https://dummyimage.com/80x80/DAC8A1/ffffff&text=no+image';
      return get(certificate, 'medias[0].url') || get(certificate, 'picture') || defaultImage;
    }
}
