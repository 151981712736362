import { Injectable } from '@angular/core';
import * as qrcode from 'qrcode-generator';
import { Utils } from '../utils/utils';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ScriptLoaderService } from '../script-loader.service/script-loader.service';
import { CertificateCoreService } from '../certificate-service/certificate-core.service';

export interface SchemaItem {
  title: string,
  url: string,
  i18n: boolean,
  version: number,
  widget?: any
}

declare var jsPDF;

@Injectable({ providedIn: 'root' })
export class PaperCertService {
  private readonly deeplinkUrl:string;

  constructor (
      private utils: Utils,
      private http: HttpClient,
      private scriptLoaderService: ScriptLoaderService,
      private certificateService: CertificateCoreService
  ) {
    this.deeplinkUrl = this.utils.deeplink();
  }

  async generatePaperCert (certificateId, paperTplName) {
    const paperTpl = this.utils.getPaperTpl(paperTplName);
    const items = await Promise.all(certificateId.map(itemId => this.certificateService.getCertificate(itemId).toPromise()));
    return this.generatepdf(items, paperTpl);
  }

  public async generatepdf (certificates, paperTpl) {
    const Arial = await this.http.get('/assets/fonts/arial-normal.txt', { responseType: 'text' }).toPromise();
    const Simsun = await this.http.get('/assets/fonts/SimSun-normal.txt', { responseType: 'text' }).toPromise();

    await this.scriptLoaderService.loadScript('pdfjs');

    return new Promise((resolve, reject) => {
      // eslint-disable-next-line new-cap
      const doc = new jsPDF({
        orientation: paperTpl.orientation,
        unit: 'mm',
        format: paperTpl.format
      });

      const a0width = 841;
      const qrCodeA0Width = 320;
      const isLandscape = paperTpl.orientation === 'l';
      let multiplier = paperTpl.multiplier | 1;
      let width;
      if (typeof (paperTpl.format) === 'string') {
        multiplier = paperTpl.format.replace('a', '');
        width = Math.floor(a0width / Math.sqrt(Math.pow(2, multiplier)));
      } else {
        width = paperTpl.format[0] / 2.835; // convert pt to mm
      }

      certificates.forEach((value, index) => {
        if (index > 0) {
          doc.addPage(paperTpl.format, paperTpl.orientation);
        }

        const content = JSON.parse(value.json);

        doc.addFileToVFS('Arial.ttf', Arial.trim());
        doc.addFileToVFS('Simsun.ttf', Simsun.trim());

        doc.addFont('Arial.ttf', 'Arial', 'normal');
        doc.setFontSize(paperTpl.fontSize);

        doc.addFont('Simsun.ttf', 'Simsun', 'normal');

        doc.setFont('Arial');

        const logo = document.createElement('img');
        logo.src = 'assets/img/arianee@2x.png';

        const qr = qrcode(4, 'L');
        qr.addData(`${this.deeplinkUrl}${value.tokenId},${value.viewKey}`);
        qr.make();

        let heightCol1 = 0;
        let heightCol2 = 0;
        let col1x = width / 2;
        let col2x = width / 2;
        if (isLandscape) {
          heightCol2 = 3;
          col1x = width * 0.27;
          col2x = width * 0.60;
        }

        if (paperTpl.logo) {
          const logoA0Width = 420;
          const logoWidth = Math.floor(logoA0Width / Math.sqrt(Math.pow(2, multiplier)));
          doc.addImage(logo, 'png', (width - logoWidth) / 2, 5, logoWidth, logoWidth / 5);
          heightCol1 += logoWidth / 5 + (paperTpl.heightIncrementcol1 * 2);
        } else {
          if (!isLandscape) {
            heightCol1 = doc.internal.pageSize.getHeight() / 4;
          }
        }

        if (isLandscape) {
          const qrcodeWidth = Math.floor(width / 3);
          doc.addImage(qr.createDataURL(), 'png', col2x - 3.5, heightCol2, qrcodeWidth, qrcodeWidth);
          heightCol2 += qrcodeWidth;

          doc.text('secured by Arianee', col2x, heightCol2);
          heightCol2 += paperTpl.heightIncrementcol2;
        }

        if (content.name) {
          if (isLandscape) {
            doc.text(content.name, col2x, heightCol2, { align: 'left' });
            heightCol2 += paperTpl.heightIncrementcol2;
          } else {
            doc.text(content.name, width / 2, heightCol1, { align: 'center' });
            heightCol1 += paperTpl.heightIncrementcol1;
          }
        }

        if (content.model) {
          if (isLandscape) {
            doc.text(content.model, col2x, heightCol2, { align: 'left' });
            heightCol2 += paperTpl.heightIncrementcol2;
          } else {
            doc.text(content.model, width / 2, heightCol1, { align: 'center' });
            heightCol1 += paperTpl.heightIncrementcol1;
          }
        }

        if (content.serialnumber && content.serialnumber.length > 0) {
          for (const serial of content.serialnumber) {
            switch (serial.type) {
              case 'serialnumber':
                if (isLandscape) {
                  doc.text(`Serial Number : ${serial.value}`, col2x, heightCol2, { align: 'left' });
                  heightCol2 += paperTpl.heightIncrementcol2;
                } else {
                  doc.text(`Serial Number : ${serial.value}`, width / 2, heightCol1, { align: 'center' });
                  heightCol1 += paperTpl.heightIncrementcol1;
                }
                break;
              case 'casenumber':
                if (isLandscape) {
                  doc.text(`Case Number : ${serial.value}`, col2x, heightCol2, { align: 'left' });
                  heightCol2 += paperTpl.heightIncrementcol2;
                } else {
                  doc.text(`Case Number : ${serial.value}`, width / 2, heightCol1, { align: 'center' });
                  heightCol1 += paperTpl.heightIncrementcol1;
                }
                break;
              case 'movementnumber':
                if (isLandscape) {
                  doc.text(`Movement Number : ${serial.value}`, col2x, heightCol2, { align: 'left' });
                  heightCol2 += paperTpl.heightIncrementcol2;
                } else {
                  doc.text(`Movement Number : ${serial.value}`, width / 2, heightCol1, { align: 'center' });
                  heightCol1 += paperTpl.heightIncrementcol1;
                }

                break;
              default:
                if (isLandscape) {
                  doc.text(`Serial Number : ${serial.value}`, col2x, heightCol2, { align: 'left' });
                  heightCol2 += paperTpl.heightIncrementcol2;
                } else {
                  doc.text(`Serial Number : ${serial.value}`, width / 2, heightCol1, { align: 'center' });
                  heightCol1 += paperTpl.heightIncrementcol1;
                }

                break;
            }
          }
        }

        const textOptions:any = {
          align: 'center'
        };

        if (isLandscape) {
          textOptions.maxWidth = doc.internal.pageSize.getWidth() * 0.40;
        }
        heightCol1 += paperTpl.heightIncrementcol1;
        doc.text('Please scan the below QR code to retrieve your Digital Certificate', col1x, heightCol1, textOptions);
        heightCol1 += paperTpl.heightIncrementcol1 * 2;
        doc.text('Veuillez scanner le QR code pour obtenir votre certificat digital', col1x, heightCol1, textOptions);
        heightCol1 += paperTpl.heightIncrementcol1 * 2;
        doc.setFont('Simsun');
        doc.text('請掃描下方的二維碼，以存取電子證書', col1x, heightCol1, textOptions);
        heightCol1 += paperTpl.heightIncrementcol1 * 2;
        doc.text('请扫描二维码，以存取电子证书', col1x, heightCol1, textOptions);
        heightCol1 += paperTpl.heightIncrementcol1 * 2;
        doc.text('下記のQRコードをスキャンしてデジタル保証書を取得して下さい。', col1x, heightCol1, textOptions);
        doc.setFont('Arial');
        heightCol1 += paperTpl.heightIncrementcol1 * 2;
        doc.text('المسح الضوئي لرمز الاستجابة السريعة أسفله للحصول على الشهادة الرقمية', col1x, heightCol1, textOptions);
        heightCol1 += paperTpl.heightIncrementcol1 * 2;
        doc.text('Cканируйте приведенный ниже QR-код и получите цифровой сертификат.', col1x, heightCol1, textOptions);
        heightCol1 += paperTpl.heightIncrementcol1;

        if (!isLandscape) {
          const qrcodeWidth = Math.floor(width / 3);
          doc.addImage(qr.createDataURL(), 'png', col1x - (qrcodeWidth / 2), heightCol1, qrcodeWidth, qrcodeWidth);
          heightCol1 += qrcodeWidth;
          doc.text('secured by Arianee', col1x - (qrcodeWidth / 2) + 6.5, heightCol1, { align: 'left' });
        }

        if (paperTpl.printedIn) {
          doc.setFontSize(paperTpl.fontSize * 0.75);
          doc.text('Printed in ' + paperTpl.printedIn, width - 3, 25, { angle: 90 });
        }
      });

      doc.save(paperTpl.name + '.pdf');
      resolve();
    });
  }
}
