import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
var ReserveTokenComponent = /** @class */ (function () {
    function ReserveTokenComponent(uiAlertService) {
        this.uiAlertService = uiAlertService;
        this.numberOfTokens = '__';
        this.form = new FormGroup({
            rangeStart: new FormControl(undefined, [
                Validators.required,
                Validators.min(1),
                Validators.pattern(/[0-9]+/)
            ]),
            rangeEnd: new FormControl(undefined, [
                Validators.required,
                Validators.min(1),
                Validators.pattern(/[0-9]+/)
            ])
        }, ReserveTokenComponent.MyAwesomeRangeValidator);
        this.reserveNCertificateIdForm = new FormGroup({
            numberOfCertificateId: new FormControl(undefined, [
                Validators.required,
                Validators.min(1),
                Validators.pattern(/[0-9]+/)
            ])
        });
        this.getArrayFromRange = function (from, to) {
            var range = [];
            for (var i = from; i <= to; i++) {
                range.push(i);
            }
            return range;
        };
    }
    ReserveTokenComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form.valueChanges.subscribe(function (d) {
            var rangeStart = d.rangeStart, rangeEnd = d.rangeEnd;
            if (rangeStart !== undefined && rangeEnd !== undefined && rangeStart <= rangeEnd) {
                _this.numberOfTokens = rangeEnd - rangeStart + 1;
            }
            else {
                _this.numberOfTokens = '__';
            }
        });
    };
    ReserveTokenComponent.prototype.onReserveNCertificateIdInternalSubmit = function () {
        return this.onSubmitNumber(this.reserveNCertificateIdForm.getRawValue().numberOfCertificateId);
    };
    ReserveTokenComponent.prototype.onInternalSubmit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var values, rangeStart, rangeEnd;
            return tslib_1.__generator(this, function (_a) {
                values = this.form.getRawValue();
                rangeStart = values.rangeStart, rangeEnd = values.rangeEnd;
                return [2 /*return*/, this.onSubmitRange(this.getArrayFromRange(rangeStart, rangeEnd))];
            });
        });
    };
    ReserveTokenComponent.MyAwesomeRangeValidator = function (fg) {
        var start = fg.get('rangeStart').value;
        var end = fg.get('rangeEnd').value;
        return start !== null && end !== null && start <= end
            ? null
            : { range: true };
    };
    return ReserveTokenComponent;
}());
export { ReserveTokenComponent };
