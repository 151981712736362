import { Component } from '@angular/core';
import { AlertController, LoadingController, NavController } from '@ionic/angular';
import { Utils } from '../../services/utils/utils';
import { AuthService } from '../../services/auth/auth';
import { config } from '../../../config';
import { filter, map, take, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';

/*
*
*  Intro Page
*
*/
@Component({
  selector: 'page-login',
  styleUrls: ['./login.scss'],
  templateUrl: 'login.html'
})
export class Login {
    user = {
      email: '',
      password: ''
    };

    returnView: any;
    public subscribe: any = {};

    constructor (
        public utils: Utils,
        public authService: AuthService,
        public loadingCtrl: LoadingController,
        private alertCtrl: AlertController,
        private route: ActivatedRoute,
        private navCtrl: NavController,
        private router: Router,
        private eventLogger: EventLoggerService

    ) {
      this.returnView = this.route.snapshot.queryParamMap.get('returnView');
    }

    ionViewDidEnter () {
      this.eventLogger.logScreen('login-page');
    }

    async signInWithEmail () {
      const loading = await this.loadingCtrl.create({
        spinner: 'dots',
        message: 'Loading'
      });
      loading.present();

      this.authService.signInRegular(this.user.email, this.user.password)
        .then((res) => {
          res.user.getIdToken(true).then((token) => {
            this.authService.getUserProfile()
              .then(role => {
                loading.dismiss();
                this.authService.$userState
                  .pipe(
                    map(d => d.isLoggedIn),
                    filter(isLoggedIn => isLoggedIn),
                    take(1),
                    tap(isLoggedIn => {
                      if (!this.returnView) {
                        return this.router.navigate(['certificate/list']);
                      } else {
                        return this.router.navigate([this.returnView]);
                      }
                    }))
                  .subscribe()
                ;
              });
          })
            .catch(err => {
              loading.dismiss();
              this.utils.error(err);
            });
        })
        .catch((err) => {
          console.error('error: ' + err);
          loading.dismiss();

          this.utils.error(err);
        });
    }

    showForgetPassword () {

      /* this.subscribe.publicConfig = this.fireStore.collection('publicConfig').snapshotChanges()
            .pipe(map((payload: any) => {
                return payload.map(a => {
                    let data = a.payload.doc.data();
                    data.id = a.payload.doc.id;
                    return data;
                })
            })).subscribe(async (data: any) => {
                let subTitle = 'Please contact your administrator to request a password reset';

                if (data[0] && data[0].emailAdmin) {
                    subTitle = 'Please contact <b>' + data[0].emailAdmin + '</b> to request a password reset';
                }

                let alert = await this.alertCtrl.create({
                    header: 'Forgot password',
                    subHeader: subTitle,
                    buttons: ['Understood']
                });
                alert.present();
                this.subscribe.publicConfig.unsubscribe();
            }) */

    }
}
