import { Component } from '@angular/core';
import { LoadingController, NavController } from '@ionic/angular';
import { Utils } from '../../services/utils/utils';

import { AuthService } from '../../services/auth/auth';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SchemaService } from '../../services/schema-service/schema-service';
import { debounceTime, map, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PaperCertService } from '../../services/paper-cert-service/paper-cert.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient } from '../../../../node_modules/@angular/common/http';
import { MessageService } from '../../services/messageService/messageService';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';

import { WalletCoreService } from '../../services/walletCoreService/walletCoreService';
import { config } from '../../../config';
import { TagsEnum } from '../../models/tags-enum';
import { certificateDBTOCertificateItem } from '../../helpers/certificateDBToCertificateItem';
import { CertificateCoreService } from '../../services/certificate-service/certificate-core.service';
import {
  distinctUntilChangedArrayOfObjectChanged,
  fakeWebSocketObservable
} from '../../helpers/fakeWebSocketObservable/fakeWebSocketObservable';
import { UsersService } from '../../services/users-service/userService';
import * as namespace from 'namespace';
import CertificateItem = namespace.CertificateItem;

/*
*
*  Wallet Page
*
*/
@Component({
  selector: 'page-wallet',
  templateUrl: './wallet.page.html'
})
export class WalletPage {
    public certificateList: CertificateItem[] = [];
    public stillOwner = false;
    public ownership = 'na';
    public filter = false;
    public loading: any;
    public textSearch: string;
    public batchProcess: boolean;
    public message: any = {};
    public transferComp: string;
    public transferNb: string;
    public tags: string;
    public legacy: boolean;
    public optout = 'na';
    public isLostOrStolen = 'na';
    public isParentCertificate = 'na';
    public address;
    public selectedPaperTpl;
    public paperTplList;
    public users: any = {};
    private subscriptions: Subscription[] = [];
    public $searchQuery = new BehaviorSubject({});
    public arianeeIdOrSerialNumber: string;

    constructor (
        public navCtrl: NavController,
        public utils: Utils,
        public loadingCtrl: LoadingController,
        public authService: AuthService,
        private schemaService: SchemaService,
        private router: Router,
        private paperCertService: PaperCertService,
        private http: HttpClient,
        private _firebaseAuth: AngularFireAuth,
        private messageService: MessageService,
        private uiAlert: UIAlertService,
        private eventLogger: EventLoggerService,
        private walletCoreService: WalletCoreService,
        private certificateCoreService: CertificateCoreService,
        private usersService: UsersService
    ) {
      this.utils.getPaperTplList().then((data) => {
        this.paperTplList = data;
        this.selectedPaperTpl = this.paperTplList[0];
      });
    }

    ionViewDidEnter () {
      this.eventLogger.logScreen('wallet-page');

      this.authService.$userState
        .pipe(take(1))
        .subscribe((state) => {
          if (state.isLoggedIn) {
            this.stillOwner = false;
            this.load();
          } else {
            this.navCtrl.navigateForward('login', { queryParams: { returnView: 'wallet' } });
          }
        });
    }

    checkLoading (that) {
      if (!that.arianeeProtocol.firstLoading) {
        setTimeout(that.checkLoading, 1000, that);
      } else {
        that.load();
        that.loading.dismiss();
      }
    }

    isCertificateYoungerThan (d, hour: number) {
      return (d.blockNumberCreation > 0 || (d.createdAt && d.createdAt.seconds > (Math.floor(Date.now() / 1000) - 60 * 60 * hour)));
    }

    public showLoader = async () => {
      if (!this.loading) {
        this.loading = this.loadingCtrl.create({
          spinner: 'dots',
          message: 'Loading certificates'
        });
        this.loading.then(d => d.present());
      }
      return this.loading;
    };

    public dismissLoader = async () => {
      if (this.loading) {
        const loader = await this.loading;
        loader.dismiss();
        this.loading = undefined;
      }
    };

    load = async () => {
      this.address = await this.walletCoreService.getAddress();

      await this.showLoader();
      this.dismissLoader();

      this.usersService.all()
        .pipe(take(1))
        .subscribe((data) => {
          let user: any;
          for (user of data) {
            this.users[user.userId] = user.email;
          }
        });

      await this.filterItem();
      const sub = this.$searchQuery
        .pipe(
          debounceTime(500),
          switchMap((userQuery) => {
            const query = {
              ...userQuery
            };

            return fakeWebSocketObservable(
              this.certificateCoreService.getCertificateList(query,
                { blockNumberCreation: -1 }, 200));
          }
          ),
          distinctUntilChangedArrayOfObjectChanged('tokenId'),
          map((data: any) =>
            data// remove certificate reserved that does not have JSON.
              .filter(d => this.isCertificateYoungerThan(d, 48))
              .map(data => certificateDBTOCertificateItem(data, this.address))
              .filter(d => d !== undefined)))
        .subscribe((data) => {
          this.certificateList = data.sort(this.orderByBlockNumber);
          this.dismissLoader();
        });

      this.subscriptions.push(sub);
    };

    orderByBlockNumber (a, b) {
      return (a.blockNumberCreation < b.blockNumberCreation) ? 1 : ((b.blockNumberCreation < a.blockNumberCreation) ? -1 : 0);
    }

    public async filterItem () {
      const deadAddress = '0x000000000000000000000000000000000000dEaD';

      const ownerShipQuery = async () => {
        const owner = this.ownership;
        if (owner === 'na') {
          return {};
        } else {
          const address = await this.walletCoreService.getAddress();

          if (owner === 'me') {
            return {
              owner: address
            };
          } else if (owner === 'other') {
            return {
              owner: { $ne: address }
            };
          } else if (owner === 'destroyed') {
            return {
              owner: deadAddress
            };
          } else if (owner === 'all') {
            return {};
          }
        }
      };

      const transferCompQuery = async () => {
        const transferComp = this.transferComp;
        const numberOfTransfer = this.transferNb;

        if (numberOfTransfer === undefined || numberOfTransfer.length === 0) {
          return {};
        }
        if (transferComp === 'equal') {
          return { numberOfTransfer: { $eq: numberOfTransfer } };
        } else if (transferComp === 'more') {
          return {
            numberOfTransfer: { $gt: numberOfTransfer }
          };
        } else if (transferComp === 'less') {
          return {
            numberOfTransfer: { $gt: numberOfTransfer }
          };
        }
      };

      const tagsQuery = async () => {
        const tags = this.tags;
        if (tags === undefined || tags.length === 0) {
          return {};
        } else {
          return {
            tags: { $in: tags }
          };
        }
      };

      const optoutQuery = async () => {
        const optout = this.optout;
        if (optout === 'na') {
          return {};
        } else {
          if (optout === 'yes') {
            return {
              whitelist: true
            };
          } else if (optout === 'no') {
            return {
              whitelist: false
            };
          }
        }
      };

      const parentQuery = async () => {
        const parent = this.isParentCertificate;
        if (parent === 'na') {
          return {};
        } else {
          const parentTag = TagsEnum.parentCertificate;
          if (parent === 'yes') {
            return {
              tags: parentTag
            };
          } else if (parent === 'no') {
            return {
              tags: { $ne: parentTag }
            };
          }
        }
      };

      let d:any = (await Promise.all(
        [
          ownerShipQuery(),
          transferCompQuery(),
          tagsQuery(),
          optoutQuery(),
          parentQuery()
        ]
      )).reduce((acc, value) => {
        return {
          ...acc,
          ...value
        };
      }, {});

      if (this.arianeeIdOrSerialNumber) {
        d = {
          $or: [
            { serialNumbers: this.arianeeIdOrSerialNumber },
            { tokenId: this.arianeeIdOrSerialNumber }
          ]
        };
      }

      this.$searchQuery.next(d);
    }

    gotoItem (itemId) {
      this.router.navigate([`/certificate/read/${itemId}`]);
    }

    toggleFilter () {
      if (this.filter) {
        this.filter = false;
      } else {
        this.filter = true;
      }
    }

    toggleBatch () {
      if (this.batchProcess) {
        this.batchProcess = false;
      } else {
        this.batchProcess = true;
      }
    }

    duplicate (item, slidingItem) {
      slidingItem.close();
      return this.router.navigate([`/certificate/duplicate/${item.id}`]);
    }

    async printFilteredCertificates () {
      if (this.selectedPaperTpl) {
        const certificateIds = this.certificateList.map(item => item.id);

        this.loading = await this.loadingCtrl.create({
          spinner: 'dots',
          message: 'Generate PDF'
        });
        this.loading.present();
        await this.paperCertService.generatePaperCert(certificateIds, this.selectedPaperTpl.name);
        this.loading.dismiss();
        this.toggleBatch();
      }
    }

    public deepLinkCreator = (arianeeId, passphrase) => `${this.utils.deeplink()}${arianeeId},${passphrase}`;

    public findSerialNumber = (serialnumbers) => {
      if (serialnumbers) {
        const serialNumber = serialnumbers
          .filter(item => item !== null && item !== undefined)
          .find(item => item.type === 'serialnumber');

        if (serialNumber) {
          return serialNumber.value;
        }
      }
    };

    public dowloadFile (encodedUri, fileName: string) {
      var link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', fileName);
      document.body.appendChild(link); // Required for FF

      link.click();
    }

    public exportFilteredCertificatesInJSON () {
      const fileName = this.createExportFileName('json');
      var encodedUri = encodeURIComponent(JSON.stringify(this.prepareForExport(), null, 2));
      const jsonContent = `data:text/csv;charset=utf-8,${encodedUri}`;

      this.dowloadFile(jsonContent, fileName);
    }

    public createExportFileName (ext: string) {
      return `export-bdh-${new Date().toISOString()}.${ext}`;
    }

    public exportFilteredCertificatesInCSV () {
      const preparedData = this.prepareForExport();

      if (preparedData.length > 0) {
        const header = Object.keys(preparedData[0]);
        const rows = [header];

        preparedData
          .map(item => header.map(key => item[key]))
          .forEach(i => rows.push(i));

        let csvContent = 'data:text/csv;charset=utf-8,';
        rows
        // escpae characters
          .map(line => line
            .map(content => content !== undefined ? content : '')
            .map(content => `"${encodeURIComponent(content)}"`))

          .forEach(function (rowArray) {
            const row = rowArray.join(',');
            csvContent += row + '\r\n';
          });

        const fileName = this.createExportFileName('csv');

        this.dowloadFile(csvContent, fileName);
      }
    }

    navigateToSendDMessage () {
      this.router.navigate(['/certificate/message/create'], {
        queryParams: {
          certificateIds: this.certificateList
            .map(d => d.id)
            .join(',')
        }
      });
    }

    ionViewWillLeave = () => {
      this.subscriptions.forEach(sub => sub.unsubscribe());
    };

    private prepareForExport () {
      return this.certificateList
        .map((item) => {
          const eventOfTransfer = item.events.find(d => d.event === 'Transfer');

          const blockNumberOfCreation = eventOfTransfer && eventOfTransfer.blockNumber;
          const firstAccessDate = item._log_firstAccess
            ? new Date(item._log_firstAccess).toUTCString()
            : 'N/A';

          const lastAccessDate = item._log_lastAccess
            ? new Date(item._log_lastAccess).toUTCString()
            : 'N/A';

          const createdAt = item.createdAt
            ? new Date(item.createdAt).toUTCString()
            : 'N/A';

          const isDestroyed = item.owner
            ? item.owner.toLowerCase() === '0x000000000000000000000000000000000000dEaD'.toLowerCase()
            : 'N/A';

          return ({
            arianeeId: item.id,
            passphrase: item.viewKey,
            deeplink: this.deepLinkCreator(item.id, item.viewKey),
            serialNumber: this.findSerialNumber(item.serialnumber),
            name: item.name,
            chain: config.network,
            firstAccessDate,
            lastAccessDate,
            numberOfAccess: item._log_fetchedCount || 'N/A',
            currentOwner: item.owner || 'N/A',
            isDestroyed,
            blockNumberOfCreation,
            createdAt
          });
        }
        );
    }
}
