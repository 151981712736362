import * as tslib_1 from "tslib";
import { FirebaseHttpClient } from '../services/firebaseHttpClient/firebaseHttpClient';
import { EndpointsService } from './endpointsService/endpointsService';
import * as i0 from "@angular/core";
import * as i1 from "../services/firebaseHttpClient/firebaseHttpClient";
import * as i2 from "./endpointsService/endpointsService";
var ContentAPIService = /** @class */ (function () {
    function ContentAPIService(firebaseHttpClient, endpointService) {
        this.firebaseHttpClient = firebaseHttpClient;
        this.endpointService = endpointService;
    }
    /**
       * Store content of certificate without passing by RPC Server
       * @param tokenId
       * @param json
       */
    ContentAPIService.prototype.storeCertificateContent = function (tokenId, json) {
        return this.firebaseHttpClient.post(this.endpointService.endpoints.content.certificate, { tokenId: tokenId, json: json });
    };
    /**
       * Store content of certificate without passing by RPC Server
       * @param tokenId
       * @param json
       */
    ContentAPIService.prototype.doctorCertificate = function (json) {
        return this.firebaseHttpClient.post(this.endpointService.endpoints.certificate.doctor, tslib_1.__assign({}, json));
    };
    /**
       * Store content of Event without passing by RPC Server
       * @param tokenId
       * @param eventId
       * @param json
       */
    ContentAPIService.prototype.storeEventContent = function (tokenId, eventId, json) {
        return this.firebaseHttpClient.post(this.endpointService.endpoints.content.event, {
            tokenId: tokenId,
            eventId: eventId,
            json: json
        });
    };
    /**
       * Store Message of Event without passing by RPC Server
       * @param tokenId
       * @param messageId
       * @param json
       */
    ContentAPIService.prototype.storeMessageContent = function (tokenId, messageId, json) {
        return this.firebaseHttpClient.post(this.endpointService.endpoints.content.message, {
            tokenId: tokenId,
            messageId: messageId,
            json: json
        });
    };
    /**
       * Store Brand identity
       * @param json
       * @param imprint
       */
    ContentAPIService.prototype.storeIdentityContent = function (content) {
        return this.firebaseHttpClient.post(this.endpointService.endpoints.identity.create, {
            content: content
        });
    };
    ContentAPIService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContentAPIService_Factory() { return new ContentAPIService(i0.ɵɵinject(i1.FirebaseHttpClient), i0.ɵɵinject(i2.EndpointsService)); }, token: ContentAPIService, providedIn: "root" });
    return ContentAPIService;
}());
export { ContentAPIService };
