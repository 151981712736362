import * as tslib_1 from "tslib";
import { UserSessionService } from '../../services/user-session-service/user-session-service';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/user-session-service/user-session-service";
import * as i2 from "../../services/UI-alert-service/UI-alert-service";
var CanLeavePreviousPage = /** @class */ (function () {
    function CanLeavePreviousPage(userSessionService, uIAlertService) {
        this.userSessionService = userSessionService;
        this.uIAlertService = uIAlertService;
    }
    CanLeavePreviousPage.prototype.askUserToConfirmLeaving = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var resolveLeaving, canLeave, alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        canLeave = new Promise(function (resolve) { return resolveLeaving = resolve; });
                        return [4 /*yield*/, this.uIAlertService.alert({
                                header: 'Confirm leave',
                                message: 'You will lose your modifications if you leave the page. Are you sure you want to leave the page?',
                                buttons: [
                                    {
                                        text: 'Cancel',
                                        role: 'cancel',
                                        handler: function () { return resolveLeaving(false); }
                                    },
                                    {
                                        text: 'Confirm',
                                        handler: function () {
                                            _this.userSessionService.preventUserFromLeavingPage = true;
                                            return resolveLeaving(true);
                                        }
                                    }
                                ]
                            })];
                    case 1:
                        alert = _a.sent();
                        return [2 /*return*/, canLeave];
                }
            });
        });
    };
    CanLeavePreviousPage.prototype.canActivate = function (route, state) {
        if (this.userSessionService.preventUserFromLeavingPage === false) {
            return this.askUserToConfirmLeaving();
        }
        else {
            return true;
        }
    };
    CanLeavePreviousPage.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CanLeavePreviousPage_Factory() { return new CanLeavePreviousPage(i0.ɵɵinject(i1.UserSessionService), i0.ɵɵinject(i2.UIAlertService)); }, token: CanLeavePreviousPage, providedIn: "root" });
    return CanLeavePreviousPage;
}());
export { CanLeavePreviousPage };
