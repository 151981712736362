import { Component, OnInit } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { AuthService } from '../../services/auth/auth';
import { Router } from '@angular/router';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';

@Component({
  templateUrl: 'resetPassword.html',
  styleUrls: ['resetPassword.scss']
})

export class ResetPassword implements OnInit {
    public newPassword: string;
    public confirmPassword: string;
    public actionCode: string;
    private _location: string;

    constructor (
        public authService: AuthService,
        public loadingCtrl: LoadingController,
        private router: Router,
        private uiAlert: UIAlertService,
        private eventLogger: EventLoggerService
    ) {
    }

    IonViewDidEnter () {
      this.eventLogger.logScreen('resetPassword-page');
    }

    ngOnInit () {
      this._location = (window as any).firstURL;
      this.actionCode = this.getQuery().get('oobCode');
      if (!this.actionCode) {
        this.uiAlert.alert(
          {
            header: 'Error',
            message: 'Did you landed here on purpose? If so, please ask you admin a new reset password link.',
            buttons: [
              {
                text: 'ok',
                handler: () => this.router.navigate(['login'])
              }
            ]

          });
      }
    }

    async handleResetPassword () {
      const loader = await this.uiAlert.load();

      try {
        await this.authService.confirmPasswordReset(
          this.actionCode,
          this.newPassword
        );
        await loader.dismiss();

        return this.uiAlert.alert(
          {
            header: 'Success',
            message: 'Your new password has been set up!',
            buttons: [
              {
                text: 'ok',
                handler: () => this.router.navigate(['login'])
              }
            ]

          });
      } catch (e) {
        await loader.dismiss();
        return this.uiAlert.alert(
          {
            header: 'Error',
            message: `An unknow error occured: ${e.message}. Please ask your admin a new reset password link.`,
            buttons: [
              {
                text: 'ok',
                role: 'cancel'
              }
            ]
          });
      }
    }

    private getQuery () {
      const path = this._location.split('?');
      return new URLSearchParams(path[1]);
    };
}
