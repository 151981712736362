import * as tslib_1 from "tslib";
import { LoadingController } from '@ionic/angular';
import { AuthService } from './services/auth/auth';
import { config } from '../config';
import { filter, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UIAlertService } from './services/UI-alert-service/UI-alert-service';
import { EventLoggerService } from './services/event-logger/event-logger-service';
import { WalletService } from './apiServices/BDHApi/api/wallet.service';
import { FirebaseHttpClient } from './services/firebaseHttpClient/firebaseHttpClient';
import { WalletCoreService } from './services/walletCoreService/walletCoreService';
import { IdentityService } from './apiServices/BDHApi';
import { TranslateService } from '@ngx-translate/core';
var AppComponent = /** @class */ (function () {
    function AppComponent(authService, loadingCtrl, router, eventLogger, uiUserAlert, WalletService, firebaseHttpClient, walletCoreService, identityService, translateService) {
        this.authService = authService;
        this.loadingCtrl = loadingCtrl;
        this.router = router;
        this.eventLogger = eventLogger;
        this.uiUserAlert = uiUserAlert;
        this.WalletService = WalletService;
        this.firebaseHttpClient = firebaseHttpClient;
        this.walletCoreService = walletCoreService;
        this.identityService = identityService;
        this.translateService = translateService;
        this.token = '';
        this.logged = false;
        this.admin = false;
        this.developerCount = 0;
        this.subscribeLogged = false;
        this.blockiesOptions = {
            seed: 'randstring',
            color: '#ffb200',
            bgcolor: '#5c5c5c',
            size: 8,
            scale: 4,
            spotcolor: '#000' // each pixel has a 13% chance of being of a third color,
            // default: random. Set to -1 to disable it. These "spots" create structures
            // that look like eyes, mouths and noses.
        };
        translateService.use('en');
        this.config = config;
        this.subscribeLogged = true;
        this.initArianeeProtocol();
    }
    AppComponent.prototype.getIdentityLogo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var address, identityObject;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.walletCoreService.getAddress()];
                    case 1:
                        address = _a.sent();
                        return [4 /*yield*/, this.identityService.getIdentity([address]).toPromise()];
                    case 2:
                        identityObject = _a.sent();
                        if (identityObject && identityObject.data.pictures) {
                            this.brandLogo = identityObject.data.pictures.find(function (element) { return element.type === 'brandLogoHeader'; });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.initArianeeProtocol = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, userDetails, profile, loader;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.authService.$userState
                            .pipe(filter(function (d) { return d.isLoggedIn; }), take(1))
                            .toPromise()];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, this.firebaseHttpClient.init()];
                    case 2:
                        _b.sent();
                        return [4 /*yield*/, Promise.all([this.authService.getUserDetails(), this.authService.getUserProfile()])];
                    case 3:
                        _a = _b.sent(), userDetails = _a[0], profile = _a[1];
                        return [4 /*yield*/, this.uiUserAlert.load()];
                    case 4:
                        loader = _b.sent();
                        this.logged = true;
                        this.admin = (profile === 'admin');
                        loader.dismiss();
                        this.eventLogger.init();
                        this.getIdentityLogo();
                        return [2 /*return*/];
                }
            });
        });
    };
    return AppComponent;
}());
export { AppComponent };
