import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { AuthService } from '../../services/auth/auth';
import { Router } from '@angular/router';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';
var ResetPassword = /** @class */ (function () {
    function ResetPassword(authService, loadingCtrl, router, uiAlert, eventLogger) {
        this.authService = authService;
        this.loadingCtrl = loadingCtrl;
        this.router = router;
        this.uiAlert = uiAlert;
        this.eventLogger = eventLogger;
    }
    ResetPassword.prototype.IonViewDidEnter = function () {
        this.eventLogger.logScreen('resetPassword-page');
    };
    ResetPassword.prototype.ngOnInit = function () {
        var _this = this;
        this._location = window.firstURL;
        this.actionCode = this.getQuery().get('oobCode');
        if (!this.actionCode) {
            this.uiAlert.alert({
                header: 'Error',
                message: 'Did you landed here on purpose? If so, please ask you admin a new reset password link.',
                buttons: [
                    {
                        text: 'ok',
                        handler: function () { return _this.router.navigate(['login']); }
                    }
                ]
            });
        }
    };
    ResetPassword.prototype.handleResetPassword = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var loader, e_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.uiAlert.load()];
                    case 1:
                        loader = _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 5, , 7]);
                        return [4 /*yield*/, this.authService.confirmPasswordReset(this.actionCode, this.newPassword)];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, loader.dismiss()];
                    case 4:
                        _a.sent();
                        return [2 /*return*/, this.uiAlert.alert({
                                header: 'Success',
                                message: 'Your new password has been set up!',
                                buttons: [
                                    {
                                        text: 'ok',
                                        handler: function () { return _this.router.navigate(['login']); }
                                    }
                                ]
                            })];
                    case 5:
                        e_1 = _a.sent();
                        return [4 /*yield*/, loader.dismiss()];
                    case 6:
                        _a.sent();
                        return [2 /*return*/, this.uiAlert.alert({
                                header: 'Error',
                                message: "An unknow error occured: " + e_1.message + ". Please ask your admin a new reset password link.",
                                buttons: [
                                    {
                                        text: 'ok',
                                        role: 'cancel'
                                    }
                                ]
                            })];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    ResetPassword.prototype.getQuery = function () {
        var path = this._location.split('?');
        return new URLSearchParams(path[1]);
    };
    ;
    return ResetPassword;
}());
export { ResetPassword };
