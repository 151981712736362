import * as tslib_1 from "tslib";
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../services/auth/auth';
import { Utils } from '../../services/utils/utils';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { FirebaseHttpClient } from '../../services/firebaseHttpClient/firebaseHttpClient';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';
import { EndpointsService } from '../../apiServices/endpointsService/endpointsService';
import { UsersService } from '../../services/users-service/userService';
import { ProfileService } from '../../services/profile-service/profileService';
var UsersManager = /** @class */ (function () {
    function UsersManager(_firebaseAuth, http, firebaseHttpClient, authService, utils, uIAlertService, eventLogger, endpointService, userService, profileService) {
        var _this = this;
        this._firebaseAuth = _firebaseAuth;
        this.http = http;
        this.firebaseHttpClient = firebaseHttpClient;
        this.authService = authService;
        this.utils = utils;
        this.uIAlertService = uIAlertService;
        this.eventLogger = eventLogger;
        this.endpointService = endpointService;
        this.userService = userService;
        this.profileService = profileService;
        this.users = [];
        this.profiles = [];
        this.addUser = false;
        this.addProfile = false;
        this.newUser = {};
        this.newProfile = {};
        this.subscribe = {};
        this.rights = [
            { mnemonic: 'Admin', title: 'admin' },
            { mnemonic: 'certRead', title: 'Certificate read' },
            { mnemonic: 'certCreate', title: 'Certificate creation' },
            { mnemonic: 'certPrint', title: 'Certificate print' },
            { mnemonic: 'certDelete', title: 'Certificate deletion' },
            { mnemonic: 'certRecover', title: 'Certificate recovery' },
            { mnemonic: 'messageSend', title: 'Message sending' },
            { mnemonic: 'assetsUpload', title: 'Assets upload' },
            { mnemonic: 'identityUpdate', title: 'Identity update' },
            { mnemonic: 'accountManage', title: 'Account management' },
            { mnemonic: 'messageSetupAuto', title: 'Message Auto setup' },
            { mnemonic: 'usersManage', title: 'Users management' },
            { mnemonic: 'eventCreate', title: 'Event creation' },
            { mnemonic: 'reporting', title: 'Reporting' }
        ];
        this.newRights = this.rights;
        this.formGroup = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
            password: new FormControl('', [Validators.required, Validators.minLength(6)]),
            profile: new FormControl('', [Validators.required])
        });
        this.getUsers = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.userService.all().toPromise()];
                    case 1:
                        _a.users = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.deleteUser = function (user) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var loading, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.uIAlertService.load()];
                    case 1:
                        loading = _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 6, , 8]);
                        return [4 /*yield*/, this.firebaseHttpClient.post(this.endpointService.endpoints.user.delete, {
                                uid: user.userId
                            }, { responseType: 'text' })
                                .toPromise()];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.getUsers()];
                    case 4:
                        _a.sent();
                        loading.dismiss();
                        return [4 /*yield*/, this.uIAlertService.toast({
                                cssClass: 'e2e_user_delete_success',
                                message: 'User has been deleted',
                                duration: 2500
                            })];
                    case 5:
                        _a.sent();
                        return [3 /*break*/, 8];
                    case 6:
                        e_1 = _a.sent();
                        loading.dismiss();
                        return [4 /*yield*/, this.uIAlertService.alert({
                                cssClass: 'e2e_user_delete_error',
                                message: 'an error occured'
                            })];
                    case 7:
                        _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        }); };
        this.getProfiles();
        this.myuid = this._firebaseAuth.auth.currentUser.uid;
    }
    UsersManager.prototype.ionViewDidEnter = function () {
        this.eventLogger.logScreen('userManager-page');
        this.getUsers();
    };
    UsersManager.prototype.getProfiles = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.profileService.all().toPromise()];
                    case 1:
                        _a.profiles = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    UsersManager.prototype.toggleAddUser = function () {
        this.addUser = !this.addUser;
    };
    UsersManager.prototype.toggleAddProfile = function () {
        this.addProfile = !this.addProfile;
        this.newRights = JSON.parse(JSON.stringify(this.rights));
    };
    UsersManager.prototype.toggleAll = function () {
        this.addProfile = false;
        this.addUser = false;
        this.newRights = JSON.parse(JSON.stringify(this.rights));
    };
    UsersManager.prototype.createUser = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var loading, values, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.uIAlertService.load()];
                    case 1:
                        loading = _a.sent();
                        values = this.formGroup.getRawValue();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 6, , 8]);
                        return [4 /*yield*/, this.userService.create(values).toPromise()];
                    case 3:
                        _a.sent();
                        this.toggleAddUser();
                        return [4 /*yield*/, this.getUsers()];
                    case 4:
                        _a.sent();
                        loading.dismiss();
                        return [4 /*yield*/, this.uIAlertService.toast({
                                cssClass: 'e2e_user_create_success',
                                message: "User " + values.email + " has been created",
                                duration: 8000
                            })];
                    case 5:
                        _a.sent();
                        return [3 /*break*/, 8];
                    case 6:
                        e_2 = _a.sent();
                        loading.dismiss();
                        return [4 /*yield*/, this.uIAlertService.alert({
                                cssClass: 'e2e_user_create_error',
                                message: 'an error occured'
                            })];
                    case 7:
                        _a.sent();
                        console.error(e_2);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    UsersManager.prototype.profileChange = function (user) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this._firebaseAuth.auth.currentUser.uid !== user.id)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.userService.update(user).toPromise()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.getUsers()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.uIAlertService.toast({
                            message: 'You can\'t edit your own profile.',
                            duration: 2000
                        })];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UsersManager.prototype.createProfile = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var rights;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        rights = [];
                        this.newRights.forEach(function (value) {
                            if (value.checked) {
                                rights.push(value.mnemonic);
                            }
                        });
                        return [4 /*yield*/, this.profileService.create(this.newProfile.name, rights).toPromise()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.getProfiles()];
                    case 2:
                        _a.sent();
                        this.newProfile = '';
                        this.toggleAddProfile();
                        return [2 /*return*/];
                }
            });
        });
    };
    UsersManager.prototype.confirmReset = function (user) {
        var _this = this;
        return this.uIAlertService.alert({
            header: 'Confirm reset password',
            message: 'Are you sure to reset <b>' + user + '</b> password ?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    handler: function () {
                    }
                },
                {
                    text: 'Reset password',
                    handler: function () {
                        _this.resetPassword(user);
                    }
                }
            ]
        });
    };
    UsersManager.prototype.resetPassword = function (user) {
        var that = this;
        this._firebaseAuth.auth.sendPasswordResetEmail(user).then(function () {
            that.utils.success('Reset password email sent !');
        });
    };
    UsersManager.prototype.confirmDelete = function (user) {
        var _this = this;
        if (this._firebaseAuth.auth.currentUser.uid !== user.id) {
            return this.uIAlertService.alert({
                header: 'Confirm delete',
                message: 'Are you sure to delete ' + user.email + ' ?',
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: function () {
                        }
                    },
                    {
                        text: 'Delete',
                        cssClass: 'e2e_user_delete_confirm',
                        handler: function () {
                            _this.deleteUser(user);
                        }
                    }
                ]
            });
        }
        else {
            return this.uIAlertService.toast({
                message: 'You can\'t delete your own account.',
                duration: 2000
            });
        }
    };
    UsersManager.prototype.confirmDeleteProfile = function (profile) {
        var _this = this;
        return this.uIAlertService.alert({
            header: 'Confirm delete',
            message: 'Are you sure to delete ' + profile.name + ' ?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    handler: function () {
                    }
                },
                {
                    text: 'Delete',
                    handler: function () {
                        _this.deleteProfile(profile._id);
                    }
                }
            ]
        });
    };
    UsersManager.prototype.deleteProfile = function (profileId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.profileService.delete(profileId).toPromise()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.getProfiles()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return UsersManager;
}());
export { UsersManager };
