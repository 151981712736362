import * as tslib_1 from "tslib";
import { SchemaService } from '../schema-service/schema-service';
import { AngularFireAuth } from '@angular/fire/auth';
import { FeatureFlipService } from '../feature-flip-service/feature-flip-service';
import { FirebaseHttpClient } from '../firebaseHttpClient/firebaseHttpClient';
import { ArianeejsAPIService } from '../../apiServices/arianeejsAPIService';
import { ContentAPIService } from '../../apiServices/contentAPIService';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "../../apiServices/arianeejsAPIService";
import * as i2 from "@angular/fire/auth";
import * as i3 from "../../apiServices/contentAPIService";
import * as i4 from "../firebaseHttpClient/firebaseHttpClient";
import * as i5 from "../feature-flip-service/feature-flip-service";
import * as i6 from "@angular/common/http";
var MessageService = /** @class */ (function () {
    function MessageService(arianeejsServerAPIService, _firebaseAuth, contentService, firebaseHttpClient, featureFlipService, httpClient) {
        this.arianeejsServerAPIService = arianeejsServerAPIService;
        this._firebaseAuth = _firebaseAuth;
        this.contentService = contentService;
        this.firebaseHttpClient = firebaseHttpClient;
        this.featureFlipService = featureFlipService;
        this.httpClient = httpClient;
    }
    MessageService.prototype.sendDMessage = function (certificateId, content) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var messageId;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.arianeejsServerAPIService.createAndStoreMessage({
                            certificateId: certificateId,
                            content: content
                        }).toPromise()];
                    case 1:
                        messageId = (_a.sent()).messageId;
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
       * Legacy!
       * @param certificateId
       * @param {{title: string; content: string}} message
       * @returns {Promise<void>}
       */
    MessageService.prototype.sendMessage = function (certificateId, message) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var content, messageId;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        content = {
                            $schema: SchemaService.schemasURL.ArianeeMessageAlpha,
                            title: message.title,
                            content: message.content
                        };
                        return [4 /*yield*/, this.arianeejsServerAPIService.createMessage({
                                certificateId: certificateId,
                                content: content
                            }).toPromise()];
                    case 1:
                        messageId = (_a.sent()).messageId;
                        return [4 /*yield*/, this.contentService.storeMessageContent(certificateId, messageId, content).toPromise()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
       * Send message to multiple owner
       * @param certificateIds
       * @param message
       */
    MessageService.prototype.sendMessages = function (certificateIds, message) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, Promise.all(certificateIds.map(function (certificateId) { return _this.sendMessage(certificateId, message); }))];
            });
        });
    };
    MessageService.prototype.getSchema = function () {
        return this.httpClient.get(SchemaService.schemasURL.ArianeeMessage);
    };
    MessageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MessageService_Factory() { return new MessageService(i0.ɵɵinject(i1.ArianeejsAPIService), i0.ɵɵinject(i2.AngularFireAuth), i0.ɵɵinject(i3.ContentAPIService), i0.ɵɵinject(i4.FirebaseHttpClient), i0.ɵɵinject(i5.FeatureFlipService), i0.ɵɵinject(i6.HttpClient)); }, token: MessageService, providedIn: "root" });
    return MessageService;
}());
export { MessageService };
