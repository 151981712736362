import { Component } from '@angular/core';
import { StringWidget } from 'ngx-schema-form';
import { ModalController } from '@ionic/angular';
import { UploadAssets } from '../../pages/uploadAssets/uploadAssets';

@Component({
  selector: 'staticAssetsWidget',
  templateUrl: 'staticAssetsWidget.html'
})
export class StaticAssetsWidget extends StringWidget {
  public button;
  public formProperty;

  constructor (public modalCtrl: ModalController) {
    super();
  }

  async presentUploadAssetsModal () {
    const uploadAssetsModal = await this.modalCtrl.create({
      component: UploadAssets,
      componentProps: {
        isModal: true
      }
    });
    await uploadAssetsModal.present();
    const result = await uploadAssetsModal.onWillDismiss();
    if (result) {
      this.formProperty.setValue(result.data, false);
    }
  }
}
