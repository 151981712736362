/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i2 from "@ionic/angular";
import * as i3 from "../../components/schema-builder.ts/schema-builder.component.ngfactory";
import * as i4 from "../../components/schema-builder.ts/schema-builder.component";
import * as i5 from "../../services/utils/utils";
import * as i6 from "../../services/schema-service/schema-service";
import * as i7 from "../../apiServices/arianeejsAPIService";
import * as i8 from "@angular/forms";
import * as i9 from "./eventBuilder";
import * as i10 from "@angular/common/http";
import * as i11 from "../../services/UI-alert-service/UI-alert-service";
import * as i12 from "../../services/ArianeeEventService/arianeeEventService";
import * as i13 from "@angular/router";
import * as i14 from "../../services/certificate-service/certificate-core.service";
import * as i15 from "@angular/common";
import * as i16 from "../../services/event-logger/event-logger-service";
import * as i17 from "../../apiServices/BDHApi/api/economy.service";
import * as i18 from "../../apiServices/BDHApi/api/event.service";
var styles_EventBuilder = [];
var RenderType_EventBuilder = i0.ɵcrt({ encapsulation: 2, styles: styles_EventBuilder, data: {} });
export { RenderType_EventBuilder as RenderType_EventBuilder };
export function View_EventBuilder_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "ion-header", [], null, null, null, i1.View_IonHeader_0, i1.RenderType_IonHeader)), i0.ɵdid(1, 49152, null, 0, i2.IonHeader, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 11, "div", [["class", "header"], ["color", "light"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "ion-menu-button", [], null, null, null, i1.View_IonMenuButton_0, i1.RenderType_IonMenuButton)), i0.ɵdid(5, 49152, null, 0, i2.IonMenuButton, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 4, "ion-title", [], null, null, null, i1.View_IonTitle_0, i1.RenderType_IonTitle)), i0.ɵdid(7, 49152, null, 0, i2.IonTitle, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(8, 0, null, 0, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "ion-icon", [["name", "chevron-back-outline"]], null, null, null, i1.View_IonIcon_0, i1.RenderType_IonIcon)), i0.ɵdid(10, 49152, null, 0, i2.IonIcon, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], { name: [0, "name"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 2, "ion-title", [], null, null, null, i1.View_IonTitle_0, i1.RenderType_IonTitle)), i0.ɵdid(12, 49152, null, 0, i2.IonTitle, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵted(-1, 0, ["Event Builder"])), (_l()(), i0.ɵeld(14, 0, null, null, 22, "ion-content", [["class", "item text-center"], ["data-e2e", "create_arianee_event_page"]], null, null, null, i1.View_IonContent_0, i1.RenderType_IonContent)), i0.ɵdid(15, 49152, null, 0, i2.IonContent, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(16, 0, null, 0, 5, "ion-card", [], null, null, null, i1.View_IonCard_0, i1.RenderType_IonCard)), i0.ɵdid(17, 49152, null, 0, i2.IonCard, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(18, 0, null, 0, 3, "ion-card-content", [], null, null, null, i1.View_IonCardContent_0, i1.RenderType_IonCardContent)), i0.ɵdid(19, 49152, null, 0, i2.IonCardContent, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(20, 0, null, 0, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(21, null, ["You are creating an event for ", " certificateId (arianeeId) : ", ")"])), (_l()(), i0.ɵeld(22, 0, null, 0, 14, "schema-builder", [["assetType", "event"]], null, [[null, "onSubmit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSubmit" === en)) {
        var pd_0 = (_co.saveEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_SchemaBuilderComponent_0, i3.RenderType_SchemaBuilderComponent)), i0.ɵdid(23, 8437760, null, 0, i4.SchemaBuilderComponent, [i5.Utils, i0.Renderer2, i6.SchemaService, i0.ElementRef, i7.ArianeejsAPIService], { assetType: [0, "assetType"], schemaUrls: [1, "schemaUrls"] }, { onSubmitEmitter: "onSubmit" }), i0.ɵpad(24, 1), (_l()(), i0.ɵeld(25, 0, null, 0, 11, "ion-item", [], null, null, null, i1.View_IonItem_0, i1.RenderType_IonItem)), i0.ɵdid(26, 49152, null, 0, i2.IonItem, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(27, 0, null, 0, 2, "ion-label", [], null, null, null, i1.View_IonLabel_0, i1.RenderType_IonLabel)), i0.ɵdid(28, 49152, null, 0, i2.IonLabel, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵted(-1, 0, ["Accept event automatically"])), (_l()(), i0.ɵeld(30, 0, null, 0, 6, "ion-toggle", [["checked", "true"], ["name", "autoAccept"], ["slot", "start"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "ionBlur"], [null, "ionChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i0.ɵnov(_v, 32)._handleBlurEvent($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i0.ɵnov(_v, 32)._handleIonChange($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.autoAccept = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_IonToggle_0, i1.RenderType_IonToggle)), i0.ɵdid(31, 49152, null, 0, i2.IonToggle, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], { checked: [0, "checked"], name: [1, "name"] }, null), i0.ɵdid(32, 16384, null, 0, i2.BooleanValueAccessor, [i0.ElementRef], null, null), i0.ɵprd(1024, null, i8.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.BooleanValueAccessor]), i0.ɵdid(34, 671744, null, 0, i8.NgModel, [[8, null], [8, null], [8, null], [6, i8.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i8.NgControl, null, [i8.NgModel]), i0.ɵdid(36, 16384, null, 0, i8.NgControlStatus, [[4, i8.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "chevron-back-outline"; _ck(_v, 10, 0, currVal_0); var currVal_3 = "event"; var currVal_4 = _ck(_v, 24, 0, "https://cert.arianee.org/version1/ArianeeEvent-i18n.json"); _ck(_v, 23, 0, currVal_3, currVal_4); var currVal_12 = "true"; var currVal_13 = "autoAccept"; _ck(_v, 31, 0, currVal_12, currVal_13); var currVal_14 = "autoAccept"; var currVal_15 = _co.autoAccept; _ck(_v, 34, 0, currVal_14, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.item == null) ? null : _co.item.name); var currVal_2 = _co.itemId; _ck(_v, 21, 0, currVal_1, currVal_2); var currVal_5 = i0.ɵnov(_v, 36).ngClassUntouched; var currVal_6 = i0.ɵnov(_v, 36).ngClassTouched; var currVal_7 = i0.ɵnov(_v, 36).ngClassPristine; var currVal_8 = i0.ɵnov(_v, 36).ngClassDirty; var currVal_9 = i0.ɵnov(_v, 36).ngClassValid; var currVal_10 = i0.ɵnov(_v, 36).ngClassInvalid; var currVal_11 = i0.ɵnov(_v, 36).ngClassPending; _ck(_v, 30, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); }); }
export function View_EventBuilder_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "page-eventBuilder", [], null, null, null, View_EventBuilder_0, RenderType_EventBuilder)), i0.ɵdid(1, 49152, null, 0, i9.EventBuilder, [i5.Utils, i10.HttpClient, i2.LoadingController, i11.UIAlertService, i12.ArianeeEventService, i13.ActivatedRoute, i14.CertificateCoreService, i13.Router, i15.Location, i16.EventLoggerService, i17.EconomyService, i18.EventService], null, null)], null, null); }
var EventBuilderNgFactory = i0.ɵccf("page-eventBuilder", i9.EventBuilder, View_EventBuilder_Host_0, {}, {}, []);
export { EventBuilderNgFactory as EventBuilderNgFactory };
