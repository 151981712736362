import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth';
import { map, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../services/auth/auth";
import * as i2 from "@angular/router";
var IsNotAuthGuard = /** @class */ (function () {
    function IsNotAuthGuard(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    IsNotAuthGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        return this.authService.$userState
            .pipe(map(function (d) { return !d.isLoggedIn; }), tap(function (isNotLogged) {
            if (!isNotLogged) {
                _this.router.navigate(['/certificate/list']);
            }
        }));
    };
    IsNotAuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IsNotAuthGuard_Factory() { return new IsNotAuthGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router)); }, token: IsNotAuthGuard, providedIn: "root" });
    return IsNotAuthGuard;
}());
export { IsNotAuthGuard };
