<ion-header>
  <div color="light" class="header">
    <div>
      <ion-menu-button></ion-menu-button>
      <ion-title>{{ 'pages.settings.title' | translate }}</ion-title>
    </div>
  </div>
</ion-header>

<ion-content class="settings text-center" data-e2e="settings_page">


  <ion-list>

    <div [hidden]="owner!=address">
      <ion-item><b>Welcome Arianee Administrator</b></ion-item>

    </div>


    <ion-item (click)="copyToClipboard(address)" class="clickable">Wallet Address : <b>{{address}}</b> <ion-icon class="ion-padding-horizontal" name="copy-outline"></ion-icon></ion-item>
    <table width="100%"><tr><td>
      <ion-card>
        <ion-card-header><b>{{ariaTokenName}}</b>
        </ion-card-header>
        <ion-card-content>
          Balance : <b>{{ariaBalance}} Aria</b>
          <form (ngSubmit)="transferAria()">
            Transfer :
            <input size="5" type="text" [(ngModel)]="aria.quantity" name="quantity" value="">
            Aria TO :
            <input type="text" [(ngModel)]="aria.to" name="to" placeholder="0x2345...">
            <ion-button color="primary" type="submit" [disabled]="!aria.quantity||!aria.to">Transfer</ion-button>

          </form>


          <i>POA : {{balance}}</i><br/>
          <span *ngIf="ariaPrice"> Price : <b>$ {{ariaPrice}}</b> <span *ngIf="lastPriceUpdate"> - <i>last update: {{  lastPriceUpdate | amFromUnix | amTimeAgo }}</i></span> </span>

        </ion-card-content>
      </ion-card>
    </td>
    <td>

      <ion-card>
        <ion-card-header><b>ARIA20</b>
        </ion-card-header>
        <ion-card-content>
          Balance : <b>{{aria20Balance}} Aria20</b>
          <form (ngSubmit)="transferAria20()">
            Transfer :
            <input size="5" type="text" [(ngModel)]="aria20.quantity" name="quantity" value="">
            Aria20 TO :
            <input type="text" [(ngModel)]="aria20.to" name="to" placeholder="0x2345...">
            <ion-button color="primary" type="submit" [disabled]="!aria20.quantity||!aria20.to">Transfer</ion-button>

          </form>

          <i>ETH : {{ethbalance}}</i><br/>
          <br/>
        </ion-card-content>
      </ion-card>

    </td></tr></table>



    <div [hidden]="AriaAllowanceStore">
      <ion-card>
        <ion-card-content>
          To access Arianee Platform, you need to allow Arianee to manage your ARIA Tokens<br />
          <ion-button color="primary" (click)="setApprove()">Request Access</ion-button>
        </ion-card-content>
      </ion-card>

    </div>




    <div>
      <ion-card>
        <ion-card-header><b>Credits</b></ion-card-header>

        <ion-card-content data-e2e="smartAssetCreditForm">
          <form (ngSubmit)="buyCredit('certificate',creditToSet[0])">
            Smart-Assets Credits : <b>{{credit[0]}}</b>
            <span>  (quantity) :
              <input type="number" data-e2e="input" [(ngModel)]="creditToSet[0]" name="creditToSet[0]">
              <ion-button color="primary" type="submit" data-e2e='submit' [disabled]="!creditToSet[0]">Buy</ion-button>
            </span>
          </form>
        </ion-card-content>

        <ion-card-content data-e2e="messageCreditForm">
          <form (ngSubmit)="buyCredit('message',creditToSet[1])">
            Message Credits : <b>{{credit[1]}}</b>
            <span>  (quantity) :
              <input type="number" data-e2e="input" [(ngModel)]="creditToSet[1]" name="creditToSet[1]">
              <ion-button color="primary" data-e2e='submit' type="submit" [disabled]="!creditToSet[1]">Buy</ion-button>
            </span>
          </form>
        </ion-card-content>

        <ion-card-content data-e2e="arianeeEventCreditForm">
          <form (ngSubmit)="buyCredit('event',creditToSet[2])">
            Event Credits : <b>{{credit[2]}}</b>
            <span> (quantity) :
              <input type="number" data-e2e="input" [(ngModel)]="creditToSet[2]" name="creditToSet[2]">
              <ion-button color="primary" data-e2e="submit" type="submit" [disabled]="!creditToSet[2]">Buy</ion-button>
            </span>
          </form>
        </ion-card-content>
        <ion-card-content data-e2e="arianeeUpdateCreditForm">
          <form (ngSubmit)="buyCredit('update',creditToSet[3])">
            Update Credits : <b>{{credit[3]}}</b>
            <span> (quantity) :
              <input type="number" data-e2e="input" [(ngModel)]="creditToSet[3]" name="creditToSet[3]">
              <ion-button color="primary" data-e2e="submit" type="submit" [disabled]="!creditToSet[3]">Buy</ion-button>
            </span>
          </form>
        </ion-card-content>

      </ion-card>

      <ion-card>
        <ion-card-header><b>Reserved Certificate Id ({{reservedTokensBalance}})</b>
          <a class="link" (click)="extractReservedAsCsvFile()"><ion-icon name="download-outline"></ion-icon></a>
        </ion-card-header>
        <ion-card-content>
          <app-reserve-token
                  [onSubmitRange]="reserveRangeOfTokens"
                  [onSubmitNumber]="reserveNRandomTokens"></app-reserve-token>
        </ion-card-content>
      </ion-card>


    </div>




  </ion-list>
</ion-content>
