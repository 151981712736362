/**
 * Arianee Brand Data Hub API
 * List API available in Brand Data Hub
 *
 * OpenAPI spec version: 1.0.0
 * Contact: techteam@arianee.org
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { EventComplete } from '../model/eventComplete';
import { EventCreated } from '../model/eventCreated';
import { TransactionCreated } from '../model/transactionCreated';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class EventService {
    protected basePath = 'https://us-central1-bdhalex-826d5.cloudfunctions.net/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor (protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
      if (basePath) {
        this.basePath = basePath;
      }
      if (configuration) {
        this.configuration = configuration;
        this.basePath = basePath || configuration.basePath || this.basePath;
      }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm (consumes: string[]): boolean {
      const form = 'multipart/form-data';
      for (const consume of consumes) {
        if (form === consume) {
          return true;
        }
      }
      return false;
    }

    /**
     * Accept an event. Operator need to be certificate issuer
     * Accept an arianee event. Operator need to be certificate issuer
     * @param body Arianee Event ID to accept
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public acceptArianeeEvent(body: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<TransactionCreated>;
    public acceptArianeeEvent(body: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TransactionCreated>>;
    public acceptArianeeEvent(body: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TransactionCreated>>;
    public acceptArianeeEvent (body: Array<number>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling acceptArianeeEvent.');
      }

      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
        'application/json'
      ];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.request<TransactionCreated>('post', `${this.basePath}/api/acceptArianeeEvent`,
        {
          body: body,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Create an arianee event and store its content into an Arianee Privacy Gateway RPC server
     * Create an arianee event in blockchain and store content into an Arianee Privacy Gateway RPC server
     * @param body Event content to create and RPC Server.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAndStoreEvent(body: Array<EventComplete>, observe?: 'body', reportProgress?: boolean): Observable<EventCreated>;
    public createAndStoreEvent(body: Array<EventComplete>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EventCreated>>;
    public createAndStoreEvent(body: Array<EventComplete>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EventCreated>>;
    public createAndStoreEvent (body: Array<EventComplete>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling createAndStoreEvent.');
      }

      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
        'application/json'
      ];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.request<EventCreated>('post', `${this.basePath}/api/createAndStoreArianeeEvent`,
        {
          body: body,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Refuse an event. Operator need to be certificate issuer
     * Accept an arianee event. Operator need to be certificate issuer
     * @param body Arianee Event ID to refuse
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public refuseArianeeEvent(body: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<TransactionCreated>;
    public refuseArianeeEvent(body: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TransactionCreated>>;
    public refuseArianeeEvent(body: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TransactionCreated>>;
    public refuseArianeeEvent (body: Array<number>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling refuseArianeeEvent.');
      }

      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
        'application/json'
      ];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.request<TransactionCreated>('post', `${this.basePath}/api/refuseArianeeEvent`,
        {
          body: body,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }
}
