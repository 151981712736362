import { Component } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Utils } from '../../services/utils/utils';
import { AuthService } from '../../services/auth/auth';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { CertificateCoreService } from '../../services/certificate-service/certificate-core.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { take } from 'rxjs/operators';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';
import { CertificateService, EconomyService, WalletService } from '../../apiServices/BDHApi';
import { WalletCoreService } from '../../services/walletCoreService/walletCoreService';
import { MainnetAPIService } from '../../apiServices/mainnetAPIServices';
import BN from 'bn.js';

@Component({
  templateUrl: 'settings.html'
})
export class Settings {
    public address: string = '';
    public balance: number;
    public ethbalance: number;
    public ariaTokenName: any;
    public ariaBalance: any = '0';
    public aria20Balance: any = '0';
    public AriaAllowanceStore: boolean = false;
    public to: string;
    public aria: any;
    public aria20: any;
    public owner: any;
    public credit: any = [];
    public creditToSet: any = [];
    public totalStaked: any = '0';
    public loading: any;
    public subscribe: any = {};
    public ariaPrice: any;
    public lastPriceUpdate: any;
    public WUNIT = 1000000000000000000;

    public reservedTokensBalance = 0;
    constructor (
        public utils: Utils,
        public authService: AuthService,
        public loadingCtrl: LoadingController,
        public uIAlertService: UIAlertService,
        private certificateCoreService:CertificateCoreService,
        private certificateService:CertificateService,
        private _firebaseAuth: AngularFireAuth,
        private eventLogger: EventLoggerService,
        private economyService: EconomyService,
        private walletService: WalletService,
        private walletCoreService: WalletCoreService,
        private mainnetAPIService: MainnetAPIService

    ) {
      this.aria = { quantity: 0, to: '' };
      this.aria20 = { quantity: 0, to: '' };
    }

    ionViewDidEnter () {
      this.eventLogger.logScreen('settings-page');
      this.loadInfo();

      this.economyService.ariaUSDExchange().subscribe((data) => {
        this.ariaPrice = (Math.pow(10, 18) / parseFloat(data) / 100).toFixed(4);
      });

      this.economyService.getPastEvents([
        'NewAriaUSDExchange',
        { fromBlock: 0, toBlock: 'latest' }
      ]).subscribe(async (data:any) => {
        const blockData:any = await this.walletService.getBlock([data[data.length - 1].blockNumber]).toPromise();
        this.lastPriceUpdate = blockData.timestamp;
      });
    }

    copyToClipboard (item) {
      document.addEventListener('copy', (e: ClipboardEvent) => {
        e.clipboardData.setData('text/plain', (item));
        e.preventDefault();
        document.removeEventListener('copy', null);
      });
      document.execCommand('copy');
      this.utils.success('Your address has been successfully copied to your clipboard');
    }

    async loadBalance () {
      const [balanceOfAria, ariaTokenName, balanceOfPoa] = await Promise.all([
        this.economyService.balanceOfAria().toPromise(),
        this.economyService.name().toPromise(),
        this.economyService.balanceOfPoa().toPromise()

      ]);

      this.balance = +balanceOfPoa / this.WUNIT;
      this.ariaBalance = Math.round(parseInt(balanceOfAria) / this.WUNIT * 1000) / 1000;
      this.ariaTokenName = ariaTokenName;

      this.loadStacking();
    }

    async loadETHBalance () {
      const [ethbalance, balanceOfAria20] = await Promise.all([
        this.mainnetAPIService.getBalance().toPromise(),
        this.mainnetAPIService.aria20Balance(this.address).toPromise()

      ]);

      this.ethbalance = +ethbalance / this.WUNIT;
      this.aria20Balance = Math.round(parseInt(balanceOfAria20.message) / this.WUNIT * 1000) / 1000;
    }

    async loadCreditBalance () {
      this.certificateCoreService.getReservedCertificate()
        .pipe(take(1))
        .subscribe(value => {
          this.reservedTokensBalance = value.count;
        });

      this.credit = await Promise.all([
        this.economyService.balanceOfCredit(['certificate']).toPromise().catch(d => 'N/A'),
        this.economyService.balanceOfCredit(['message']).toPromise().catch(d => 'N/A'),
        this.economyService.balanceOfCredit(['event']).toPromise().catch(d => 'N/A'),
        this.economyService.balanceOfCredit(['update']).toPromise().catch(d => 'N/A')

      ]);
    }

    async loadAllowance () {
      const [walletAdress, contractAddress] = await Promise.all([
        this.walletCoreService.getAddress(),
        this.walletCoreService.getContractAddress()

      ]);
      const isAllowed = await this.economyService.allowance([walletAdress, contractAddress.contractAdresses.store]).toPromise();
      if (parseInt(isAllowed, 10) > 0) {
        this.AriaAllowanceStore = true;
      }
    }

    async loadStacking () {
      const stacking = await this.economyService.totalStakedFor([this.address]).toPromise();
      this.totalStaked = Math.round((parseInt(stacking) / this.WUNIT) * 1000) / 1000;
    }

    async loadInfo () {
      const loader = await this.uIAlertService.load();

      this.address = await this.walletCoreService.getAddress();
      try {
        await Promise.all([
          this.loadCreditBalance(),
          this.loadBalance(),
          this.loadETHBalance(),
          this.loadAllowance()
        ]);
      } catch (e) {
        this.uIAlertService.error('Something went wrong fetching your data');
        console.error(e);
      }

      loader.dismiss();
    }

    async setApprove () {
      this.waiting();
      const contractAddress = await this.walletCoreService.getContractAddress();
      await this.economyService.approve([contractAddress.contractAdresses.store, '10000000000000000000000000000'])
        .toPromise();
      this.loading.dismiss();
    }

    async setApproveStaking () {
      this.waiting();
      const contractAddress = await this.walletCoreService.getContractAddress();
      await this.economyService.approve([contractAddress.contractAdresses.staking, '10000000000000000000000000000'])
        .toPromise();

      this.loading.dismiss();
    }

    async transferAria () {
      if (!this.aria.to || !this.aria.quantity) {
        alert('all fields must be filled');
        return;
      }
      this.waiting();
      const userAmount = new BN(this.aria.quantity);
      const multiply = new BN(this.WUNIT.toString());

      const amount = userAmount.mul(multiply).toString();

      await this.economyService.transfer([this.aria.to, amount]).toPromise();

      this.loading.dismiss();
    }

    async transferAria20 () {
      if (!this.aria20.to || !this.aria20.quantity) {
        alert('all fields must be filled');
        return;
      }
      this.waiting();
      const userAmount = new BN(this.aria20.quantity);
      const multiply = new BN(this.WUNIT.toString());

      const amount = userAmount.mul(multiply).toString();

      await this.mainnetAPIService.aria20Transfer(this.aria20.to, amount).toPromise();

      await this.loadETHBalance();

      this.loading.dismiss();
    }

    async stake () {
      this.waiting();

      await this.economyService.stake([(this.aria.quantityStake * 1000000000000000000).toString(), this.address]).toPromise();
      this.aria.quantityStake = 0;

      this.loading.dismiss();
    }

    async buyCredit (type:string, quantity:number) {
      this.waiting();
      await this.economyService.buyCredits([type, quantity]).toPromise();
      await this.uIAlertService.alert({
        cssClass: 'e2e_buy_credit_success',
        message: 'Success buying credits.'
      });
      this.loading.dismiss();
    }

    async waiting () {
      this.loading = await this.loadingCtrl.create({
        spinner: 'dots',
        message: 'waiting'
      });
      this.loading.present();
    }

    /**
     * Check if certificateId is availaible for reservation (no owner)
     * @param {number[]} tokensId
     * @returns {Promise<{availableIds: any[]; notAvailablesIds: any[]}>}
     */
    async checkReserveAvailability (tokensId: number[]) {
      const loading = await this.uIAlertService.load(
        { message: 'Checking Arianee IDs availabilities' });

      const result = await this.certificateCoreService.certificateIdsAvailibility(tokensId, false);

      loading.dismiss();

      if (result.notAvailablesIds.length > 0) {
        this.uIAlertService.alert({
          message: `Impossible to reserve the range you have chosen.
                Some certificateIds are already used. Try again with range with only free certificateIds.
                Already used certificateIds: ${result.notAvailablesIds.join(' | ')}`
        });
      }

      return result;
    }

    public reserveRangeOfTokens = async (rangeOfTokenIds: number[]) => {
      if (rangeOfTokenIds.length > 1000) {
        this.uIAlertService.error('You cannot reserve more than 1000 ids at the same time');
      } else {
        const areAvailaible = await this.checkReserveAvailability(rangeOfTokenIds);
        if (areAvailaible.notAvailablesIds.length === 0) {
          const loader = await this.uIAlertService.load();
          try {
            await this.certificateService.reserveRange({ range: rangeOfTokenIds }).toPromise();
            this.uIAlertService.success('Your reservations are on its way');
          } catch (e) {
            console.error(e);
            this.uIAlertService.error('An error occured. Please check your credit. You cannot reserve more than 1000 ids and you should have enough credits.');
          }
          loader.dismiss();
        }
      }
    };

    public reserveNRandomTokens = async (numberOfCertificateId: number) => {
      const loader = await this.uIAlertService.load({ message: `Reserving ${numberOfCertificateId} certificates` });

      this.certificateService.reserveBatch({ numberOfReservations: numberOfCertificateId }).subscribe((data) => {
        loader.dismiss();
        if (data.code !== 'inProgress') {
          const loading = this.uIAlertService.alert(
            {
              header: 'Reservation fail.',
              message: 'Too many reservation / not enough credits'
            }
            , true);
        } else {
          const loading = this.uIAlertService.alert(
            {
              header: 'Reservation sucess.'
            }
            , true);
        }
      });
    };

    extractReservedAsCsvFile = async () => {
      const loader = await this.uIAlertService.load();
      try {
        await this.certificateCoreService.getReportReservedCertificatesInCsv();
        loader.dismiss();
      } catch (e) {
        console.error(e);
        loader.dismiss();
        this.uIAlertService.error('an error occured. Please contact us');
      }
    };
}
