import { Injectable } from '@angular/core';
import { WalletService } from '../../apiServices/BDHApi';
import { config } from '../../../config';
import { ContractAdress } from '../../models/contractsAddress';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class WalletCoreService {
    private address$;
    constructor (private walletService: WalletService, private httpClient: HttpClient) {
    }

    public getContractAddress = async (): Promise<ContractAdress> => {
      const url = config.network === 'mainnet'
        ? 'https://cert.arianee.org/contractAddresses/newpoacore.json'
        : 'https://cert.arianee.org/contractAddresses/newsokol.json';

      return this.httpClient.get<ContractAdress>(url).toPromise();
    };

    public getAddress=async ():Promise<string> => {
      if (this.address$ === undefined) {
        this.address$ = this.walletService.getWalletAddress()
          .toPromise()
          .then(d => d.address);
      }
      return this.address$;
    }
}
