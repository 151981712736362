import { Component } from '@angular/core';
import { LoadingController, NavController } from '@ionic/angular';
import { Utils } from '../../services/utils/utils';

import { AuthService } from '../../services/auth/auth';
import { SchemaService } from '../../services/schema-service/schema-service';
import { Observable, Subscription, timer, Subject } from 'rxjs';
import { distinctUntilChanged, distinctUntilKeyChanged, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { config } from '../../../config';
import { Router } from '@angular/router';
import { PaperCertService } from '../../services/paper-cert-service/paper-cert.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';
import { TransactionService } from '../../services/transaction-service/transaction-service';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { DecodeAbiService } from '../../services/decodeAbiService/decodeAbiService';
import { TransactionStatus } from '../../models/transactionStatus.enum';

import {
  distinctUntilChangedArrayOfObjectChanged,
  fakeWebSocketObservable
} from '../../helpers/fakeWebSocketObservable/fakeWebSocketObservable';
import { TransactionDB } from '../../models/transaction.model';
import { UsersService } from '../../services/users-service/userService';

/*
*
*  Transaction Page
*
*/
@Component({
  selector: 'page-transaction',
  styleUrls: ['./transaction.scss'],
  templateUrl: 'transaction.html'
})
export class Transaction {
    public filterStatus: string = undefined;
    public items: any;
    public stillOwner = false;
    public read = 'na';
    public filter = false;
    public loading: any;
    public message: any = {};
    public subscribe: any = {};
    public tags: string;
    public legacy: boolean;
    public destroyed: boolean;

    public $transactionSub: Subscription;
    public users: any = {};
    public blockscoutBaseUrl:string = '';

    constructor (
        public navCtrl: NavController,
        public utils: Utils,
        public loadingCtrl: LoadingController,
        public authService: AuthService,
        private schemaService: SchemaService,
        private router:Router,
        private paperCertService:PaperCertService,
        private http: HttpClient,
        private _firebaseAuth: AngularFireAuth,
        private transactionService: TransactionService,
        private eventLogger: EventLoggerService,
        private uiAlertService: UIAlertService,
        private decodeAbiSerivce: DecodeAbiService,
        private usersService: UsersService

    ) {
      if (config.network === 'testnet') {
        this.blockscoutBaseUrl = 'https://blockscout.com/poa/sokol/tx/';
      } else {
        this.blockscoutBaseUrl = 'https://blockscout.com/poa/core/tx/';
      }
    }

    public transactionStatus = TransactionStatus;

    public displayFilter = (filterName: TransactionStatus) => {
      this.loading.present();
      if (this.filterStatus !== filterName) {
        this.filterStatus = filterName;
        if (this.subscribe.transaction) {
          this.subscribe.transaction.unsubscribe();
        }
        this.watchTransactionByStatus(filterName);
      } else {
        this.loading.dismiss();
      }
    };

    ionViewWillLeave () {
      this.$transactionSub.unsubscribe();
    }

    async ionViewDidEnter () {
      this.loading = await this.loadingCtrl.create({
        spinner: 'dots',
        message: 'Loading transactions'
      });
      this.loading.present();
      this.eventLogger.logScreen('transaction-page');
      await this.decodeAbiSerivce.initDecodeAbi();

      this.authService.$userState
        .subscribe((state) => {
          if (state.isLoggedIn) {
            this.stillOwner = false;
            this.load();
          } else {
            this.navCtrl.navigateForward('login', { queryParams: { returnView: 'transaction' } });
          }
        });

      this.displayFilter(TransactionStatus.minted);
    }

    private watchTransactionByStatus = (status: TransactionStatus) => {
      if (this.$transactionSub) {
        this.$transactionSub.unsubscribe();
      }

      const $transactions = fakeWebSocketObservable(this.transactionService
        .getTransactionByStatus(status, 100));

      this.$transactionSub = $transactions
        .pipe(
          distinctUntilChangedArrayOfObjectChanged('transactionHash'),
          this.mapTransaction(),
          this.tapSetTransaction()
        )
        .subscribe(d => {
        });
    };

    checkLoading (that) {
      if (!that.arianeeProtocol.firstLoading) {
        setTimeout(that.checkLoading, 1000, that);
      } else {
        that.load();
        that.loading.dismiss();
      }
    }

    async load () {
      const that = this;

      this.usersService.all().subscribe((data) => {
        let user: any;
        for (user of data) {
          that.users[user.userId] = user.email;
        }
      });
    }

    tapSetTransaction = () => {
      return tap(data => {
        this.items = data;
        this.loading.dismiss();
      });
    };

    mapTransaction = () => {
      return map((data: any) => {
        return data
          .map(data => {
            const call = this.decodeAbiSerivce.decodeAbi(data.to, data.data);
            if (call) {
              const methodName = '<b>' + call.method + '</b>';

              const methodParameters = call.names.map((parameterName, index) => {
                let value;
                if (call.types[index] === 'bytes32') {
                  // @ts-ignore
                  const bytes32 = Buffer.from(call.inputs[index]);
                  value = `0x${bytes32.toString('hex')}`;
                } else if (call.types[index] === 'address') {
                  value = `0x${call.inputs[index]}`;
                } else {
                  value = call.inputs[index];
                }

                return `${parameterName}=\`${value}\``;
              }).join(', ');

              data.call = `${methodName}(${methodParameters})`;
              data.contractName = call.contractName;
            } else {
              data.contractName = 'Unknown contract';
              data.call = data.data;
            }

            return data;
          });
      });
    };

    async resetTransaction (itemId) {
      this.loading = await this.loadingCtrl.create({
        spinner: 'dots',
        message: 'Re creating a new transaction'
      });
      this.loading.present();

      this.transactionService.resetTransaction(itemId)
        .subscribe(d => {
          this.loading.dismiss();
        },
        err => {
          this.loading.dismiss();
          this.uiAlertService.error('An error occured');
        });
    }

    async resendTransaction (itemId) {
      this.loading = await this.loadingCtrl.create({
        spinner: 'dots',
        message: 'Resending transaction'
      });
      this.loading.present();

      this.transactionService.resendTransaction(itemId)
        .subscribe(d => {
          this.loading.dismiss();
        },
        err => {
          this.loading.dismiss();
          this.uiAlertService.error('An error occured');
        });
    }
}
