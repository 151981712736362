import * as tslib_1 from "tslib";
import { StringWidget } from 'ngx-schema-form';
import { ModalController } from '@ionic/angular';
import { UploadAssets } from '../../pages/uploadAssets/uploadAssets';
var StaticAssetsWidget = /** @class */ (function (_super) {
    tslib_1.__extends(StaticAssetsWidget, _super);
    function StaticAssetsWidget(modalCtrl) {
        var _this = _super.call(this) || this;
        _this.modalCtrl = modalCtrl;
        return _this;
    }
    StaticAssetsWidget.prototype.presentUploadAssetsModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var uploadAssetsModal, result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalCtrl.create({
                            component: UploadAssets,
                            componentProps: {
                                isModal: true
                            }
                        })];
                    case 1:
                        uploadAssetsModal = _a.sent();
                        return [4 /*yield*/, uploadAssetsModal.present()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, uploadAssetsModal.onWillDismiss()];
                    case 3:
                        result = _a.sent();
                        if (result) {
                            this.formProperty.setValue(result.data, false);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return StaticAssetsWidget;
}(StringWidget));
export { StaticAssetsWidget };
