
<ion-content text-center class="home">
    <div class="loginBlock" (keyup.enter)="signInWithEmail()">

        <ion-item>
            <ion-label position="floating" for="exampleInputEmail1" >Email</ion-label>
            <ion-input data-e2e="login-email" type="email" class="form-control" [(ngModel)]="user.email" placeholder="Email" required></ion-input>
        </ion-item>

        <ion-item>
            <ion-label position="floating"  for="exampleInputPassword1" >Password</ion-label>
            <ion-input data-e2e="login-password" type="password" class="form-control" [(ngModel)]="user.password" placeholder="Password" required></ion-input>
        </ion-item>

        <br/>
        <ion-button data-e2e="login-submit" color="primary" class="btn btn-primary btn-block" style="margin-bottom: 20px" (click)="signInWithEmail()" >
            Login with Email
        </ion-button>

        <a (click)="showForgetPassword()"  color="custom">Forgot password ?</a>
        <br>
    </div>

</ion-content>
