import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { WalletCoreService } from '../walletCoreService/walletCoreService';
import InputDataDecoder from '../../helpers/decodeAbi/decodeAbi';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../walletCoreService/walletCoreService";
var DecodeAbiService = /** @class */ (function () {
    function DecodeAbiService(httpClient, walletCoreService) {
        var _this = this;
        this.httpClient = httpClient;
        this.walletCoreService = walletCoreService;
        this.mapperName = {
            aria: 'Aria.json',
            creditHistory: 'ArianeeCreditHistory.json',
            eventArianee: 'ArianeeEvent.json',
            identity: 'ArianeeIdentity.json',
            smartAsset: 'ArianeeSmartAsset.json',
            staking: 'ArianeeStaking.json',
            store: 'ArianeeStore.json',
            whitelist: 'ArianeeWhitelist.json',
            message: 'ArianeeMessage.json',
            lost: 'ArianeeLost.json',
            userAction: 'ArianeeUserAction.json'
        };
        this.baseURL = function (abiName) {
            var contractName = _this.mapperName[abiName];
            return "https://unpkg.com/@arianee/arianee-abi@latest/abi/" + contractName;
        };
    }
    DecodeAbiService.prototype.initDecodeAbi = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var address;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.abiList = {};
                        return [4 /*yield*/, this.walletCoreService.getContractAddress()];
                    case 1:
                        address = _a.sent();
                        return [4 /*yield*/, Promise.all(Object.keys(address.contractAdresses)
                                .filter(function (d) { return _this.mapperName[d] !== undefined; })
                                .map(function (contractAddress) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var addr, abiURL, abi;
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            addr = address.contractAdresses[contractAddress];
                                            abiURL = this.baseURL(contractAddress);
                                            return [4 /*yield*/, this.httpClient.get(abiURL).toPromise().catch()];
                                        case 1:
                                            abi = _a.sent();
                                            this.abiList[addr.toLowerCase()] = {
                                                name: contractAddress,
                                                address: addr,
                                                abi: abi
                                            };
                                            return [2 /*return*/];
                                    }
                                });
                            }); }))];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DecodeAbiService.prototype.decodeAbi = function (contractAddress, data) {
        if (!this.abiList) {
            throw new Error('please invoke initDecodeAbi first');
        }
        if (this.abiList[contractAddress.toLowerCase()]) {
            var decoder = new InputDataDecoder(this.abiList[contractAddress.toLowerCase()].abi);
            var result = decoder.decodeData(data);
            result.contractName = this.abiList[contractAddress.toLowerCase()].name;
            return result;
        }
        else {
        }
    };
    DecodeAbiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DecodeAbiService_Factory() { return new DecodeAbiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.WalletCoreService)); }, token: DecodeAbiService, providedIn: "root" });
    return DecodeAbiService;
}());
export { DecodeAbiService };
