<div class="contents"
     data-e2e="wallet-component"
     style="height:100%" *ngIf="certificateList && certificateList.length>0; else noCertificate">
    <ion-virtual-scroll no-lines style="border-bottom:1px solid #eee" [items]="certificateList"
                        approxItemHeight='100px'>

        <ion-item-sliding *virtualItem="let item; let index = index"  class="clickable" >
            <ion-item (click)="onInternalItemClick(item.id)" [attr.data-e2e]="'wallet-item-'+index">

                        <div *ngIf="item.legacy" class="legacy">Legacy certificate</div>
                        <ion-thumbnail item-start>
                        <div class="thumbnail">
                            <img src="{{ getImage(item) }}">
                        </div>
                        </ion-thumbnail>
                            <div class="cert-content">
                                <ion-badge *ngIf="item.class=='waiting'" color="secondary" >In progress</ion-badge>
                                <div class="cert-content--item">
                                    <span class="cert-content--title">{{ item.name }} &bull; {{ item.model }} </span>

                                </div>

                                <div class="cert-content--item">
                                    <ion-badge [attr.data-e2e]="'arianeeid-'+item.id" color="primary">Arianee Id
                                        : {{ item.id }}</ion-badge>
                                    <ion-badge color="dark" *ngIf="item.events.length>0">
                                        <span *ngIf="!item.destroyed">{{item.events.length - 1}}</span>
                                        <span *ngIf="item.destroyed">{{item.events.length - 2}}</span>
                                        <span> transfer</span> <span *ngIf="(item.events.length-1)>1">s</span>
                                    </ion-badge>
                                    <ion-badge *ngIf="item.isOwner" color="success">Still owner</ion-badge>
                                    <ion-badge *ngIf="!item.whitelist" color="warning">Owner opt-out</ion-badge>
                                    <ion-badge *ngIf="item.destroyed" color="danger">DESTROYED</ion-badge>
                                    <ion-badge *ngIf="item.isLostOrStolen" color="danger">Lost/Stolen</ion-badge>
                                    <span *ngFor="let tag of item.tags">
                                        <ion-badge color="light">{{tag}}</ion-badge>
                                    </span>                                    
                                </div>
                                <div *ngIf="isArray(item.serialnumber)" class="cert-content--item">

                                    <ion-badge color="medium" *ngFor="let serialnumber of item.serialnumber">
                                        <span *ngIf="serialnumber.type=='serialnumber'">Serial Number : {{ serialnumber.value }}</span>
                                        <span *ngIf="serialnumber.type=='casenumber'">Case Number : {{ serialnumber.value }}</span>
                                        <span *ngIf="serialnumber.type=='movementnumber'">Movement Number : {{ serialnumber.value }}</span>
                                    </ion-badge>
                                </div>


                                <div class="cert-content--item">
                                    <span *ngIf="item.userId">Created  {{item.createdAt | amTimeAgo }}
                                        by {{getUser([item.userId]) || 'unknown'}}</span>
                                </div>
                            </div>
                </ion-item>
            </ion-item-sliding>


        </ion-virtual-scroll>

    </div>
<ng-template #noCertificate>
    <div class="content--no-certificate">
        <h2>No certificate to display here yet.</h2>
    </div>
</ng-template>

