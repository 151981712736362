export const widgetizeI18n = {
  properties: {
    $schema: {
      widget: 'hidden'
    },
    name: {},
    sku: {},
    gtin: {},
    brandInternalId: {},
    category: {
      widget: 'select'
    },
    subCategory: {
      widget: 'select'
    },
    intended: {
      widget: 'select'
    },
    serialnumber: {
      items: {
        properties: {
          type: {
            widget: 'select'
          },
          value: {
            widget: 'string'
          }
        }
      }
    },
    subBrand: {},
    model: {},
    language: {
      widget: 'select'
    },
    description: {
      widget: 'textarea'
    },
    subDescription: {
      items: {
        type: 'object',
        properties: {
          type: {
            widget: 'select'
          },
          title: {
            widget: 'string'
          },
          content: {
            widget: 'textarea'
          },
          order: {
            widget: 'hidden'
          }
        }
      }
    },
    externalContents: {
      items: {
        properties: {
          type: {
            widget: 'select'
          },
          title: {
            widget: 'string'
          },
          url: {
            widget: 'staticAssets'
          },
          order: {
            widget: 'number'
          }
        }
      }
    },
    i18n: {
      items: {
        type: 'object',
        properties: {
          language: {
            widget: 'select'
          },
          description: {
            widget: 'textarea'
          },
          subDescription: {
            items: {
              properties: {
                type: {
                  widget: 'select'
                },
                title: {
                  widget: 'string'
                },
                content: {
                  widget: 'textarea'
                },
                order: {
                  widget: 'hidden'
                }
              }
            }
          },
          externalContents: {
            items: {
              properties: {
                type: {
                  widget: 'select'
                },
                title: {
                  widget: 'string'
                },
                url: {
                  widget: 'staticAssets'
                },
                order: {
                  widget: 'number'
                }
              }
            }
          }
        }
      }
    },
    msrp: {
      items: {
        properties: {
          msrp: {
          },
          currency: {
            widget: 'select'
          },
          msrpCountry: {
          }
        }
      }
    },

    medias: {
      items: {
        properties: {
          mediaType: {
            widget: 'select'
          },
          type: {
            widget: 'select'
          },
          url: {
            widget: 'staticAssets'
          },
          hash: {
            widget: 'hidden'
          },
          order: {
            widget: 'number'
          }
        }
      }
    },
    attributes: {
      items: {
        properties: {
          type: {
            widget: 'select'
          },
          value: {
            widget: 'string'
          }
        }
      }
    },
    materials: {
      items: {
        properties: {
          material: {
            widget: 'select'
          },
          value: {
            widget: 'hidden'
          },
          pourcentage: {
            widget: 'string'
          }
        }
      }
    },
    size: {
      items: {
        properties: {
          type: {
            widget: 'select'
          },
          value: {
            widget: 'string'
          },
          unit: {
            widget: 'select'
          }
        }
      }
    },
    manufacturingCountry: {},
    facilityId: {},
    productCertification: {
      items: {
        type: 'object',
        properties: {
          name: {
            widget: 'select'
          }
        }
      }
    }
  },
  required: [
    '$schema'
  ]
};
