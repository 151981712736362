import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth';
import { map, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../services/auth/auth";
import * as i2 from "@angular/router";
var IsAuthGuard = /** @class */ (function () {
    function IsAuthGuard(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    IsAuthGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        return this.authService.$userState
            .pipe(map(function (d) { return d.isLoggedIn; }), tap(function (isLoggedIn) {
            if (!isLoggedIn) {
                _this.router.navigate(['/login'], {
                    queryParams: {
                        returnView: state.url
                    }
                });
            }
        }));
    };
    IsAuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IsAuthGuard_Factory() { return new IsAuthGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router)); }, token: IsAuthGuard, providedIn: "root" });
    return IsAuthGuard;
}());
export { IsAuthGuard };
