import * as tslib_1 from "tslib";
import { OnChanges, SimpleChanges } from '@angular/core';
import { SCHEMA_NAME, SchemaService } from '../../services/schema-service/schema-service';
import { CertificateService, IdentityService } from '../../apiServices/BDHApi';
import { WalletCoreService } from '../../services/walletCoreService/walletCoreService';
var CertificateViewer = /** @class */ (function () {
    function CertificateViewer(schemaService, certificateService, identityService, walletCoreService) {
        this.schemaService = schemaService;
        this.certificateService = certificateService;
        this.identityService = identityService;
        this.walletCoreService = walletCoreService;
        this.truncating = false;
        this.seeMore = false;
    }
    CertificateViewer.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.item.previousValue !== changes.item.currentValue) {
            if (this.timer) {
                clearTimeout(this.timer);
            }
            this.timer = setTimeout(function () { _this.setCertV2Media(); }, 1000);
        }
    };
    Object.defineProperty(CertificateViewer.prototype, "EnumSchemaName", {
        get: function () {
            return SCHEMA_NAME;
        },
        enumerable: true,
        configurable: true
    });
    CertificateViewer.prototype.setCertV2Media = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var identity, issuer, address, data, medias;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.schemaVersion = this.schemaService.getSchemaItemFromURL(this.item.$schema).name;
                        this.displayedItem = tslib_1.__assign({}, this.item);
                        if (!(this.item && this.schemaVersion === SCHEMA_NAME.i18N)) return [3 /*break*/, 6];
                        identity = void 0;
                        if (!this.itemId) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.certificateService
                                .getCertificate([this.itemId, undefined, { issuer: true }])
                                .toPromise()];
                    case 1:
                        issuer = (_a.sent()).issuer;
                        identity = issuer.identity.data;
                        return [3 /*break*/, 5];
                    case 2: return [4 /*yield*/, this.walletCoreService.getAddress()];
                    case 3:
                        address = _a.sent();
                        return [4 /*yield*/, this.identityService.getIdentity([address]).toPromise()];
                    case 4:
                        data = (_a.sent()).data;
                        identity = data;
                        _a.label = 5;
                    case 5:
                        this.displayedItem.itemBackgroundPicture = certificateImageLogo(this.item, ['brandItemBackgroundPicture']) || brandImageLogo(identity, ['brandItemBackgroundPicture']);
                        this.displayedItem.brandLogoHeaderReversed = brandImageLogo(identity, ['brandLogoHeaderReversed', 'brandLogoHeader']);
                        this.displayedItem = this.handleMedias(this.displayedItem);
                        medias = this.item.medias || [];
                        this.displayedItem.pictures = medias
                            .filter(function (media) { return media.mediaType === 'picture' && media.type === 'product'; })
                            .map(function (media) { return media.url; })
                            .filter(function (i) { return i !== undefined; });
                        this.displayedItem.otherMedias = medias
                            .filter(function (media) { return media.mediaType !== 'picture'; })
                            .map(function (media) { return media; })
                            .filter(function (i) { return i !== undefined; });
                        if (this.item.description && this.item.description.length > 100) {
                            this.seeMore = true;
                        }
                        this.language = this.detectLanguage();
                        this.selectMainPicture(0);
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    CertificateViewer.prototype.detectLanguage = function () {
        var language = [];
        this.selectedLanguage = { lang: this.item.language, index: null };
        language.push(this.selectedLanguage);
        if (this.item.i18n) {
            this.item.i18n.forEach(function (lang, index) {
                if (lang.language) {
                    language.push({ lang: lang.language, index: index });
                }
            });
        }
        return language;
    };
    CertificateViewer.prototype.changeLanguage = function (event) {
        if (this.selectedLanguage.index === null) {
            this.displayedItem.description = this.item.description;
        }
        else {
            this.displayedItem.description = this.item.i18n[this.selectedLanguage.index].description;
        }
    };
    CertificateViewer.prototype.isArray = function (value) {
        return Array.isArray(value);
    };
    CertificateViewer.prototype.selectMainPicture = function (index) {
        this.embedURL = null;
        this.hightlightPicture = this.displayedItem.pictures[index];
        this.hightlightMediaIndex = null;
        this.hightlightPictureIndex = index;
    };
    CertificateViewer.prototype.selectMedia = function (index) {
        this.hightlightPicture = null;
        this.embedURL = this.displayedItem.otherMedias[index].embedURL;
        this.hightlightPictureIndex = null;
        this.hightlightMediaIndex = index;
    };
    CertificateViewer.prototype.toggleSeeMore = function () {
        this.seeMore = !this.seeMore;
    };
    CertificateViewer.prototype.handleMedias = function (certificateDisplay) {
        if (certificateDisplay.medias) {
            certificateDisplay.medias.map(function (media) {
                var returnedMedia;
                switch (media.mediaType) {
                    case 'youtube':
                        returnedMedia = getYoutubeMedia(media);
                        break;
                    default:
                        returnedMedia = media;
                        break;
                }
                return returnedMedia;
            });
        }
        return certificateDisplay;
    };
    return CertificateViewer;
}());
export { CertificateViewer };
export var getYoutubeMedia = function (media) {
    if (media.url) {
        media.ytId = extractYoutubeId(media.url);
        media.thumbnail = getYoutubeThumbnail(media.ytId);
        media.embedURL = getYoutubeEmbedUrl(media.ytId);
    }
    return media;
};
export var extractYoutubeId = function (url) {
    return url.match(/(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/user\/\S+|\/ytscreeningroom\?v=|\/sandalsResorts#\w\/\w\/.*\/))([^/&]{10,12})/)[1];
};
export var getYoutubeThumbnail = function (ytId) {
    return "https://img.youtube.com/vi/" + ytId + "/0.jpg";
};
export var getYoutubeEmbedUrl = function (ytId) {
    return "https://www.youtube.com/embed/" + ytId;
};
export var brandImageLogo = function (identity, picturesOrder) {
    var pictures = identity.pictures;
    var isPicture = function (pictureName) {
        var picture = pictures
            .find(function (pic) { return pic.type === pictureName; });
        return picture !== undefined;
    };
    var pictureUrl = function (pictureName) {
        return pictures
            .find(function (pic) { return pic.type === pictureName; }).url;
    };
    var findPicture = function (picturesNames) {
        for (var _i = 0, picturesNames_1 = picturesNames; _i < picturesNames_1.length; _i++) {
            var pictureName = picturesNames_1[_i];
            if (isPicture(pictureName)) {
                return pictureUrl(pictureName);
            }
        }
        return '';
    };
    if (pictures) {
        return findPicture(picturesOrder);
    }
    return '';
};
export var certificateImageLogo = function (certificate, picturesOrder) {
    var medias = certificate.medias;
    var isPicture = function (pictureName) {
        var picture = medias
            .find(function (pic) { return pic.type === pictureName; });
        return picture !== undefined;
    };
    var pictureUrl = function (pictureName) {
        return medias
            .find(function (pic) { return pic.type === pictureName; }).url;
    };
    var findPicture = function (picturesNames) {
        for (var _i = 0, picturesNames_2 = picturesNames; _i < picturesNames_2.length; _i++) {
            var pictureName = picturesNames_2[_i];
            if (isPicture(pictureName)) {
                return pictureUrl(pictureName);
            }
        }
        return undefined;
    };
    if (medias) {
        return findPicture(picturesOrder);
    }
    return undefined;
};
