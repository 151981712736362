import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { SchemaService } from '../../services/schema-service/schema-service';
import { Utils } from '../../services/utils/utils';
import { ArianeejsAPIService } from '../../apiServices/arianeejsAPIService';
import { cleanObject } from '../../helpers/cleanObject/cleanObject';

interface IButtonLabels {
    create?: string;
    copy?: string;
    paste?: string;
}

interface IButtonShow {
    create?: boolean;
    copy?: boolean;
    paste?: boolean;
}
@Component({
  selector: 'schema-builder',
  styleUrls: ['./schema-builder.component.scss'],
  templateUrl: 'schema-builder.component.html'
})
export class SchemaBuilderComponent {
    private _documentModel: any;
    @Input('showButtons') showButtons:IButtonShow = {
      create: true,
      copy: true,
      paste: true
    };

    @Output('onSubmit') onSubmitEmitter = new EventEmitter();
    @Output('onChange') onChangeEmitter = new EventEmitter();

    @Input('assetType') assetType: string;

    get documentModel () {
      return this._documentModel;
    }

    @Input('documentModel') set documentModel (value) {
      this._documentModel = value;
      this.prepare();
    };

    private _buttonLabelsDefault: IButtonLabels = {
      create: 'Create in blockchain',
      copy: 'copy',
      paste: 'paste'
    };

    constructor (private utils: Utils,
                private _renderer: Renderer2,
                private schemaService: SchemaService,
                private elem: ElementRef,
                private arianeeJsServerService: ArianeejsAPIService
    ) {

    }

    get buttonLabels (): IButtonLabels {
      return this._buttonLabelsDefault;
    }

    public selectedSchemas;
    public schemasDetails: any[];
    public creditAsset: number;
    public schemasSelectList: { title: string; id: string }[];

    @Input('buttonLabels') set buttonLabels (value: IButtonLabels) {
      this._buttonLabelsDefault = {
        ...this._buttonLabelsDefault,
        ...value
      };
    };

    public _document: any;

    get document () {
      return this._document;
    }

    set document (value) {
      this._document = cleanObject(value);
    }

    public _schemaUrls: string[];

    get schemaUrls (): string[] {
      return this._schemaUrls;
    }

    @Input('schemaUrls') set schemaUrls (value: string[]) {
      this._schemaUrls = value;
      this.prepare();
    };

    public creditCheck = async () => {
      if (this.assetType) {
        this.creditAsset = await this.arianeeJsServerService.balanceOfCredit(this.assetType).toPromise();
      }
    };

    async prepare () {
      this.creditCheck();

      if (this._schemaUrls) {
        this.schemasDetails = await Promise.all(
          this._schemaUrls.map(url => this.schemaService.overrideSchemaWidget(url)));

        this.schemasSelectList = this.schemasDetails.map(schema => ({
          title: schema.title,
          id: schema.$schema
        }));

        if (this.documentModel && this.documentModel.$schema) {
          const schema = await this.schemaService.overrideSchemaWidget(this.documentModel.$schema);
          this.selectedSchemas = schema;
        } else {
          this.selectedSchemas = this.schemasDetails[0];
        }
      }
    }

    public schemaSelectChange ($event: string) {
      this._documentModel = undefined;
      this.selectedSchemas = this.schemasDetails.find(schema =>
        schema.$schema === $event
      );
    }

    public isOneOfList () {
      if (this.document) {
        const { $schema } = this.document;
        const isOneOfList = this.matchingSchema($schema);
        return isOneOfList !== undefined;
      } else {
        return true;
      }
    }

    public onSubmit () {
      if (this.document) {
        this.onSubmitEmitter.emit(this.document);
      }
    }

    public copy () {
      const item = JSON.stringify(this.document);
      function copyToClipboard (e: ClipboardEvent) {
        e.clipboardData.setData('text/plain', (item));
        e.preventDefault();
        document.removeEventListener('copy', copyToClipboard);
      }
      document.addEventListener('copy', copyToClipboard);
      document.execCommand('copy');
      this.utils.success('Content copied to your clipboard');
    }

    async paste () {
      const text = await navigator.clipboard.readText();
      try {
        const parsedDoc = JSON.parse(text);

        const matchingSchema = this.schemasDetails.find(schema =>
          schema.$schema === parsedDoc.$schema
        );

        if (!matchingSchema) {
          this.utils.alert('The content you are trying to paste is not from a schema in the list');
        } else {
          this.documentModel = parsedDoc;
          this.selectedSchemas = matchingSchema;
          this.utils.success('Content pasted sucessfully');
        }
      } catch (e) {
        this.utils.alert('The content you are trying to paste is not json schema.');
      }
    }

    private matchingSchema = ($schema) => this.schemasDetails.find(schema =>
      schema.$schema === $schema
    );

    onChange (event) {
      this.document = event.value;

      if (this.document) {
        this.onChangeEmitter.emit(this.document);
      }
    }

    replaceInformationForm () {
      const elements = this.elem.nativeElement.querySelectorAll('.formHelp');

      for (let i = 0; i < elements.length; ++i) {
        const elem = elements[i];
        const isExist = elem.querySelector('.description-container');

        if (isExist === null) {
          const originalContent = elem.textContent.replace(/^\n|\n$/g, '').replace(/\n/g, '<br />');

          this._renderer.setProperty(elem, 'innerHTML', `
                    <div class="description-container">
          <div class="description-button"><img src="/assets/img/info.svg" />
          </div>
          <div class="description-content" >${originalContent}</div>
      </div>`);
        }
      }
    }

    ngAfterViewChecked () {
      this.replaceInformationForm();
    }
}
