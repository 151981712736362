import { Injectable } from '@angular/core';
import { widgetizeI18n } from './widget-i18n';
import { merge, mergeWith, isArray } from 'lodash';
import { HttpClient } from '@angular/common/http';
import schemas from '../../../environments/schemas';
import { widgetMessagei18n } from './widget-message-i18n';
import { widgetEventi18n } from './widget-event-i18n';
import { widgetizeI18nV2 } from './widget-i18nV2';
import { widgetizeI18nV3 } from './widget-i18nV3';
import { widgetizeI18nV4 } from './widget-i18nV4';

export interface SchemaItem {
    title: string,
    url: string,
    name?:string,
    version: number,
    widget?: any
}

export enum SCHEMA_NAME {
    i18N = 'i18N',
    arianeeAsset='arianeeAsset'

}

@Injectable({ providedIn: 'root' })
export class SchemaService {
    private widgetOverride = {
      'https://cert.arianee.org/version1/ArianeeMessage-i18n.json': widgetMessagei18n,
      'https://cert.arianee.org/version1/ArianeeProductCertificate-i18n.json': widgetizeI18n,
      'https://cert.arianee.org/version2/ArianeeProductCertificate-i18n.json': widgetizeI18nV2,
      'https://cert.arianee.org/version3/ArianeeProductCertificate-i18n.json': widgetizeI18nV3,
      'https://cert.arianee.org/version4/ArianeeProductCertificate-i18n.json': widgetizeI18nV4,
      'https://cert.arianee.org/version1/ArianeeEvent-i18n.json': widgetEventi18n
    };

    constructor (private httpClient: HttpClient) {
    }

    /**
     * Get all schemas url available
     */
    public static get schemasURL () {
      return schemas;
    }

    /**
     * Instance version of schemasURL static getter
     */
    public get schemasURL () {
      return SchemaService.schemasURL;
    }

    private _schemaList: SchemaItem[] = [
      {
        title: 'ArianeeAsset (default)',
        name: SCHEMA_NAME.arianeeAsset,
        url: 'https://cert.arianee.org/version1/ArianeeAsset.json',
        version: 1
      },
      {
        title: 'Arianee Product (multilanguage)',
        url: 'https://cert.arianee.org/version1/ArianeeProductCertificate-i18n.json',
        name: SCHEMA_NAME.i18N,
        version: 2,
        widget: widgetizeI18n
      },
      {
        title: 'Arianee Product (multilanguage)',
        url: 'https://cert.arianee.org/version2/ArianeeProductCertificate-i18n.json',
        version: 3,
        name: SCHEMA_NAME.i18N,
        widget: widgetizeI18nV2
      },
      {
        title: 'Arianee Product (multilanguage)',
        url: 'https://cert.arianee.org/version3/ArianeeProductCertificate-i18n.json',
        version: 3,
        name: SCHEMA_NAME.i18N,
        widget: widgetizeI18nV3
      },
      {
        title: 'Arianee Product (multilanguage)',
        url: 'https://cert.arianee.org/version4/ArianeeProductCertificate-i18n.json',
        version: 3,
        name: SCHEMA_NAME.i18N,
        widget: widgetizeI18nV4
      }
    ];

    public get schemaList (): SchemaItem[] {
      return this._schemaList;
    }

    public async get$Schema (version: number): Promise<any> {
      const schemaItem = this.getSchemaItemFromVersionNumber(version);
      const schema = await this.httpClient.get(schemaItem.url).toPromise();
      return schema;
    }

    /**
     * Pass $schema (url) and return schema with hidden widget
     * @param {string} $schema
     */
    public async overrideSchemaWidget ($schemaURL: string) {
      const schema = await this.httpClient.get<any>($schemaURL).toPromise();
      const { $schema } = schema;

      if (this.widgetOverride[$schema]) {
        const d = mergeWith(schema, this.widgetOverride[$schema],
          (objValue, srcValue) => {
            if (isArray(objValue)) {
              return objValue.concat(srcValue);
            }
          });

        return d;
      } else {
        return schema;
      }
    }

    public async getCertificateWithWidget (version: number) {
      var schema = await this.get$Schema(version);
      const schemaItem = this.getSchemaItemFromVersionNumber(version);

      schema = merge(schema, schemaItem.widget);

      return schema;
    }

    public getSchemaItemFromURL (url: string): SchemaItem {
      return this._schemaList.find(item => item.url === url);
    }

    public getSchemaItemFromVersionNumber (versionNumber: number): SchemaItem {
      return this._schemaList.find(item => item.version === versionNumber);
    }
}
