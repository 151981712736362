import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserModel } from '../../models/User.model';
import { FirebaseHttpClient } from '../firebaseHttpClient/firebaseHttpClient';
import { EndpointsService } from '../../apiServices/endpointsService/endpointsService';
import { mongooseQueryFactory } from '../../helpers/mongoose-query-factory';

@Injectable({ providedIn: 'root' })
export class AssetsService {
  constructor (
    private firebaseHttpClient: FirebaseHttpClient,
    private endpointService: EndpointsService
  ) {
  }

  public all = (query?: any, sort?: any, limit = 20): Observable<UserModel[]> => {
    const options = mongooseQueryFactory(query, sort, limit);
    return this.firebaseHttpClient.get(this.endpointService.endpoints.assetsCrud.list, options);
  };
}
