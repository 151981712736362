import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WalletCoreService } from '../walletCoreService/walletCoreService';
import InputDataDecoder from '../../helpers/decodeAbi/decodeAbi';

@Injectable({ providedIn: 'root' })
export class DecodeAbiService {
    private mapperName={
      aria: 'Aria.json',
      creditHistory: 'ArianeeCreditHistory.json',
      eventArianee: 'ArianeeEvent.json',
      identity: 'ArianeeIdentity.json',
      smartAsset: 'ArianeeSmartAsset.json',
      staking: 'ArianeeStaking.json',
      store: 'ArianeeStore.json',
      whitelist: 'ArianeeWhitelist.json',
      message: 'ArianeeMessage.json',
      lost: 'ArianeeLost.json',
      userAction: 'ArianeeUserAction.json'
    }

    public abiList: {
        [key: string]: {
            abi: any,
            name: string,
            address: string
        };
    };

    constructor (private httpClient: HttpClient, private walletCoreService: WalletCoreService) {
    }

    public async initDecodeAbi () {
      this.abiList = {};
      const address = await this.walletCoreService.getContractAddress();

      await Promise.all(Object.keys(address.contractAdresses)
        .filter(d => this.mapperName[d] !== undefined)
        .map(async (contractAddress) => {
          const addr = address.contractAdresses[contractAddress];

          const abiURL = this.baseURL(contractAddress);

          const abi = await this.httpClient.get(abiURL).toPromise().catch();

          this.abiList[addr.toLowerCase()] = {
            name: contractAddress,
            address: addr,
            abi: abi
          };
        }));
    }

    decodeAbi (contractAddress, data) {
      if (!this.abiList) {
        throw new Error('please invoke initDecodeAbi first');
      }

      if (this.abiList[contractAddress.toLowerCase()]) {
        const decoder = new InputDataDecoder(this.abiList[contractAddress.toLowerCase()].abi);

        const result = decoder.decodeData(data);

        result.contractName = this.abiList[contractAddress.toLowerCase()].name;

        return result;
      } else {

      }
    }

    private baseURL = (abiName) => {
      const contractName = this.mapperName[abiName];
      return `https://unpkg.com/@arianee/arianee-abi@latest/abi/${contractName}`;
    };
}
