import { Observable, timer } from 'rxjs';
import { distinctUntilChanged, exhaustMap, switchMap, take } from 'rxjs/operators';
import { isEqual } from 'lodash';

export const fakeWebSocketObservable = (observable: Observable<any>) => {
  return timer(0, 8000)
    .pipe(
      exhaustMap(d => observable.pipe(take(1)))
    );
};

const compareArrayOfObjectByKey = (key:string) => (prev:any[], curr:any[]) => {
  const areSame = () => {
    const prevTokenIds = prev.map(d => d[key]).sort();
    const currTokenIds = curr.map(d => d[key]).sort();
    return isEqual(currTokenIds, prevTokenIds);
  };
  if (prev.length !== curr.length) {
    return false;
  } else if (!areSame()) {
    return false;
  } else {
    return true;
  }
};

export const distinctUntilChangedArrayOfObjectChanged = (key:string) => {
  return distinctUntilChanged(compareArrayOfObjectByKey(key));
};
