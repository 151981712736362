import { FirebaseHttpClient } from '../firebaseHttpClient/firebaseHttpClient';
import { EndpointsService } from '../../apiServices/endpointsService/endpointsService';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../firebaseHttpClient/firebaseHttpClient";
import * as i2 from "../../apiServices/endpointsService/endpointsService";
var PublicConfigurationService = /** @class */ (function () {
    function PublicConfigurationService(firebaseHttpClient, endpointService) {
        var _this = this;
        this.firebaseHttpClient = firebaseHttpClient;
        this.endpointService = endpointService;
        /**
         * Get public configuration based
         * @param {string} name
         * @returns {Observable<UserModel[]>}
         */
        this.getByName = function (name) {
            return _this.firebaseHttpClient.get(_this.endpointService.endpoints.publicConfiguration.list, {
                params: {
                    q: JSON.stringify({
                        name: name
                    })
                }
            }).pipe(map(function (d) { return d[0]; }));
        };
        this.update = function (id, value) {
            var url = _this.endpointService.endpoints.publicConfiguration.update.replace(':id', id);
            return _this.firebaseHttpClient.put(url, value);
        };
        this.create = function (value) {
            return _this.firebaseHttpClient.post(_this.endpointService.endpoints.publicConfiguration.create, value);
        };
    }
    PublicConfigurationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PublicConfigurationService_Factory() { return new PublicConfigurationService(i0.ɵɵinject(i1.FirebaseHttpClient), i0.ɵɵinject(i2.EndpointsService)); }, token: PublicConfigurationService, providedIn: "root" });
    return PublicConfigurationService;
}());
export { PublicConfigurationService };
