import * as i0 from "@angular/core";
var ScriptLoaderService = /** @class */ (function () {
    function ScriptLoaderService() {
        this.scripts = {
            pdfjs: {
                isLoaded: false,
                url: 'https://unpkg.com/jspdf@1.5.3/dist/jspdf.min.js'
            },
            swaggerEditor: {
                url: 'https://unpkg.com/swagger-editor-dist@3.13.0/swagger-editor-bundle.js',
                isLoaded: false
            },
            swaggerEditorStandalonePreset: {
                url: 'https://unpkg.com/swagger-editor-dist@3.13.0/swagger-editor-standalone-preset.js',
                isLoaded: false
            }
        };
    }
    ScriptLoaderService.prototype.loadScript = function (scriptName) {
        var _this = this;
        if (!this.scripts[scriptName].isLoaded) {
            return new Promise(function (resolve) {
                var scriptToLoad = _this.scripts[scriptName];
                var node = document.createElement('script'); // creates the script tag
                node.src = scriptToLoad.url; // sets the source (insert url in between quotes)
                node.type = 'text/javascript'; // set the script type
                node.async = true; // makes script run asynchronously
                node.charset = 'utf-8';
                // append to head of document
                document.getElementsByTagName('head')[0].appendChild(node);
                node.addEventListener('load', function () {
                    _this.scripts[scriptName].isLoaded = true;
                    resolve();
                });
            });
        }
    };
    ScriptLoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ScriptLoaderService_Factory() { return new ScriptLoaderService(); }, token: ScriptLoaderService, providedIn: "root" });
    return ScriptLoaderService;
}());
export { ScriptLoaderService };
