<ion-header>
    <ion-toolbar color="light">
        <ion-title><img alt="logo" height="20" src="assets/img/arianee@2x.png" > </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content text-center  class="home">
    <br/><br/><br/><br/>
    <div  class="loginBlock">

        <ion-item>
            <ion-label floating for="exampleInputEmail1" >New password</ion-label>
            <ion-input type="password" class="form-control" [(ngModel)]="newPassword" required></ion-input>
        </ion-item>


        <ion-item>
            <ion-label floating  for="exampleInputPassword1" >Password confirmation</ion-label>
            <ion-input type="password" class="form-control" [(ngModel)]="confirmPassword" required></ion-input>
        </ion-item>
                <br/>
                Password must be at least 6 characters long
                <br/>
                <br/>
                <ion-button [disabled]="(!newPassword || newPassword != confirmPassword || newPassword.length<6)" color="primary" class="btn btn-primary btn-block" style="margin-bottom: 20px" (click)="handleResetPassword()" >
                Reset password

                </ion-button>

    </div>

</ion-content>
