import * as tslib_1 from "tslib";
import { LoadingController } from '@ionic/angular';
import { AutoMessages } from '../../pages/autoMessages/autoMessages';
import { AuthService } from '../../services/auth/auth';
import { config } from '../../../config';
import { filter, skip } from 'rxjs/operators';
import { Router } from '@angular/router';
import { version } from '../../../../package.json';
import { IdentityService } from '../../apiServices/BDHApi';
import { WalletCoreService } from '../../services/walletCoreService/walletCoreService';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { fakeWebSocketObservable } from '../../helpers/fakeWebSocketObservable/fakeWebSocketObservable';
import { TransactionStatus } from '../../models/transactionStatus.enum';
import { TransactionService } from '../../services/transaction-service/transaction-service';
import { CertificateCoreService } from '../../services/certificate-service/certificate-core.service';
var LoggedComponent = /** @class */ (function () {
    function LoggedComponent(authService, loadingCtrl, router, identityService, walletCoreService, uiAlertService, transactionService, certificateService) {
        this.authService = authService;
        this.loadingCtrl = loadingCtrl;
        this.router = router;
        this.identityService = identityService;
        this.walletCoreService = walletCoreService;
        this.uiAlertService = uiAlertService;
        this.transactionService = transactionService;
        this.certificateService = certificateService;
        this.token = '';
        this.logged = false;
        this.admin = false;
        this.developerCount = 0;
        this.subscribeLogged = false;
        this.version = version;
        this.blockiesOptions = {
            seed: 'randstring',
            color: '#ffb200',
            bgcolor: '#5c5c5c',
            size: 8,
            scale: 4,
            spotcolor: '#000' // each pixel has a 13% chance of being of a third color,
            // default: random. Set to -1 to disable it. These "spots" create structures
            // that look like eyes, mouths and noses.
        };
        this.config = config;
        this.subscribeLogged = true;
        this.initArianeeProtocol();
    }
    LoggedComponent.prototype.getIdentityLogo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var address, identityObject;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.walletCoreService.getAddress()];
                    case 1:
                        address = _a.sent();
                        return [4 /*yield*/, this.identityService.getIdentity([address]).toPromise()];
                    case 2:
                        identityObject = _a.sent();
                        if (identityObject && identityObject.data.pictures) {
                            this.brandLogo = identityObject.data.pictures.find(function (element) { return element.type === 'brandLogoHeader'; });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    LoggedComponent.prototype.initArianeeProtocol = function () {
        var _this = this;
        this.authService.$userState
            .pipe(filter(function (d) { return d.isLoggedIn; }))
            .subscribe(function (state) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a, userDetails, profile, token;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Promise.all([this.authService.getUserDetails(), this.authService.getUserProfile()])];
                    case 1:
                        _a = _b.sent(), userDetails = _a[0], profile = _a[1];
                        return [4 /*yield*/, userDetails.getIdToken(true)];
                    case 2:
                        token = _b.sent();
                        this.logged = true;
                        this.admin = (profile === 'admin');
                        fakeWebSocketObservable(this.certificateService
                            .getCertificateCount({ isSync: false }))
                            .subscribe(function (data) {
                            _this.certificateNotSync = data.count;
                        });
                        fakeWebSocketObservable(this.transactionService
                            .getTransactionCountByStatus([TransactionStatus.waiting, TransactionStatus.pending]))
                            .subscribe(function (data) {
                            _this.transactionsPending = data.count;
                        });
                        this.getIdentityLogo();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    LoggedComponent.prototype.autoMessages = function () {
        this.navCtrl.setRoot(AutoMessages);
    };
    LoggedComponent.prototype.iterateForDev = function () {
        if (this.admin) {
            this.developerCount++;
        }
    };
    LoggedComponent.prototype.logout = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var buttons;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                buttons = [
                    {
                        text: 'Cancel',
                        role: 'cancel'
                    },
                    {
                        text: 'Logout',
                        handler: function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var d;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        this.logged = false;
                                        this.authService.logout();
                                        return [4 /*yield*/, this.authService.$userState
                                                .pipe(skip(1))
                                                .toPromise()];
                                    case 1:
                                        d = _a.sent();
                                        return [4 /*yield*/, this.router.navigate(['login'])];
                                    case 2:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); }
                    }
                ];
                this.uiAlertService.alert({
                    message: 'Are you sure you want to log out?',
                    buttons: buttons
                });
                return [2 /*return*/];
            });
        });
    };
    return LoggedComponent;
}());
export { LoggedComponent };
