import { FirebaseHttpClient } from '../firebaseHttpClient/firebaseHttpClient';
import { EndpointsService } from '../../apiServices/endpointsService/endpointsService';
import { UserModel } from '../../models/User.model';
import { validate } from 'class-validator';
import { from } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../firebaseHttpClient/firebaseHttpClient";
import * as i2 from "../../apiServices/endpointsService/endpointsService";
var UsersService = /** @class */ (function () {
    function UsersService(firebaseHttpClient, endpointService) {
        var _this = this;
        this.firebaseHttpClient = firebaseHttpClient;
        this.endpointService = endpointService;
        /**
         * Get list of users
         * @returns {Observable<UserModel[]>}
         */
        this.all = function () {
            return _this.firebaseHttpClient.get(_this.endpointService.endpoints.user.list);
        };
        /**
         * Get a user from firebaseUid
         * @returns {Observable<UserModel>}
         */
        this.getUserFromUserUid = function (userId) {
            return _this.firebaseHttpClient.get(_this.endpointService.endpoints.user.list, {
                params: {
                    q: JSON.stringify({ userId: userId })
                }
            })
                .pipe(map(function (d) { return d[0]; }));
        };
        /**
         * Update a user
         * @param {UserModel} user
         * @returns {Observable<UserModel>}
         */
        this.update = function (user) {
            return from(validate(UserModel))
                .pipe(mergeMap(function () { return _this.firebaseHttpClient.post(_this.endpointService.endpoints.user.update, user); }));
        };
        /**
         * Create a user
         * @param {UserModel} user
         * @returns {Observable<UserModel>}
         */
        this.create = function (user) {
            return from(validate(UserModel))
                .pipe(mergeMap(function () { return _this.firebaseHttpClient.post(_this.endpointService.endpoints.user.create, user); }));
        };
        /**
         * Delete user by userId
         * @param {string} userId
         * @returns {Observable<UserModel>}
         */
        this.delete = function (userId) {
            return _this.firebaseHttpClient.post(_this.endpointService.endpoints.user.delete, { userId: userId });
        };
    }
    UsersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UsersService_Factory() { return new UsersService(i0.ɵɵinject(i1.FirebaseHttpClient), i0.ɵɵinject(i2.EndpointsService)); }, token: UsersService, providedIn: "root" });
    return UsersService;
}());
export { UsersService };
