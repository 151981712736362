import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';

@Component({
  selector: 'app-reserve-token',
  templateUrl: './reserve-token.component.html',
  styleUrls: ['./reserve-token.component.scss']
})
export class ReserveTokenComponent implements OnInit {
    @Input() onSubmitRange: (range:number[]) => {};
    @Input() onSubmitNumber: (number:number) => {};

    public numberOfTokens: number | string = '__';

    public form = new FormGroup({
      rangeStart: new FormControl(undefined,
        [
          Validators.required,
          Validators.min(1),
          Validators.pattern(/[0-9]+/)
        ]
      ),
      rangeEnd: new FormControl(undefined,
        [
          Validators.required,
          Validators.min(1),
          Validators.pattern(/[0-9]+/)]
      )
    }, ReserveTokenComponent.MyAwesomeRangeValidator);

    public reserveNCertificateIdForm = new FormGroup({
      numberOfCertificateId: new FormControl(undefined,
        [
          Validators.required,
          Validators.min(1),
          Validators.pattern(/[0-9]+/)
        ]
      )
    });

    constructor (private uiAlertService: UIAlertService) {
    }

    private static MyAwesomeRangeValidator: ValidatorFn = (fg: FormGroup) => {
      const start = fg.get('rangeStart').value;
      const end = fg.get('rangeEnd').value;
      return start !== null && end !== null && start <= end
        ? null
        : { range: true };
    };

    ngOnInit () {
      this.form.valueChanges.subscribe(d => {
        const { rangeStart, rangeEnd } = d;
        if (rangeStart !== undefined && rangeEnd !== undefined && rangeStart <= rangeEnd) {
          this.numberOfTokens = rangeEnd - rangeStart + 1;
        } else {
          this.numberOfTokens = '__';
        }
      });
    }

    private getArrayFromRange = (from: number, to: number): number[] => {
      const range = [];
      for (let i = from; i <= to; i++) {
        range.push(i);
      }
      return range;
    }

    public onReserveNCertificateIdInternalSubmit () {
      return this.onSubmitNumber(this.reserveNCertificateIdForm.getRawValue().numberOfCertificateId);
    }

    async onInternalSubmit () {
      const values = this.form.getRawValue();
      const { rangeStart, rangeEnd } = values;
      return this.onSubmitRange(this.getArrayFromRange(rangeStart, rangeEnd));
    }
}
