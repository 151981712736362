import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { from, Observable } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';

declare var window:any;

@Injectable({ providedIn: 'root' })
export class FirebaseHttpClient {
    private timer;
    constructor (private httpClient: HttpClient,
                private _firebaseAuth: AngularFireAuth) {

    }

    private static parseJwt (token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    };

    private static setToHappen (fn, d) {
      var t = d.getTime() - (new Date()).getTime();
      return setTimeout(fn, t);
    }

    async init () {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      await this._firebaseAuth.authState
        .pipe(
          filter(user => user !== undefined),
          take(1))
        .toPromise();

      await this.refreshTokenId().toPromise();
      this.refreshAccessToken();
    }

    private refreshAccessToken = async () => {
      if (window.accessToken) {
        const { exp } = FirebaseHttpClient.parseJwt(window.accessToken);

        const expDate = new Date((exp * 1000) - (60 * 1000));

        if (expDate.valueOf() < new Date().valueOf()) {
          await this.refreshTokenId().toPromise();
          this.refreshAccessToken();
        } else {
          this.timer = FirebaseHttpClient.setToHappen(this.refreshAccessToken, expDate);
        }
      } else {
        this.timer = setTimeout(this.refreshAccessToken, 5000);
      }
    };

    public get (url, options = {} as any): Observable<any> {
      return this.refreshTokenId()
        .pipe(
          mergeMap(authorizationHeader =>
            this.httpClient.get(url, {

              observe: 'body',
              responseType: 'json',
              ...options,
              headers: {
                ...authorizationHeader,
                ...options.headers
              }
            })));
    }

    public post (url, body, options?):Observable<any> {
      return this.refreshTokenId()
        .pipe(
          mergeMap(authorizationHeader => this.httpClient.post(url,
            body,
            {
              observe: 'body',
              responseType: 'json',
              headers: {
                ...authorizationHeader
              },
              ...options
            })
          )
        );
    }

    public put (url, body, options?):Observable<any> {
      return this.refreshTokenId()
        .pipe(
          mergeMap(authorizationHeader => this.httpClient.put(url,
            body,
            {
              observe: 'body',
              responseType: 'json',
              headers: {
                ...authorizationHeader
              },
              ...options
            })
          )
        );
    }

    public delete (url, options?):Observable<any> {
      return this.refreshTokenId()
        .pipe(
          mergeMap(authorizationHeader => this.httpClient.delete(url,
            {
              observe: 'body',
              responseType: 'json',
              headers: {
                ...authorizationHeader
              },
              ...options
            })
          )
        );
    }

    /**
     * Refresh access token of user and return authorization header property
     */
    private refreshTokenId (): Observable<{'authorization': string}> {
      const $idToken = () => from(this._firebaseAuth.auth.currentUser.getIdToken());
      return this._firebaseAuth.authState
        .pipe(
          filter(user => user !== undefined),
          take(1),
          mergeMap(user => $idToken()),
          tap(accessToken => window.accessToken = accessToken),
          map(userId => ({ authorization: `Bearer ${userId}` }))

        );
    }
}
