import * as tslib_1 from "tslib";
import { ElementRef, Renderer2 } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular'; // PopoverController
import { Utils } from '../../services/utils/utils';
import { AuthService } from '../../services/auth/auth';
import { AngularFireAuth } from '@angular/fire/auth';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';
import { TruncateModule } from '@yellowspot/ng-truncate';
import { SchemaService } from '../../services/schema-service/schema-service';
import { CertificateCoreService } from '../../services/certificate-service/certificate-core.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { UserSessionService } from '../../services/user-session-service/user-session-service';
import { HttpClient } from '@angular/common/http';
import { FeatureFlipService } from '../../services/feature-flip-service/feature-flip-service';
import { range } from 'lodash';
import { Location } from '@angular/common';
import { CertificateService } from '../../apiServices/BDHApi';
var JsonBuilder = /** @class */ (function () {
    function JsonBuilder(utils, authService, truncate, loadingCtrl, _firebaseAuth, httpClient, toasterCtrl, schemaService, _renderer, elem, certificateService, certificateCoreService, activiatedRoute, uIAlertService, userSessionService, featureFlipService, router, eventLogger, location) {
        var _this = this;
        this.utils = utils;
        this.authService = authService;
        this.truncate = truncate;
        this.loadingCtrl = loadingCtrl;
        this._firebaseAuth = _firebaseAuth;
        this.httpClient = httpClient;
        this.toasterCtrl = toasterCtrl;
        this.schemaService = schemaService;
        this._renderer = _renderer;
        this.elem = elem;
        this.certificateService = certificateService;
        this.certificateCoreService = certificateCoreService;
        this.activiatedRoute = activiatedRoute;
        this.uIAlertService = uIAlertService;
        this.userSessionService = userSessionService;
        this.featureFlipService = featureFlipService;
        this.router = router;
        this.eventLogger = eventLogger;
        this.location = location;
        this.certificateDocumentModel = {};
        this.subscribe = {};
        this.tags = [];
        this.enableSaveBtn = true;
        this.sameRequestOwnershipPassphrase = true;
        this.parentCertificateTitle = '';
        this.customTags = '';
        this.isParent = false;
        this.schemaUrls = [];
        this.schema = {
            properties: {}
        };
        this.loaded = false;
        this.updateCertificate = function (certificateDocument) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.certificateCoreService.update({
                            certificateId: this.tokenIdInput,
                            content: certificateDocument
                        })];
                    case 1:
                        result = _a.sent();
                        this.successCreatingCertificate("Your certificate (" + this.tokenIdInput + ") has been updated");
                        return [2 /*return*/, result];
                }
            });
        }); };
        this.updateProperties = function (certificateId) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var customTags, body;
            return tslib_1.__generator(this, function (_a) {
                customTags = this.customTags.split(',').map(function (item) { return item.trim(); });
                body = {};
                if (this.isParent) {
                    customTags.push("parentPassport" /* parentCertificate */);
                    body.displayTitle = this.parentCertificateTitle;
                }
                body.tags = customTags;
                if (customTags.length > 0) {
                    return [2 /*return*/, this.certificateService
                            .certificateProperties(body, certificateId)
                            .toPromise()];
                }
                return [2 /*return*/];
            });
        }); };
        this.createCertificate = function (certificateDocument) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a, canCreate, result, err_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!!this.tokenIdInput) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.certificateCoreService.getFreeTokenId()];
                    case 1:
                        _a.tokenId = _b.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        this.tokenId = this.tokenIdInput;
                        return [4 /*yield*/, this.certificateCoreService.canCreateCertificateWithCertificateId(this.tokenId)];
                    case 3:
                        canCreate = _b.sent();
                        if (!canCreate) {
                            this.uIAlertService.error("This Arianee ID " + this.tokenId + " is not available.");
                            return [2 /*return*/];
                        }
                        _b.label = 4;
                    case 4:
                        _b.trys.push([4, 6, , 7]);
                        return [4 /*yield*/, this.certificateCoreService.createCertificate({
                                certificateId: this.tokenId,
                                content: certificateDocument,
                                sameRequestOwnershipPassphrase: this.sameRequestOwnershipPassphrase
                            })];
                    case 5:
                        result = _b.sent();
                        this.successCreatingCertificate("Your certificate (" + this.tokenId + ") has been created");
                        return [2 /*return*/, result];
                    case 6:
                        err_1 = _b.sent();
                        console.error(err_1);
                        this.uIAlertService.error('Something went wrong');
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        }); };
    }
    JsonBuilder.prototype.ionViewDidEnter = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var loader, certificateId, tmpmodel, certificateInDatabase, parentCertificateTagIndex, content, e_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.uIAlertService.load()];
                    case 1:
                        loader = _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 6, , 7]);
                        this.eventLogger.logScreen('jsonBuilder-page');
                        this.schemaUrls = [
                            'https://cert.arianee.org/version2/ArianeeProductCertificate-i18n.json',
                            'https://cert.arianee.org/version1/ArianeeAsset.json',
                            'https://cert.arianee.org/version4/ArianeeProductCertificate-i18n.json'
                        ];
                        this.userSessionService.preventUserFromLeavingPage = false;
                        certificateId = this.certificateId = parseInt(this.activiatedRoute.snapshot.paramMap.get('certificateId'));
                        this.method = this.activiatedRoute.snapshot.paramMap.get('method');
                        if (this.method === 'update') {
                            this.assetType = 'update';
                            this.tokenIdInput = certificateId;
                        }
                        else {
                            this.assetType = 'certificate';
                            this.certificateCoreService.getFreeTokenId()
                                .then(function (freeCertifId) { return _this.tokenIdInput = freeCertifId; });
                        }
                        tmpmodel = void 0;
                        if (!certificateId) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.certificateCoreService.getCertificate(certificateId).toPromise()];
                    case 3:
                        certificateInDatabase = _a.sent();
                        if (certificateInDatabase.tags.length) {
                            parentCertificateTagIndex = certificateInDatabase.tags.indexOf("parentPassport" /* parentCertificate */);
                            if (parentCertificateTagIndex > -1) {
                                this.isParent = true;
                                certificateInDatabase.tags.splice(parentCertificateTagIndex, 1);
                            }
                            this.customTags = certificateInDatabase.tags.join(', ');
                        }
                        return [4 /*yield*/, this.certificateService.getCertificate([certificateId,
                                certificateInDatabase.viewKey, {
                                    content: true
                                }]).toPromise()];
                    case 4:
                        content = (_a.sent()).content;
                        if (!content.isAuthentic) {
                            this.uIAlertService.alert({ message: 'the content of this certificate is not authentic' });
                        }
                        tmpmodel = content.raw;
                        _a.label = 5;
                    case 5:
                        // For older version of serial number
                        if (tmpmodel && tmpmodel.serialnumber && !Array.isArray(tmpmodel.serialnumber)) {
                            tmpmodel.serialnumber = [{ type: 'serialnumber', value: tmpmodel.serialnumber }];
                        }
                        if (tmpmodel) {
                            this.certificateDocumentModel = tmpmodel;
                        }
                        this.loaded = true;
                        return [3 /*break*/, 7];
                    case 6:
                        e_1 = _a.sent();
                        console.error(e_1);
                        this.uIAlertService.error('something went wrong');
                        return [3 /*break*/, 7];
                    case 7:
                        loader.dismiss();
                        return [2 /*return*/];
                }
            });
        });
    };
    JsonBuilder.prototype.navigateBack = function () {
        this.location.back();
    };
    JsonBuilder.prototype.updateCertNumber = function () {
        if (this.arianeeIdsLines === this.duplicateValueLines && this.duplicateValueLines === this.recoverKeyLines) {
            this.certNumber = this.duplicateValueLines;
        }
        else if (this.arianeeIdsLines === this.duplicateValueLines && !this.recoverKeyLines) {
            this.certNumber = this.duplicateValueLines;
        }
        else if (this.arianeeIdsLines === this.recoverKeyLines && !this.duplicateValueLines) {
            this.certNumber = this.arianeeIdsLines;
        }
        else if (this.duplicateValueLines === this.recoverKeyLines && !this.arianeeIdsLines) {
            this.certNumber = this.duplicateValueLines;
        }
        else if (this.arianeeIdsLines && !this.duplicateValueLines && !this.recoverKeyLines) {
            this.certNumber = this.arianeeIdsLines;
        }
        else if (this.duplicateValueLines && !this.arianeeIdsLines && !this.recoverKeyLines) {
            this.certNumber = this.duplicateValueLines;
        }
        else {
            this.certNumber = 0;
        }
    };
    JsonBuilder.prototype.updateDuplicateValue = function () {
        if (this.duplicateValue) {
            this.duplicateValueLines = this.duplicateValue.trim().split(/\r\n|\r|\n/).length;
        }
        else {
            this.duplicateValueLines = 0;
        }
        this.updateCertNumber();
    };
    JsonBuilder.prototype.updateArianeeId = function () {
        if (this.arianeeIds) {
            this.arianeeIdsLines = this.arianeeIds.trim().split(/\r\n|\r|\n/).length;
        }
        else {
            this.arianeeIdsLines = 0;
        }
        this.updateCertNumber();
    };
    JsonBuilder.prototype.updateRecoverKey = function () {
        if (this.recoverKey) {
            this.recoverKeyLines = this.recoverKey.trim().split(/\r\n|\r|\n/).length;
        }
        else {
            this.recoverKeyLines = 0;
        }
        this.updateCertNumber();
    };
    JsonBuilder.prototype.checkAvaibility = function (tokensId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var loading, result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.uIAlertService.load({ message: 'Checking Arianee IDs availabilities' })];
                    case 1:
                        loading = _a.sent();
                        return [4 /*yield*/, this.certificateCoreService.certificateIdsAvailibility(tokensId)];
                    case 2:
                        result = _a.sent();
                        loading.dismiss();
                        return [2 /*return*/, result];
                }
            });
        });
    };
    JsonBuilder.prototype.verifyArianeeIdNotSameOwner = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ArianeeIds, result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.arianeeIds) return [3 /*break*/, 2];
                        ArianeeIds = this.arianeeIds
                            .trim()
                            .split(/\r\n|\r|\n/)
                            .map(function (d) { return +d; });
                        return [4 /*yield*/, this.checkAvaibility(ArianeeIds)];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, {
                                nbError: result.notAvailablesIds.length,
                                error: result.notAvailablesIds.length > 0
                            }];
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    JsonBuilder.prototype.verifyArianeeV2Certificate = function (json) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var regexi18n, hasErrors, loading, data, mediasWithErrors, loading;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        regexi18n = /https:\/\/cert\.arianee\.org\/version\d\/(ArianeeProductCertificate-i18n\.json)/;
                        if (!json.$schema.match(regexi18n)) return [3 /*break*/, 8];
                        hasErrors = false;
                        if (!json.medias) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.uIAlertService.load({
                                message: "Confirming your certificate's medias."
                            })];
                    case 1:
                        loading = _a.sent();
                        return [4 /*yield*/, this.certificateCoreService.doctorI18N(json)];
                    case 2:
                        data = _a.sent();
                        this.schemaErrors = data.medias
                            .filter(function (m) { return !m.doctor.valid; });
                        hasErrors = this.schemaErrors.length !== 0;
                        return [4 /*yield*/, loading.dismiss()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        if (!hasErrors) return [3 /*break*/, 6];
                        mediasWithErrors = this.schemaErrors
                            .map(function (mediaWithError) {
                            var mediaType = mediaWithError.mediaType, type = mediaWithError.type, url = mediaWithError.url;
                            return "media type: " + mediaType + " | type " + type + " | " + url;
                        }).join('\n');
                        return [4 /*yield*/, this.uIAlertService.alert({
                                header: "Certificate's medias do not match specs",
                                message: mediasWithErrors
                            }, true)];
                    case 5:
                        loading = _a.sent();
                        return [3 /*break*/, 7];
                    case 6:
                        this.schemaErrors = undefined;
                        _a.label = 7;
                    case 7: return [2 /*return*/, hasErrors];
                    case 8: return [2 /*return*/, false];
                }
            });
        });
    };
    JsonBuilder.prototype.onChangeModel = function (event) {
        this.templateForWysiwyg = event;
    };
    JsonBuilder.prototype.executeBlockChainOperation = function (certificateDocument) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var hasError, e_2, loader, result, certificateId, e_3;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.verifyArianeeV2Certificate(certificateDocument)];
                    case 1:
                        hasError = _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        console.warn(e_2);
                        console.error('error in your certificate');
                        hasError = true;
                        return [3 /*break*/, 3];
                    case 3:
                        if (hasError) {
                            this.uIAlertService.error('An error occured in your certificate. Please check contents and medias');
                        }
                        if (this.isParent && this.parentCertificateTitle.length === 0) {
                            hasError = true;
                            this.uIAlertService.error('Your certificate is tagged as a parent certificate. A title is required');
                        }
                        return [4 /*yield*/, this.uIAlertService.load()];
                    case 4:
                        loader = _a.sent();
                        this.enableSaveBtn = false;
                        _a.label = 5;
                    case 5:
                        _a.trys.push([5, 12, , 13]);
                        if (!!hasError) return [3 /*break*/, 11];
                        if (!(this.assetType === 'certificate')) return [3 /*break*/, 8];
                        return [4 /*yield*/, this.createCertificate(certificateDocument)];
                    case 6:
                        result = _a.sent();
                        certificateId = result.certificateId;
                        return [4 /*yield*/, this.updateProperties(certificateId)];
                    case 7:
                        _a.sent();
                        this.assetType = 'certificate';
                        this.certificateCoreService.getFreeTokenId()
                            .then(function (freeCertifId) { return _this.tokenIdInput = freeCertifId; });
                        return [3 /*break*/, 11];
                    case 8:
                        if (!(this.assetType === 'update')) return [3 /*break*/, 10];
                        certificateId = this.tokenIdInput;
                        return [4 /*yield*/, this.updateCertificate(certificateDocument)];
                    case 9:
                        _a.sent();
                        return [3 /*break*/, 11];
                    case 10: throw new Error("this method does not exist: " + this.method);
                    case 11:
                        this.enableSaveBtn = true;
                        loader.dismiss();
                        return [3 /*break*/, 13];
                    case 12:
                        e_3 = _a.sent();
                        this.enableSaveBtn = true;
                        loader.dismiss();
                        this.uIAlertService.error('An error occured');
                        return [3 /*break*/, 13];
                    case 13: return [2 /*return*/];
                }
            });
        });
    };
    JsonBuilder.prototype.duplicateCert = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var testIds, loading, duplicateValueArray, ArianeeIdsArray, recoverKeyArray, itemTmp, creatingCertificate;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.arianeeIdsLines) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.verifyArianeeIdNotSameOwner()];
                    case 1:
                        testIds = _a.sent();
                        if (testIds.nbError) {
                            this.utils.alert('Some Arianee IDs (' + (testIds.nbError) + ') are unavailable...');
                            return [2 /*return*/];
                        }
                        _a.label = 2;
                    case 2:
                        if (this.arianeeIdsLines && this.duplicateValueLines && this.arianeeIdsLines !== this.duplicateValueLines) {
                            this.utils.alert('Same number of Arianee IDs and custom values are required ...');
                            return [2 /*return*/];
                        }
                        if (this.arianeeIdsLines && this.recoverKeyLines && this.arianeeIdsLines !== this.recoverKeyLines) {
                            this.utils.alert('Same number of Arianee IDs and recoverKeys are required ...');
                            return [2 /*return*/];
                        }
                        if (this.recoverKeyLines && !this.arianeeIdsLines) {
                            this.utils.alert('No recoverKey allowed if random Arianee IDs ...');
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.uIAlertService.loadUpdatable({ message: 'Duplicate process 0/' + this.certNumber })];
                    case 3:
                        loading = _a.sent();
                        duplicateValueArray = [];
                        if (this.duplicateValue) {
                            duplicateValueArray = this.duplicateValue.trim().split(/\r\n|\r|\n/);
                        }
                        ArianeeIdsArray = [];
                        if (this.arianeeIds) {
                            ArianeeIdsArray = this.arianeeIds.trim().split(/\r\n|\r|\n/);
                        }
                        recoverKeyArray = [];
                        if (this.recoverKey) {
                            recoverKeyArray = this.recoverKey.trim().split(/\r\n|\r|\n/);
                        }
                        itemTmp = JSON.stringify(this.templateForWysiwyg);
                        loading.message = 'Step 2/3 : Preparing transactions';
                        creatingCertificate = range(this.certNumber)
                            .map(function (index) {
                            var certificate = itemTmp.replace('%ID%', duplicateValueArray[index]);
                            var content = JSON.parse(certificate);
                            return {
                                certificateId: ArianeeIdsArray[index],
                                content: content,
                                sameRequestOwnershipPassphrase: _this.sameRequestOwnershipPassphrase,
                                passphrase: recoverKeyArray[index]
                            };
                        })
                            .map(function (certificate) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var cert;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.certificateCoreService.createCertificate(certificate)];
                                    case 1:
                                        cert = _a.sent();
                                        return [4 /*yield*/, this.updateProperties(cert.certificateId)];
                                    case 2:
                                        _a.sent();
                                        return [2 /*return*/, cert];
                                }
                            });
                        }); });
                        loading.message = 'Step 3/3 : Sending transaction';
                        return [4 /*yield*/, Promise.all(creatingCertificate)];
                    case 4:
                        _a.sent();
                        loading.message = 'Finalizing';
                        loading.dismiss();
                        this.successCreatingCertificate("Your certificate has been duplicated " + this.certNumber + " times sucessfully");
                        return [2 /*return*/];
                }
            });
        });
    };
    JsonBuilder.prototype.successCreatingCertificate = function (message) {
        var _this = this;
        return this.uIAlertService.alert({
            message: message,
            cssClass: 'e2e_certif_create_success',
            buttons: [
                {
                    text: 'See my certificates',
                    cssClass: 'e2e_see_certificiates',
                    handler: function () {
                        _this.userSessionService.preventUserFromLeavingPage = true;
                        _this.router.navigate(['certificate/list']);
                    }
                },
                {
                    text: 'Later',
                    cssClass: 'e2e_later',
                    role: 'cancel'
                }
            ]
        });
    };
    return JsonBuilder;
}());
export { JsonBuilder };
