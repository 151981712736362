import { Injectable } from '@angular/core';
import { ToastController, LoadingController, AlertController } from '@ionic/angular';
import { ToastOptions, AlertOptions, LoadingOptions } from '@ionic/core';

@Injectable({ providedIn: 'root' })
export class UIAlertService {
  constructor (private toasterCtrl: ToastController, private loadingController:LoadingController, private alertController:AlertController) {

  }

  async alert (opts:AlertOptions, withButton = false) {
    if (withButton) {
      opts = {
        ...opts,
        buttons: [
          {
            text: 'ok',
            role: 'cancel'
          }
        ]
      };
    }

    const alert = await this.alertController.create(opts);
    alert.present();
  }

  async load (opts?: LoadingOptions): Promise<HTMLIonLoadingElement> {
    const toaster = await this.loadingController.create({
      spinner: 'dots',
      message: 'loading',
      cssClass: '.loading-spinner',
      ...opts
    });

    toaster.present();

    return toaster;
  }

  async loadUpdatable (opts?: LoadingOptions) {
    let loadReturn: HTMLIonLoadingElement = null;

    const toaster = await this.loadingController.create({
      spinner: 'dots',
      message: 'loading',
      cssClass: 'toast-success',
      ...opts
    }).then((load: HTMLIonLoadingElement) => {
      loadReturn = load;

      load.present();
    });

    return loadReturn;
  }

  async toast (opts: ToastOptions, withButton = false): Promise<HTMLIonToastElement> {
    if (withButton) {
      opts = {
        ...opts,
        buttons: [
          {
            text: 'ok',
            role: 'cancel'
          }
        ]
      };
    }
    const toaster = await this.toasterCtrl.create({
      message: 'hello',
      ...opts
    });

    toaster.present();

    return toaster;
  }

  async error (message, params?: any) {
    const toast = await this.toasterCtrl.create({
      message: message,
      duration: 3000,
      cssClass: 'toast-error',
      position: 'top'
    });
    toast.present();
  }

  /*
    *  Toast success message
    */
  async success (message, params?: any) {
    const toast = await this.toasterCtrl.create({
      message: message,
      duration: 3000,
      cssClass: 'toast-success',
      position: 'top'
    });
    toast.present();
  }
}
