import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import * as amplitude from 'amplitude-js';
import { environment } from '../../../environments/environment';
import { version } from '../../../../package.json';
import { config } from '../../../config';
import { AuthService } from '../auth/auth';
import { filter, skip } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class EventLoggerService {
  private client: any;
  private queueLogs:Array<{eventName, params}>=[];

  constructor (
    private platform: Platform,
    private authService: AuthService
  ) {

  }

  public init (userId?) {
    const instance = amplitude.getInstance();

    instance.init('3f7bdb21badb340f48d1a4161391de4b', config.projectId);

    instance.setVersionName(version);

    this.authService.$userState
      .subscribe(async (state) => {
        instance.setUserProperties({ email: state.details.email });
        this.client = instance;
      });
  }

  public logScreen (screenName: string, params: any = {}) {
    this.logEvent('SCREEN_' + screenName, params);
  }

  public logEvent (eventName: string, params: any = {}) {
    if (!this.client) {
      this.queueLogs.push({ eventName, params });
    } else {
      this.client.logEvent(eventName, params);
    }
  }
}
