<ion-header>
    <div color="light" class="header">
        <div>
            <ion-menu-button></ion-menu-button>
            <ion-title><a (click)="navigateBack()"><ion-icon name="chevron-back-outline"></ion-icon></a></ion-title>
            <ion-title>Message Builder</ion-title>
        </div>
    </div>
</ion-header>

<ion-content class="item text-center" data-e2e="create_dmessage_page">
    <ion-card>
        <ion-card-content>
            <h2>Creating DMessage for {{item?.name}} <span *ngIf="itemId">certificate ID (arianee Id) : {{itemId}}</span></h2>
        </ion-card-content>
    </ion-card>
    <schema-builder
            assetType="message"
            (onSubmit)="sendMessage($event)"
            [schemaUrls]="['https://cert.arianee.org/version1/ArianeeMessage-i18n.json']"
    ></schema-builder>
</ion-content>
