import { Component } from '@angular/core';
import { Utils } from '../../services/utils/utils';
import { AuthService } from '../../services/auth/auth';
import { map } from 'rxjs/operators';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';
import { CertificateCoreService } from '../../services/certificate-service/certificate-core.service';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { WalletCoreService } from '../../services/walletCoreService/walletCoreService';

/*
*
*  Reporting
*
*/
@Component({
  selector: 'page-reporting',
  templateUrl: 'reporting.html',
  styleUrls: ['./reporting.scss']
})
export class Reporting {
    public barChartOptions = {
      scales: {
        xAxes: [{
          type: 'time',
          tick: {
            source: 'auto'
          },
          time: {
            unit: 'day',
            displayFormats: {
              quarter: 'MMM YYYY'
            }
          }
        }],
        yAxes: [{
          id: 'y-axis-1',
          ticks: {
            beginAtZero: true,
            stepSize: 10,
            maxTicksLimit: 10
          }
        },
        {
          id: 'y-axis-2',
          position: 'right',
          ticks: {
            beginAtZero: true,
            stepSize: 10,
            maxTicksLimit: 10
          },
          gridLines: {
            display: false
          }
        }
        ]
      }

    };

    public barChartType = 'bar';
    public barChartLegend = true;
    public barChartData2 = [{ data: [], label: 'Certificates Creation' }, {
      data: [],
      label: 'Cumulative',
      yAxisID: 'y-axis-2',
      type: 'line',
      lineTension: 0,
      fill: false,
      gridLines: {
        drawOnChartArea: false
      }
    }];

    public barChartData: any;

    public transferChartData2 = [{ data: [], label: 'First Transfer' }, {
      data: [],
      label: 'Cumulative',
      yAxisID: 'y-axis-2',
      type: 'line',
      lineTension: 0,
      fill: false,
      gridLines: {
        drawOnChartArea: false
      }
    }];

    public transferChartData: any;

    public totalCertificate = 0;
    public destroyedCertificate = 0;
    public reservedCertificate = 0;

    public totalFirstTransfer = 0;
    public transferedAtLeastOnce = 0;

    public totalOptin = 0;
    public address: string;

    public subscribe: any = {};

    /*
    public barChartData = [
      {data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A'},
      {data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B'}
    ];
    */

    constructor (
        public utils: Utils,
        public authService: AuthService,
        private eventLogger: EventLoggerService,
        private certificateService:CertificateCoreService,
        private loaderService: UIAlertService,
        private walletCoreService: WalletCoreService

    ) {

    }

    public get totalFirstTransferRatio (): number {
      if (this.totalCertificate > 0) {
        return Math.round((this.transferedAtLeastOnce / this.totalCertificate) * 100);
      } else {
        return 0;
      }
    }

    public get totalOptinRatio (): number {
      if (this.totalOptin > 0) {
        return Math.round((this.totalOptin / this.transferedAtLeastOnce) * 100);
      } else {
        return 0;
      }
    }

    public loadStats = async () => {
      const deadAddress = '0x000000000000000000000000000000000000dEaD';

      const $totalHydatedCertificate = this.certificateService.getCertificateCount({ isConsumed: { $ne: false } }).toPromise();
      const $totalReserved = this.certificateService.getCertificateCount({ isConsumed: false }).toPromise();

      const $totalDestroyed = this.certificateService.getCertificateCount({ owner: deadAddress }).toPromise();

      const $transferdAtLeastOnce = this.certificateService.getCertificateCount({
        $nor: [{ owner: '0x000000000000000000000000000000000000dEaD' }, { owner: this.address }]
      }).toPromise();

      const $optin = this.certificateService.getCertificateCount({
        $nor: [{ owner: '0x000000000000000000000000000000000000dEaD' }, { owner: this.address }],
        whitelist: true
      }).toPromise();

      const [totalHydratedCertificate,
        reservedCertificate,
        destroyedCertificate,
        transferedAtLeastOnce,
        totalOptin
      ] = await Promise.all([
        $totalHydatedCertificate,
        $totalReserved,
        $totalDestroyed,
        $transferdAtLeastOnce,
        $optin
      ]);

      this.reservedCertificate = reservedCertificate.count;
      this.destroyedCertificate = destroyedCertificate.count;
      this.totalCertificate = totalHydratedCertificate.count;
      this.transferedAtLeastOnce = transferedAtLeastOnce.count;
      this.totalOptin = totalOptin.count;
    };

    async ionViewDidEnter () {
      const loader = await this.loaderService.load();
      this.address = await this.walletCoreService.getAddress();

      await this.loadStats();

      this.eventLogger.logScreen('reporting-page');

      this.subscribe.json = this.certificateService.getCertificateList({ mintTimeStamp: { $gte: 0 } }, { mintTimeStamp: 1 }, 0)
        .pipe(
          map((data: any) => {
            return data.map(data => {
              // const id = a.payload.doc.id;
              const json = {} as any;

              json.id = data.tokenId;
              json.blockNumberCreation = data.blockNumberCreation;

              json.mintDate = new Date(data.mintTimeStamp * 1000);

              json.firstTransferDate = new Date(data.firstTransferTimeStamp * 1000);
              json.firstTransfer = data.firstTransfer;
              json.firstTransferTimeStamp = data.firstTransferTimeStamp;

              json.destroyed = data.destroyed;

              json.owner = data.owner;

              if (data.owner === this.address) {
                json.isOwner = true;
              } else {
                json.isOwner = false;
              }

              if (!data.events) { json.events = []; } else {
                json.events = data.events;
              }

              if (data.whitelist === true || data.whitelist === undefined) { json.whitelist = true; } else { json.whitelist = false; }

              return json;
              // return { id, ...data };
            });
          })).subscribe((data) => {
          this.calculateData(data);
          loader.dismiss();
        });
    }

    calculateData (data) {
      var tmpData = {};
      var tmpDataCumulative = {};
      var tmpDataCumulativeCounter = 0;

      var tmpTransferData = {};
      var tmpTransferDataCumulative = {};
      var tmpTransferDataCumulativeCounter = 0;

      var optoutCounter = 0;

      data.forEach((item: any, idx: any, dataArray: any) => {
        // Only real certificates
        if (!item.destroyed) {
          var datum = new Date(Date.UTC(item.mintDate.getUTCFullYear(), item.mintDate.getUTCMonth(), item.mintDate.getUTCDate(), 0, 0, 0));
          var timestamp = datum.getTime();

          if (item.whitelist === false) { optoutCounter++; }

          if (!tmpData[timestamp]) {
            tmpData[timestamp] = 1;
          } else {
            tmpData[timestamp]++;
          }

          tmpDataCumulativeCounter++;

          tmpDataCumulative[timestamp] = tmpDataCumulativeCounter;
        }
      });
      var nowTimeStamp = new Date().valueOf();

      tmpDataCumulative[nowTimeStamp] = tmpDataCumulativeCounter;
      tmpData[nowTimeStamp] = 0;

      data.sort((a, b) => (a.firstTransferTimeStamp > b.firstTransferTimeStamp || a.firstTransferTimeStamp === undefined) ? 1 : -1);

      data.forEach((item: any, idx: any, dataArray: any) => {
        // Only real certificates
        if (!item.destroyed) {
          if (item.firstTransfer) {
            tmpTransferDataCumulativeCounter++;

            var firstTransferDate = new Date(Date.UTC(item.firstTransferDate.getUTCFullYear(), item.firstTransferDate.getUTCMonth(), item.firstTransferDate.getUTCDate(), 0, 0, 0));
            var firstTimestamp = firstTransferDate.getTime();

            if (!tmpTransferData[firstTimestamp]) {
              tmpTransferData[firstTimestamp] = 1;
            } else {
              tmpTransferData[firstTimestamp]++;
            }

            tmpTransferDataCumulative[firstTimestamp] = tmpTransferDataCumulativeCounter;
          }
        }
      });

      tmpTransferDataCumulative[nowTimeStamp] = tmpTransferDataCumulativeCounter;
      tmpTransferData[nowTimeStamp] = 0;

      let prop;

      for (prop in tmpData) {
        this.barChartData2[0].data.push({ x: new Date(parseInt(prop)), y: tmpData[prop] });
        this.barChartData2[1].data.push({ x: new Date(parseInt(prop)), y: tmpDataCumulative[prop] });
      }

      const ordered = {};
      Object.keys(tmpTransferData).sort().forEach(function (key) {
        ordered[key] = tmpTransferData[key];
      });

      tmpTransferData = ordered;

      for (prop in tmpTransferData) {
        this.transferChartData2[0].data.push({ x: new Date(parseInt(prop)), y: tmpTransferData[prop] });
        this.transferChartData2[1].data.push({ x: new Date(parseInt(prop)), y: tmpTransferDataCumulative[prop] });
      }

      this.totalFirstTransfer = tmpTransferDataCumulativeCounter;

      this.barChartData = this.barChartData2;
      this.transferChartData = this.transferChartData2;
    }

    extractAsCsvFile = async (fileType: 'csv') => {
      const loader = await this.loaderService.load();
      try {
        await this.certificateService.getReportInCsv();
        loader.dismiss();
      } catch (e) {
        console.error(e);
        loader.dismiss();
        this.loaderService.error('an error occured. Please contact us');
      }
    };
}
