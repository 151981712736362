import * as tslib_1 from "tslib";
import { AngularFireAuth } from '@angular/fire/auth';
import { ArianeejsAPIService } from '../../apiServices/arianeejsAPIService';
import { ContentAPIService } from '../../apiServices/contentAPIService';
import * as i0 from "@angular/core";
import * as i1 from "../../apiServices/arianeejsAPIService";
import * as i2 from "@angular/fire/auth";
import * as i3 from "../../apiServices/contentAPIService";
var ArianeeEventService = /** @class */ (function () {
    function ArianeeEventService(arianeejsServerAPIService, _firebaseAuth, contentService) {
        this.arianeejsServerAPIService = arianeejsServerAPIService;
        this._firebaseAuth = _firebaseAuth;
        this.contentService = contentService;
    }
    ArianeeEventService.prototype.createEvent = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var arianeeEventId;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.arianeejsServerAPIService.createAndStoreArianeeEvent(event).toPromise()];
                    case 1:
                        arianeeEventId = (_a.sent()).arianeeEventId;
                        return [2 /*return*/, {
                                arianeeEventId: arianeeEventId
                            }];
                }
            });
        });
    };
    ArianeeEventService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ArianeeEventService_Factory() { return new ArianeeEventService(i0.ɵɵinject(i1.ArianeejsAPIService), i0.ɵɵinject(i2.AngularFireAuth), i0.ɵɵinject(i3.ContentAPIService)); }, token: ArianeeEventService, providedIn: "root" });
    return ArianeeEventService;
}());
export { ArianeeEventService };
