import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { ModalController } from '@ionic/angular';
import { AuthService } from '../../services/auth/auth';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IsAuthGuard implements CanActivate {
  constructor (private authService: AuthService, private router:Router) {
  }

  public canActivate (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.$userState
      .pipe(
        map(d => d.isLoggedIn),
        tap(isLoggedIn => {
          if (!isLoggedIn) {
            this.router.navigate(['/login'], {
              queryParams: {
                returnView: state.url
              }
            });
          }
        })
      );
  }
}
