import * as tslib_1 from "tslib";
import { LoadingController } from '@ionic/angular';
import { Utils } from '../../services/utils/utils';
import { AuthService } from '../../services/auth/auth';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { CertificateCoreService } from '../../services/certificate-service/certificate-core.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { take } from 'rxjs/operators';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';
import { CertificateService, EconomyService, WalletService } from '../../apiServices/BDHApi';
import { WalletCoreService } from '../../services/walletCoreService/walletCoreService';
import { MainnetAPIService } from '../../apiServices/mainnetAPIServices';
import BN from 'bn.js';
var Settings = /** @class */ (function () {
    function Settings(utils, authService, loadingCtrl, uIAlertService, certificateCoreService, certificateService, _firebaseAuth, eventLogger, economyService, walletService, walletCoreService, mainnetAPIService) {
        var _this = this;
        this.utils = utils;
        this.authService = authService;
        this.loadingCtrl = loadingCtrl;
        this.uIAlertService = uIAlertService;
        this.certificateCoreService = certificateCoreService;
        this.certificateService = certificateService;
        this._firebaseAuth = _firebaseAuth;
        this.eventLogger = eventLogger;
        this.economyService = economyService;
        this.walletService = walletService;
        this.walletCoreService = walletCoreService;
        this.mainnetAPIService = mainnetAPIService;
        this.address = '';
        this.ariaBalance = '0';
        this.aria20Balance = '0';
        this.AriaAllowanceStore = false;
        this.credit = [];
        this.creditToSet = [];
        this.totalStaked = '0';
        this.subscribe = {};
        this.WUNIT = 1000000000000000000;
        this.reservedTokensBalance = 0;
        this.reserveRangeOfTokens = function (rangeOfTokenIds) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var areAvailaible, loader, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(rangeOfTokenIds.length > 1000)) return [3 /*break*/, 1];
                        this.uIAlertService.error('You cannot reserve more than 1000 ids at the same time');
                        return [3 /*break*/, 8];
                    case 1: return [4 /*yield*/, this.checkReserveAvailability(rangeOfTokenIds)];
                    case 2:
                        areAvailaible = _a.sent();
                        if (!(areAvailaible.notAvailablesIds.length === 0)) return [3 /*break*/, 8];
                        return [4 /*yield*/, this.uIAlertService.load()];
                    case 3:
                        loader = _a.sent();
                        _a.label = 4;
                    case 4:
                        _a.trys.push([4, 6, , 7]);
                        return [4 /*yield*/, this.certificateService.reserveRange({ range: rangeOfTokenIds }).toPromise()];
                    case 5:
                        _a.sent();
                        this.uIAlertService.success('Your reservations are on its way');
                        return [3 /*break*/, 7];
                    case 6:
                        e_1 = _a.sent();
                        console.error(e_1);
                        this.uIAlertService.error('An error occured. Please check your credit. You cannot reserve more than 1000 ids and you should have enough credits.');
                        return [3 /*break*/, 7];
                    case 7:
                        loader.dismiss();
                        _a.label = 8;
                    case 8: return [2 /*return*/];
                }
            });
        }); };
        this.reserveNRandomTokens = function (numberOfCertificateId) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var loader;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.uIAlertService.load({ message: "Reserving " + numberOfCertificateId + " certificates" })];
                    case 1:
                        loader = _a.sent();
                        this.certificateService.reserveBatch({ numberOfReservations: numberOfCertificateId }).subscribe(function (data) {
                            loader.dismiss();
                            if (data.code !== 'inProgress') {
                                var loading = _this.uIAlertService.alert({
                                    header: 'Reservation fail.',
                                    message: 'Too many reservation / not enough credits'
                                }, true);
                            }
                            else {
                                var loading = _this.uIAlertService.alert({
                                    header: 'Reservation sucess.'
                                }, true);
                            }
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        this.extractReservedAsCsvFile = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var loader, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.uIAlertService.load()];
                    case 1:
                        loader = _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.certificateCoreService.getReportReservedCertificatesInCsv()];
                    case 3:
                        _a.sent();
                        loader.dismiss();
                        return [3 /*break*/, 5];
                    case 4:
                        e_2 = _a.sent();
                        console.error(e_2);
                        loader.dismiss();
                        this.uIAlertService.error('an error occured. Please contact us');
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        this.aria = { quantity: 0, to: '' };
        this.aria20 = { quantity: 0, to: '' };
    }
    Settings.prototype.ionViewDidEnter = function () {
        var _this = this;
        this.eventLogger.logScreen('settings-page');
        this.loadInfo();
        this.economyService.ariaUSDExchange().subscribe(function (data) {
            _this.ariaPrice = (Math.pow(10, 18) / parseFloat(data) / 100).toFixed(4);
        });
        this.economyService.getPastEvents([
            'NewAriaUSDExchange',
            { fromBlock: 0, toBlock: 'latest' }
        ]).subscribe(function (data) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var blockData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.walletService.getBlock([data[data.length - 1].blockNumber]).toPromise()];
                    case 1:
                        blockData = _a.sent();
                        this.lastPriceUpdate = blockData.timestamp;
                        return [2 /*return*/];
                }
            });
        }); });
    };
    Settings.prototype.copyToClipboard = function (item) {
        document.addEventListener('copy', function (e) {
            e.clipboardData.setData('text/plain', (item));
            e.preventDefault();
            document.removeEventListener('copy', null);
        });
        document.execCommand('copy');
        this.utils.success('Your address has been successfully copied to your clipboard');
    };
    Settings.prototype.loadBalance = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, balanceOfAria, ariaTokenName, balanceOfPoa;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            this.economyService.balanceOfAria().toPromise(),
                            this.economyService.name().toPromise(),
                            this.economyService.balanceOfPoa().toPromise()
                        ])];
                    case 1:
                        _a = _b.sent(), balanceOfAria = _a[0], ariaTokenName = _a[1], balanceOfPoa = _a[2];
                        this.balance = +balanceOfPoa / this.WUNIT;
                        this.ariaBalance = Math.round(parseInt(balanceOfAria) / this.WUNIT * 1000) / 1000;
                        this.ariaTokenName = ariaTokenName;
                        this.loadStacking();
                        return [2 /*return*/];
                }
            });
        });
    };
    Settings.prototype.loadETHBalance = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, ethbalance, balanceOfAria20;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            this.mainnetAPIService.getBalance().toPromise(),
                            this.mainnetAPIService.aria20Balance(this.address).toPromise()
                        ])];
                    case 1:
                        _a = _b.sent(), ethbalance = _a[0], balanceOfAria20 = _a[1];
                        this.ethbalance = +ethbalance / this.WUNIT;
                        this.aria20Balance = Math.round(parseInt(balanceOfAria20.message) / this.WUNIT * 1000) / 1000;
                        return [2 /*return*/];
                }
            });
        });
    };
    Settings.prototype.loadCreditBalance = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.certificateCoreService.getReservedCertificate()
                            .pipe(take(1))
                            .subscribe(function (value) {
                            _this.reservedTokensBalance = value.count;
                        });
                        _a = this;
                        return [4 /*yield*/, Promise.all([
                                this.economyService.balanceOfCredit(['certificate']).toPromise().catch(function (d) { return 'N/A'; }),
                                this.economyService.balanceOfCredit(['message']).toPromise().catch(function (d) { return 'N/A'; }),
                                this.economyService.balanceOfCredit(['event']).toPromise().catch(function (d) { return 'N/A'; }),
                                this.economyService.balanceOfCredit(['update']).toPromise().catch(function (d) { return 'N/A'; })
                            ])];
                    case 1:
                        _a.credit = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Settings.prototype.loadAllowance = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, walletAdress, contractAddress, isAllowed;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            this.walletCoreService.getAddress(),
                            this.walletCoreService.getContractAddress()
                        ])];
                    case 1:
                        _a = _b.sent(), walletAdress = _a[0], contractAddress = _a[1];
                        return [4 /*yield*/, this.economyService.allowance([walletAdress, contractAddress.contractAdresses.store]).toPromise()];
                    case 2:
                        isAllowed = _b.sent();
                        if (parseInt(isAllowed, 10) > 0) {
                            this.AriaAllowanceStore = true;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    Settings.prototype.loadStacking = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var stacking;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.economyService.totalStakedFor([this.address]).toPromise()];
                    case 1:
                        stacking = _a.sent();
                        this.totalStaked = Math.round((parseInt(stacking) / this.WUNIT) * 1000) / 1000;
                        return [2 /*return*/];
                }
            });
        });
    };
    Settings.prototype.loadInfo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var loader, _a, e_3;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.uIAlertService.load()];
                    case 1:
                        loader = _b.sent();
                        _a = this;
                        return [4 /*yield*/, this.walletCoreService.getAddress()];
                    case 2:
                        _a.address = _b.sent();
                        _b.label = 3;
                    case 3:
                        _b.trys.push([3, 5, , 6]);
                        return [4 /*yield*/, Promise.all([
                                this.loadCreditBalance(),
                                this.loadBalance(),
                                this.loadETHBalance(),
                                this.loadAllowance()
                            ])];
                    case 4:
                        _b.sent();
                        return [3 /*break*/, 6];
                    case 5:
                        e_3 = _b.sent();
                        this.uIAlertService.error('Something went wrong fetching your data');
                        console.error(e_3);
                        return [3 /*break*/, 6];
                    case 6:
                        loader.dismiss();
                        return [2 /*return*/];
                }
            });
        });
    };
    Settings.prototype.setApprove = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var contractAddress;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.waiting();
                        return [4 /*yield*/, this.walletCoreService.getContractAddress()];
                    case 1:
                        contractAddress = _a.sent();
                        return [4 /*yield*/, this.economyService.approve([contractAddress.contractAdresses.store, '10000000000000000000000000000'])
                                .toPromise()];
                    case 2:
                        _a.sent();
                        this.loading.dismiss();
                        return [2 /*return*/];
                }
            });
        });
    };
    Settings.prototype.setApproveStaking = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var contractAddress;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.waiting();
                        return [4 /*yield*/, this.walletCoreService.getContractAddress()];
                    case 1:
                        contractAddress = _a.sent();
                        return [4 /*yield*/, this.economyService.approve([contractAddress.contractAdresses.staking, '10000000000000000000000000000'])
                                .toPromise()];
                    case 2:
                        _a.sent();
                        this.loading.dismiss();
                        return [2 /*return*/];
                }
            });
        });
    };
    Settings.prototype.transferAria = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userAmount, multiply, amount;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.aria.to || !this.aria.quantity) {
                            alert('all fields must be filled');
                            return [2 /*return*/];
                        }
                        this.waiting();
                        userAmount = new BN(this.aria.quantity);
                        multiply = new BN(this.WUNIT.toString());
                        amount = userAmount.mul(multiply).toString();
                        return [4 /*yield*/, this.economyService.transfer([this.aria.to, amount]).toPromise()];
                    case 1:
                        _a.sent();
                        this.loading.dismiss();
                        return [2 /*return*/];
                }
            });
        });
    };
    Settings.prototype.transferAria20 = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userAmount, multiply, amount;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.aria20.to || !this.aria20.quantity) {
                            alert('all fields must be filled');
                            return [2 /*return*/];
                        }
                        this.waiting();
                        userAmount = new BN(this.aria20.quantity);
                        multiply = new BN(this.WUNIT.toString());
                        amount = userAmount.mul(multiply).toString();
                        return [4 /*yield*/, this.mainnetAPIService.aria20Transfer(this.aria20.to, amount).toPromise()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.loadETHBalance()];
                    case 2:
                        _a.sent();
                        this.loading.dismiss();
                        return [2 /*return*/];
                }
            });
        });
    };
    Settings.prototype.stake = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.waiting();
                        return [4 /*yield*/, this.economyService.stake([(this.aria.quantityStake * 1000000000000000000).toString(), this.address]).toPromise()];
                    case 1:
                        _a.sent();
                        this.aria.quantityStake = 0;
                        this.loading.dismiss();
                        return [2 /*return*/];
                }
            });
        });
    };
    Settings.prototype.buyCredit = function (type, quantity) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.waiting();
                        return [4 /*yield*/, this.economyService.buyCredits([type, quantity]).toPromise()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.uIAlertService.alert({
                                cssClass: 'e2e_buy_credit_success',
                                message: 'Success buying credits.'
                            })];
                    case 2:
                        _a.sent();
                        this.loading.dismiss();
                        return [2 /*return*/];
                }
            });
        });
    };
    Settings.prototype.waiting = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loadingCtrl.create({
                                spinner: 'dots',
                                message: 'waiting'
                            })];
                    case 1:
                        _a.loading = _b.sent();
                        this.loading.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Check if certificateId is availaible for reservation (no owner)
     * @param {number[]} tokensId
     * @returns {Promise<{availableIds: any[]; notAvailablesIds: any[]}>}
     */
    Settings.prototype.checkReserveAvailability = function (tokensId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var loading, result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.uIAlertService.load({ message: 'Checking Arianee IDs availabilities' })];
                    case 1:
                        loading = _a.sent();
                        return [4 /*yield*/, this.certificateCoreService.certificateIdsAvailibility(tokensId, false)];
                    case 2:
                        result = _a.sent();
                        loading.dismiss();
                        if (result.notAvailablesIds.length > 0) {
                            this.uIAlertService.alert({
                                message: "Impossible to reserve the range you have chosen.\n                Some certificateIds are already used. Try again with range with only free certificateIds.\n                Already used certificateIds: " + result.notAvailablesIds.join(' | ')
                            });
                        }
                        return [2 /*return*/, result];
                }
            });
        });
    };
    return Settings;
}());
export { Settings };
