<ion-header>
    <div color="light" class="header">
        <div>
            <ion-menu-button></ion-menu-button>
            <ion-title><a (click)="navigateBack()"><ion-icon name="chevron-back-outline"></ion-icon></a></ion-title>
            <ion-title>Event Builder</ion-title>
        </div>
    </div>
</ion-header>

<ion-content class="item text-center" data-e2e="create_arianee_event_page">
    <ion-card>
        <ion-card-content>
            <h2>You are creating an event for {{item?.name}} certificateId (arianeeId) : {{itemId}})</h2>
        </ion-card-content>
    </ion-card>
    <schema-builder
            assetType="event"
            (onSubmit)="saveEvent($event)"
            [schemaUrls]="['https://cert.arianee.org/version1/ArianeeEvent-i18n.json']"
    >
    <ion-item>
        <ion-label>Accept event automatically</ion-label>
        <ion-toggle checked="true"  [(ngModel)]="autoAccept" name="autoAccept" slot="start"></ion-toggle>
    </ion-item>

</schema-builder>

</ion-content>
