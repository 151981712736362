export const widgetEventi18n = {
  $id: 'https://cert.arianee.org/version1/ArianeeEvent-i18n.json',
  $schema: 'https://cert.arianee.org/version1/ArianeeEvent-i18n.json',
  properties: {
    externalContents: {
      required: false,
      type: 'array',
      title: 'External Contents',
      description: 'This field is designed to store the links to external contents the Event issuer whish to introduce to the end customer in a wallet app.\n Specific external contents can be stored for each language.',
      items: {
        type: 'object',
        properties: {
          order: {
            widget: 'hidden'
          }
        }
      }
    },
    i18n: {
      items: {
        type: 'object',
        properties: {
          externalContents: {
            items: {
              type: 'object',
              properties: {
                order: {
                  widget: 'hidden'
                }
              }
            }
          }
        }
      }
    },
    medias: {
      items: {
        properties: {
          hash: {
            widget: 'hidden'
          },
          order: {
            widget: 'hidden'
          }
        }
      }
    }
  }
};
