<ion-header>
    <div color="light" class="header">
        <div>
            <ion-menu-button></ion-menu-button>
            <ion-title>{{ 'pages.brandIdentity.title' | translate }}</ion-title>
        </div>
    </div>
</ion-header>

<ion-content class="item text-center" data-e2e="page-identity">
    <div class="contents" >

      <ion-card *ngIf="addressApproved">
        <ion-card-content>
          Your address is approved by Arianee
        </ion-card-content>
      </ion-card >

      <ion-card *ngIf="!addressApproved">
          <ion-card-content>
            Your address is <b>Not</b> approved by Arianee
          </ion-card-content>
      </ion-card >

        <ion-grid *ngIf="addressApproved" >
            <ion-row>

              <ion-col size="4">
                    <div class="form">
                      <legend>1. Brand Identity (edit)</legend>
                        <schema-builder
                                *ngIf="model"
                                [documentModel]="model"
                                (onSubmit)="setNewIdentity($event)"
                                [schemaUrls]="['https://cert.arianee.org/version1/ArianeeBrandIdentity-i18n.json']"
                        >
                        </schema-builder>
                    </div>
              </ion-col>

              <ion-col size="4">
                    <div class="form view">
                        <legend>2. Brand Identity to validate</legend>
                        <div *ngIf="!waitingIdentity">
                            Brand Identity to validate does not exist
                          </div>
                        <schema-builder
                                [showButtons]="{copy:true,create:false,paste:false}"
                                *ngIf="waitingIdentity"
                                [documentModel]="waitingIdentity"
                                [schemaUrls]="['https://cert.arianee.org/version1/ArianeeBrandIdentity-i18n.json']"
                        >
                            <ion-button *ngIf="waitingIdentity" (click)="copyIdentity(waitingIdentity)" color="primary">Use as template</ion-button>
                        </schema-builder>
                    </div>
              </ion-col>

              <ion-col size="4">
                      <div class="form view">
                          <legend>3. Brand Identity validated</legend>
                            <div *ngIf="!identity">
                              Brand Identity not validated
                            </div>

                          <schema-builder
                                  [showButtons]="{copy:true,create:false,paste:false}"
                                  *ngIf="identity"
                                  [documentModel]="identity"
                                  [schemaUrls]="['https://cert.arianee.org/version1/ArianeeBrandIdentity-i18n.json']"
                          >
                              <ion-button data-e2e="use-as-template-Validated-Identity" (click)="copyIdentity(identity)" color="primary">Use as template</ion-button>
                          </schema-builder>
                      </div>
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>
</ion-content>
