<input *ngIf="this.schema.widget.id ==='hidden'; else notHiddenFieldBlock"
       [attr.name]="name" type="hidden" [formControl]="control">
<ng-template #notHiddenFieldBlock>
    <div class="widget form-group static-asset">
        <label [attr.for]="id" class="horizontal control-label">
            {{ schema.title }}
        </label>
        <span *ngIf="schema.description" class="formHelp">{{schema.description}}</span>
        <input [name]="name" [attr.readonly]="(schema.widget.id!=='color') && schema.readOnly?true:null"
               class="text-widget.id textline-widget form-control"
               [attr.type]="!this.schema.widget.id || this.schema.widget.id === 'string' ? 'text' : this.schema.widget.id"
               [attr.id]="id" [formControl]="control" [attr.placeholder]="schema.placeholder"
               [attr.maxLength]="schema.maxLength || null"
               [attr.minLength]="schema.minLength || null"
               [attr.required]="schema.isRequired || null"
               [attr.disabled]="(schema.widget.id=='color' && schema.readOnly)?true:null"
               style="width:85%;  display:inline">
        <input *ngIf="(schema.widget.id==='color' && schema.readOnly)" [attr.name]="name" type="hidden"
               [formControl]="control" style="width:80%; display:inline">
        <ion-button size="small" color="primary" (click)="presentUploadAssetsModal()">
            <ion-icon slot="icon-only" name="ellipsis-horizontal-outline"></ion-icon>
        </ion-button>
        <img [src]="formProperty.value" style="width:100px" *ngIf="formProperty.value">

    </div>
</ng-template>
<ion-icon></ion-icon>
