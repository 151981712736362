<ion-header>
  <div color="light" class="header">

      <div>
          <ion-menu-button></ion-menu-button>
          <ion-title>{{ 'pages.blockchainTransactions.title' | translate }}</ion-title>
          <ion-button [fill]="filterStatus !=='waiting'?'outline':'solid'" (click)="displayFilter(transactionStatus.waiting)">Waiting</ion-button>
          <ion-button [fill]="filterStatus !=='pending'?'outline':'solid'" (click)="displayFilter(transactionStatus.pending)">Pending</ion-button>
          <ion-button [fill]="filterStatus !=='minted'?'outline':'solid'" (click)="displayFilter(transactionStatus.minted)">Minted</ion-button>
          <ion-button [fill]="filterStatus !=='error'?'outline':'solid'" (click)="displayFilter(transactionStatus.error)">Error</ion-button>

        <!--<ion-button (click)="resendAllTransactions()">Resend All</ion-button>-->

      </div>



  </div>

</ion-header>
<ion-content class="wallet" data-e2e="transaction_page">
  <div class="contents" style="height:100%">
      <div class="ion-padding" *ngIf="items==undefined || items.length===0">No transactions with this status</div>
        <ion-virtual-scroll no-lines style="border-bottom:1px solid #eee" [items]="items" approxItemHeight='100px'>

            <ion-item-sliding *virtualItem="let item" #slidingItem>
                  <ion-item >
                        <ion-icon name="checkmark-circle-outline" slot="start" *ngIf="item.status=='minted'"></ion-icon>

                        <ion-icon name="cloud-upload-outline" slot="start"  *ngIf="item.status=='pending'"></ion-icon>

                        <ion-icon name="hourglass-outline" slot="start"  *ngIf="item.status=='waiting'"></ion-icon>

                        <ion-icon name="close-circle-outline" slot="start"  *ngIf="item.status=='error'" color="danger"></ion-icon>

                        <div >
                            <h4>{{item.contractName}}</h4>
                            <span [innerHTML]="item.call" class="call" data-e2e="call"></span>
                            <br/>
                            <ion-badge color="primary" >
                            Created <b data-e2e="created_at">{{item.createdAt | amTimeAgo }}</b>

                            <span *ngIf="item.userId"> by <b data-e2e="created_by">{{users[item.userId]}}</b></span>

                            </ion-badge>&nbsp;

                            <ion-badge color="grey" >nonce: #<span data-e2e="nonce">{{item.nonce}}</span></ion-badge>
                            &nbsp;
                            <ion-badge color="grey" *ngIf="item.receipt&&item.receipt.blockNumber">BlockNumber: #<span data-e2e="block_number">{{item.receipt.blockNumber}}</span></ion-badge>
                            &nbsp;
                            <ion-badge color="danger" *ngIf="item.receipt&&item.receipt.status==0">Reverted</ion-badge>


                            <br/>
                            <span *ngIf="item.transactionHash" class="call">TxHash: <a target="_blank" href="{{blockscoutBaseUrl}}{{item.transactionHash}}">{{item.transactionHash}}</a></span>

                            <ion-button *ngIf="item.status=='pending'"
                            (click)="resendTransaction(item._id)">
                                Resend
                            </ion-button>
                            <ion-button *ngIf="item.status=='error'"
                                        (click)="resetTransaction(item._id)">
                                Reset
                            </ion-button>
                        </div>
                </ion-item>
            </ion-item-sliding>


        </ion-virtual-scroll>
    </div>


</ion-content>

