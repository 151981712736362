/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i2 from "@ionic/angular";
import * as i3 from "@angular/forms";
import * as i4 from "../../components/schema-builder.ts/schema-builder.component.ngfactory";
import * as i5 from "../../components/schema-builder.ts/schema-builder.component";
import * as i6 from "../../services/utils/utils";
import * as i7 from "../../services/schema-service/schema-service";
import * as i8 from "../../apiServices/arianeejsAPIService";
import * as i9 from "@angular/common";
import * as i10 from "./autoMessages";
import * as i11 from "../../services/auth/auth";
import * as i12 from "../../services/UI-alert-service/UI-alert-service";
import * as i13 from "../../services/messageService/messageService";
import * as i14 from "../../services/event-logger/event-logger-service";
import * as i15 from "../../services/autoMessage-service/automessage.service";
var styles_AutoMessages = [];
var RenderType_AutoMessages = i0.ɵcrt({ encapsulation: 2, styles: styles_AutoMessages, data: {} });
export { RenderType_AutoMessages as RenderType_AutoMessages };
function View_AutoMessages_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 22, "ion-content", [["class", "item text-center"]], null, null, null, i1.View_IonContent_0, i1.RenderType_IonContent)), i0.ɵdid(1, 49152, null, 0, i2.IonContent, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 16, "ion-card", [], null, null, null, i1.View_IonCard_0, i1.RenderType_IonCard)), i0.ɵdid(3, 49152, null, 0, i2.IonCard, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(4, 0, null, 0, 14, "ion-card-content", [], null, null, null, i1.View_IonCardContent_0, i1.RenderType_IonCardContent)), i0.ɵdid(5, 49152, null, 0, i2.IonCardContent, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(6, 0, null, 0, 10, "ion-item", [], null, null, null, i1.View_IonItem_0, i1.RenderType_IonItem)), i0.ɵdid(7, 49152, null, 0, i2.IonItem, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(8, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Enable autoMessaging on each transfer"])), (_l()(), i0.ɵeld(10, 0, null, 0, 6, "ion-toggle", [["checked", "message.enable"], ["color", "primary"], ["cols", "80"], ["name", "enable"], ["rows", "5"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "ionBlur"], [null, "ionChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i0.ɵnov(_v, 12)._handleBlurEvent($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i0.ɵnov(_v, 12)._handleIonChange($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.message.enable = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_IonToggle_0, i1.RenderType_IonToggle)), i0.ɵdid(11, 49152, null, 0, i2.IonToggle, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], { checked: [0, "checked"], color: [1, "color"], name: [2, "name"] }, null), i0.ɵdid(12, 16384, null, 0, i2.BooleanValueAccessor, [i0.ElementRef], null, null), i0.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.BooleanValueAccessor]), i0.ɵdid(14, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i0.ɵdid(16, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i0.ɵeld(17, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["A message will be automatically sent to the new owner after each transfer."])), (_l()(), i0.ɵeld(19, 0, null, 0, 3, "schema-builder", [["assetType", "message"]], null, [[null, "onSubmit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSubmit" === en)) {
        var pd_0 = (_co.updateAutoMessages($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_SchemaBuilderComponent_0, i4.RenderType_SchemaBuilderComponent)), i0.ɵdid(20, 8437760, null, 0, i5.SchemaBuilderComponent, [i6.Utils, i0.Renderer2, i7.SchemaService, i0.ElementRef, i8.ArianeejsAPIService], { assetType: [0, "assetType"], documentModel: [1, "documentModel"], buttonLabels: [2, "buttonLabels"], schemaUrls: [3, "schemaUrls"] }, { onSubmitEmitter: "onSubmit" }), i0.ɵpod(21, { "create": 0 }), i0.ɵpad(22, 1)], function (_ck, _v) { var _co = _v.component; var currVal_7 = "message.enable"; var currVal_8 = "primary"; var currVal_9 = "enable"; _ck(_v, 11, 0, currVal_7, currVal_8, currVal_9); var currVal_10 = "enable"; var currVal_11 = _co.message.enable; _ck(_v, 14, 0, currVal_10, currVal_11); var currVal_12 = "message"; var currVal_13 = _co.message.content; var currVal_14 = _ck(_v, 21, 0, "Update"); var currVal_15 = _ck(_v, 22, 0, "https://cert.arianee.org/version1/ArianeeMessage-i18n.json"); _ck(_v, 20, 0, currVal_12, currVal_13, currVal_14, currVal_15); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 16).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 16).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 16).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 16).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 16).ngClassValid; var currVal_5 = i0.ɵnov(_v, 16).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 16).ngClassPending; _ck(_v, 10, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_AutoMessages_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "ion-header", [], null, null, null, i1.View_IonHeader_0, i1.RenderType_IonHeader)), i0.ɵdid(1, 49152, null, 0, i2.IonHeader, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 6, "div", [["class", "header"], ["color", "light"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "ion-menu-button", [], null, null, null, i1.View_IonMenuButton_0, i1.RenderType_IonMenuButton)), i0.ɵdid(5, 49152, null, 0, i2.IonMenuButton, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 2, "ion-title", [], null, null, null, i1.View_IonTitle_0, i1.RenderType_IonTitle)), i0.ɵdid(7, 49152, null, 0, i2.IonTitle, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵted(-1, 0, ["Auto Message"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AutoMessages_1)), i0.ɵdid(10, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.schema; _ck(_v, 10, 0, currVal_0); }, null); }
export function View_AutoMessages_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "page-autoMessages", [], null, null, null, View_AutoMessages_0, RenderType_AutoMessages)), i0.ɵdid(1, 49152, null, 0, i10.AutoMessages, [i6.Utils, i11.AuthService, i2.LoadingController, i12.UIAlertService, i13.MessageService, i14.EventLoggerService, i15.AutoMessageService], null, null)], null, null); }
var AutoMessagesNgFactory = i0.ɵccf("page-autoMessages", i10.AutoMessages, View_AutoMessages_Host_0, {}, {}, []);
export { AutoMessagesNgFactory as AutoMessagesNgFactory };
