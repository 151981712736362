/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i2 from "@ionic/angular";
import * as i3 from "../../wallet-component/wallet.ngfactory";
import * as i4 from "../../wallet-component/wallet";
import * as i5 from "./wallet";
var styles_WalletWidgetComponent = [];
var RenderType_WalletWidgetComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_WalletWidgetComponent, data: {} });
export { RenderType_WalletWidgetComponent as RenderType_WalletWidgetComponent };
export function View_WalletWidgetComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "ion-content", [], null, null, null, i1.View_IonContent_0, i1.RenderType_IonContent)), i0.ɵdid(1, 49152, null, 0, i2.IonContent, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "component-wallet", [], null, [[null, "onItemClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onItemClick" === en)) {
        var pd_0 = (_co.onInternalItemClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_WalletComponent_0, i3.RenderType_WalletComponent)), i0.ɵdid(3, 49152, null, 0, i4.WalletComponent, [], { certificateList: [0, "certificateList"], users: [1, "users"] }, { onItemClick: "onItemClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.certificateList; var currVal_1 = _co.users; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_WalletWidgetComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_WalletWidgetComponent_0, RenderType_WalletWidgetComponent)), i0.ɵdid(1, 49152, null, 0, i5.WalletWidgetComponent, [i2.ModalController], null, null)], null, null); }
var WalletWidgetComponentNgFactory = i0.ɵccf("ng-component", i5.WalletWidgetComponent, View_WalletWidgetComponent_Host_0, { certificateList: "certificateList", users: "users" }, {}, []);
export { WalletWidgetComponentNgFactory as WalletWidgetComponentNgFactory };
