import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EndpointsService } from '../../apiServices/endpointsService/endpointsService';
import { FirebaseHttpClient } from '../firebaseHttpClient/firebaseHttpClient';

@Injectable({ providedIn: 'root' })
export class AutoMessageService {
  constructor (
               private endpointService: EndpointsService,
               private firebaseHttpClient: FirebaseHttpClient) {

  }

  getAutoMessage (type: string):Observable<any> {
    return this.firebaseHttpClient.get(this.endpointService.endpoints.autoMessage.list,
      {
        params: {
          q: JSON.stringify({
            type
          })
        }
      }
    ).pipe(
      map(d => d[0])
    );
  }

  createAutoMessage (autoMessage) {
    return this.firebaseHttpClient.post(this.endpointService.endpoints.autoMessage.create,
      autoMessage
    );
  }

  updateAutoMessage (messageId, autoMessage) {
    const url = this.endpointService.endpoints.autoMessage.update.replace(':id', messageId);
    return this.firebaseHttpClient.put(url,
      autoMessage
    );
  }
}
