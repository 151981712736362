import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as namespace from 'namespace';
import CertificateItem = namespace.CertificateItem;

@Component({
  template: `
        <ion-content>
            <component-wallet
                    [certificateList]="certificateList"
                    [users]="users"
                    (onItemClick)="onInternalItemClick($event)"
            ></component-wallet>
        </ion-content>
    `
})
export class WalletWidgetComponent {
  constructor (private modalController: ModalController) {

  }

    @Input('certificateList') certificateList: CertificateItem[];
    @Input('users') users: { [id: string]: string };

    onInternalItemClick (value) {
      this.modalController.dismiss(value);
    }
}
