import { FirebaseHttpClient } from '../firebaseHttpClient/firebaseHttpClient';
import { EndpointsService } from '../../apiServices/endpointsService/endpointsService';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../firebaseHttpClient/firebaseHttpClient";
import * as i2 from "../../apiServices/endpointsService/endpointsService";
var ProfileService = /** @class */ (function () {
    function ProfileService(firebaseHttpClient, endpointService) {
        var _this = this;
        this.firebaseHttpClient = firebaseHttpClient;
        this.endpointService = endpointService;
        /**
         * Get list of users
         * @returns {Observable<ProfileModel[]>}
         */
        this.all = function () {
            return _this.firebaseHttpClient.get(_this.endpointService.endpoints.profile.list);
        };
        this.getProfile = function (name) {
            return _this.firebaseHttpClient.get(_this.endpointService.endpoints.profile.list, {
                params: {
                    q: JSON.stringify({ name: name })
                }
            })
                .pipe(map(function (d) { return d[0]; }));
        };
        this.create = function (name, rights) {
            return _this.firebaseHttpClient.post(_this.endpointService.endpoints.profile.create, { name: name, rights: rights });
        };
        this.delete = function (profileId) {
            var url = _this.endpointService.endpoints.profile.delete.replace(':id', profileId);
            return _this.firebaseHttpClient.delete(url);
        };
    }
    ProfileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProfileService_Factory() { return new ProfileService(i0.ɵɵinject(i1.FirebaseHttpClient), i0.ɵɵinject(i2.EndpointsService)); }, token: ProfileService, providedIn: "root" });
    return ProfileService;
}());
export { ProfileService };
