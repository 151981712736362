import { FEATURE_FLIP } from './feature-flip-enum';
import * as Config from '../../../config';
import * as i0 from "@angular/core";
var FeatureFlipService = /** @class */ (function () {
    function FeatureFlipService() {
        this.ENUM = FEATURE_FLIP;
        this.feature = Config.config.featureFlips;
    }
    FeatureFlipService.prototype.isFeatureFlip = function (featureFlipName) {
        var isFeatureFlip = this.feature && this.feature[featureFlipName];
        return isFeatureFlip || false;
    };
    FeatureFlipService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FeatureFlipService_Factory() { return new FeatureFlipService(); }, token: FeatureFlipService, providedIn: "root" });
    return FeatureFlipService;
}());
export { FeatureFlipService };
