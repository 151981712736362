import * as tslib_1 from "tslib";
import { LoadingController, NavController } from '@ionic/angular';
import { Utils } from '../../services/utils/utils';
import { AuthService } from '../../services/auth/auth';
import { BehaviorSubject } from 'rxjs';
import { SchemaService } from '../../services/schema-service/schema-service';
import { debounceTime, map, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PaperCertService } from '../../services/paper-cert-service/paper-cert.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient } from '../../../../node_modules/@angular/common/http';
import { MessageService } from '../../services/messageService/messageService';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';
import { WalletCoreService } from '../../services/walletCoreService/walletCoreService';
import { config } from '../../../config';
import { certificateDBTOCertificateItem } from '../../helpers/certificateDBToCertificateItem';
import { CertificateCoreService } from '../../services/certificate-service/certificate-core.service';
import { distinctUntilChangedArrayOfObjectChanged, fakeWebSocketObservable } from '../../helpers/fakeWebSocketObservable/fakeWebSocketObservable';
import { UsersService } from '../../services/users-service/userService';
/*
*
*  Wallet Page
*
*/
var WalletPage = /** @class */ (function () {
    function WalletPage(navCtrl, utils, loadingCtrl, authService, schemaService, router, paperCertService, http, _firebaseAuth, messageService, uiAlert, eventLogger, walletCoreService, certificateCoreService, usersService) {
        var _this = this;
        this.navCtrl = navCtrl;
        this.utils = utils;
        this.loadingCtrl = loadingCtrl;
        this.authService = authService;
        this.schemaService = schemaService;
        this.router = router;
        this.paperCertService = paperCertService;
        this.http = http;
        this._firebaseAuth = _firebaseAuth;
        this.messageService = messageService;
        this.uiAlert = uiAlert;
        this.eventLogger = eventLogger;
        this.walletCoreService = walletCoreService;
        this.certificateCoreService = certificateCoreService;
        this.usersService = usersService;
        this.certificateList = [];
        this.stillOwner = false;
        this.ownership = 'na';
        this.filter = false;
        this.message = {};
        this.optout = 'na';
        this.isLostOrStolen = 'na';
        this.isParentCertificate = 'na';
        this.users = {};
        this.subscriptions = [];
        this.$searchQuery = new BehaviorSubject({});
        this.showLoader = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (!this.loading) {
                    this.loading = this.loadingCtrl.create({
                        spinner: 'dots',
                        message: 'Loading certificates'
                    });
                    this.loading.then(function (d) { return d.present(); });
                }
                return [2 /*return*/, this.loading];
            });
        }); };
        this.dismissLoader = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var loader;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.loading) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.loading];
                    case 1:
                        loader = _a.sent();
                        loader.dismiss();
                        this.loading = undefined;
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        this.load = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a, sub;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.walletCoreService.getAddress()];
                    case 1:
                        _a.address = _b.sent();
                        return [4 /*yield*/, this.showLoader()];
                    case 2:
                        _b.sent();
                        this.dismissLoader();
                        this.usersService.all()
                            .pipe(take(1))
                            .subscribe(function (data) {
                            var user;
                            for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
                                user = data_1[_i];
                                _this.users[user.userId] = user.email;
                            }
                        });
                        return [4 /*yield*/, this.filterItem()];
                    case 3:
                        _b.sent();
                        sub = this.$searchQuery
                            .pipe(debounceTime(500), switchMap(function (userQuery) {
                            var query = tslib_1.__assign({}, userQuery);
                            return fakeWebSocketObservable(_this.certificateCoreService.getCertificateList(query, { blockNumberCreation: -1 }, 200));
                        }), distinctUntilChangedArrayOfObjectChanged('tokenId'), map(function (data) {
                            return data // remove certificate reserved that does not have JSON.
                                .filter(function (d) { return _this.isCertificateYoungerThan(d, 48); })
                                .map(function (data) { return certificateDBTOCertificateItem(data, _this.address); })
                                .filter(function (d) { return d !== undefined; });
                        }))
                            .subscribe(function (data) {
                            _this.certificateList = data.sort(_this.orderByBlockNumber);
                            _this.dismissLoader();
                        });
                        this.subscriptions.push(sub);
                        return [2 /*return*/];
                }
            });
        }); };
        this.deepLinkCreator = function (arianeeId, passphrase) { return "" + _this.utils.deeplink() + arianeeId + "," + passphrase; };
        this.findSerialNumber = function (serialnumbers) {
            if (serialnumbers) {
                var serialNumber = serialnumbers
                    .filter(function (item) { return item !== null && item !== undefined; })
                    .find(function (item) { return item.type === 'serialnumber'; });
                if (serialNumber) {
                    return serialNumber.value;
                }
            }
        };
        this.ionViewWillLeave = function () {
            _this.subscriptions.forEach(function (sub) { return sub.unsubscribe(); });
        };
        this.utils.getPaperTplList().then(function (data) {
            _this.paperTplList = data;
            _this.selectedPaperTpl = _this.paperTplList[0];
        });
    }
    WalletPage.prototype.ionViewDidEnter = function () {
        var _this = this;
        this.eventLogger.logScreen('wallet-page');
        this.authService.$userState
            .pipe(take(1))
            .subscribe(function (state) {
            if (state.isLoggedIn) {
                _this.stillOwner = false;
                _this.load();
            }
            else {
                _this.navCtrl.navigateForward('login', { queryParams: { returnView: 'wallet' } });
            }
        });
    };
    WalletPage.prototype.checkLoading = function (that) {
        if (!that.arianeeProtocol.firstLoading) {
            setTimeout(that.checkLoading, 1000, that);
        }
        else {
            that.load();
            that.loading.dismiss();
        }
    };
    WalletPage.prototype.isCertificateYoungerThan = function (d, hour) {
        return (d.blockNumberCreation > 0 || (d.createdAt && d.createdAt.seconds > (Math.floor(Date.now() / 1000) - 60 * 60 * hour)));
    };
    WalletPage.prototype.orderByBlockNumber = function (a, b) {
        return (a.blockNumberCreation < b.blockNumberCreation) ? 1 : ((b.blockNumberCreation < a.blockNumberCreation) ? -1 : 0);
    };
    WalletPage.prototype.filterItem = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var deadAddress, ownerShipQuery, transferCompQuery, tagsQuery, optoutQuery, parentQuery, d;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        deadAddress = '0x000000000000000000000000000000000000dEaD';
                        ownerShipQuery = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var owner, address;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        owner = this.ownership;
                                        if (!(owner === 'na')) return [3 /*break*/, 1];
                                        return [2 /*return*/, {}];
                                    case 1: return [4 /*yield*/, this.walletCoreService.getAddress()];
                                    case 2:
                                        address = _a.sent();
                                        if (owner === 'me') {
                                            return [2 /*return*/, {
                                                    owner: address
                                                }];
                                        }
                                        else if (owner === 'other') {
                                            return [2 /*return*/, {
                                                    owner: { $ne: address }
                                                }];
                                        }
                                        else if (owner === 'destroyed') {
                                            return [2 /*return*/, {
                                                    owner: deadAddress
                                                }];
                                        }
                                        else if (owner === 'all') {
                                            return [2 /*return*/, {}];
                                        }
                                        _a.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); };
                        transferCompQuery = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var transferComp, numberOfTransfer;
                            return tslib_1.__generator(this, function (_a) {
                                transferComp = this.transferComp;
                                numberOfTransfer = this.transferNb;
                                if (numberOfTransfer === undefined || numberOfTransfer.length === 0) {
                                    return [2 /*return*/, {}];
                                }
                                if (transferComp === 'equal') {
                                    return [2 /*return*/, { numberOfTransfer: { $eq: numberOfTransfer } }];
                                }
                                else if (transferComp === 'more') {
                                    return [2 /*return*/, {
                                            numberOfTransfer: { $gt: numberOfTransfer }
                                        }];
                                }
                                else if (transferComp === 'less') {
                                    return [2 /*return*/, {
                                            numberOfTransfer: { $gt: numberOfTransfer }
                                        }];
                                }
                                return [2 /*return*/];
                            });
                        }); };
                        tagsQuery = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var tags;
                            return tslib_1.__generator(this, function (_a) {
                                tags = this.tags;
                                if (tags === undefined || tags.length === 0) {
                                    return [2 /*return*/, {}];
                                }
                                else {
                                    return [2 /*return*/, {
                                            tags: { $in: tags }
                                        }];
                                }
                                return [2 /*return*/];
                            });
                        }); };
                        optoutQuery = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var optout;
                            return tslib_1.__generator(this, function (_a) {
                                optout = this.optout;
                                if (optout === 'na') {
                                    return [2 /*return*/, {}];
                                }
                                else {
                                    if (optout === 'yes') {
                                        return [2 /*return*/, {
                                                whitelist: true
                                            }];
                                    }
                                    else if (optout === 'no') {
                                        return [2 /*return*/, {
                                                whitelist: false
                                            }];
                                    }
                                }
                                return [2 /*return*/];
                            });
                        }); };
                        parentQuery = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var parent, parentTag;
                            return tslib_1.__generator(this, function (_a) {
                                parent = this.isParentCertificate;
                                if (parent === 'na') {
                                    return [2 /*return*/, {}];
                                }
                                else {
                                    parentTag = "parentPassport" /* parentCertificate */;
                                    if (parent === 'yes') {
                                        return [2 /*return*/, {
                                                tags: parentTag
                                            }];
                                    }
                                    else if (parent === 'no') {
                                        return [2 /*return*/, {
                                                tags: { $ne: parentTag }
                                            }];
                                    }
                                }
                                return [2 /*return*/];
                            });
                        }); };
                        return [4 /*yield*/, Promise.all([
                                ownerShipQuery(),
                                transferCompQuery(),
                                tagsQuery(),
                                optoutQuery(),
                                parentQuery()
                            ])];
                    case 1:
                        d = (_a.sent()).reduce(function (acc, value) {
                            return tslib_1.__assign({}, acc, value);
                        }, {});
                        if (this.arianeeIdOrSerialNumber) {
                            d = {
                                $or: [
                                    { serialNumbers: this.arianeeIdOrSerialNumber },
                                    { tokenId: this.arianeeIdOrSerialNumber }
                                ]
                            };
                        }
                        this.$searchQuery.next(d);
                        return [2 /*return*/];
                }
            });
        });
    };
    WalletPage.prototype.gotoItem = function (itemId) {
        this.router.navigate(["/certificate/read/" + itemId]);
    };
    WalletPage.prototype.toggleFilter = function () {
        if (this.filter) {
            this.filter = false;
        }
        else {
            this.filter = true;
        }
    };
    WalletPage.prototype.toggleBatch = function () {
        if (this.batchProcess) {
            this.batchProcess = false;
        }
        else {
            this.batchProcess = true;
        }
    };
    WalletPage.prototype.duplicate = function (item, slidingItem) {
        slidingItem.close();
        return this.router.navigate(["/certificate/duplicate/" + item.id]);
    };
    WalletPage.prototype.printFilteredCertificates = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var certificateIds, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.selectedPaperTpl) return [3 /*break*/, 3];
                        certificateIds = this.certificateList.map(function (item) { return item.id; });
                        _a = this;
                        return [4 /*yield*/, this.loadingCtrl.create({
                                spinner: 'dots',
                                message: 'Generate PDF'
                            })];
                    case 1:
                        _a.loading = _b.sent();
                        this.loading.present();
                        return [4 /*yield*/, this.paperCertService.generatePaperCert(certificateIds, this.selectedPaperTpl.name)];
                    case 2:
                        _b.sent();
                        this.loading.dismiss();
                        this.toggleBatch();
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    WalletPage.prototype.dowloadFile = function (encodedUri, fileName) {
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', fileName);
        document.body.appendChild(link); // Required for FF
        link.click();
    };
    WalletPage.prototype.exportFilteredCertificatesInJSON = function () {
        var fileName = this.createExportFileName('json');
        var encodedUri = encodeURIComponent(JSON.stringify(this.prepareForExport(), null, 2));
        var jsonContent = "data:text/csv;charset=utf-8," + encodedUri;
        this.dowloadFile(jsonContent, fileName);
    };
    WalletPage.prototype.createExportFileName = function (ext) {
        return "export-bdh-" + new Date().toISOString() + "." + ext;
    };
    WalletPage.prototype.exportFilteredCertificatesInCSV = function () {
        var preparedData = this.prepareForExport();
        if (preparedData.length > 0) {
            var header_1 = Object.keys(preparedData[0]);
            var rows_1 = [header_1];
            preparedData
                .map(function (item) { return header_1.map(function (key) { return item[key]; }); })
                .forEach(function (i) { return rows_1.push(i); });
            var csvContent_1 = 'data:text/csv;charset=utf-8,';
            rows_1
                // escpae characters
                .map(function (line) { return line
                .map(function (content) { return content !== undefined ? content : ''; })
                .map(function (content) { return "\"" + encodeURIComponent(content) + "\""; }); })
                .forEach(function (rowArray) {
                var row = rowArray.join(',');
                csvContent_1 += row + '\r\n';
            });
            var fileName = this.createExportFileName('csv');
            this.dowloadFile(csvContent_1, fileName);
        }
    };
    WalletPage.prototype.navigateToSendDMessage = function () {
        this.router.navigate(['/certificate/message/create'], {
            queryParams: {
                certificateIds: this.certificateList
                    .map(function (d) { return d.id; })
                    .join(',')
            }
        });
    };
    WalletPage.prototype.prepareForExport = function () {
        var _this = this;
        return this.certificateList
            .map(function (item) {
            var eventOfTransfer = item.events.find(function (d) { return d.event === 'Transfer'; });
            var blockNumberOfCreation = eventOfTransfer && eventOfTransfer.blockNumber;
            var firstAccessDate = item._log_firstAccess
                ? new Date(item._log_firstAccess).toUTCString()
                : 'N/A';
            var lastAccessDate = item._log_lastAccess
                ? new Date(item._log_lastAccess).toUTCString()
                : 'N/A';
            var createdAt = item.createdAt
                ? new Date(item.createdAt).toUTCString()
                : 'N/A';
            var isDestroyed = item.owner
                ? item.owner.toLowerCase() === '0x000000000000000000000000000000000000dEaD'.toLowerCase()
                : 'N/A';
            return ({
                arianeeId: item.id,
                passphrase: item.viewKey,
                deeplink: _this.deepLinkCreator(item.id, item.viewKey),
                serialNumber: _this.findSerialNumber(item.serialnumber),
                name: item.name,
                chain: config.network,
                firstAccessDate: firstAccessDate,
                lastAccessDate: lastAccessDate,
                numberOfAccess: item._log_fetchedCount || 'N/A',
                currentOwner: item.owner || 'N/A',
                isDestroyed: isDestroyed,
                blockNumberOfCreation: blockNumberOfCreation,
                createdAt: createdAt
            });
        });
    };
    return WalletPage;
}());
export { WalletPage };
