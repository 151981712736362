/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i2 from "@ionic/angular";
import * as i3 from "@angular/common";
import * as i4 from "../../components/schema-builder.ts/schema-builder.component.ngfactory";
import * as i5 from "../../components/schema-builder.ts/schema-builder.component";
import * as i6 from "../../services/utils/utils";
import * as i7 from "../../services/schema-service/schema-service";
import * as i8 from "../../apiServices/arianeejsAPIService";
import * as i9 from "./message-builder.page";
import * as i10 from "@angular/common/http";
import * as i11 from "../../services/UI-alert-service/UI-alert-service";
import * as i12 from "../../services/ArianeeEventService/arianeeEventService";
import * as i13 from "@angular/router";
import * as i14 from "../../services/certificate-service/certificate-core.service";
import * as i15 from "../../services/messageService/messageService";
import * as i16 from "../../services/event-logger/event-logger-service";
import * as i17 from "../../apiServices/BDHApi/api/economy.service";
var styles_MessageBuilder = [];
var RenderType_MessageBuilder = i0.ɵcrt({ encapsulation: 2, styles: styles_MessageBuilder, data: {} });
export { RenderType_MessageBuilder as RenderType_MessageBuilder };
function View_MessageBuilder_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["certificate ID (arianee Id) : ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.itemId; _ck(_v, 1, 0, currVal_0); }); }
export function View_MessageBuilder_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "ion-header", [], null, null, null, i1.View_IonHeader_0, i1.RenderType_IonHeader)), i0.ɵdid(1, 49152, null, 0, i2.IonHeader, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 11, "div", [["class", "header"], ["color", "light"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "ion-menu-button", [], null, null, null, i1.View_IonMenuButton_0, i1.RenderType_IonMenuButton)), i0.ɵdid(5, 49152, null, 0, i2.IonMenuButton, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 4, "ion-title", [], null, null, null, i1.View_IonTitle_0, i1.RenderType_IonTitle)), i0.ɵdid(7, 49152, null, 0, i2.IonTitle, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(8, 0, null, 0, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "ion-icon", [["name", "chevron-back-outline"]], null, null, null, i1.View_IonIcon_0, i1.RenderType_IonIcon)), i0.ɵdid(10, 49152, null, 0, i2.IonIcon, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], { name: [0, "name"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 2, "ion-title", [], null, null, null, i1.View_IonTitle_0, i1.RenderType_IonTitle)), i0.ɵdid(12, 49152, null, 0, i2.IonTitle, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵted(-1, 0, ["Message Builder"])), (_l()(), i0.ɵeld(14, 0, null, null, 12, "ion-content", [["class", "item text-center"], ["data-e2e", "create_dmessage_page"]], null, null, null, i1.View_IonContent_0, i1.RenderType_IonContent)), i0.ɵdid(15, 49152, null, 0, i2.IonContent, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(16, 0, null, 0, 7, "ion-card", [], null, null, null, i1.View_IonCard_0, i1.RenderType_IonCard)), i0.ɵdid(17, 49152, null, 0, i2.IonCard, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(18, 0, null, 0, 5, "ion-card-content", [], null, null, null, i1.View_IonCardContent_0, i1.RenderType_IonCardContent)), i0.ɵdid(19, 49152, null, 0, i2.IonCardContent, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(20, 0, null, 0, 3, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(21, null, ["Creating DMessage for ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MessageBuilder_1)), i0.ɵdid(23, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(24, 0, null, 0, 2, "schema-builder", [["assetType", "message"]], null, [[null, "onSubmit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSubmit" === en)) {
        var pd_0 = (_co.sendMessage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_SchemaBuilderComponent_0, i4.RenderType_SchemaBuilderComponent)), i0.ɵdid(25, 8437760, null, 0, i5.SchemaBuilderComponent, [i6.Utils, i0.Renderer2, i7.SchemaService, i0.ElementRef, i8.ArianeejsAPIService], { assetType: [0, "assetType"], schemaUrls: [1, "schemaUrls"] }, { onSubmitEmitter: "onSubmit" }), i0.ɵpad(26, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "chevron-back-outline"; _ck(_v, 10, 0, currVal_0); var currVal_2 = _co.itemId; _ck(_v, 23, 0, currVal_2); var currVal_3 = "message"; var currVal_4 = _ck(_v, 26, 0, "https://cert.arianee.org/version1/ArianeeMessage-i18n.json"); _ck(_v, 25, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.item == null) ? null : _co.item.name); _ck(_v, 21, 0, currVal_1); }); }
export function View_MessageBuilder_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_MessageBuilder_0, RenderType_MessageBuilder)), i0.ɵdid(1, 49152, null, 0, i9.MessageBuilder, [i6.Utils, i10.HttpClient, i2.LoadingController, i11.UIAlertService, i12.ArianeeEventService, i13.ActivatedRoute, i14.CertificateCoreService, i13.Router, i15.MessageService, i3.Location, i16.EventLoggerService, i17.EconomyService], null, null)], null, null); }
var MessageBuilderNgFactory = i0.ɵccf("ng-component", i9.MessageBuilder, View_MessageBuilder_Host_0, {}, {}, []);
export { MessageBuilderNgFactory as MessageBuilderNgFactory };
