/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./arianeeLoaderComponent.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./arianeeLoaderComponent";
var styles_ArianeeLoaderComponent = [i0.styles];
var RenderType_ArianeeLoaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ArianeeLoaderComponent, data: {} });
export { RenderType_ArianeeLoaderComponent as RenderType_ArianeeLoaderComponent };
export function View_ArianeeLoaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "arianee-logo"]], null, null, null, null, null))], null, null); }
export function View_ArianeeLoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "arianee-loader", [], null, null, null, View_ArianeeLoaderComponent_0, RenderType_ArianeeLoaderComponent)), i1.ɵdid(1, 49152, null, 0, i2.ArianeeLoaderComponent, [], null, null)], null, null); }
var ArianeeLoaderComponentNgFactory = i1.ɵccf("arianee-loader", i2.ArianeeLoaderComponent, View_ArianeeLoaderComponent_Host_0, {}, {}, []);
export { ArianeeLoaderComponentNgFactory as ArianeeLoaderComponentNgFactory };
