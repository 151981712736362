<ion-card>
    <ion-card-content *ngIf="selectedSchemas">
        <div
                class="ion-padding-bottom"
                *ngIf="isOneOfList() && schemasSelectList.length > 1"
        >
            <ion-label>Please select schema :</ion-label>
            <select class="select"
                    data-e2e="select-schema"
                    (change)="schemaSelectChange($event.target.value)"
            >
                <option
                        *ngFor="let schemaItem of schemasSelectList"
                        [value]="schemaItem.id"
                        data-e2e="select-schema2"
                >
                    {{schemaItem.title}}
                </option>
            </select>
        </div>

        <sf-form [schema]="selectedSchemas"
                 [model]="documentModel"
                 (onChange)="onChange($event)"
        >
        </sf-form>
    </ion-card-content>
    <ng-content></ng-content>
    <ion-card-content>
        <ion-button
                *ngIf="showButtons.create"
                data-e2e="submit" text-center color="primary" (click)="onSubmit()">
            <ion-icon name="create-outline"></ion-icon>
            <span class="button__title">{{buttonLabels.create}}</span>
        </ion-button>
        <ion-button
                *ngIf="showButtons.copy"

                (click)="copy()"
                text-center color="primary">
            <ion-icon name="copy-outline"></ion-icon>
            <span class="button__title">{{buttonLabels.copy}}</span>
        </ion-button>
        <ion-button
                *ngIf="showButtons.paste"
                (click)="paste()"
                text-center color="primary">
            <ion-icon name="clipboard-outline"></ion-icon>
            <span class="button__title">{{buttonLabels.paste}}</span>

        </ion-button>
    </ion-card-content>
    <ion-card-content *ngIf="assetType">
        <a routerLink="/settings"><h3>{{assetType}} credit left: <span *ngIf="creditAsset">{{creditAsset}}</span></h3>
        </a>
    </ion-card-content>
</ion-card>
