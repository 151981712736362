import { IsEmail, IsOptional, IsString } from 'class-validator';

export class UserModel {
    @IsOptional()
    @IsString()
    userId:string;

    @IsString()
    profile:string;

    @IsEmail()
    email:string;

    @IsOptional()
    @IsString()
    apiKey:string;

    @IsOptional()
    @IsString()
    pubkey:string;

    @IsOptional()
    @IsString()
    deleted:boolean;
}
