import * as tslib_1 from "tslib";
import { StaticAssetsWidget } from './static-assets-widget/staticAssetsWidget';
import { DefaultWidgetRegistry } from 'ngx-schema-form';
import { ParentWidget } from './parent-widget/parent-widget';
var MyWidgetRegistry = /** @class */ (function (_super) {
    tslib_1.__extends(MyWidgetRegistry, _super);
    function MyWidgetRegistry() {
        var _this = _super.call(this) || this;
        _this.register('staticAssets', StaticAssetsWidget);
        _this.register('parentWidget', ParentWidget);
        return _this;
    }
    return MyWidgetRegistry;
}(DefaultWidgetRegistry));
export { MyWidgetRegistry };
