<ion-header>
  <div color="light" class="header">
      <div>
          <ion-menu-button></ion-menu-button>
          <ion-title>{{ 'pages.certificateList.title' | translate }}</ion-title>
      </div>
    <div>
      <div>
        <ion-item>
          <ion-label position="floating">Search by ArianeeId or S/N</ion-label>
          <ion-input [(ngModel)]="arianeeIdOrSerialNumber" (ionChange)="filterItem()"></ion-input>
        </ion-item>
      </div>
      <div>
          <ion-button (click)="toggleFilter()">
              <ion-icon name="options"></ion-icon>
          </ion-button>
      </div>
    </div>
  </div>
  <ion-toolbar *ngIf="filter">

        <ion-item>
                <ion-label>Ownership</ion-label>
                <ion-select interface="popover" [(ngModel)]="ownership" (ionChange)="filterItem()">
                    <ion-select-option value="na">All (destroyed excluded)</ion-select-option>
                    <ion-select-option value="me">me</ion-select-option>
                    <ion-select-option value="other">other</ion-select-option>
                    <ion-select-option value="all">All (destroyed included)</ion-select-option>
                </ion-select>
        </ion-item>

      <ion-item class="ion-justify-content-between">
          <span>Certificate has been transfered</span>
          <ion-select interface="popover" [(ngModel)]="transferComp" (ionChange)="filterItem()">
                  <ion-select-option value="">choose option</ion-select-option>
                  <ion-select-option value="more">more than</ion-select-option>
                  <ion-select-option value="equal">equal to</ion-select-option>
                  <ion-select-option value="less">less than</ion-select-option>
                </ion-select>
          <ion-input [(ngModel)]="transferNb" placeholder="0" size="3" (ionChange)="filterItem()"></ion-input>
          time(s)
        </ion-item>
        <ion-item>
            <ion-label>Owner has opted-out</ion-label>
                <ion-select interface="popover" [(ngModel)]="optout" (ionChange)="filterItem()">
                    <ion-select-option value="na">both</ion-select-option>
                    <ion-select-option value="no">no</ion-select-option>
                    <ion-select-option value="yes">yes</ion-select-option>
                </ion-select>
        </ion-item>
      <ion-item>
          <ion-label>Is parent certificate</ion-label>
          <ion-select interface="popover" [(ngModel)]="isParentCertificate" (ionChange)="filterItem()">
              <ion-select-option value="na">both</ion-select-option>
              <ion-select-option value="no">no</ion-select-option>
              <ion-select-option value="yes">yes</ion-select-option>
          </ion-select>
      </ion-item>
        <ion-item>
            <ion-label>Tag</ion-label>
            <ion-input [(ngModel)]="tags" placeholder="tag" (ionChange)="filterItem()"></ion-input>
        </ion-item>
</ion-toolbar>
</ion-header>
<ion-content class="wallet">
    <component-wallet
            [certificateList]="certificateList"
            (onItemClick)="gotoItem($event)"
            [users]="users"></component-wallet>
</ion-content>


<ion-footer>
    <ion-toolbar>
        <ion-title (click)="toggleBatch()">Batch Process for {{this.certificateList.length}} selected certificates</ion-title>
        <ion-buttons slot="secondary">
            <button ion-button icon-only (click)="toggleBatch()">
                <ion-icon name="arrow-up"></ion-icon>
            </button>
        </ion-buttons>
    </ion-toolbar>

    <ion-toolbar *ngIf="batchProcess">

        <ion-title>Choose format for printing</ion-title>
        <ion-select *ngIf="paperTplList.length>1" [(ngModel)]="selectedPaperTpl" name="selectedPaperTpl" text-center style="margin: auto;width: 20%;">
                <ion-select-option *ngFor="let paperTplItem of paperTplList" [value] = "paperTplItem" >{{paperTplItem.title}}</ion-select-option>
        </ion-select>
            <ion-button (click)="printFilteredCertificates()" block color="primary">Print Certificates</ion-button>
        <ion-item-divider>
        </ion-item-divider>
            <ion-button (click)="exportFilteredCertificatesInCSV()" block color="primary">Download as CSV File</ion-button>
            <ion-button (click)="exportFilteredCertificatesInJSON()" block color="primary">Download as JSON File</ion-button>
        <ion-item-divider>
        </ion-item-divider>
        <ion-button (click)="navigateToSendDMessage()" block color="primary">Send messages</ion-button>
        <ion-item-divider>
        </ion-item-divider>
    </ion-toolbar>


</ion-footer>
