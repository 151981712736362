<form [formGroup]="reserveNCertificateIdForm">
    <ion-item>
        <ion-label position="floating">Enter the number of certificateId to reserve</ion-label>
        <ion-input type="number" formControlName="numberOfCertificateId"></ion-input>
    </ion-item>
    <ion-button (click)="onReserveNCertificateIdInternalSubmit()"
                text-center
                color="primary" [disabled]="!reserveNCertificateIdForm.valid">
        Reserve {{reserveNCertificateIdForm.getRawValue().numberOfCertificateId}} certificates
    </ion-button>
</form>

<br>
<br>

<form [formGroup]="form" class="ion-padding-bottom">
    <legend>Reserve a range of Arianee IDs</legend>
    <ion-item>
        <ion-label position="floating">From Arianee ID (included)</ion-label>
        <ion-input type="number" formControlName="rangeStart"></ion-input>
    </ion-item>
    <ion-item>
        <ion-label position="floating">To Arianee ID (included)</ion-label>
        <ion-input type="number" formControlName="rangeEnd"></ion-input>
    </ion-item>
    <ion-button
            (click)="onInternalSubmit()"
            text-center
            block
            color="primary"
            [disabled]="!form.valid">
        Reserve {{numberOfTokens}} certificates
    </ion-button>
</form>
