import { FirebaseHttpClient } from '../firebaseHttpClient/firebaseHttpClient';
import { EndpointsService } from '../../apiServices/endpointsService/endpointsService';
import { mongooseQueryFactory } from '../../helpers/mongoose-query-factory';
import * as i0 from "@angular/core";
import * as i1 from "../../apiServices/endpointsService/endpointsService";
import * as i2 from "../firebaseHttpClient/firebaseHttpClient";
var TransactionService = /** @class */ (function () {
    function TransactionService(endpointService, firebaseHttpClient) {
        var _this = this;
        this.endpointService = endpointService;
        this.firebaseHttpClient = firebaseHttpClient;
        this.getTransactionCountByStatus = function (status, limit) {
            if (limit === void 0) { limit = 10; }
            var url = _this.endpointService.endpoints.transactions
                .count;
            var query = {
                $or: status.map(function (statusName) { return ({ status: statusName }); })
            };
            var options = mongooseQueryFactory(query, {}, 0);
            return _this.firebaseHttpClient.get(url, options);
        };
        this.getTransactionByStatus = function (status, limit, order) {
            if (limit === void 0) { limit = 10; }
            if (order === void 0) { order = 'descending'; }
            var url = _this.endpointService.endpoints.transactions.status.replace(':status', status);
            return _this.firebaseHttpClient.get(url, { params: { limit: limit, order: order } });
        };
    }
    TransactionService.prototype.resetTransaction = function (id) {
        var url = this.endpointService.endpoints.transactions.reset.replace(':id', id);
        return this.firebaseHttpClient.post(url, {});
    };
    TransactionService.prototype.resendTransaction = function (id) {
        var url = this.endpointService.endpoints.transactions.send.replace(':id', id);
        return this.firebaseHttpClient.post(url, {});
    };
    TransactionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TransactionService_Factory() { return new TransactionService(i0.ɵɵinject(i1.EndpointsService), i0.ɵɵinject(i2.FirebaseHttpClient)); }, token: TransactionService, providedIn: "root" });
    return TransactionService;
}());
export { TransactionService };
