import * as tslib_1 from "tslib";
import * as qrcode from 'qrcode-generator';
import { Utils } from '../utils/utils';
import { HttpClient } from '@angular/common/http';
import { ScriptLoaderService } from '../script-loader.service/script-loader.service';
import { CertificateCoreService } from '../certificate-service/certificate-core.service';
import * as i0 from "@angular/core";
import * as i1 from "../utils/utils";
import * as i2 from "@angular/common/http";
import * as i3 from "../script-loader.service/script-loader.service";
import * as i4 from "../certificate-service/certificate-core.service";
var PaperCertService = /** @class */ (function () {
    function PaperCertService(utils, http, scriptLoaderService, certificateService) {
        this.utils = utils;
        this.http = http;
        this.scriptLoaderService = scriptLoaderService;
        this.certificateService = certificateService;
        this.deeplinkUrl = this.utils.deeplink();
    }
    PaperCertService.prototype.generatePaperCert = function (certificateId, paperTplName) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var paperTpl, items;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        paperTpl = this.utils.getPaperTpl(paperTplName);
                        return [4 /*yield*/, Promise.all(certificateId.map(function (itemId) { return _this.certificateService.getCertificate(itemId).toPromise(); }))];
                    case 1:
                        items = _a.sent();
                        return [2 /*return*/, this.generatepdf(items, paperTpl)];
                }
            });
        });
    };
    PaperCertService.prototype.generatepdf = function (certificates, paperTpl) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var Arial, Simsun;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get('/assets/fonts/arial-normal.txt', { responseType: 'text' }).toPromise()];
                    case 1:
                        Arial = _a.sent();
                        return [4 /*yield*/, this.http.get('/assets/fonts/SimSun-normal.txt', { responseType: 'text' }).toPromise()];
                    case 2:
                        Simsun = _a.sent();
                        return [4 /*yield*/, this.scriptLoaderService.loadScript('pdfjs')];
                    case 3:
                        _a.sent();
                        return [2 /*return*/, new Promise(function (resolve, reject) {
                                // eslint-disable-next-line new-cap
                                var doc = new jsPDF({
                                    orientation: paperTpl.orientation,
                                    unit: 'mm',
                                    format: paperTpl.format
                                });
                                var a0width = 841;
                                var qrCodeA0Width = 320;
                                var isLandscape = paperTpl.orientation === 'l';
                                var multiplier = paperTpl.multiplier | 1;
                                var width;
                                if (typeof (paperTpl.format) === 'string') {
                                    multiplier = paperTpl.format.replace('a', '');
                                    width = Math.floor(a0width / Math.sqrt(Math.pow(2, multiplier)));
                                }
                                else {
                                    width = paperTpl.format[0] / 2.835; // convert pt to mm
                                }
                                certificates.forEach(function (value, index) {
                                    if (index > 0) {
                                        doc.addPage(paperTpl.format, paperTpl.orientation);
                                    }
                                    var content = JSON.parse(value.json);
                                    doc.addFileToVFS('Arial.ttf', Arial.trim());
                                    doc.addFileToVFS('Simsun.ttf', Simsun.trim());
                                    doc.addFont('Arial.ttf', 'Arial', 'normal');
                                    doc.setFontSize(paperTpl.fontSize);
                                    doc.addFont('Simsun.ttf', 'Simsun', 'normal');
                                    doc.setFont('Arial');
                                    var logo = document.createElement('img');
                                    logo.src = 'assets/img/arianee@2x.png';
                                    var qr = qrcode(4, 'L');
                                    qr.addData("" + _this.deeplinkUrl + value.tokenId + "," + value.viewKey);
                                    qr.make();
                                    var heightCol1 = 0;
                                    var heightCol2 = 0;
                                    var col1x = width / 2;
                                    var col2x = width / 2;
                                    if (isLandscape) {
                                        heightCol2 = 3;
                                        col1x = width * 0.27;
                                        col2x = width * 0.60;
                                    }
                                    if (paperTpl.logo) {
                                        var logoA0Width = 420;
                                        var logoWidth = Math.floor(logoA0Width / Math.sqrt(Math.pow(2, multiplier)));
                                        doc.addImage(logo, 'png', (width - logoWidth) / 2, 5, logoWidth, logoWidth / 5);
                                        heightCol1 += logoWidth / 5 + (paperTpl.heightIncrementcol1 * 2);
                                    }
                                    else {
                                        if (!isLandscape) {
                                            heightCol1 = doc.internal.pageSize.getHeight() / 4;
                                        }
                                    }
                                    if (isLandscape) {
                                        var qrcodeWidth = Math.floor(width / 3);
                                        doc.addImage(qr.createDataURL(), 'png', col2x - 3.5, heightCol2, qrcodeWidth, qrcodeWidth);
                                        heightCol2 += qrcodeWidth;
                                        doc.text('secured by Arianee', col2x, heightCol2);
                                        heightCol2 += paperTpl.heightIncrementcol2;
                                    }
                                    if (content.name) {
                                        if (isLandscape) {
                                            doc.text(content.name, col2x, heightCol2, { align: 'left' });
                                            heightCol2 += paperTpl.heightIncrementcol2;
                                        }
                                        else {
                                            doc.text(content.name, width / 2, heightCol1, { align: 'center' });
                                            heightCol1 += paperTpl.heightIncrementcol1;
                                        }
                                    }
                                    if (content.model) {
                                        if (isLandscape) {
                                            doc.text(content.model, col2x, heightCol2, { align: 'left' });
                                            heightCol2 += paperTpl.heightIncrementcol2;
                                        }
                                        else {
                                            doc.text(content.model, width / 2, heightCol1, { align: 'center' });
                                            heightCol1 += paperTpl.heightIncrementcol1;
                                        }
                                    }
                                    if (content.serialnumber && content.serialnumber.length > 0) {
                                        for (var _i = 0, _a = content.serialnumber; _i < _a.length; _i++) {
                                            var serial = _a[_i];
                                            switch (serial.type) {
                                                case 'serialnumber':
                                                    if (isLandscape) {
                                                        doc.text("Serial Number : " + serial.value, col2x, heightCol2, { align: 'left' });
                                                        heightCol2 += paperTpl.heightIncrementcol2;
                                                    }
                                                    else {
                                                        doc.text("Serial Number : " + serial.value, width / 2, heightCol1, { align: 'center' });
                                                        heightCol1 += paperTpl.heightIncrementcol1;
                                                    }
                                                    break;
                                                case 'casenumber':
                                                    if (isLandscape) {
                                                        doc.text("Case Number : " + serial.value, col2x, heightCol2, { align: 'left' });
                                                        heightCol2 += paperTpl.heightIncrementcol2;
                                                    }
                                                    else {
                                                        doc.text("Case Number : " + serial.value, width / 2, heightCol1, { align: 'center' });
                                                        heightCol1 += paperTpl.heightIncrementcol1;
                                                    }
                                                    break;
                                                case 'movementnumber':
                                                    if (isLandscape) {
                                                        doc.text("Movement Number : " + serial.value, col2x, heightCol2, { align: 'left' });
                                                        heightCol2 += paperTpl.heightIncrementcol2;
                                                    }
                                                    else {
                                                        doc.text("Movement Number : " + serial.value, width / 2, heightCol1, { align: 'center' });
                                                        heightCol1 += paperTpl.heightIncrementcol1;
                                                    }
                                                    break;
                                                default:
                                                    if (isLandscape) {
                                                        doc.text("Serial Number : " + serial.value, col2x, heightCol2, { align: 'left' });
                                                        heightCol2 += paperTpl.heightIncrementcol2;
                                                    }
                                                    else {
                                                        doc.text("Serial Number : " + serial.value, width / 2, heightCol1, { align: 'center' });
                                                        heightCol1 += paperTpl.heightIncrementcol1;
                                                    }
                                                    break;
                                            }
                                        }
                                    }
                                    var textOptions = {
                                        align: 'center'
                                    };
                                    if (isLandscape) {
                                        textOptions.maxWidth = doc.internal.pageSize.getWidth() * 0.40;
                                    }
                                    heightCol1 += paperTpl.heightIncrementcol1;
                                    doc.text('Please scan the below QR code to retrieve your Digital Certificate', col1x, heightCol1, textOptions);
                                    heightCol1 += paperTpl.heightIncrementcol1 * 2;
                                    doc.text('Veuillez scanner le QR code pour obtenir votre certificat digital', col1x, heightCol1, textOptions);
                                    heightCol1 += paperTpl.heightIncrementcol1 * 2;
                                    doc.setFont('Simsun');
                                    doc.text('請掃描下方的二維碼，以存取電子證書', col1x, heightCol1, textOptions);
                                    heightCol1 += paperTpl.heightIncrementcol1 * 2;
                                    doc.text('请扫描二维码，以存取电子证书', col1x, heightCol1, textOptions);
                                    heightCol1 += paperTpl.heightIncrementcol1 * 2;
                                    doc.text('下記のQRコードをスキャンしてデジタル保証書を取得して下さい。', col1x, heightCol1, textOptions);
                                    doc.setFont('Arial');
                                    heightCol1 += paperTpl.heightIncrementcol1 * 2;
                                    doc.text('المسح الضوئي لرمز الاستجابة السريعة أسفله للحصول على الشهادة الرقمية', col1x, heightCol1, textOptions);
                                    heightCol1 += paperTpl.heightIncrementcol1 * 2;
                                    doc.text('Cканируйте приведенный ниже QR-код и получите цифровой сертификат.', col1x, heightCol1, textOptions);
                                    heightCol1 += paperTpl.heightIncrementcol1;
                                    if (!isLandscape) {
                                        var qrcodeWidth = Math.floor(width / 3);
                                        doc.addImage(qr.createDataURL(), 'png', col1x - (qrcodeWidth / 2), heightCol1, qrcodeWidth, qrcodeWidth);
                                        heightCol1 += qrcodeWidth;
                                        doc.text('secured by Arianee', col1x - (qrcodeWidth / 2) + 6.5, heightCol1, { align: 'left' });
                                    }
                                    if (paperTpl.printedIn) {
                                        doc.setFontSize(paperTpl.fontSize * 0.75);
                                        doc.text('Printed in ' + paperTpl.printedIn, width - 3, 25, { angle: 90 });
                                    }
                                });
                                doc.save(paperTpl.name + '.pdf');
                                resolve();
                            })];
                }
            });
        });
    };
    PaperCertService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaperCertService_Factory() { return new PaperCertService(i0.ɵɵinject(i1.Utils), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.ScriptLoaderService), i0.ɵɵinject(i4.CertificateCoreService)); }, token: PaperCertService, providedIn: "root" });
    return PaperCertService;
}());
export { PaperCertService };
