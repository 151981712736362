import * as tslib_1 from "tslib";
// import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { UIAlertService } from '../UI-alert-service/UI-alert-service';
import { UsersService } from '../users-service/userService';
import { ProfileService } from '../profile-service/profileService';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/auth";
import * as i2 from "../UI-alert-service/UI-alert-service";
import * as i3 from "../users-service/userService";
import * as i4 from "../profile-service/profileService";
var AuthService = /** @class */ (function () {
    function AuthService(_firebaseAuth, loaderService, usersService, profileService) {
        var _this = this;
        this._firebaseAuth = _firebaseAuth;
        this.loaderService = loaderService;
        this.usersService = usersService;
        this.profileService = profileService;
        this.userDetails = null;
        this.$userState = new ReplaySubject(1);
        this.user = _firebaseAuth.authState;
        this.user.subscribe(function (user) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var rights;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!user) return [3 /*break*/, 2];
                        this.userDetails = user;
                        return [4 /*yield*/, this.getUserRights()];
                    case 1:
                        rights = _a.sent();
                        this.userRights = {};
                        if (rights.length > 0) {
                            rights.forEach(function (right) {
                                _this.userRights[right] = true;
                            });
                        }
                        this.$userState.next({
                            isLoggedIn: true,
                            rights: this.userRights,
                            details: this.userDetails
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        this.userDetails = null;
                        this.userRights = null;
                        this.$userState.next({
                            isLoggedIn: false,
                            rights: this.userRights,
                            details: this.userDetails
                        });
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    }
    AuthService.prototype.signInRegular = function (email, password) {
        // const credential = firebase.auth.EmailAuthProvider.credential( email, password );
        return this._firebaseAuth.auth.signInWithEmailAndPassword(email, password);
    };
    AuthService.prototype.isLoggedIn = function () {
        if (this.userDetails == null) {
            return false;
        }
        else {
            return true;
        }
    };
    AuthService.prototype.hasRight = function (right) {
        if (this.userRights && this.userRights[right]) {
            return true;
        }
        return false;
    };
    AuthService.prototype.getUserDetails = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.user.subscribe(function (user) {
                if (user) {
                    // this.userDetails = user;
                    resolve(_this.userDetails);
                }
                else {
                    _this.userDetails = null;
                    reject('not looged in');
                }
            });
        });
    };
    AuthService.prototype.getUserProfile = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.user.subscribe(function (user) {
                try {
                    var profileSubscribe_1 = _this.usersService.getUserFromUserUid(user.uid)
                        .subscribe(function (user) {
                        if (user.profile) {
                            resolve(user.profile);
                        }
                        else {
                            reject('You are not authorized to access.');
                        }
                        profileSubscribe_1.unsubscribe();
                    });
                }
                catch (err) {
                    reject('You are not authorized to access.');
                }
            });
        });
    };
    AuthService.prototype.getUserRights = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.getUserProfile().then(function (profile) {
                var profileSubscribe = _this.profileService.getProfile(profile)
                    .subscribe(function (profile) {
                    if (profile && Object.prototype.hasOwnProperty.call(profile, 'rights') && profile.rights) {
                        resolve(profile.rights);
                    }
                    else {
                        resolve({});
                    }
                    profileSubscribe.unsubscribe();
                });
            });
        });
    };
    AuthService.prototype.logout = function () {
        this.loaderService.load({ message: 'logging out' });
        return this._firebaseAuth.auth.signOut()
            .then(function (d) { return window.location.reload(); });
    };
    AuthService.prototype.confirmPasswordReset = function (code, password) {
        return this._firebaseAuth.auth.confirmPasswordReset(code, password);
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.AngularFireAuth), i0.ɵɵinject(i2.UIAlertService), i0.ɵɵinject(i3.UsersService), i0.ɵɵinject(i4.ProfileService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
