/**
 * Arianee Brand Data Hub API
 * List API available in Brand Data Hub
 *
 * OpenAPI spec version: 1.0.0
 * Contact: techteam@arianee.org
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { Certificate } from '../model/certificate';
import { CertificateComplete } from '../model/certificateComplete';
import { CertificateCompleteUpdate } from '../model/certificateCompleteUpdate';
import { CertificateCreated } from '../model/certificateCreated';
import { CertificateId, Passphrase, ConsolidatedCertificateRequest } from '../model/models';
import { CreateCertificateRequestOwnershipLinkObject } from '../model/createCertificateRequestOwnershipLinkObject';
import { GetCertificateReturn } from '../model/getCertificateReturn';
import { NoReservedCertificate } from '../model/noReservedCertificate';
import { ReservationBatch } from '../model/reservationBatch';
import { ReservationBatchFail } from '../model/reservationBatchFail';
import { ReservationBatchSuccess } from '../model/reservationBatchSuccess';
import { ReservationRange } from '../model/reservationRange';
import { ReservedCount } from '../model/reservedCount';
import { SearchResult } from '../model/searchResult';
import { TransactionCreated } from '../model/transactionCreated';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class CertificateService {
    protected basePath = 'https://us-central1-bdhalex-826d5.cloudfunctions.net/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor (protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
      if (basePath) {
        this.basePath = basePath;
      }
      if (configuration) {
        this.configuration = configuration;
        this.basePath = basePath || configuration.basePath || this.basePath;
      }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm (consumes: string[]): boolean {
      const form = 'multipart/form-data';
      for (const consume of consumes) {
        if (form === consume) {
          return true;
        }
      }
      return false;
    }

    /**
     * Add a security token to a certificate
     * Add a security token to a certificate
     * @param body Arianee Certificate ID and passphrase
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addTokenAccess(body: Array<number | string | boolean>, observe?: 'body', reportProgress?: boolean): Observable<TransactionCreated>;
    public addTokenAccess(body: Array<number | string | boolean>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TransactionCreated>>;
    public addTokenAccess(body: Array<number | string | boolean>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TransactionCreated>>;
    public addTokenAccess (body: Array<number | string | boolean>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling addTokenAccess.');
      }

      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
        'application/json'
      ];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.request<TransactionCreated>('post', `${this.basePath}/api/contracts/smartAssetContract/addTokenAccess/send`,
        {
          body: body,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Create a certificate and store its content into an Arianee Privacy Gateway RPC server
     * Create a certificate in blockchain and store content into an Arianee Privacy Gateway RPC server
     * @param body Certificate content to create and RPC Server.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAndStoreCertificate(body: Array<CertificateComplete>, observe?: 'body', reportProgress?: boolean): Observable<CertificateCreated>;
    public createAndStoreCertificate(body: Array<CertificateComplete>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CertificateCreated>>;
    public createAndStoreCertificate(body: Array<CertificateComplete>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CertificateCreated>>;
    public createAndStoreCertificate (body: Array<CertificateComplete>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling createAndStoreCertificate.');
      }

      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
        'application/json'
      ];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.request<CertificateCreated>('post', `${this.basePath}/api/createAndStoreCertificate`,
        {
          body: body,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Create a certificate using a reserved Arianee Id
     * Create a certificate using a reserved Arianee Id
     * @param body Certificate content to create and RPC Server.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAndStoreReserved(body: Array<Certificate>, observe?: 'body', reportProgress?: boolean): Observable<CertificateCreated>;
    public createAndStoreReserved(body: Array<Certificate>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CertificateCreated>>;
    public createAndStoreReserved(body: Array<Certificate>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CertificateCreated>>;
    public createAndStoreReserved (body: Array<Certificate>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling createAndStoreReserved.');
      }

      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
        'application/json'
      ];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.request<CertificateCreated>('post', `${this.basePath}/certificate/reserved/createAndStore`,
        {
          body: body,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Create a certificate in blockchain (content need to be stored separately)
     * Create a certificate in blockchain (content need to be stored separately)
     * @param body Certificate content to create and RPC Server.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCertificate(body: Array<CertificateComplete>, observe?: 'body', reportProgress?: boolean): Observable<CertificateCreated>;
    public createCertificate(body: Array<CertificateComplete>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CertificateCreated>>;
    public createCertificate(body: Array<CertificateComplete>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CertificateCreated>>;
    public createCertificate (body: Array<CertificateComplete>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling createCertificate.');
      }

      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
        'application/json'
      ];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.request<CertificateCreated>('post', `${this.basePath}/api/createCertificate`,
        {
          body: body,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Create a request ownership link.
     * Create a request ownership link.
     * @param body Arianee Certificate ID and passphrase
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCertificateRequestOwnershipLink(body: Array<number | string>, observe?: 'body', reportProgress?: boolean): Observable<CreateCertificateRequestOwnershipLinkObject>;
    public createCertificateRequestOwnershipLink(body: Array<number | string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateCertificateRequestOwnershipLinkObject>>;
    public createCertificateRequestOwnershipLink(body: Array<number | string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateCertificateRequestOwnershipLinkObject>>;
    public createCertificateRequestOwnershipLink (body: Array<number | string>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling createCertificateRequestOwnershipLink.');
      }

      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
        'application/json'
      ];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.request<CreateCertificateRequestOwnershipLinkObject>('post', `${this.basePath}/api/createCertificateRequestOwnershipLink`,
        {
          body: body,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Destroy a certificate.
     * Destroy a certificate. Need to be owner.
     * @param body Arianee Certificate ID to destroy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public destroyCertificate(body: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<TransactionCreated>;
    public destroyCertificate(body: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TransactionCreated>>;
    public destroyCertificate(body: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TransactionCreated>>;
    public destroyCertificate (body: Array<number>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling destroyCertificate.');
      }

      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
        'application/json'
      ];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.request<TransactionCreated>('post', `${this.basePath}/api/destroyCertificate`,
        {
          body: body,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Retrieve certificate details
     * Retrieve information from a certificate (content, issuer, status, event, ...)
     * @param body Array of parameters - Only certificateId is mandatory. Passphrase are optionnal if requester is the owner. consolidatedCertificateRequest let you define the type of return object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCertificate(body: Array<CertificateId | Passphrase | ConsolidatedCertificateRequest>, observe?: 'body', reportProgress?: boolean): Observable<GetCertificateReturn>;
    public getCertificate(body: Array<CertificateId | Passphrase | ConsolidatedCertificateRequest>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetCertificateReturn>>;
    public getCertificate(body: Array<CertificateId | Passphrase | ConsolidatedCertificateRequest>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetCertificateReturn>>;
    public getCertificate (body: Array<CertificateId | Passphrase | ConsolidatedCertificateRequest>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling getCertificate.');
      }

      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
        'application/json'
      ];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.request<GetCertificateReturn>('post', `${this.basePath}/api/getCertificate`,
        {
          body: body,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Get certificate owner address.
     * Get certificate owner address .
     * @param body Arianee Certificate ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ownerOf(body: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public ownerOf(body: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public ownerOf(body: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public ownerOf (body: Array<number>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling ownerOf.');
      }

      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'document'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
        'application/json'
      ];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.request<string>('post', `${this.basePath}/api/contracts/smartAssetContract/ownerOf/call`,
        {
          body: body,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Recover a certificate.
     * Recover a certificate. Recover is direct if certificate \&quot;tokenRecoveryTimestamp\&quot; is in the future. Unless, Arianee validation is needed
     * @param body Arianee Certificate ID to recover
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public recoverCertificate(body: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<TransactionCreated>;
    public recoverCertificate(body: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TransactionCreated>>;
    public recoverCertificate(body: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TransactionCreated>>;
    public recoverCertificate (body: Array<number>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling recoverCertificate.');
      }

      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
        'application/json'
      ];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.request<TransactionCreated>('post', `${this.basePath}/api/recoverCertificate`,
        {
          body: body,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Recover status for a certificate.
     * Get certificate recovery status.
     * @param body Arianee Certificate ID to recover
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public recoveryRequestOpen(body: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public recoveryRequestOpen(body: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public recoveryRequestOpen(body: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public recoveryRequestOpen (body: Array<number>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling recoveryRequestOpen.');
      }

      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'document'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
        'application/json'
      ];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.request<boolean>('post', `${this.basePath}/api/contracts/smartAssetContract/recoveryRequestOpen/call`,
        {
          body: body,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Create a reservation batch
     * Create a reservation batch
     * @param body Number of reservation.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reserveBatch(body: ReservationBatch, observe?: 'body', reportProgress?: boolean): Observable<ReservationBatchSuccess>;
    public reserveBatch(body: ReservationBatch, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReservationBatchSuccess>>;
    public reserveBatch(body: ReservationBatch, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReservationBatchSuccess>>;
    public reserveBatch (body: ReservationBatch, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling reserveBatch.');
      }

      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
        'application/json'
      ];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.request<ReservationBatchSuccess>('post', `${this.basePath}/certificate/reserveBatch`,
        {
          body: body,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Create a reservation batch of specific range of certificateId
     * Create a reservation batch of specific range of certificateId
     * @param body Range of certificateIds.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reserveRange(body: ReservationRange, observe?: 'body', reportProgress?: boolean): Observable<ReservationBatchSuccess>;
    public reserveRange(body: ReservationRange, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReservationBatchSuccess>>;
    public reserveRange(body: ReservationRange, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReservationBatchSuccess>>;
    public reserveRange (body: ReservationRange, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling reserveRange.');
      }

      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
        'application/json'
      ];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.request<ReservationBatchSuccess>('post', `${this.basePath}/certificate/reserveRange`,
        {
          body: body,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Count reserved certificate available
     * Count reserved certificate available
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reservedCount(observe?: 'body', reportProgress?: boolean): Observable<ReservedCount>;
    public reservedCount(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReservedCount>>;
    public reservedCount(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReservedCount>>;
    public reservedCount (observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
      ];

      return this.httpClient.request<ReservedCount>('get', `${this.basePath}/certificate/reserved/count`,
        {
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Transfer a certificate Id from an address to another address
     * Transfer a certificate Id from an address to another address
     * @param body from address, to address, Certificate ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public safeTransferFrom(body: Array<string | number>, observe?: 'body', reportProgress?: boolean): Observable<TransactionCreated>;
    public safeTransferFrom(body: Array<string | number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TransactionCreated>>;
    public safeTransferFrom(body: Array<string | number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TransactionCreated>>;
    public safeTransferFrom (body: Array<string | number>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling safeTransferFrom.');
      }

      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
        'application/json'
      ];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.request<TransactionCreated>('post', `${this.basePath}/api/contracts/smartAssetContract/safeTransferFrom/send`,
        {
          body: body,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * search certificateIds based on text
     * Update a certificate in blockchain (content need to be stored separately)
     * @param q Search pattern
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchCertificate(q: string, observe?: 'body', reportProgress?: boolean): Observable<SearchResult>;
    public searchCertificate(q: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SearchResult>>;
    public searchCertificate(q: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SearchResult>>;
    public searchCertificate (q: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (q === null || q === undefined) {
        throw new Error('Required parameter q was null or undefined when calling searchCertificate.');
      }

      let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
      if (q !== undefined && q !== null) {
        queryParameters = queryParameters.set('q', <any>q);
      }

      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
      ];

      return this.httpClient.request<SearchResult>('get', `${this.basePath}/certificate/search/`,
        {
          params: queryParameters,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Update a certificate and store its content into an Arianee Privacy Gateway RPC server
     * Update a certificate in blockchain and store content into an Arianee Privacy Gateway RPC server
     * @param body Certificate content to update and RPC Server.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAndStoreCertificate(body: CertificateCompleteUpdate, observe?: 'body', reportProgress?: boolean): Observable<TransactionCreated>;
    public updateAndStoreCertificate(body: CertificateCompleteUpdate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TransactionCreated>>;
    public updateAndStoreCertificate(body: CertificateCompleteUpdate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TransactionCreated>>;
    public updateAndStoreCertificate (body: CertificateCompleteUpdate, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling updateAndStoreCertificate.');
      }

      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
        'application/json'
      ];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.request<TransactionCreated>('post', `${this.basePath}/api/updateAndStoreCertificate`,
        {
          body: body,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Update a certificate in blockchain (content need to be stored separately)
     * Update a certificate in blockchain (content need to be stored separately)
     * @param body Certificate content to update.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCertificate(body: CertificateCompleteUpdate, observe?: 'body', reportProgress?: boolean): Observable<TransactionCreated>;
    public updateCertificate(body: CertificateCompleteUpdate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TransactionCreated>>;
    public updateCertificate(body: CertificateCompleteUpdate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TransactionCreated>>;
    public updateCertificate (body: CertificateCompleteUpdate, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling updateCertificate.');
      }

      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
        'application/json'
      ];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.request<TransactionCreated>('post', `${this.basePath}/api/updateCertificate`,
        {
          body: body,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * update tags, viewKey or displayTitle
     * update tags, viewKey or displayTitle
     * @param body Properties to update
     * @param certificateId certificateId / ArianeeId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public certificateProperties(body: any, certificateId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public certificateProperties(body: any, certificateId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public certificateProperties(body: any, certificateId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public certificateProperties (body: any, certificateId: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling certificateProperties.');
      }

      if (certificateId === null || certificateId === undefined) {
        throw new Error('Required parameter certificateId was null or undefined when calling certificateProperties.');
      }

      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
        'application/json'
      ];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.request<any>('put', `${this.basePath}/certificate/properties/${encodeURIComponent(String(certificateId))}`,
        {
          body: body,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    /**
     * Update recover status for a certificate.
     * Update recover status for a certificate.
     * @param body Arianee Certificate ID to recover + status
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateRecoveryRequest(body: Array<number | boolean>, observe?: 'body', reportProgress?: boolean): Observable<TransactionCreated>;
    public updateRecoveryRequest(body: Array<number | boolean>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TransactionCreated>>;
    public updateRecoveryRequest(body: Array<number | boolean>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TransactionCreated>>;
    public updateRecoveryRequest (body: Array<number | boolean>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling updateRecoveryRequest.');
      }

      let headers = this.defaultHeaders;

      // authentication (AccessToken) required
      if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
      }
      // authentication (ApiKeyAuth) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['x-api-key']) {
        headers = headers.set('x-api-key', this.configuration.apiKeys['x-api-key']);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
        'application/json'
      ];
      const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }

      return this.httpClient.request<TransactionCreated>('post', `${this.basePath}/api/contracts/smartAssetContract/updateRecoveryRequest/send`,
        {
          body: body,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }
}
