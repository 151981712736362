<ion-header>
    <div color="light" class="header">
        <div>
            <ion-menu-button></ion-menu-button>
            <ion-title><a (click)="navigateBack()"><ion-icon name="chevron-back-outline"></ion-icon></a></ion-title>
            <ion-title>Asynchronous transfer</ion-title>
        </div>
    </div>
</ion-header>

<ion-content class="item" data-e2e="asynchronous_transfer_page">



    <div *ngIf="!passphraseSetted">
      <h2 >Prepare your certificate </h2>
      <p  class="description">for asynchronous transfer (paper, mail, ...)</p><br />
      <div class="list padding text-center" >

          <label class="item item-input">
              <input type="text" placeholder="passphrase" [(ngModel)]="passphrase">
          </label>

        <h4 >Requested Passphrase is just for generating QRCode, no need to store/transfer it</h4><br/>


      </div>

        <div class="" style="bottom:inherit;padding:0 20%;">
            <button  ion-button block  color="custom" (click)="setItemPassphrase()">Set passphrase</button>
        </div>
    </div>


    <br/><br/><br/><br/>

    <div class="padding" *ngIf="passphraseSetted&&transactionSent">

        <div *ngIf="withUrl" style="text-align: center; position: relative">
                <div class="selectWrapper">
                    <ion-select *ngIf="paperTplList.length>0" [(ngModel)]="selectedPaperTpl" name="selectedPaperTpl" text-center style="margin: auto;width: 20%;">
                            <ion-select-option *ngFor="let paperTplItem of paperTplList" [value] = "paperTplItem" >{{paperTplItem.title}}</ion-select-option>
                    </ion-select>
                </div>
            <ion-button color="primary" data-e2e="pdf_generator_button" (click)="paperCert()">Paper Certificate
            </ion-button>
            <br/>
                Paper certificate including Arianee QR Code - ready to print

            <br/><br/><br/>
            <hr/>
            <br/><br/><br/>

            <div style="text-align: center">
                <img
                        data-e2e="asynchronous_qrcode"
                        src="https://tools.arianee.org/qrcode.php?&size=200x200&logo=https://demo.arianee.org/demo/logoa.png&data={{deeplinkUrl}}{{itemId}},{{passphrase}}"
                        width="200" height="200"/>
            </div>
            QR Code to get ownership

            <br/><br/><br/>
            <hr/>
            <br/><br/><br/>
            <a href="{{deeplinkUrl}}{{itemId}},{{passphrase}}"
               data-e2e="asynchronous_link"
               target="_blank">{{deeplinkUrl}}{{itemId}},{{passphrase}}</a><br/>
            URL to embed

            <!--
            <div *ngIf="item&&item.v=='0.1'" text-center>
                <a href="paper/paper.html?id=https://arian.ee/{{itemId}}&passphrase={{passphrase}}&v=logo&title={{item.title}}&brand={{item.brand}}
            &serialnumber={{item.serialnumber}}&image={{item.picture}}" target="_blank">Open paper certificate with Arianee logo</a>
            </div>

            <div style="text-align: center">
                <img src="https://tools.arianee.org/qrcode.php?&size=200x200&chld=L&data=https://arian.ee/{{itemId}},{{passphrase}}" width="200"/>
            </div>


            <div *ngIf="item&&item.v=='0.1'" text-center>
                <a href="paper/paper.html?id=https://arian.ee/{{itemId}}&passphrase={{passphrase}}&v=simple&chld=L&title={{item.title}}&brand={{item.brand}}
        &serialnumber={{item.serialnumber}}&image={{item.picture}}" target="_blank">Open paper certificate</a>
            </div>
            -->


        </div>


        <div *ngIf="!withUrl" style="text-align: center">
                <button color="custom" ion-button (click)="paperCert()">Paper Certificate</button>

            <div style="text-align: center">
            <img src="{{qrCode}}" width="200"/>
            </div>

            <!--



            <div *ngIf="item&&item.v=='0.1'" text-center>
                <a href="paper/paper.html?id={{item.id}}&passphrase={{passphrase}}&v=logo&title={{item.title}}&brand={{item.brand}}
            &serialnumber={{item.serialnumber}}&image={{item.picture}}" target="_blank">Open paper certificate with Arianee logo</a>
            </div>

            <div style="text-align: center">
                <img src="{{qrCode2}}" width="200"/>
            </div>


            <div *ngIf="item&&item.v=='0.1'" text-center>
                <a href="paper/paper.html?id={{item.id}}&passphrase={{passphrase}}&v=simple&chld=L&title={{item.title}}&brand={{item.brand}}
        &serialnumber={{item.serialnumber}}&image={{item.picture}}" target="_blank">Open paper certificate</a>
            </div>
            -->

        </div>

        <!--
        <ion-item>
            <ion-label>With integrated URL (https://arian.ee)</ion-label>
            <ion-checkbox [(ngModel)]="withUrl"> </ion-checkbox>
        </ion-item>
        -->





    </div>


</ion-content>
