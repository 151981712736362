import { Injectable } from '@angular/core';
import { FirebaseHttpClient } from '../firebaseHttpClient/firebaseHttpClient';
import { EndpointsService } from '../../apiServices/endpointsService/endpointsService';
import { Observable } from 'rxjs';
import { TransactionDB } from '../../models/transaction.model';
import { TransactionStatus } from '../../models/transactionStatus.enum';
import { mongooseQueryFactory } from '../../helpers/mongoose-query-factory';

@Injectable({ providedIn: 'root' })
export class TransactionService {
  constructor (
        private endpointService: EndpointsService,
        private firebaseHttpClient: FirebaseHttpClient
  ) {

  }

    public getTransactionCountByStatus = (status: TransactionStatus[], limit = 10): Observable<TransactionDB[]> => {
      const url = this.endpointService.endpoints.transactions
        .count;

      const query = {
        $or: status.map(statusName => ({ status: statusName }))
      };
      const options = mongooseQueryFactory(
        query, {}, 0);

      return this.firebaseHttpClient.get(url, options);
    }

    public getTransactionByStatus = (status: TransactionStatus, limit = 10, order = 'descending'): Observable<TransactionDB[]> => {
      const url = this.endpointService.endpoints.transactions.status.replace(':status', status);
      return this.firebaseHttpClient.get(url, { params: { limit, order } });
    };

    public resetTransaction (id: string) {
      const url = this.endpointService.endpoints.transactions.reset.replace(':id', id);
      return this.firebaseHttpClient.post(url, {});
    }

    public resendTransaction (id: string) {
      const url = this.endpointService.endpoints.transactions.send.replace(':id', id);
      return this.firebaseHttpClient.post(url, {});
    }
}
