import * as tslib_1 from "tslib";
import { StringWidget } from 'ngx-schema-form';
import { ModalController } from '@ionic/angular';
import { map, take } from 'rxjs/operators';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { certificateDBTOCertificateItem } from '../../helpers/certificateDBToCertificateItem';
import { WalletWidgetComponent } from './wallet-widget-component/wallet';
import { Utils } from '../../services/utils/utils';
import { CertificateCoreService } from '../../services/certificate-service/certificate-core.service';
var ParentWidget = /** @class */ (function (_super) {
    tslib_1.__extends(ParentWidget, _super);
    function ParentWidget(modalCtrl, uiAlertService, utils, certificateService) {
        var _this = _super.call(this) || this;
        _this.modalCtrl = modalCtrl;
        _this.uiAlertService = uiAlertService;
        _this.utils = utils;
        _this.certificateService = certificateService;
        return _this;
    }
    ParentWidget.prototype.presentModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var loader, parentCertificateList, uploadAssetsModal, result, _a, id, viewKey, deeplink;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.uiAlertService.load()];
                    case 1:
                        loader = _b.sent();
                        return [4 /*yield*/, this.certificateService.getCertificateList({ tags: "parentPassport" /* parentCertificate */ })
                                .pipe(take(1), map(function (data) { return data.map(certificateDBTOCertificateItem); }))
                                .toPromise()];
                    case 2:
                        parentCertificateList = _b.sent();
                        return [4 /*yield*/, this.modalCtrl.create({
                                component: WalletWidgetComponent,
                                componentProps: {
                                    certificateList: parentCertificateList,
                                    user: {}
                                }
                            })];
                    case 3:
                        uploadAssetsModal = _b.sent();
                        return [4 /*yield*/, loader.dismiss()];
                    case 4:
                        _b.sent();
                        return [4 /*yield*/, uploadAssetsModal.present()];
                    case 5:
                        _b.sent();
                        return [4 /*yield*/, uploadAssetsModal.onWillDismiss()];
                    case 6:
                        result = _b.sent();
                        if (result) {
                            _a = parentCertificateList.find(function (cert) { return cert.id === result.data; }), id = _a.id, viewKey = _a.viewKey;
                            deeplink = this.utils.deeplink(id, viewKey);
                            this.formProperty.setValue(deeplink, false);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return ParentWidget;
}(StringWidget));
export { ParentWidget };
