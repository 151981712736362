import { Injectable } from '@angular/core';
// import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';

import { Observable, BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { UIAlertService } from '../UI-alert-service/UI-alert-service';
import { UsersService } from '../users-service/userService';
import { ProfileService } from '../profile-service/profileService';

@Injectable({ providedIn: 'root' })
export class AuthService {
    public userRights: any;
    private user: Observable<any>;
    private userDetails: any = null;

    public $userState: Subject<{ isLoggedIn: boolean, rights, details }> = new ReplaySubject(1);

    constructor (
      private _firebaseAuth: AngularFireAuth,
      private loaderService:UIAlertService,
      private usersService: UsersService,
      private profileService: ProfileService
    ) {
      this.user = _firebaseAuth.authState;

      this.user.subscribe(
        async (user) => {
          if (user) {
            this.userDetails = user;
            const rights: Array<string> = await this.getUserRights();
            this.userRights = {};
            if (rights.length > 0) {
              rights.forEach(right => {
                this.userRights[right] = true;
              });
            }
            this.$userState.next({
              isLoggedIn: true,
              rights: this.userRights,
              details: this.userDetails
            });
          } else {
            this.userDetails = null;
            this.userRights = null;
            this.$userState.next({
              isLoggedIn: false,
              rights: this.userRights,
              details: this.userDetails
            });
          }
        }
      );
    }

    signInRegular (email, password) {
      // const credential = firebase.auth.EmailAuthProvider.credential( email, password );
      return this._firebaseAuth.auth.signInWithEmailAndPassword(email, password);
    }

    isLoggedIn () {
      if (this.userDetails == null) {
        return false;
      } else {
        return true;
      }
    }

    hasRight (right) {
      if (this.userRights && this.userRights[right]) { return true; }

      return false;
    }

    getUserDetails () {
      return new Promise((resolve, reject) => {
        this.user.subscribe(
          (user) => {
            if (user) {
              // this.userDetails = user;
              resolve(this.userDetails);
            } else {
              this.userDetails = null;
              reject('not looged in');
            }
          }
        );
      });
    }

    getUserProfile () {
      return new Promise((resolve, reject) => {
        this.user.subscribe(user => {
          try {
            const profileSubscribe = this.usersService.getUserFromUserUid(user.uid)
              .subscribe((user: any) => {
                if (user.profile) {
                  resolve(user.profile);
                } else {
                  reject('You are not authorized to access.');
                }
                profileSubscribe.unsubscribe();
              });
          } catch (err) {
            reject('You are not authorized to access.');
          }
        });
      });
    }

    getUserRights ():Promise<any> {
      return new Promise((resolve, reject) => {
        this.getUserProfile().then((profile:string) => {
          const profileSubscribe = this.profileService.getProfile(profile)
            .subscribe((profile: any) => {
              if (profile && Object.prototype.hasOwnProperty.call(profile, 'rights') && profile.rights) {
                resolve(profile.rights);
              } else {
                resolve({});
              }
              profileSubscribe.unsubscribe();
            });
        });
      });
    }

    logout () {
      this.loaderService.load({ message: 'logging out' });

      return this._firebaseAuth.auth.signOut()
        .then(d => window.location.reload());
    }

    confirmPasswordReset (code, password) {
      return this._firebaseAuth.auth.confirmPasswordReset(code, password);
    }
}
