import * as tslib_1 from "tslib";
import { LoadingController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { Utils } from '../../services/utils/utils';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { ActivatedRoute, Router } from '@angular/router';
import { CertificateCoreService } from '../../services/certificate-service/certificate-core.service';
import { ArianeeEventService } from 'src/app/services/ArianeeEventService/arianeeEventService';
import { Location } from '@angular/common';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';
import { EconomyService, EventService } from '../../apiServices/BDHApi';
var EventBuilder = /** @class */ (function () {
    function EventBuilder(utils, http, loadingCtrl, uIAleertService, arianeEventService, activiated, certificateService, router, location, eventLogger, economyService, eventService) {
        var _this = this;
        this.utils = utils;
        this.http = http;
        this.loadingCtrl = loadingCtrl;
        this.uIAleertService = uIAleertService;
        this.arianeEventService = arianeEventService;
        this.activiated = activiated;
        this.certificateService = certificateService;
        this.router = router;
        this.location = location;
        this.eventLogger = eventLogger;
        this.economyService = economyService;
        this.eventService = eventService;
        this.schema = {
            properties: {}
        };
        this.loadCreditLeft = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var credit;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.economyService.balanceOfCredit(['event']).toPromise()];
                    case 1:
                        credit = _a.sent();
                        this.credit = +credit;
                        if (this.credit === 0) {
                            this.utils.alert('Not enough credit', 'Please add credit to continue. Go on settings tab');
                        }
                        return [2 /*return*/];
                }
            });
        }); };
    }
    EventBuilder.prototype.navigateBack = function () {
        this.location.back();
    };
    EventBuilder.prototype.ionViewDidEnter = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.loadCreditLeft();
                        this.itemId = parseInt(this.activiated.snapshot.paramMap.get('certificateId'));
                        this.eventLogger.logScreen('eventBuilder-page', { itemId: this.itemId });
                        _a = this;
                        return [4 /*yield*/, this.certificateService.getCertificateOnce(this.itemId).toPromise()];
                    case 1:
                        _a.item = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    EventBuilder.prototype.saveEvent = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var loading, toast, e_1, toast;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.uIAleertService.load({
                            message: 'Waiting blockchain confirmation for <b>event creation</b>.'
                        })];
                    case 1:
                        loading = _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 8, , 11]);
                        if (!this.eventId) {
                            this.eventId = Math.round(Math.random() * 100000);
                        }
                        return [4 /*yield*/, this.arianeEventService.createEvent({
                                content: tslib_1.__assign({ $schema: this.schema }, event),
                                certificateId: this.itemId,
                                arianeeEventId: this.eventId
                            })];
                    case 3:
                        _a.sent();
                        if (!this.autoAccept) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.eventService.acceptArianeeEvent([this.eventId]).toPromise()];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        loading.dismiss();
                        return [4 /*yield*/, this.uIAleertService.toast({
                                cssClass: 'e2e_event_created',
                                message: 'Your event is created.',
                                duration: 2000
                            })];
                    case 6:
                        toast = _a.sent();
                        return [4 /*yield*/, toast.onDidDismiss()];
                    case 7:
                        _a.sent();
                        this.router.navigate(["/certificate/read/" + this.itemId]);
                        return [3 /*break*/, 11];
                    case 8:
                        e_1 = _a.sent();
                        return [4 /*yield*/, loading.dismiss()];
                    case 9:
                        _a.sent();
                        return [4 /*yield*/, this.uIAleertService.error('Something went wrong.', {
                                duration: 2000
                            })];
                    case 10:
                        toast = _a.sent();
                        return [3 /*break*/, 11];
                    case 11: return [2 /*return*/];
                }
            });
        });
    };
    return EventBuilder;
}());
export { EventBuilder };
