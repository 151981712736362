import * as tslib_1 from "tslib";
import { widgetizeI18n } from './widget-i18n';
import { merge, mergeWith, isArray } from 'lodash';
import { HttpClient } from '@angular/common/http';
import schemas from '../../../environments/schemas';
import { widgetMessagei18n } from './widget-message-i18n';
import { widgetEventi18n } from './widget-event-i18n';
import { widgetizeI18nV2 } from './widget-i18nV2';
import { widgetizeI18nV3 } from './widget-i18nV3';
import { widgetizeI18nV4 } from './widget-i18nV4';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export var SCHEMA_NAME;
(function (SCHEMA_NAME) {
    SCHEMA_NAME["i18N"] = "i18N";
    SCHEMA_NAME["arianeeAsset"] = "arianeeAsset";
})(SCHEMA_NAME || (SCHEMA_NAME = {}));
var SchemaService = /** @class */ (function () {
    function SchemaService(httpClient) {
        this.httpClient = httpClient;
        this.widgetOverride = {
            'https://cert.arianee.org/version1/ArianeeMessage-i18n.json': widgetMessagei18n,
            'https://cert.arianee.org/version1/ArianeeProductCertificate-i18n.json': widgetizeI18n,
            'https://cert.arianee.org/version2/ArianeeProductCertificate-i18n.json': widgetizeI18nV2,
            'https://cert.arianee.org/version3/ArianeeProductCertificate-i18n.json': widgetizeI18nV3,
            'https://cert.arianee.org/version4/ArianeeProductCertificate-i18n.json': widgetizeI18nV4,
            'https://cert.arianee.org/version1/ArianeeEvent-i18n.json': widgetEventi18n
        };
        this._schemaList = [
            {
                title: 'ArianeeAsset (default)',
                name: SCHEMA_NAME.arianeeAsset,
                url: 'https://cert.arianee.org/version1/ArianeeAsset.json',
                version: 1
            },
            {
                title: 'Arianee Product (multilanguage)',
                url: 'https://cert.arianee.org/version1/ArianeeProductCertificate-i18n.json',
                name: SCHEMA_NAME.i18N,
                version: 2,
                widget: widgetizeI18n
            },
            {
                title: 'Arianee Product (multilanguage)',
                url: 'https://cert.arianee.org/version2/ArianeeProductCertificate-i18n.json',
                version: 3,
                name: SCHEMA_NAME.i18N,
                widget: widgetizeI18nV2
            },
            {
                title: 'Arianee Product (multilanguage)',
                url: 'https://cert.arianee.org/version3/ArianeeProductCertificate-i18n.json',
                version: 3,
                name: SCHEMA_NAME.i18N,
                widget: widgetizeI18nV3
            },
            {
                title: 'Arianee Product (multilanguage)',
                url: 'https://cert.arianee.org/version4/ArianeeProductCertificate-i18n.json',
                version: 3,
                name: SCHEMA_NAME.i18N,
                widget: widgetizeI18nV4
            }
        ];
    }
    Object.defineProperty(SchemaService, "schemasURL", {
        /**
         * Get all schemas url available
         */
        get: function () {
            return schemas;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SchemaService.prototype, "schemasURL", {
        /**
         * Instance version of schemasURL static getter
         */
        get: function () {
            return SchemaService.schemasURL;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SchemaService.prototype, "schemaList", {
        get: function () {
            return this._schemaList;
        },
        enumerable: true,
        configurable: true
    });
    SchemaService.prototype.get$Schema = function (version) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var schemaItem, schema;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        schemaItem = this.getSchemaItemFromVersionNumber(version);
                        return [4 /*yield*/, this.httpClient.get(schemaItem.url).toPromise()];
                    case 1:
                        schema = _a.sent();
                        return [2 /*return*/, schema];
                }
            });
        });
    };
    /**
     * Pass $schema (url) and return schema with hidden widget
     * @param {string} $schema
     */
    SchemaService.prototype.overrideSchemaWidget = function ($schemaURL) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var schema, $schema, d;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.httpClient.get($schemaURL).toPromise()];
                    case 1:
                        schema = _a.sent();
                        $schema = schema.$schema;
                        if (this.widgetOverride[$schema]) {
                            d = mergeWith(schema, this.widgetOverride[$schema], function (objValue, srcValue) {
                                if (isArray(objValue)) {
                                    return objValue.concat(srcValue);
                                }
                            });
                            return [2 /*return*/, d];
                        }
                        else {
                            return [2 /*return*/, schema];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SchemaService.prototype.getCertificateWithWidget = function (version) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var schema, schemaItem;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.get$Schema(version)];
                    case 1:
                        schema = _a.sent();
                        schemaItem = this.getSchemaItemFromVersionNumber(version);
                        schema = merge(schema, schemaItem.widget);
                        return [2 /*return*/, schema];
                }
            });
        });
    };
    SchemaService.prototype.getSchemaItemFromURL = function (url) {
        return this._schemaList.find(function (item) { return item.url === url; });
    };
    SchemaService.prototype.getSchemaItemFromVersionNumber = function (versionNumber) {
        return this._schemaList.find(function (item) { return item.version === versionNumber; });
    };
    SchemaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SchemaService_Factory() { return new SchemaService(i0.ɵɵinject(i1.HttpClient)); }, token: SchemaService, providedIn: "root" });
    return SchemaService;
}());
export { SchemaService };
