import { Component } from '@angular/core';
import { StringWidget } from 'ngx-schema-form';
import { ModalController } from '@ionic/angular';
import { TagsEnum } from '../../models/tags-enum';
import { map, take } from 'rxjs/operators';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { certificateDBTOCertificateItem } from '../../helpers/certificateDBToCertificateItem';
import { WalletWidgetComponent } from './wallet-widget-component/wallet';
import { Utils } from '../../services/utils/utils';
import { CertificateCoreService } from '../../services/certificate-service/certificate-core.service';

@Component({
  templateUrl: './parent-widget.html'
})
export class ParentWidget extends StringWidget {
    public button;
    public formProperty;

    constructor (public modalCtrl: ModalController,
                private uiAlertService: UIAlertService,
                private utils:Utils,
                private certificateService:CertificateCoreService
    ) {
      super();
    }

    async presentModal () {
      const loader = await this.uiAlertService.load();

      const parentCertificateList = await this.certificateService.getCertificateList({ tags: TagsEnum.parentCertificate })
        .pipe(
          take(1),
          map(data => data.map(certificateDBTOCertificateItem))
        )
        .toPromise();

      const uploadAssetsModal = await this.modalCtrl.create({
        component: WalletWidgetComponent,
        componentProps: {
          certificateList: parentCertificateList,
          user: {}
        }
      });

      await loader.dismiss();
      await uploadAssetsModal.present();

      const result = await uploadAssetsModal.onWillDismiss();
      if (result) {
        const { id, viewKey } = parentCertificateList.find(cert => cert.id === result.data);
        const deeplink = this.utils.deeplink(id, viewKey);

        this.formProperty.setValue(deeplink, false);
      }
    }
}
