import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ScriptLoaderService {
    public scripts = {
      pdfjs: {
        isLoaded: false,
        url: 'https://unpkg.com/jspdf@1.5.3/dist/jspdf.min.js'
      },
      swaggerEditor: {
        url: 'https://unpkg.com/swagger-editor-dist@3.13.0/swagger-editor-bundle.js',
        isLoaded: false
      },
      swaggerEditorStandalonePreset: {
        url: 'https://unpkg.com/swagger-editor-dist@3.13.0/swagger-editor-standalone-preset.js',
        isLoaded: false
      }

    };

    public loadScript (scriptName: string) {
      if (!this.scripts[scriptName].isLoaded) {
        return new Promise((resolve) => {
          const scriptToLoad = this.scripts[scriptName];

          const node = document.createElement('script'); // creates the script tag
          node.src = scriptToLoad.url; // sets the source (insert url in between quotes)
          node.type = 'text/javascript'; // set the script type
          node.async = true; // makes script run asynchronously
          node.charset = 'utf-8';
          // append to head of document
          document.getElementsByTagName('head')[0].appendChild(node);

          node.addEventListener('load', () => {
            this.scripts[scriptName].isLoaded = true;
            resolve();
          });
        });
      }
    }
}
