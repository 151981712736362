import { Component } from '@angular/core';

import { LoadingController } from '@ionic/angular';
import { AuthService } from './services/auth/auth';
import { config } from '../config';
import { filter, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UIAlertService } from './services/UI-alert-service/UI-alert-service';
import { EventLoggerService } from './services/event-logger/event-logger-service';

import { WalletService } from './apiServices/BDHApi/api/wallet.service';
import { FirebaseHttpClient } from './services/firebaseHttpClient/firebaseHttpClient';
import { WalletCoreService } from './services/walletCoreService/walletCoreService';
import { IdentityService } from './apiServices/BDHApi';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
    public navCtrl: any;

    rootPage: any;
    public token: any = '';
    public logged = false;
    public admin = false;
    public developerCount = 0;
    public config;
    public subscribeLogged = false;
    public brandLogo:any;

    blockiesOptions: Object = { // All options are optional
      seed: 'randstring', // seed used to generate icon data, default: random
      color: '#ffb200', // to manually specify the icon color, default: random
      bgcolor: '#5c5c5c', // choose a different background color, default: random
      size: 8, // width/height of the icon in blocks, default: 8
      scale: 4, // width/height of each block in pixels, default: 4
      spotcolor: '#000' // each pixel has a 13% chance of being of a third color,
      // default: random. Set to -1 to disable it. These "spots" create structures
      // that look like eyes, mouths and noses.
    };

    constructor (
        public authService: AuthService,
        public loadingCtrl: LoadingController,
        private router: Router,
        private eventLogger: EventLoggerService,
        private uiUserAlert: UIAlertService,
        private WalletService: WalletService,
        private firebaseHttpClient:FirebaseHttpClient,
        private walletCoreService:WalletCoreService,
        private identityService:IdentityService,
        private translateService:TranslateService
    ) {
      translateService.use('en');
      this.config = config;

      this.subscribeLogged = true;

      this.initArianeeProtocol();
    }

    async getIdentityLogo () {
      const address = await this.walletCoreService.getAddress();
      const identityObject = await this.identityService.getIdentity([address]).toPromise();
      if (identityObject && identityObject.data.pictures) {
        this.brandLogo = identityObject.data.pictures.find(element => element.type === 'brandLogoHeader');
      }
    }

    async initArianeeProtocol () {
      await this.authService.$userState
        .pipe(
          filter(d => d.isLoggedIn),
          take(1)
        )
        .toPromise();

      await this.firebaseHttpClient.init();

      const [userDetails, profile] = await Promise.all([this.authService.getUserDetails(), this.authService.getUserProfile()]);
      const loader = await this.uiUserAlert.load();

      this.logged = true;
      this.admin = (profile === 'admin');
      loader.dismiss();

      this.eventLogger.init();

      this.getIdentityLogo();
    }
}
