import { Component } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { Utils } from '../../services/utils/utils';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { ActivatedRoute, Router } from '@angular/router';
import { CertificateCoreService } from '../../services/certificate-service/certificate-core.service';
import { ArianeeEventService } from 'src/app/services/ArianeeEventService/arianeeEventService';
import { MessageService } from '../../services/messageService/messageService';
import { map, take } from 'rxjs/operators';
import { Location } from '@angular/common';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';
import { EconomyService } from '../../apiServices/BDHApi';

@Component({
  templateUrl: 'message-builder.page.html'
})
export class MessageBuilder {
    public schema: any = {
      properties: {}
    };

    public model: any;
    public item: any = {};
    public itemId: number;
    public certificate: any;
    private arrayOfCertificateIds: number[];

    constructor (
        public utils: Utils,
        public http: HttpClient,
        public loadingCtrl: LoadingController,
        public uIAleertService: UIAlertService,
        private arianeEventService: ArianeeEventService,
        private activiated: ActivatedRoute,
        private certificateService: CertificateCoreService,
        private router: Router,
        private messageService: MessageService,
        private location:Location,
        private eventLogger: EventLoggerService,
        private economyService:EconomyService

    ) {

    }

    async loadCreditLeft () {
      const credit = await this.economyService.balanceOfCredit(['message']).toPromise();

      if (+credit === 0) {
        this.utils.alert('Not enough credit', 'Please add credit to continue. Go on settings tab');
      }
    }

    public navigateBack () {
      this.location.back();
    }

    async ionViewDidEnter () {
      this.loadCreditLeft();

      this.arrayOfCertificateIds = await this.activiated.queryParams
        .pipe(
          take(1),
          map((d: any) => d.certificateIds),
          map((certificateIds: string) => certificateIds.split(',').map(certId => +certId))
        )
        .toPromise();

      this.eventLogger.logScreen('MessageBuilder-page', { itemIds: this.arrayOfCertificateIds });

      if (this.arrayOfCertificateIds.length === 0) {
        this.utils.alert('You need to specify at least one certificateID');
      } else if (this.arrayOfCertificateIds.length === 1) {
        this.itemId = this.arrayOfCertificateIds[0];
        this.item = await this.certificateService.getCertificateOnce(this.itemId).toPromise();
      } else {
        this.item.name = `${this.arrayOfCertificateIds.length} certificate owners`;
      }

      this.messageService.getSchema()
        .subscribe(schema => {
          this.schema = schema;
        });
    }

    async sendMessage (message) {
      const loading = await this.uIAleertService.load({
        message: 'Waiting blockchain confirmation for <b>message creation</b>.'
      });

      try {
        await Promise.all(this.arrayOfCertificateIds
          .map(certId => this.messageService.sendDMessage(certId, message)));
        loading.dismiss();
        const toast = await this.uIAleertService.toast({
          cssClass: 'e2e_message_created',
          message: 'Your message has been sent',
          duration: 2000
        });

        await toast.onDidDismiss();
        this.router.navigate([`/certificate/read/${this.itemId}`]);
      } catch (e) {
        await loading.dismiss();
        const toast = await this.uIAleertService.error(
          'Something went wrong.',
          {
            duration: 2000
          }
        );
      }
    }
}
