<ion-split-pane  contentId="main">

    <ion-menu  side="start" contentId="main" menuId="main">
        <ion-header>
            <div color="light" class="header">
                <ion-title (click)="iterateForDev()">
                    <div class="title">
                        <img alt="logo" height="20" src="assets/img/arianee@2x.png"> Brand Data Hub
                    </div>
                </ion-title>
            </div>
        </ion-header>
        <ion-content >

            <ion-list>

                <ion-item detail  data-e2e="menu-certificatesList" [routerLink]="['/certificate/list']"
                          [routerLinkActive]="['menuItem--active']" *ngIf="authService.hasRight('certRead')">
                    <ion-icon name="list" color="primary" item-start></ion-icon>
                    <span class="menuTitle"> {{ 'pages.certificateList.title' | translate }}
                        <ion-badge color="primary" *ngIf="certificateNotSync">Syncing {{certificateNotSync}}</ion-badge>
                    </span>
                </ion-item>

                <ion-item detail data-e2e="menu-certificateBuilder" menuToggle [routerLink]="['/certificate/create']"
                          *ngIf="authService.hasRight('certCreate')">
                    <ion-icon name="create" color="primary" item-start></ion-icon>
                    <span class="menuTitle">{{ 'pages.createCertificate.title' | translate }} </span>

                </ion-item>
                <br>

                <ion-item detail data-e2e="menu-setIdentity" [routerLink]="['/identity']"
                          *ngIf="authService.hasRight('identityUpdate')">
                    <ion-icon name="newspaper-outline" color="primary" item-start></ion-icon>
                    <span class="menuTitle"> {{ 'pages.brandIdentity.title' | translate }} </span>
                </ion-item>

                <ion-item detail data-e2e="menu-autoMessages" [routerLink]="['/message']"
                          *ngIf="authService.hasRight('messageSetupAuto')">
                    <ion-icon name="mail" color="primary" item-start></ion-icon>
                    <span class="menuTitle"> {{ 'pages.autoMessage.title' | translate }} </span>
                </ion-item>
                <br>

                <ion-item detail [routerLink]="['/reporting']" *ngIf="authService.hasRight('reporting')">
                    <ion-icon name="stats-chart-outline" color="primary" item-start></ion-icon>
                    <span class="menuTitle"> {{ 'pages.reporting.title' | translate }} </span>
                </ion-item>
                <ion-item detail data-e2e="menu-uploadAssets" [routerLink]="['/assets/upload']"
                          *ngIf="authService.hasRight('assetsUpload')">
                    <ion-icon name="cloud-upload" color="primary" item-start></ion-icon>
                    <span class="menuTitle"> {{ 'pages.assets.title' | translate }} </span>
                </ion-item>


                <br>

                <ion-item detail data-e2e="menu-settings" [routerLink]="['/settings']"
                          *ngIf="authService.hasRight('accountManage')">
                    <ion-icon name="cog" color="primary" item-start></ion-icon>
                    <span class="menuTitle"> {{ 'pages.settings.title' | translate }} </span>
                </ion-item>

                <ion-item detail data-e2e="menu-usersManager" [routerLink]="['/users']"
                          *ngIf="authService.hasRight('usersManage')">
                    <ion-icon name="people-circle-outline" color="primary" item-start></ion-icon>
                    <span class="menuTitle"> {{ 'pages.userAndProfile.title' | translate }} </span>
                </ion-item>
                <ion-item detail data-e2e="menu-adminPage" [routerLink]="['/admin']"
                          *ngIf="logged && admin && developerCount>5">
                    <ion-icon name="lock-closed-outline" color="primary" item-start></ion-icon>
                    <span class="menuTitle"> {{ 'pages.admin.title' | translate }} </span>
                </ion-item>
                <ion-item detail data-e2e="menu-transactionPage" [routerLink]="['/transaction']"
                        *ngIf="authService.hasRight('accountManage')">
                    <ion-icon name="receipt-outline" color="primary" item-start></ion-icon>
                    <span class="menuTitle"> {{ 'pages.blockchainTransactions.title' | translate }}
                        <ion-badge color="primary" *ngIf="transactionsPending"
                                   data-e2e="waiting_transactions"
                        >{{transactionsPending}}</ion-badge>
                    </span>
                </ion-item>
                <br>
                <br>

                <ion-item data-e2e="menu-logout" (click)="logout()" class="clickable" >
                    <ion-icon name="log-out" color="primary" item-start></ion-icon>
                    <span class="menuTitle"> {{ 'pages.logout.title' | translate }}</span>
                </ion-item>
            </ion-list>

            <div [routerLink]="['/transaction']"></div>


        </ion-content>
        <ion-footer class="menuFooter">
            <img *ngIf="brandLogo" [src]="brandLogo.url" alt="">
            <p><a href="/assets/docs/changelog.md">Version {{version}}</a><span style="color:#F00; font-weight: bold"
                *ngIf="config&&config.network!='mainnet'"> / testnet </span> / <a href="/docs-api" target="_blank">API DOC</a></p>

        </ion-footer>
    </ion-menu>

    <ion-router-outlet id="main"></ion-router-outlet>

</ion-split-pane>


