import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { UserSessionService } from '../../services/user-session-service/user-session-service';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { ArianeejsAPIService } from '../../apiServices/arianeejsAPIService';
import * as i0 from "@angular/core";
import * as i1 from "../../services/user-session-service/user-session-service";
import * as i2 from "../../services/UI-alert-service/UI-alert-service";
import * as i3 from "../../apiServices/arianeejsAPIService";
import * as i4 from "@angular/router";
var CreditCheckGuard = /** @class */ (function () {
    function CreditCheckGuard(userSessionService, uiSessionAlter, arianeeJsServerService, router) {
        this.userSessionService = userSessionService;
        this.uiSessionAlter = uiSessionAlter;
        this.arianeeJsServerService = arianeeJsServerService;
        this.router = router;
        this.userJustArrived = true;
        this.minimumCredit = 10;
    }
    CreditCheckGuard.prototype.canActivate = function (route, state) {
        if (this.userJustArrived) {
            this.checkCredits();
            this.userJustArrived = false;
        }
        return true;
    };
    CreditCheckGuard.prototype.userCredits = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, certificate, event, message;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            this.arianeeJsServerService.balanceOfCredit('certificate').toPromise()
                        ])];
                    case 1:
                        _a = _b.sent(), certificate = _a[0], event = _a[1], message = _a[2];
                        return [2 /*return*/, {
                                certificate: certificate,
                                event: event,
                                message: message
                            }];
                }
            });
        });
    };
    CreditCheckGuard.prototype.checkCredits = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var credits, lowCredit;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.userCredits()];
                    case 1:
                        credits = _a.sent();
                        lowCredit = Object.keys(credits)
                            .map(function (key) { return credits[key]; })
                            .filter(function (value) { return value < _this.minimumCredit; });
                        if (lowCredit.length > 0) {
                            this.uiSessionAlter.alert({
                                message: 'Your credit balance is low. Please visit your "Manage Account" section.',
                                buttons: [
                                    {
                                        text: 'Buy credits',
                                        handler: function () {
                                            _this.router.navigate(['settings']);
                                        }
                                    },
                                    {
                                        text: 'Later',
                                        role: 'cancel'
                                    }
                                ]
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    CreditCheckGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CreditCheckGuard_Factory() { return new CreditCheckGuard(i0.ɵɵinject(i1.UserSessionService), i0.ɵɵinject(i2.UIAlertService), i0.ɵɵinject(i3.ArianeejsAPIService), i0.ɵɵinject(i4.Router)); }, token: CreditCheckGuard, providedIn: "root" });
    return CreditCheckGuard;
}());
export { CreditCheckGuard };
