import { Component } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { Utils } from '../../services/utils/utils';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { ActivatedRoute, Router } from '@angular/router';
import { CertificateCoreService } from '../../services/certificate-service/certificate-core.service';
import { ArianeeEventService } from 'src/app/services/ArianeeEventService/arianeeEventService';
import { Location } from '@angular/common';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';
import { EconomyService, EventService } from '../../apiServices/BDHApi';

@Component({
  selector: 'page-eventBuilder',
  templateUrl: 'eventBuilder.html'
})
export class EventBuilder {
    public schema: any = {
      properties: {}
    };

    public model: any;
    public myActions: any;
    public item: any;
    public itemId: number;
    public certificate: any;
    public event: any;
    public eventId: number;
    public credit: number;
    public autoAccept: boolean;

    constructor (
        public utils: Utils,
        public http: HttpClient,
        public loadingCtrl: LoadingController,
        public uIAleertService: UIAlertService,
        private arianeEventService: ArianeeEventService,
        private activiated: ActivatedRoute,
        private certificateService: CertificateCoreService,
        private router: Router,
        private location: Location,
        private eventLogger: EventLoggerService,
        private economyService: EconomyService,
        private eventService: EventService
    ) {

    }

    public navigateBack () {
      this.location.back();
    }

    loadCreditLeft = async () => {
      const credit = await this.economyService.balanceOfCredit(['event']).toPromise();
      this.credit = +credit;
      if (this.credit === 0) {
        this.utils.alert('Not enough credit', 'Please add credit to continue. Go on settings tab');
      }
    }

    async ionViewDidEnter () {
      this.loadCreditLeft();
      this.itemId = parseInt(this.activiated.snapshot.paramMap.get('certificateId'));

      this.eventLogger.logScreen('eventBuilder-page', { itemId: this.itemId });

      this.item = await this.certificateService.getCertificateOnce(this.itemId).toPromise();
    }

    async saveEvent (event) {
      const loading = await this.uIAleertService.load({
        message: 'Waiting blockchain confirmation for <b>event creation</b>.'
      });

      try {
        if (!this.eventId) {
          this.eventId = Math.round(Math.random() * 100000);
        }

        await this.arianeEventService.createEvent({
          content: {
            $schema: this.schema,
            ...event
          },
          certificateId: this.itemId,
          arianeeEventId: this.eventId
        });

        if (this.autoAccept) {
          await this.eventService.acceptArianeeEvent([this.eventId]).toPromise();
        }

        loading.dismiss();

        const toast = await this.uIAleertService.toast({
          cssClass: 'e2e_event_created',
          message: 'Your event is created.',
          duration: 2000
        });

        await toast.onDidDismiss();

        this.router.navigate([`/certificate/read/${this.itemId}`]);
      } catch (e) {
        await loading.dismiss();
        const toast = await this.uIAleertService.error(
          'Something went wrong.',
          {
            duration: 2000
          }
        );
      }
    }
}
