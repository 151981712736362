import { Injectable } from '@angular/core';
import { FEATURE_FLIP } from './feature-flip-enum';
import * as Config from '../../../config';

@Injectable({ providedIn: 'root' })
export class FeatureFlipService {
    public ENUM = FEATURE_FLIP;

    private feature: { [key: string]: boolean } = (Config.config as any).featureFlips;

    public isFeatureFlip (featureFlipName: FEATURE_FLIP) {
      const isFeatureFlip = this.feature && this.feature[featureFlipName];

      return isFeatureFlip || false;
    }
}
