import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { Utils } from '../../services/utils/utils';

import { AuthService } from '../../services/auth/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { CertificateCoreService } from '../../services/certificate-service/certificate-core.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { MessageService } from '../../services/messageService/messageService';
import { map, take } from 'rxjs/operators';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';
import { CertificateService, IdentityService, WhitelistService } from '../../apiServices/BDHApi';
import { WalletCoreService } from '../../services/walletCoreService/walletCoreService';

/*
*
*  Wallet Page
*
*/
@Component({
  selector: 'page-item',
  styleUrls: ['item.scss'],
  templateUrl: 'item.html'
})
export class Item implements OnInit {
  public itemId: any;
  public item: any;
  public currentOwner: any;
  public itemsEvents: any = [];
  public isOwner: boolean = false;
  public message: any = {};
  public passphrase: string;
  public recoverable: boolean = false;
  public recoverableTested: boolean = false;
  public subscribe: any = {};
  public isBrandAthorizedToMessage: any;
  public eventsJSON: any;
  public isDestroyed: boolean;
  private viewKey: string;
  public qrCode: string;
  public deepLink: string;
  public createdAt:number;

  constructor (
      public utils: Utils,
      private activiated: ActivatedRoute,
      public loadingCtrl: LoadingController,
      public alertController: AlertController,
      public authService: AuthService,
      private router: Router,
      private certificateCoreService:CertificateCoreService,
      private http: HttpClient,
      private _firebaseAuth: AngularFireAuth,
      private uiAlert: UIAlertService,
      private messageService: MessageService,
      private eventLogger: EventLoggerService,
      private certificateService: CertificateService,
      private identityService: IdentityService,
      private whitelistService: WhitelistService,
      private walletCoreService: WalletCoreService
  ) {

  }

  ngOnInit () {
    this.itemId = parseInt(this.activiated.snapshot.paramMap.get('certificateId'));
    this.eventLogger.logScreen('item-page', { itemId: this.itemId });

    this.load();
  }

  async load () {
    const loader = await this.uiAlert.load();
    try {
      await this.loadContent();
      await this.getBddInfos();
      loader.dismiss();
      await this.loadBrandMessagingAuthorization();
      await this.loadEvent();
      await this.loadQRCode();
    } catch (e) {
      console.error(e);
      loader.dismiss();
      this.uiAlert.error('An error occured while trying to display your certificate');
    }
  }

  get canSendMessage (): boolean {
    return this.item !== undefined &&
        !this.isOwner &&
        !this.isDestroyed &&
        this.authService.hasRight('messageSend') &&
        this.isBrandAthorizedToMessage;
  }

  get canDisplayQRCode (): boolean {
    return this.isOwner &&
        this.authService.hasRight('certPrint');
  }

  get canCreateEvent (): boolean {
    return this.authService.hasRight('eventCreate');
  }

  get canCertificateCreate (): boolean {
    return this.authService.hasRight('certCreate');
  }

  get canCertificateUpdate (): boolean {
    return this.authService.hasRight('certCreate');
  }

  get canRecover (): boolean {
    return this.recoverableTested &&
        !this.isOwner &&
        !this.isDestroyed &&
        this.authService.hasRight('certRecover');
  }

  get canDestroy (): boolean {
    return this.isOwner &&
        this.authService.hasRight('certDelete');
  }

  async getBddInfos () {
    const certif = await this.certificateCoreService.getCertificate(this.itemId)
      .toPromise();

    const { viewKey, mintTimeStamp } = certif as any;
    if (!viewKey) {
      this.uiAlert.error('an error occurer. This Certificate does not have viewkey');
    }
    this.viewKey = viewKey;
    this.createdAt = mintTimeStamp * 1000;
  }

  public navigateTosendDmessage () {
    this.router.navigate(['/certificate/message/create'], { queryParams: { certificateIds: this.itemId } });
  }

  public findBrandLogoSquareFromIdentity (data): string {
    if (data && data.pictures) {
      const brandLogoSquare = data.pictures.find(element => element.type === 'brandLogoSquare');
      if (brandLogoSquare) {
        return brandLogoSquare.url;
      }
    }
  }

  async loadQRCode () {
    this.deepLink = `${this.utils.deeplink()}${this.itemId},${this.viewKey}`;
    this.qrCode = `https://tools.arianee.org/qrcode.php?&size=200x200&logo=https://demo.arianee.org/demo/logoa.png&data=${this.deepLink}`;
  }

  private loadContent = async () => {
    // @ts-ignore
    const { owner, recover, content } = await this.certificateService
      .getCertificate([
        this.itemId,
        this.viewKey,
        {
          content: true,
          owner: true,
          recover: true
        }
      ]
      ).toPromise();

    this.item = content.data;
    this.currentOwner = owner.address;
    this.isOwner = owner.isOwner;

    this.recoverable = recover.isRecoverable;
    this.recoverable = true;
    this.recoverableTested = true;

    this.isDestroyed = owner.address === '0x000000000000000000000000000000000000dead';
  };

  private loadEvent = async () => {
    const { events } = await this.certificateService
      .getCertificate([
        this.itemId,
        this.viewKey,
        {
          events: true,
          arianeeEvents: true
        }
      ]
      ).toPromise();

    this.itemsEvents = events.transfer;
    this.itemsEvents.shift();
    this.itemsEvents.reverse();

    this.eventsJSON = events.arianeeEvents.map((arianeeEvent: any) => {
      return {
        json: arianeeEvent.content.data,
        issuerAddress: arianeeEvent.issuer.address,
        mintTimeStamp: arianeeEvent.timestamp
      };
    });
  };

  async loadBrandMessagingAuthorization () {
    const address = await this.walletCoreService.getAddress();

    this.isBrandAthorizedToMessage = await this.whitelistService.isAuthorized([
      this.itemId,
      address,
      this.currentOwner
    ]).toPromise();
  }

  duplicate (item) {
    return this.router.navigate([`/certificate/duplicate/${this.itemId}`]);
  }

  update (item) {
    return this.router.navigate([`/certificate/update/${this.itemId}`]);
  }

  asynchTransferItem () {
    return this.router.navigate(['certificate', 'transfer', this.itemId]);
  }

  async destroyItem () {
    const alert = await this.alertController.create({
      header: 'Are you sure ?',
      message: 'This action cannot be undone',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Confirm',
          handler: () => {
            this.destroyItemProcess();
          }
        }
      ]
    });

    await alert.present();
  }

  async destroyItemProcess () {
    const loading = await this.loadingCtrl.create({
      spinner: 'dots',
      message: 'Certificate destruction in progress'
    });
    loading.present();

    await this.certificateService.addTokenAccess([this.itemId, '0x0000000000000000000000000000000000000000', false, 1]).toPromise();
    this.certificateService.destroyCertificate([this.itemId]).toPromise();
    loading.dismiss();
  }

  async recoverItem () {
    const alert = await this.alertController.create({
      header: 'Are you sure ?',
      message: 'This action cannot be undone',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Confirm',
          handler: () => {
            this.recoverItemProcess();
          }
        }
      ]
    });

    alert.present();
  }

  async recoverItemProcess () {
    const loading = await this.loadingCtrl.create({
      spinner: 'dots',
      message: 'Certificate recovering in progress'
    });
    loading.present();

    await this.certificateService.recoverCertificate([this.itemId]).toPromise();
    await this.certificateService.createCertificateRequestOwnershipLink([this.itemId, this.viewKey]).toPromise();

    loading.dismiss();
  }
}
