export const widgetizeI18nV2 = {
  properties: {
    $schema: {
      widget: 'hidden'
    },
    name: {},
    sku: {},
    gtin: {},
    brandInternalId: {},
    category: {
      widget: 'select'
    },
    subCategory: {
      widget: 'select'
    },
    intended: {
      widget: 'select'
    },
    serialnumber: {
      items: {
        properties: {
          type: {
            widget: 'select',
            oneOf: [
              {
                description: '[TEST ONLY - DO NOT USE IN PROD] Swiss Made',
                title: ' [TEST ONLY - DO NOT USE IN PROD] Swiss Made',
                enum: [
                  'swissmade'
                ]
              }
            ]
          },
          value: {
            widget: 'string'
          }

        }
      }
    },
    subBrand: {},
    model: {},
    language: {
      widget: 'select'
    },
    description: {
      widget: 'textarea'
    },
    subDescription: {
      items: {
        type: 'object',
        properties: {
          type: {
            widget: 'select'
          },
          title: {
            widget: 'string'
          },
          content: {
            widget: 'textarea'
          },
          order: {
            widget: 'hidden'
          }
        }
      }
    },
    externalContents: {
      items: {
        properties: {
          type: {
            widget: 'select',
            oneOf: [
              {
                enum: [
                  'resellLink'
                ],
                title: 'Resell Link',
                description: '[TEST ONLY - DO NOT USE IN PROD] Resell Link'
              },
              {
                enum: [
                  'repairLink'
                ],
                title: 'Repair Link',
                description: '[TEST ONLY - DO NOT USE IN PROD] Repair Link'
              },
              {
                enum: [
                  'lostLink'
                ],
                title: 'Lost Link',
                description: '[TEST ONLY - DO NOT USE IN PROD] Lost Link'
              },
              {
                enum: [
                  'system'
                ],
                title: 'System Link',
                description: '[TEST ONLY - DO NOT USE IN PROD] System Link'
              }
            ]
          },
          title: {
            widget: 'string'
          },
          order: {
            widget: 'number'
          },
          url: {
            widget: 'staticAssets'
          }
        }
      }
    },

    transparencyItems: {
      type: 'array',
      title: 'Transparency information about product',
      description: 'Transparency information regarding the product',
      items: {
        type: 'object',
        properties: {
          title: {
            type: 'string',
            title: 'Title to be displayed'
          },
          description: {
            type: 'string',
            title: 'Description',
            description: 'Description of the product. (HTML Accepted)\n A description can be stored for each language'
          },
          externalContents: {
            items: {
              properties: {
                type: {
                  widget: 'select'
                },
                title: {
                  widget: 'string'
                },
                order: {
                  widget: 'number'
                },
                url: {
                  widget: 'staticAssets'
                }
              }
            }
          },
          medias: {
            items: {
              properties: {
                mediaType: {
                  widget: 'select'
                },
                type: {
                  widget: 'select'
                },
                url: {
                  widget: 'staticAssets'
                },
                hash: {
                  widget: 'hidden'
                },
                order: {
                  widget: 'number'
                }
              }
            }
          },
          type: {
            widget: 'select'
          }
        }
      }
    },
    i18n: {
      items: {
        type: 'object',
        properties: {
          language: {
            widget: 'select'
          },
          description: {
            widget: 'textarea'
          },
          subDescription: {
            items: {
              properties: {
                type: {
                  widget: 'select'
                },
                title: {
                  widget: 'string'
                },
                content: {
                  widget: 'textarea'
                },
                order: {
                  widget: 'hidden'
                }
              }
            }
          },
          medias: {
            items: {
              properties: {
                mediaType: {
                  widget: 'select'
                },
                type: {
                  widget: 'select'
                },
                url: {
                  widget: 'staticAssets'
                },
                hash: {
                  widget: 'hidden'
                },
                order: {
                  widget: 'order'
                }
              }
            }
          },
          externalContents: {
            items: {
              properties: {
                type: {
                  widget: 'select',
                  oneOf: [
                    {
                      enum: [
                        'resellLink'
                      ],
                      title: 'Resell Link',
                      description: '[TEST ONLY - DO NOT USE IN PROD] Resell Link'
                    },
                    {
                      enum: [
                        'repairLink'
                      ],
                      title: 'Repair Link',
                      description: '[TEST ONLY - DO NOT USE IN PROD] Repair Link'
                    },
                    {
                      enum: [
                        'lostLink'
                      ],
                      title: 'Lost Link',
                      description: '[TEST ONLY - DO NOT USE IN PROD] Lost Link'
                    },
                    {
                      enum: [
                        'system'
                      ],
                      title: 'System Link',
                      description: '[TEST ONLY - DO NOT USE IN PROD] System Link'
                    }
                  ]
                },
                title: {
                  widget: 'string'
                },
                url: {
                  widget: 'staticAssets'
                },
                order: {
                  widget: 'number'
                }
              }
            }
          },
          transparencyItems: {
            type: 'array',
            title: 'Transparency information about product',
            description: 'Transparency information regarding the product',
            items: {
              type: 'object',
              properties: {
                title: {
                  type: 'string',
                  title: 'Title to be displayed'
                },
                description: {
                  type: 'string',
                  title: 'Description',
                  description: 'Description of the product. (HTML Accepted)\n A description can be stored for each language'
                },
                externalContents: {
                  items: {
                    properties: {
                      type: {
                        widget: 'select'
                      },
                      title: {
                        widget: 'string'
                      },
                      order: {
                        widget: 'hidden'
                      },
                      url: {
                        widget: 'staticAssets'
                      }
                    }
                  }
                },
                medias: {
                  items: {
                    properties: {
                      mediaType: {
                        widget: 'select'
                      },
                      type: {
                        widget: 'select'
                      },
                      url: {
                        widget: 'staticAssets'
                      },
                      hash: {
                        widget: 'hidden'
                      },
                      order: {
                        widget: 'hidden'
                      }
                    }
                  }
                },
                type: {
                  widget: 'select'
                }
              }
            }
          }
        }
      }
    },
    msrp: {
      items: {
        properties: {
          msrp: {
          },
          currency: {
            widget: 'select'
          },
          msrpCountry: {
          }
        }
      }
    },

    medias: {
      items: {
        properties: {
          mediaType: {
            widget: 'select'
          },
          type: {
            widget: 'select'
          },
          url: {
            widget: 'staticAssets'
          },
          hash: {
            widget: 'hidden'
          },
          order: {
            widget: 'hidden'
          }
        }
      }
    },
    attributes: {
      items: {
        properties: {
          type: {
            widget: 'select'
          },
          value: {
            widget: 'string'
          }
        }
      }
    },
    materials: {
      items: {
        properties: {
          material: {
            widget: 'select'
          },
          value: {
            widget: 'hidden'
          },
          pourcentage: {
            widget: 'string'
          }
        }
      }
    },
    size: {
      items: {
        properties: {
          type: {
            widget: 'select'
          },
          value: {
            widget: 'string'
          },
          unit: {
            widget: 'select'
          }
        }
      }
    },
    manufacturingCountry: {},
    facilityId: {},
    productCertification: {
      items: {
        type: 'object',
        properties: {
          name: {
            widget: 'select'
          }
        }
      }
    }
  },
  required: [
    '$schema'
  ]
};
