import { map } from 'rxjs/operators';
import { EndpointsService } from '../../apiServices/endpointsService/endpointsService';
import { FirebaseHttpClient } from '../firebaseHttpClient/firebaseHttpClient';
import * as i0 from "@angular/core";
import * as i1 from "../../apiServices/endpointsService/endpointsService";
import * as i2 from "../firebaseHttpClient/firebaseHttpClient";
var DomainService = /** @class */ (function () {
    function DomainService(endpointService, firebaseHttpClient) {
        this.endpointService = endpointService;
        this.firebaseHttpClient = firebaseHttpClient;
    }
    DomainService.prototype.getDomains = function () {
        return this.firebaseHttpClient.get(this.endpointService.endpoints.domain.list)
            .pipe(map(function (d) { return d[0]; }));
    };
    DomainService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DomainService_Factory() { return new DomainService(i0.ɵɵinject(i1.EndpointsService), i0.ɵɵinject(i2.FirebaseHttpClient)); }, token: DomainService, providedIn: "root" });
    return DomainService;
}());
export { DomainService };
