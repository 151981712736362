import { Component, ElementRef, Renderer2 } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular'; // PopoverController
import { Utils } from '../../services/utils/utils';
import { AuthService } from '../../services/auth/auth';
import { AngularFireAuth } from '@angular/fire/auth';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';

import { TruncateModule } from '@yellowspot/ng-truncate';
import { SchemaService } from '../../services/schema-service/schema-service';
import { CertificateCoreService } from '../../services/certificate-service/certificate-core.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { UserSessionService } from '../../services/user-session-service/user-session-service';
import { HttpClient } from '@angular/common/http';
import { FeatureFlipService } from '../../services/feature-flip-service/feature-flip-service';
import { range, pickBy } from 'lodash';
import { Location } from '@angular/common';
import { CertificateService } from '../../apiServices/BDHApi';
import { TagsEnum } from '../../models/tags-enum';

@Component({
  selector: 'page-jsonBuilder',
  styleUrls: ['./jsonBuilder.scss'],
  templateUrl: 'jsonBuilder.html'
})

export class JsonBuilder {
    public certificateDocumentModel: any = {};
    value: any;
    tokenId: number;
    tokenIdInput: number;
    credit: number;
    subscribe: any = {};
    duplicateValue: string;
    duplicateValueLines: number;
    arianeeIds: string;
    arianeeIdsLines: number;
    recoverKey: string;
    recoverKeyLines: number;
    tags: string[] = [];
    certNumber: number;
    certificateId: number;
    method: string;
    assetType: string;
    public enableSaveBtn = true;
    public schemaErrors;
    public sameRequestOwnershipPassphrase: boolean = true;
    public parentCertificateTitle: string = '';
    public customTags: string = '';

    public isParent = false;

    public schemaUrls: string[] = [];

    public templateForWysiwyg;
    public schema: any = {
      properties: {}
    };

    loaded = false;

    constructor (
        public utils: Utils,
        private authService: AuthService,
        public truncate: TruncateModule,
        public loadingCtrl: LoadingController,
        private _firebaseAuth: AngularFireAuth,
        private httpClient: HttpClient,
        private toasterCtrl: ToastController,
        private schemaService: SchemaService,
        private _renderer: Renderer2,
        private elem: ElementRef,
        private certificateService: CertificateService,
        private certificateCoreService: CertificateCoreService,
        private activiatedRoute: ActivatedRoute,
        private uIAlertService: UIAlertService,
        private userSessionService:UserSessionService,
        public featureFlipService: FeatureFlipService,
        private router: Router,
        private eventLogger:EventLoggerService,
        private location:Location

    ) {

    }

    async ionViewDidEnter () {
      const loader = await this.uIAlertService.load();
      try {
        this.eventLogger.logScreen('jsonBuilder-page');

        this.schemaUrls = [
          'https://cert.arianee.org/version2/ArianeeProductCertificate-i18n.json',
          'https://cert.arianee.org/version1/ArianeeAsset.json',
          'https://cert.arianee.org/version4/ArianeeProductCertificate-i18n.json'
        ];

        this.userSessionService.preventUserFromLeavingPage = false;

        const certificateId = this.certificateId = parseInt(this.activiatedRoute.snapshot.paramMap.get('certificateId'));

        this.method = this.activiatedRoute.snapshot.paramMap.get('method');

        if (this.method === 'update') {
          this.assetType = 'update';
          this.tokenIdInput = certificateId;
        } else {
          this.assetType = 'certificate';
          this.certificateCoreService.getFreeTokenId()
            .then(freeCertifId => this.tokenIdInput = freeCertifId);
        }

        let tmpmodel;
        if (certificateId) {
          const certificateInDatabase = await this.certificateCoreService.getCertificate(certificateId).toPromise();

          if (certificateInDatabase.tags.length) {
            const parentCertificateTagIndex = certificateInDatabase.tags.indexOf(TagsEnum.parentCertificate);

            if (parentCertificateTagIndex > -1) {
              this.isParent = true;
              certificateInDatabase.tags.splice(parentCertificateTagIndex, 1);
            }

            this.customTags = certificateInDatabase.tags.join(', ');
          }

          const { content } = await this.certificateService.getCertificate([certificateId,
            certificateInDatabase.viewKey, {
              content: true
            }]).toPromise();

          if (!content.isAuthentic) {
            this.uIAlertService.alert({ message: 'the content of this certificate is not authentic' });
          }
          tmpmodel = content.raw;
        }

        // For older version of serial number
        if (tmpmodel && tmpmodel.serialnumber && !Array.isArray(tmpmodel.serialnumber)) {
          tmpmodel.serialnumber = [{ type: 'serialnumber', value: tmpmodel.serialnumber }];
        }
        if (tmpmodel) {
          this.certificateDocumentModel = tmpmodel;
        }
        this.loaded = true;
      } catch (e) {
        console.error(e);
        this.uIAlertService.error('something went wrong');
      }
      loader.dismiss();
    }

    public navigateBack () {
      this.location.back();
    }

    updateCertNumber () {
      if (this.arianeeIdsLines === this.duplicateValueLines && this.duplicateValueLines === this.recoverKeyLines) {
        this.certNumber = this.duplicateValueLines;
      } else if (this.arianeeIdsLines === this.duplicateValueLines && !this.recoverKeyLines) {
        this.certNumber = this.duplicateValueLines;
      } else if (this.arianeeIdsLines === this.recoverKeyLines && !this.duplicateValueLines) {
        this.certNumber = this.arianeeIdsLines;
      } else if (this.duplicateValueLines === this.recoverKeyLines && !this.arianeeIdsLines) {
        this.certNumber = this.duplicateValueLines;
      } else if (this.arianeeIdsLines && !this.duplicateValueLines && !this.recoverKeyLines) {
        this.certNumber = this.arianeeIdsLines;
      } else if (this.duplicateValueLines && !this.arianeeIdsLines && !this.recoverKeyLines) {
        this.certNumber = this.duplicateValueLines;
      } else {
        this.certNumber = 0;
      }
    }

    updateDuplicateValue () {
      if (this.duplicateValue) {
        this.duplicateValueLines = this.duplicateValue.trim().split(/\r\n|\r|\n/).length;
      } else {
        this.duplicateValueLines = 0;
      }

      this.updateCertNumber();
    }

    updateArianeeId () {
      if (this.arianeeIds) {
        this.arianeeIdsLines = this.arianeeIds.trim().split(/\r\n|\r|\n/).length;
      } else {
        this.arianeeIdsLines = 0;
      }

      this.updateCertNumber();
    }

    updateRecoverKey () {
      if (this.recoverKey) {
        this.recoverKeyLines = this.recoverKey.trim().split(/\r\n|\r|\n/).length;
      } else {
        this.recoverKeyLines = 0;
      }

      this.updateCertNumber();
    }

    async checkAvaibility (tokensId: number[]) {
      const loading = await this.uIAlertService.load(
        { message: 'Checking Arianee IDs availabilities' });

      const result = await this.certificateCoreService.certificateIdsAvailibility(tokensId);

      loading.dismiss();

      return result;
    }

    async verifyArianeeIdNotSameOwner (): Promise<{ error: boolean, nbError: number }> {
      if (this.arianeeIds) {
        const ArianeeIds = this.arianeeIds
          .trim()
          .split(/\r\n|\r|\n/)
          .map(d => +d);

        const result = await this.checkAvaibility(ArianeeIds);

        return {
          nbError: result.notAvailablesIds.length,
          error: result.notAvailablesIds.length > 0
        };
      }
    }

    async verifyArianeeV2Certificate (json): Promise<boolean> {
      const regexi18n = /https:\/\/cert\.arianee\.org\/version\d\/(ArianeeProductCertificate-i18n\.json)/;
      if (json.$schema.match(regexi18n)) {
        let hasErrors = false;
        if (json.medias) {
          const loading = await this.uIAlertService.load({
            message: "Confirming your certificate's medias."
          });

          const data = await this.certificateCoreService.doctorI18N(json);

          this.schemaErrors = data.medias
            .filter(m => !m.doctor.valid);

          hasErrors = this.schemaErrors.length !== 0;

          await loading.dismiss();
        }

        if (hasErrors) {
          const mediasWithErrors = this.schemaErrors
            .map(mediaWithError => {
              const { mediaType, type, url } = mediaWithError;
              return `media type: ${mediaType} | type ${type} | ${url}`;
            }).join('\n');

          const loading = await this.uIAlertService.alert(
            {
              header: "Certificate's medias do not match specs",
              message: mediasWithErrors
            }
            , true);
        } else {
          this.schemaErrors = undefined;
        }

        return hasErrors;
      }

      return false;
    }

    onChangeModel (event) {
      this.templateForWysiwyg = event;
    }

    updateCertificate = async (certificateDocument) => {
      const result = await this.certificateCoreService.update({
        certificateId: this.tokenIdInput,
        content: certificateDocument
      });

      this.successCreatingCertificate(`Your certificate (${this.tokenIdInput}) has been updated`);
      return result;
    };

    async executeBlockChainOperation (certificateDocument) {
      let hasError;
      try {
        hasError = await this.verifyArianeeV2Certificate(certificateDocument);
      } catch (e) {
        console.warn(e);
        console.error('error in your certificate');
        hasError = true;
      }

      if (hasError) {
        this.uIAlertService.error('An error occured in your certificate. Please check contents and medias');
      }
      if (this.isParent && this.parentCertificateTitle.length === 0) {
        hasError = true;
        this.uIAlertService.error('Your certificate is tagged as a parent certificate. A title is required');
      }
      const loader = await this.uIAlertService.load();
      this.enableSaveBtn = false;

      let result;
      let certificateId;
      try {
        if (!hasError) {
          if (this.assetType === 'certificate') {
            result = await this.createCertificate(certificateDocument);
            certificateId = result.certificateId;
            await this.updateProperties(certificateId);

            this.assetType = 'certificate';
            this.certificateCoreService.getFreeTokenId()
              .then(freeCertifId => this.tokenIdInput = freeCertifId);
          } else if (this.assetType === 'update') {
            certificateId = this.tokenIdInput;
            await this.updateCertificate(certificateDocument);
          } else {
            throw new Error(`this method does not exist: ${this.method}`);
          }
        }

        this.enableSaveBtn = true;
        loader.dismiss();
      } catch (e) {
        this.enableSaveBtn = true;
        loader.dismiss();
        this.uIAlertService.error('An error occured');
      }
    }

    private updateProperties = async (certificateId) => {
      const customTags = this.customTags.split(',').map(item => item.trim());
      const body = {} as any;
      if (this.isParent) {
        customTags.push(TagsEnum.parentCertificate);
        body.displayTitle = this.parentCertificateTitle;
      }

      body.tags = customTags;
      if (customTags.length > 0) {
        return this.certificateService
          .certificateProperties(body, certificateId)
          .toPromise();
      }
    };

    async duplicateCert () {
      if (this.arianeeIdsLines) {
        const testIds = await this.verifyArianeeIdNotSameOwner();
        if (testIds.nbError) {
          this.utils.alert('Some Arianee IDs (' + (testIds.nbError) + ') are unavailable...');
          return;
        }
      }

      if (this.arianeeIdsLines && this.duplicateValueLines && this.arianeeIdsLines !== this.duplicateValueLines) {
        this.utils.alert('Same number of Arianee IDs and custom values are required ...');
        return;
      }

      if (this.arianeeIdsLines && this.recoverKeyLines && this.arianeeIdsLines !== this.recoverKeyLines) {
        this.utils.alert('Same number of Arianee IDs and recoverKeys are required ...');
        return;
      }

      if (this.recoverKeyLines && !this.arianeeIdsLines) {
        this.utils.alert('No recoverKey allowed if random Arianee IDs ...');
        return;
      }

      const loading = await this.uIAlertService.loadUpdatable({ message: 'Duplicate process 0/' + this.certNumber });

      let duplicateValueArray = [];
      if (this.duplicateValue) {
        duplicateValueArray = this.duplicateValue.trim().split(/\r\n|\r|\n/);
      }

      let ArianeeIdsArray = [];
      if (this.arianeeIds) {
        ArianeeIdsArray = this.arianeeIds.trim().split(/\r\n|\r|\n/);
      }

      let recoverKeyArray = [];
      if (this.recoverKey) {
        recoverKeyArray = this.recoverKey.trim().split(/\r\n|\r|\n/);
      }

      const itemTmp = JSON.stringify(this.templateForWysiwyg);
      loading.message = 'Step 2/3 : Preparing transactions';

      const creatingCertificate = range(this.certNumber)
        .map((index) => {
          const certificate = itemTmp.replace('%ID%', duplicateValueArray[index]);
          const content = JSON.parse(certificate);
          return {
            certificateId: ArianeeIdsArray[index],
            content,
            sameRequestOwnershipPassphrase: this.sameRequestOwnershipPassphrase,
            passphrase: recoverKeyArray[index]
          };
        })
        .map(async (certificate) => {
          const cert = await this.certificateCoreService.createCertificate(certificate);
          await this.updateProperties(cert.certificateId);
          return cert;
        });

      loading.message = 'Step 3/3 : Sending transaction';

      await Promise.all(creatingCertificate);

      loading.message = 'Finalizing';

      loading.dismiss();

      this.successCreatingCertificate(`Your certificate has been duplicated ${this.certNumber} times sucessfully`);
    }

    private createCertificate = async (certificateDocument) => {
      if (!this.tokenIdInput) {
        this.tokenId = await this.certificateCoreService.getFreeTokenId();
      } else {
        this.tokenId = this.tokenIdInput;
        const canCreate = await this.certificateCoreService.canCreateCertificateWithCertificateId(this.tokenId);
        if (!canCreate) {
          this.uIAlertService.error(`This Arianee ID ${this.tokenId} is not available.`);
          return;
        }
      }

      try {
        const result = await this.certificateCoreService.createCertificate({
          certificateId: this.tokenId,
          content: certificateDocument,
          sameRequestOwnershipPassphrase: this.sameRequestOwnershipPassphrase
        });

        this.successCreatingCertificate(`Your certificate (${this.tokenId}) has been created`);

        return result;
      } catch (err) {
        console.error(err);
        this.uIAlertService.error('Something went wrong');
      }
    };

    private successCreatingCertificate (message: string) {
      return this.uIAlertService.alert({
        message: message,
        cssClass: 'e2e_certif_create_success',
        buttons: [
          {
            text: 'See my certificates',
            cssClass: 'e2e_see_certificiates',
            handler: () => {
              this.userSessionService.preventUserFromLeavingPage = true;
              this.router.navigate(['certificate/list']);
            }
          },
          {
            text: 'Later',
            cssClass: 'e2e_later',
            role: 'cancel'
          }
        ]
      });
    }
}
