import * as tslib_1 from "tslib";
import { LoadingController } from '@ionic/angular'; // PopoverController
import { Utils } from '../../services/utils/utils';
import { AuthService } from '../../services/auth/auth';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { MessageService } from '../../services/messageService/messageService';
import { EventLoggerService } from '../../services/event-logger/event-logger-service';
import { AutoMessageService } from '../../services/autoMessage-service/automessage.service';
import { filter, tap } from 'rxjs/operators';
/*
*
*  AutoMessages
* {'companyName':'Arianee',
*  'companyLogo':'https://demo.arianee.org/demo/logo-arianee.png',
*  'companyWebsite':'https://arianee.org'}
*
*/
var AutoMessages = /** @class */ (function () {
    function AutoMessages(utils, authService, loadingCtrl, uiAlert, messageService, eventLogger, autoMessageService) {
        this.utils = utils;
        this.authService = authService;
        this.loadingCtrl = loadingCtrl;
        this.uiAlert = uiAlert;
        this.messageService = messageService;
        this.eventLogger = eventLogger;
        this.autoMessageService = autoMessageService;
        this.subscribe = {};
        this.message = {
            enable: false,
            content: ''
        };
    }
    AutoMessages.prototype.ionViewDidEnter = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var loader, _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.eventLogger.logScreen('autoMessage-page');
                        return [4 /*yield*/, this.uiAlert.load()];
                    case 1:
                        loader = _b.sent();
                        _a = this;
                        return [4 /*yield*/, this.messageService.getSchema()];
                    case 2:
                        _a.schema = _b.sent();
                        return [4 /*yield*/, this.autoMessageService.getAutoMessage('eachTransfer')
                                .pipe(filter(function (autoMessageDoc) { return !!autoMessageDoc; }), tap(function (autoMessage) {
                                _this.message = autoMessage;
                                _this.message.content = JSON.parse(_this.message.content);
                                _this.docId = autoMessage._id;
                            }))
                                .subscribe()];
                    case 3:
                        _b.sent();
                        loader.dismiss();
                        return [2 /*return*/];
                }
            });
        });
    };
    AutoMessages.prototype.updateAutoMessages = function (messageToUpdate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var loader, message, _id;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.uiAlert.load()];
                    case 1:
                        loader = _a.sent();
                        message = tslib_1.__assign({}, this.message, { content: JSON.stringify(messageToUpdate), type: 'eachTransfer' });
                        if (!this.docId) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.autoMessageService.updateAutoMessage(this.docId, message).toPromise()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.autoMessageService.createAutoMessage(message).toPromise()];
                    case 4:
                        _id = (_a.sent())._id;
                        this.docId = _id;
                        _a.label = 5;
                    case 5:
                        this.utils.success('Update successful');
                        loader.dismiss();
                        return [2 /*return*/];
                }
            });
        });
    };
    return AutoMessages;
}());
export { AutoMessages };
