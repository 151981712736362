import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TruncateModule } from '@yellowspot/ng-truncate';
import { MomentModule } from 'ngx-moment';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireModule } from '@angular/fire';
import { config } from '../config';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Login } from './pages/login/login';

import { Transaction } from './pages/transaction/transaction';
import { StripHtmlPipe } from './services/utils/stripHtmlPipe';
import { JsonBuilder } from './pages/jsonBuilder/jsonBuilder';
import { UploadAssets } from './pages/uploadAssets/uploadAssets';
import { SetIdentity } from './pages/setIdentity/setIdentity';
import { Reporting } from './pages/reporting/reporting';
import { Settings } from './pages/settings/settings';
import { Admin } from './pages/admin/admin';
import { UsersManager } from './pages/userManager/usersManager';
import { SchemaFormModule, WidgetRegistry } from 'ngx-schema-form';
import { MyWidgetRegistry } from './components/myWidgetRegistry';
import { CertificateViewer } from './components/certificateViewer/certificateViewer';
import { JsonCopy } from './components/jsonCopy/jsonCopy';
import { Item } from './pages/item/item';
import { AsyncTransfer } from './pages/asyncTransfer/asyncTransfer';
import { AutoMessages } from './pages/autoMessages/autoMessages';
import { EventBuilder } from './pages/eventBuilder/eventBuilder';
import { ResetPassword } from './pages/reset-password/resetPassword';
import { StaticAssetsWidget } from './components/static-assets-widget/staticAssetsWidget';
import { SafePipe } from './components/safePipe';
import { ChartsModule } from 'ng2-charts-x';
import { JazziconComponent } from './components/jazzicon';
import { LoggedComponent } from './components/loggedComponent/logged.component';
import { ReserveTokenComponent } from './components/reserve-token/reserve-token.component';
import { ArianeeLoaderComponent } from './components/ArianeeLoader/arianeeLoaderComponent';
import { SentryErrorHandlerService } from './services/sentry-error-handler-service/sentry-error-handler.service';
import { MessageBuilder } from './pages/MessageBuilder/message-builder.page';
import { SchemaBuilderComponent } from './components/schema-builder.ts/schema-builder.component';

import { ApiModule } from './apiServices/BDHApi/api.module';
import { Configuration } from './apiServices/BDHApi';
import { httpOrHttps } from './helpers/httpOrHttps.helper';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { WalletComponent } from './components/wallet-component/wallet';
import { WalletPage } from './pages/certificate-page/wallet.page';
import { ParentWidget } from './components/parent-widget/parent-widget';
import { WalletWidgetComponent } from './components/parent-widget/wallet-widget-component/wallet';

declare var window: any;

export function apiModuleRootFactory () {
  return new Configuration({
    basePath: httpOrHttps(config.backendDomain),
    accessToken: () => window.accessToken
  });
}
export function createTranslateLoader (http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    ParentWidget,
    Login,
    WalletPage,
    Transaction,
    UploadAssets,
    SetIdentity,
    Reporting,
    Settings,
    Admin,
    UsersManager,
    CertificateViewer,
    JsonCopy,
    Item,
    AsyncTransfer,
    Settings,
    JsonBuilder,
    Reporting,
    AutoMessages,
    UsersManager,
    Admin,
    EventBuilder,
    ResetPassword,
    StaticAssetsWidget,
    CertificateViewer,
    SafePipe,
    StripHtmlPipe,
    JazziconComponent,
    LoggedComponent,
    ReserveTokenComponent,
    ArianeeLoaderComponent,
    MessageBuilder,
    SchemaBuilderComponent,
    WalletComponent,
    WalletWidgetComponent
  ],
  entryComponents: [StaticAssetsWidget, ArianeeLoaderComponent, ParentWidget, WalletWidgetComponent, WalletComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientModule,
    FormsModule,
    MomentModule,
    SchemaFormModule.forRoot(),
    ChartsModule,
    TruncateModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AngularFireModule.initializeApp({
      apiKey: config.apiKey,
      authDomain: config.authDomain,
      projectId: config.projectId
    }),
    ApiModule
      .forRoot(apiModuleRootFactory)

  ],
  providers: [
    HttpClient,
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: WidgetRegistry, useClass: MyWidgetRegistry },
    { provide: ErrorHandler, useClass: SentryErrorHandlerService }

  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
