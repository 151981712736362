import { map } from 'rxjs/operators';
import { EndpointsService } from '../../apiServices/endpointsService/endpointsService';
import { FirebaseHttpClient } from '../firebaseHttpClient/firebaseHttpClient';
import * as i0 from "@angular/core";
import * as i1 from "../../apiServices/endpointsService/endpointsService";
import * as i2 from "../firebaseHttpClient/firebaseHttpClient";
var AutoMessageService = /** @class */ (function () {
    function AutoMessageService(endpointService, firebaseHttpClient) {
        this.endpointService = endpointService;
        this.firebaseHttpClient = firebaseHttpClient;
    }
    AutoMessageService.prototype.getAutoMessage = function (type) {
        return this.firebaseHttpClient.get(this.endpointService.endpoints.autoMessage.list, {
            params: {
                q: JSON.stringify({
                    type: type
                })
            }
        }).pipe(map(function (d) { return d[0]; }));
    };
    AutoMessageService.prototype.createAutoMessage = function (autoMessage) {
        return this.firebaseHttpClient.post(this.endpointService.endpoints.autoMessage.create, autoMessage);
    };
    AutoMessageService.prototype.updateAutoMessage = function (messageId, autoMessage) {
        var url = this.endpointService.endpoints.autoMessage.update.replace(':id', messageId);
        return this.firebaseHttpClient.put(url, autoMessage);
    };
    AutoMessageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AutoMessageService_Factory() { return new AutoMessageService(i0.ɵɵinject(i1.EndpointsService), i0.ɵɵinject(i2.FirebaseHttpClient)); }, token: AutoMessageService, providedIn: "root" });
    return AutoMessageService;
}());
export { AutoMessageService };
