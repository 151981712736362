import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserSessionService } from '../../services/user-session-service/user-session-service';
import { UIAlertService } from '../../services/UI-alert-service/UI-alert-service';
import { ArianeejsAPIService } from '../../apiServices/arianeejsAPIService';

@Injectable({
  providedIn: 'root'
})
export class CreditCheckGuard implements CanActivate {
    private userJustArrived = true;

    private minimumCredit = 10;

    constructor (private userSessionService: UserSessionService,
                private uiSessionAlter: UIAlertService,
                private arianeeJsServerService: ArianeejsAPIService,
                private router:Router
    ) {
    }

    public canActivate (
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this.userJustArrived) {
        this.checkCredits();
        this.userJustArrived = false;
      }
      return true;
    }

    private async userCredits () {
      const [certificate, event, message] = await Promise.all([
        this.arianeeJsServerService.balanceOfCredit('certificate').toPromise()
      ]);

      return {
        certificate,
        event,
        message
      };
    }

    private async checkCredits () {
      const credits = await this.userCredits();
      const lowCredit = Object.keys(credits)
        .map(key => credits[key])
        .filter(value => value < this.minimumCredit);

      if (lowCredit.length > 0) {
        this.uiSessionAlter.alert({
          message: 'Your credit balance is low. Please visit your "Manage Account" section.',
          buttons: [
            {
              text: 'Buy credits',
              handler: () => {
                this.router.navigate(['settings']);
              }
            },
            {
              text: 'Later',
              role: 'cancel'
            }
          ]
        });
      }
    }
}
