import { Injectable } from '@angular/core';
import { config } from '../../../config';
import { endpoints } from '../../../environments/enpoints';
import { httpOrHttps } from '../../helpers/httpOrHttps.helper';

@Injectable({ providedIn: 'root' })
export class EndpointsService {
    public endpoints: typeof endpoints;

    constructor () {
      endpoints.baseRoot = EndpointsService.getBaseRoot(config.backendDomain);

      this.endpoints = EndpointsService.prepare(endpoints) as any;
    }

    public static getBaseRoot (baseRoute: string) {
      return httpOrHttps(baseRoute);
    }

    public static prepare = (endpointsConfiguration) => {
      return Object.keys(endpointsConfiguration)
        .filter(d => d !== 'baseRoot')
        .reduce((acc, currEndpoints) => {
          acc[currEndpoints] = {};
          Object.keys(endpointsConfiguration[currEndpoints])
            .filter(d => d !== 'baseRoot')
            .forEach(endpointName => {
              acc[currEndpoints][endpointName] = `${endpointsConfiguration.baseRoot}/${endpointsConfiguration[currEndpoints].baseRoot}/${endpointsConfiguration[currEndpoints][endpointName]}`;
            });

          return acc;
        }, {});
    }
}
