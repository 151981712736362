import { cloneDeep } from 'lodash';

function clearEmpties (obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      clearEmpties(obj[key]);
    }

    const shouldDelete =
            obj[key] === null ||
            obj[key] === undefined ||
            obj[key].length === 0 ||
            (Object.keys(obj[key]).length === 0 && typeof obj[key] !== 'number');

    if (shouldDelete) {
      delete obj[key]; // The object had no properties, so delete that property
    }
  }
}

export const cleanObject = (obj) => {
  const clonedObject = cloneDeep(obj);

  clearEmpties(clonedObject);

  return clonedObject;
};
