<ion-header>
    <div color="light" class="header">
        <div>
            <ion-menu-button></ion-menu-button>
            <ion-title>{{ 'pages.reporting.title' | translate }}</ion-title>
        </div>
    </div>
</ion-header>

<ion-content >
    <ion-card>
      <ion-card-header><b>General info</b></ion-card-header>


        <ion-card-content>
            <div class="card_content">
                <div class="card_left_content">
                    <ion-card-header>Certificates : <b>{{totalCertificate}}</b></ion-card-header>
                    <ion-card-header>Reserved certificates : <b>{{reservedCertificate}}</b></ion-card-header>
                    <ion-card-header>Destroyed Certificates : <b>{{destroyedCertificate}}</b></ion-card-header>

                    <ion-card-header>Transfered at least once : <b>{{transferedAtLeastOnce}}</b>
                        ({{totalFirstTransferRatio}}%)
                    </ion-card-header>
                    <ion-card-header>Optin ratio : <b>{{totalOptin}}</b>({{totalOptinRatio}}%)</ion-card-header>
                </div>
                <div class="card_right_content">
                    <ion-button color="primary" class="btn btn-primary btn-block"
                                (click)="extractAsCsvFile('csv')">
                        Download as csv file
                    </ion-button>
                </div>
            </div>
        </ion-card-content>
    </ion-card>

    <ion-card>


        <ion-card-header><b>Certificates</b></ion-card-header>

        <ion-card-content>
            <div>
              <div style="display: block">
                <canvas *ngIf="barChartData" baseChart
                        [datasets]="barChartData"
                        [options]="barChartOptions"
                        [legend]="barChartLegend"
                        [chartType]="barChartType">
                </canvas>
              </div>
            </div>
        </ion-card-content>
    </ion-card>

    <ion-card>
        <ion-card-header><b>First Transfer</b></ion-card-header>

        <ion-card-content>
            <div>
              <div style="display: block">
                <canvas *ngIf="transferChartData" baseChart
                        [datasets]="transferChartData"
                        [options]="barChartOptions"
                        [legend]="barChartLegend"
                        [chartType]="barChartType">
                </canvas>
              </div>
            </div>
        </ion-card-content>
    </ion-card>

</ion-content>
